import {useRootStore} from '../../../../lib/hooks';
import {Resource} from '../../../../stores/domainObjects/Resource';

type CreateAuthorityParams = {
  associated: boolean; // Assigned as organization authority or not
  service: string;
  fieldMapping: Record<string, string>;
};

type UpdateAuthorityParams = Partial<CreateAuthorityParams>;

export function useAuthority() {
  const {org, newResourceStore: resourceStore} = useRootStore();

  const settings = org.idpSettings || undefined;
  const resource = resourceStore.getResourceByConnectionId(settings?.connectionId);

  const isConfigured = Boolean(settings);
  const isDisabled = Boolean(resource?.connector?.disabled);

  const updateAuthority = async (params: UpdateAuthorityParams) => {
    if (!isConfigured || !resource) {
      return; // Do nothing
    }

    const {associated, service, fieldMapping} = params;

    // Used to keep the format of current settings update but allowing to update specific properties
    const updateParams = {
      idpAssociated: associated !== undefined ? associated : true,
      idpService: service || settings?.service,
      idpFieldMapping: fieldMapping || settings?.fieldMapping,
    };

    await resource.updateConnectionSettings({...updateParams});
  };

  const createAuthority = async (params: CreateAuthorityParams, newAuthorityResource: Resource) => {
    if (isConfigured) {
      return; // Do nothing
    }
    const createParams = {
      idpAssociated: params.associated,
      idpService: params.service,
      idpFieldMapping: params.fieldMapping,
    };

    await newAuthorityResource.updateConnectionSettings({...createParams});
  };

  return {
    settings,
    resource,
    isConfigured,
    isDisabled,
    updateAuthority,
    createAuthority,
  };
}
