import {Button, Checkbox, Tab, Table, Tooltip} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {fuseSearch} from 'src/lib';
import {Loading} from '@trustle/component-library';
import {Search} from '../Search/Search';
import {CSVContent, getColumns} from './helper';

export enum TabAction {
  ADDITIONS = 'new',
  UPDATES = 'updates',
  DELETIONS = 'offboarded',
}

type PropsT = {
  data: _.Dictionary<any>;
  onClose: any;
  onConfirm: any;
  syncInProgress: boolean;
  filename: string;
  csvContent: CSVContent;
};

export const CSVProcessedRows = observer((props: PropsT) => {
  const {onConfirm, onClose, syncInProgress, filename, data, csvContent} = props;

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [query, setQuery] = useState('');
  const [deletionEnabled, setDeletionEnabled] = useState<boolean>(false);

  const {additions = [], updates = [], deletions = []} = data || {};

  const filter = (data: any[]) => {
    return fuseSearch(query, ['email', 'firstName', 'lastName', 'name'], data);
  };

  const candidatesCount = _.size([...additions, ...updates]);
  const tabData = selectedIndex === 0 ? additions : selectedIndex === 1 ? updates : deletions;
  const filteredData = filter(tabData);
  const sortedData = _.orderBy(filteredData, ['ignored', 'email'], ['asc', 'asc']);

  const tabCount = (data: any[]) => {
    return _.isEmpty(query) ? _.size(data) : `${_.size(filter(data))} of ${_.size(data)}`;
  };

  const disableDeletionFor = !deletionEnabled
    ? undefined
    : csvContent === CSVContent.ACCOUNT
    ? {accounts: false}
    : csvContent === CSVContent.RESOURCE_PERMISSION
    ? {resource: false, permissions: false}
    : csvContent === CSVContent.ACCESS_RECORD
    ? {accesess: false}
    : undefined;

  return (
    <div>
      <div>
        <div className="tr-flex tr-mb-4">
          <div className="tr-ml-1 tr-mt-3 body6">
            <strong>{`${candidatesCount} rows `}</strong>
            <span>{'detected on the uploaded file: '}</span>
            <strong>{filename}</strong>
            <span className="tr-mt-3 body6">
              {deletionEnabled && (
                <span className="tr-mt-3 body6">
                  <span className="tr-mx-3">{' >> '}</span>
                  <strong>{_.size(deletions)}</strong>
                  <span>{' rows to be deleted'}</span>
                </span>
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="tr-mt-4">
        <Search placeholder="Search" filterOptions={{}} onChange={(query) => setQuery(query)} />
      </div>

      <div className="tr-mt-4">
        <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
          <Tab.List className="border-bottom">
            <Tab key={TabAction.ADDITIONS}>{`${_.upperFirst(TabAction.ADDITIONS)} (${tabCount(additions)})`}</Tab>
            <Tab key={TabAction.UPDATES}>{`${_.upperFirst(TabAction.UPDATES)} (${tabCount(updates)})`}</Tab>
            <Tab key={TabAction.DELETIONS}>{`${_.upperFirst(TabAction.DELETIONS)} (${
              deletionEnabled ? tabCount(deletions) : 0
            })`}</Tab>
          </Tab.List>
          <Tab.Panels>
            <Table
              overrideKeyField={'name'}
              columns={getColumns(csvContent, selectedIndex, deletionEnabled)}
              data={sortedData}
              tableKey={'procesedrows'}
              striped={false}
              wrapperClasses="rounded-xl border border-black rounded"
              bordered={false}
              rowClasses={(_row: any, _rowIndex: number) =>
                selectedIndex === 2 && !deletionEnabled ? 'tr-bg-gray-100 tr-opacity-60' : ''
              }
            />
          </Tab.Panels>
        </Tab.Group>
        <div>
          <div className="tr-flex tr-flex-row-reverse tr-mt-3 tr-mb-5 tr-pr-8 tr-mr-8">
            <Tooltip
              size="md"
              position="right"
              content={<div>{"Delete existing entities that weren't uploaded in this file"}</div>}
              className="tr-font-normal"
            >
              <div className={`tr-flex align-items-center ${_.isEmpty(deletions) ? 'tr-opacity-60' : ''}`}>
                <Checkbox
                  checked={deletionEnabled}
                  onChange={() => setDeletionEnabled(!deletionEnabled)}
                  disabled={_.isEmpty(deletions)}
                />
                <div
                  className="cursor tr-text-xs strong tr-mx-3"
                  onClick={() => {
                    !_.isEmpty(deletions) ?? setDeletionEnabled(!deletionEnabled);
                  }}
                >
                  {`Delete missing entities (${_.size(deletions)})`}
                </div>
              </div>
            </Tooltip>
          </div>
          <div className="tr-flex tr-flex-row-reverse">
            <Button
              disabled={_.isEmpty([...additions, ...updates]) && !deletionEnabled}
              onClick={() => onConfirm(disableDeletionFor)}
            >
              {syncInProgress && <Loading>Processing...</Loading>}
              {!syncInProgress && (
                <div>{`Confirm (${candidatesCount + (deletionEnabled ? _.size(deletions) : 0)})`}</div>
              )}
            </Button>
            <Button variant="secondary" onClick={() => onClose()}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});
