import React, {useEffect, useState} from 'react';
import {Redirect, Switch, useHistory, useLocation} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Logout from 'src/views/Logout';
import ResetPassword from 'src/views/User/ResetPassword/ResetPassword';
import SetupProfile from 'src/views/User/SetupProfile/SetupProfile';
import SetupComplete from 'src/views/SetupComplete';
import MainView from 'src/views/MainView';
import ManageHome from 'src/views/ManageHome';
import TasksHome from 'src/views/Tasks/TasksHome';
import Profile from 'src/views/User/Profile/Profile';
import AdminBase from './AdminBase';
import ResourceBase from './ResourceBase';
import CreateResource from 'src/views/Resource/Create';
import ConnectorsBase from 'src/connectors/ConnectorsBase';
import DocsBase from 'src/docs/DocsBase';
import OTL from './OTL';
import AppLayout from 'src/components/layouts/AppLayout';
import ResetPasswordForm from 'src/views/User/ResetPassword/ResetPasswordForm';
import Checkout from 'src/views/signup/CheckoutPage';
import axios from 'axios';
import _ from 'lodash';
import {AuthInfoT} from 'src/context';
import {useQueryParams, logger} from 'src/lib';
import {RootStoreProvider} from 'src/storeContext';
import AppToastProvider from 'src/App/AppToastProvider';
import {Confirmation, Loading} from '@trustle/component-library';

export type OrgInfoT = {
  orgName?: string;
  isOauthAllowed?: boolean;
  isPwdAllowed?: boolean;
  found?: boolean;
  baseHostname?: string;
  samlLoginUrl?: string;
};
function Routes({orgInfo}: {orgInfo: OrgInfoT}) {
  const history = useHistory();
  const location = useLocation();
  const [authInfo, setAuthInfo] = useState<AuthInfoT>();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [origSubdomain] = useQueryParams(['origSubdomain']);
  const [changeOrgOpen, setChangeOrgOpen] = useState<boolean>(!!origSubdomain);

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.request?.responseURL?.includes('/api') && error.response.status === 401) {
          goToLogin();
        }
      }
    );

    // Return cleanup function to remove interceptors if necessary
    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  function redirectToNewSubdomain(subdomain: string) {
    const host = window.location.host;
    const hostParts = host.split('.');
    hostParts[0] = subdomain;

    window.location.href = `${window.location.protocol}//${hostParts.join('.')}${window.location.pathname}`;
  }

  async function logout() {
    try {
      await axios.get('/api/auth/logout');
      setAuthInfo(undefined);
      setLoadingData(false);

      if (origSubdomain) {
        redirectToNewSubdomain(origSubdomain);
      } else {
        history.push('/login');
      }
    } catch (err) {
      logger.error("Can't logout - error:", {err});
    }
  }
  useEffect(() => {
    void loadAuth();
  }, []);

  function goToLogin() {
    //Avoid redirection loop
    if (['/login', '/api/auth/oauth/authenticate', orgInfo.samlLoginUrl].includes(location.pathname)) {
      return;
    }

    if (!orgInfo || orgInfo?.isPwdAllowed) {
      history.push('/login', {from: location});
    } else if (orgInfo?.isOauthAllowed) {
      window.location.href = '/api/auth/oauth/authenticate';
    } else if (orgInfo.samlLoginUrl) {
      history.push(orgInfo && orgInfo.samlLoginUrl ? orgInfo.samlLoginUrl : '/login');
    }
  }

  async function loadAuth() {
    try {
      setLoadingData(true);
      const info = await axios.get('/api/auth/info');
      const {loginMethod = 'password', sensitivitySettings, idpSettings, userAgreements, filters} = info.data;
      setAuthInfo({
        ..._.pick(info.data, ['user', 'org', 'featureFlags', 'publishableKeys', 'env']),
        loginMethod,
        sensitivitySettings,
        idpSettings,
        isLoggedIn: true,
        loadAuth,
        reloadAuth: loadAuth,
        logout,
        userAgreements,
        filters,
      });
      setLoadingData(false);
    } catch (err) {
      setLoadingData(false);
      setAuthInfo(undefined);
      goToLogin();
    }
  }

  if (loadingData) {
    return <Loading />;
  }

  return (
    <>
      {origSubdomain && changeOrgOpen && (
        <Confirmation
          cancelButtonLabel="Logout"
          confirmButtonLabel="Cancel"
          onClose={() => {
            void logout();
          }}
          onConfirm={() => {
            setChangeOrgOpen(false);
            history.push(`${location.pathname}?origSubdomain=''`);
          }}
          title="Action required"
        >
          <p>
            You are currently logged in to the <strong>{authInfo!.org.name}</strong> organization, and must first logout
            before logging in to the new organization. Do you wish to
            <strong> Logout</strong>?
          </p>
        </Confirmation>
      )}
      {authInfo?.isLoggedIn ? (
        <StoreComponent authInfo={authInfo} />
      ) : (
        <Redirect to={{pathname: `/login`, state: {from: location}}} />
      )}
    </>
  );
}

export default Routes;

function StoreComponent({authInfo}: {authInfo: AuthInfoT}) {
  return (
    <RootStoreProvider authInfo={authInfo}>
      <AppToastProvider>
        <Switch>
          <PrivateRoute path="/otl" render={() => <OTL />} />
          <PrivateRoute path="/docs" render={() => <DocsBase />} />
          <PrivateRoute path="/logout" render={() => <Logout />} />
          <PrivateRoute path="/user/reset_password" render={() => <ResetPassword />} />
          <PrivateRoute path="/user/create_profile" render={() => <SetupProfile />} />
          <PrivateRoute path="/setup/profile" render={() => <SetupProfile />} />
          <PrivateRoute path="/setup/complete" render={() => <SetupComplete />} />
          <PrivateRoute
            path="/payments/:step?"
            render={() => (
              <AppLayout>
                <Checkout />
              </AppLayout>
            )}
          />
          <PrivateRoute
            path="/user/profile/reset_password"
            render={() => (
              <AppLayout>
                <div className="container my-4">
                  <ResetPasswordForm />
                </div>
              </AppLayout>
            )}
          />
          <Redirect from="/users/:id/" to="/users/:id/current" exact />
          <PrivateRoute
            path="/users/:id/:activeTab"
            render={() => (
              <AppLayout>
                <Profile />
              </AppLayout>
            )}
          />
          <PrivateRoute
            path="/connect/:type?"
            render={() => (
              <AppLayout>
                <ConnectorsBase />
              </AppLayout>
            )}
          />
          <PrivateRoute
            path={['/resource/manage/:rid/:activeMode?', '/resource/access/:rid', '/resource/:rid']}
            render={() => (
              <AppLayout>
                <ResourceBase />
              </AppLayout>
            )}
          />
          <PrivateRoute
            path="/tasks/:tab?"
            render={() => (
              <AppLayout>
                <TasksHome />
              </AppLayout>
            )}
          />
          <PrivateRoute
            path="/access/:activeTab"
            render={() => (
              <AppLayout>
                <MainView />
              </AppLayout>
            )}
          />
          <Redirect from="/manage" to="/manage/active" exact />
          <PrivateRoute
            path="/manage/:tab"
            exact
            render={() => (
              <AppLayout>
                <ManageHome />
              </AppLayout>
            )}
          />
          <PrivateRoute
            path="/admin/:tab?"
            render={() => (
              <AppLayout>
                <AdminBase />
              </AppLayout>
            )}
          />
          <PrivateRoute
            path="/resources/create"
            render={() => (
              <AppLayout>
                <CreateResource />
              </AppLayout>
            )}
          />
          <Redirect exact from="/home" to="/access/available" />
        </Switch>
      </AppToastProvider>
    </RootStoreProvider>
  );
}
