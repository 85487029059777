import {Alert, Icon} from '@trustle/component-library';
import {observer} from 'mobx-react';
import React from 'react';
import {Link} from 'react-router-dom';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ImportStatus, MessageTypeE, getConnectionServiceName} from 'src/types';
import ErrorHeader from './ErrorHeader';

const ConnectorErrorHeader = observer(({resource}: {resource: Resource}) => {
  const {connector} = resource;

  if (!connector) {
    return null;
  }

  if (resource.pendingSetup) {
    return (
      <Alert
        title="Pending Setup"
        colorVariant={MessageTypeE.WARNING}
        icon={<Icon type="unlinkedUser" className="text-red-800" />}
      >
        <div>
          {`Setup for ${getConnectionServiceName(connector.service)} is incomplete. Please complete connection setup `}
          <Link to={`/connect/reentry/${resource.connectionId}`}>here</Link>.
        </div>
      </Alert>
    );
  }

  if (!resource.userIsOwner) {
    return (
      <>
        <Alert title="Read-only System" icon={<Icon type="locked" title="Read-only System" />}>
          <div>You do not have the ability to make changes to this system.</div>
        </Alert>
        {resource.importStatus === ImportStatus.FAILED && <ErrorHeader resource={resource} />}
      </>
    );
  }

  if (resource.importStatus === ImportStatus.FAILED) {
    return <ErrorHeader resource={resource} />;
  }

  return null;
});

export default ConnectorErrorHeader;
