import React from 'react';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';
import {AccountAccessType, AccountStatus, UserType} from 'src/types';
import {CSVContent, entityColumnsDef, UploadedEntityType} from 'src/components/MergeController/helper';
import _ from 'lodash';
import {Table} from '@trustle/component-library';

function GenericContentGuide() {
  const allColumns = entityColumnsDef();
  const tabs: TabsDocsT = [
    {id: 'generic-overview', label: 'Overview', description: overview(), active: true},
    {id: 'generic-examples', label: 'Examples', description: examples(), active: true},
  ];

  function overview(): JSX.Element {
    return (
      <div>
        <div>
          <p className="tr-w-75">
            The data related to a connector in Trustle can be uploaded through a CSV file.
            <p>
              The following list of columns is a specification of the required format for the CSV. These definitions
              apply to all the available entity types:
            </p>
          </p>
          <div className="mt-1 ml-4 mb-4 tr-w-75">
            <li className="pb-2">
              <strong>ACCOUNT:</strong> represents a user in the system. Each user can be associated with several
              resources and permissions.
            </li>
            <li className="pb-2">
              <strong>RESOURCE:</strong> entity that allows to logically group permissions.
            </li>
            <li className="pb-2">
              <strong>PERMISSION:</strong> represents the definition of an entitlement or a set of entitlements and can
              be associated with a resource or a user. Examples of permissions are: policies, roles, group of users,
              etc. If the permission represents a group, all of its members will inherit the permissions associated with
              it.
            </li>
          </div>
        </div>
        <div className={'border-bottom pt-3 mb-2'}>
          <p className={'mb-1'}>
            <strong>Column Definitions</strong>
          </p>
        </div>
        <div className={'pb-4'}>
          {allColumns.map((column: any) => (
            <div className={'mb-1'} key={column.key}>
              <b className={'text-capitalize pr-2'}>{`${column.key}:`}</b> {column.description}
              <br />
              <div className={'text-muted'}>
                {column.conditions &&
                  `${
                    _.isArray(column.conditions) ? 'One of: '.concat(column.conditions.join(', ')) : column.conditions
                  }`}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function examples(): JSX.Element {
    return (
      <div>
        <p className="pb-3">
          The following examples are based on an AWS account with 3 users, 3 resources, 4 permissions and 3 accesses.
          <p className="pt-2">
            The result of importing this scenario into Trustle will be:
            <div className="pl-4 tr-w-75">
              <li>
                Three accounts will be imported: two personal accounts, <strong>administrator</strong> and
                <strong> username@domain.com</strong>, and one service account, <strong>trustle_app</strong>.
              </li>
              <li>
                The <strong>AWS Groups</strong> resource will contain all the user groups defined, which are
                <strong> development</strong> and <strong>production</strong>.
              </li>
              <li>
                The <strong>AWS Managed Policies</strong> resource will contain 3 policies:
                <strong> AdministratorAccess</strong>, <strong> AmazonS3ReadOnlyAccess</strong> and
                <strong> AmazonS3FullAccess</strong>.
              </li>
              <li>
                The <strong>AWS Inline Policies</strong> resource will contain just 1 policy:
                <strong> list_access_reports</strong>.
              </li>
              <li>
                Since the <strong>admin</strong> role permission doesn't belong to any specific resource, it will be
                displayed in the top level as a System Permission.
              </li>
              <li>
                The <strong>administrator</strong> account will have the <strong>admin</strong> role associated, which
                is also related to the <strong>AdministratorAccess</strong> and
                <strong> AmazonS3FullAccess</strong> policies.
              </li>
              <li>
                The <strong>username@domain.com</strong> account will be a member of the<strong> development </strong>
                group and will have permission to <strong>list_access_reports</strong>. Also, the user will inherit the
                policy <strong>AmazonS3FullAccess</strong> (through the membership to the <strong>development </strong>
                group).
              </li>
              <li>
                The <strong>trustle_app</strong> service account will have permission to perform actions allowed by
                <strong> AmazonS3ReadOnlyAccess</strong> policy only.
              </li>
            </div>
          </p>
        </p>

        <ExampleTable type={CSVContent.ACCOUNT} />
        <ExampleTable type={CSVContent.RESOURCE_PERMISSION} />
        <ExampleTable type={CSVContent.ACCESS_RECORD} />
      </div>
    );
  }

  return (
    <>
      <DocSection tabs={tabs} title="Guide: How to create connector's data through CSV file upload" />
    </>
  );
}

const getColumns = (content: CSVContent) => {
  const isAccount = content === CSVContent.ACCOUNT;
  const isAccess = content === CSVContent.ACCESS_RECORD;
  const isResOrPerm = content === CSVContent.RESOURCE_PERMISSION;

  return [
    {dataField: 'id', text: 'id', id: true},
    {dataField: 'name', text: 'name'},
    {dataField: 'type', text: 'type'},
    {dataField: 'subtype', text: 'subtype', hidden: isAccess},
    {dataField: 'firstName', text: 'firstName', hidden: !isAccount},
    {dataField: 'lastName', text: 'lastName', hidden: !isAccount},
    {dataField: 'email', text: 'email', hidden: !isAccount},
    {dataField: 'userType', text: 'userType', hidden: !isAccount},
    {dataField: 'lastLogin', text: 'lastLogin', hidden: !isAccount},
    {dataField: 'status', text: 'status', hidden: !isAccount},
    {dataField: 'createdDate', text: 'createdDate', hidden: !isAccount},
    {dataField: 'deletedDate', text: 'deletedDate', hidden: !isAccount},
    {dataField: 'resource', text: 'resource', hidden: !isResOrPerm},
    {dataField: 'permissions', text: 'permissions', hidden: !isAccess},
    {
      dataField: 'metadata',
      text: 'metadata',
      hidden: isAccess,
      formatter: (cell: any) => {
        return (
          <pre>
            <code lang="json">{cell}</code>
          </pre>
        );
      },
    },
  ];
};

const getSampleData = (content: CSVContent): any[] => {
  switch (content) {
    case CSVContent.ACCOUNT:
      return [
        {
          type: UploadedEntityType.ACCOUNT,
          id: 'ACCOUNT-001',
          name: 'administrator',
          description: 'This is an example',
          createdDate: '2021-11-09 04:41:27 PM',
          firstName: 'administrator',
          lastName: 'user',
          email: 'administrator@trustle.com',
          userType: UserType.employee,
          status: AccountStatus.ACTIVE,
          lastLogin: '2024-03-09 01:31:28 PM',
          metadata: '{}',
        },
        {
          type: UploadedEntityType.ACCOUNT,
          id: 'ACCOUNT-002',
          name: 'username@domain.com',
          description: 'This is an example',
          email: 'username@trustle.com',
          userType: UserType.contractor,
          subtype: AccountAccessType.PERSONAL,
          status: AccountStatus.SUSPENDED,
          deletedDate: '2021-11-09 04:41:27 PM',
          lastLogin: '2024-02-01 01:31:28 PM',
          metadata: '{}',
        },
        {
          type: UploadedEntityType.ACCOUNT,
          id: 'ACCOUNT-003',
          name: 'trustle_app',
          description: 'This is an example',
          userType: UserType.system,
          subtype: AccountAccessType.SERVICE,
          status: AccountStatus.BLOCKED,
          lastLogin: '2024-03-19 01:31:28 PM',
          metadata: JSON.stringify({
            displayName: 'Trustle App',
          }),
        },
      ];

    case CSVContent.RESOURCE_PERMISSION:
      return [
        {
          id: 'RES_001',
          name: 'AWS Groups',
          type: UploadedEntityType.RESOURCE,
          description: 'This is an example',
        },
        {
          id: 'RES_002',
          name: 'AWS Managed Policies',
          type: UploadedEntityType.RESOURCE,
          description: 'This is an example',
        },
        {
          id: 'RES_003',
          name: 'AWS Inline Policies',
          type: UploadedEntityType.RESOURCE,
          description: 'This is an example',
        },
        {
          id: 'PERM_001',
          name: 'AmazonS3ReadOnlyAccess',
          type: UploadedEntityType.PERMISSION,
          subtype: 'policy',
          description: 'This is an example',
          resource: 'AWS Managed Policies',
          metadata: JSON.stringify({
            Version: '2012-10-17',
            Statement: [
              {
                Effect: 'Allow',
                Action: ['s3:Get*', 's3:List*', 's3-object-lambda:Get*', 's3-object-lambda:List*'],
                Resource: '*',
              },
            ],
          }),
        },
        {
          id: 'PERM_002',
          name: 'development',
          type: UploadedEntityType.PERMISSION,
          subtype: 'group',
          description: 'This is an example',
          resource: 'AWS Groups',
          metadata: JSON.stringify({owner: 'administrator'}),
        },
        {
          id: 'PERM_003',
          name: 'list_access_reports',
          type: UploadedEntityType.PERMISSION,
          subtype: 'policy',
          description: 'This is an example',
          resource: 'AWS Inline Policies',
          metadata: JSON.stringify({
            Version: '2012-10-17',
            Statement: [
              {
                Effect: 'Allow',
                Action: [
                  'iam:GenerateServiceLastAccessedDetails',
                  'iam:GetServiceLastAccessedDetails',
                  'iam:GetServiceLastAccessedDetailsWithEntities',
                ],
                Resource: '*',
              },
            ],
          }),
        },
        {
          id: 'PERM_004',
          name: 'admin',
          type: UploadedEntityType.PERMISSION,
          subtype: 'role',
          description: 'This is an example',
          resource: undefined,
          metadata: JSON.stringify({'Role ARN': 'arn:aws:iam::account:role/admin'}),
        },
        {
          id: 'PERM_005',
          name: 'AdministratorAccess',
          type: UploadedEntityType.PERMISSION,
          subtype: 'policy',
          description: 'This is an example',
          resource: 'AWS Managed Policies',
          metadata: JSON.stringify({
            Version: '2012-10-17',
            Statement: [
              {
                Effect: 'Allow',
                Action: ['*'],
                Resource: '*',
              },
            ],
          }),
        },
        {
          id: 'PERM_006',
          name: 'AmazonS3FullAccess',
          type: UploadedEntityType.PERMISSION,
          subtype: 'policy',
          description: 'This is an example',
          resource: 'AWS Managed Policies',
          metadata: JSON.stringify({
            Version: '2012-10-17',
            Statement: [
              {
                Effect: 'Allow',
                Action: ['s3:*', 's3-object-lambda:*'],
                Resource: '*',
              },
            ],
          }),
        },
        {
          id: 'PERM_007',
          name: 'production',
          type: UploadedEntityType.PERMISSION,
          subtype: 'group',
          description: 'This is an example',
          resource: 'AWS Groups',
          metadata: JSON.stringify({owner: 'administrator'}),
        },
      ];

    case CSVContent.ACCESS_RECORD:
      return [
        {
          id: 'ACCOUNT_001',
          name: 'administrator',
          type: UploadedEntityType.ACCOUNT,
          permissions: 'PERM_004',
        },
        {
          id: 'ACCOUNT_002',
          name: 'username@domain.com',
          type: UploadedEntityType.ACCOUNT,
          permissions: 'PERM_002,PERM_003',
        },
        {
          id: 'ACCOUNT_003',
          name: 'trustle_app',
          type: UploadedEntityType.ACCOUNT,
          permissions: 'PERM_001',
        },
      ];

    default:
      return [];
  }
};

function ExampleTable(props: {type: CSVContent}): JSX.Element {
  const {type} = props;
  const [mandatory] = _.partition(entityColumnsDef(type), 'isRequired');
  const columns = getColumns(type);
  const data = getSampleData(type);
  const link = `/api/connect/generic/download_example?content=${type}`;

  return (
    <div className="mb-5">
      <div className={'border-bottom tr-flex tr-justify-between my-2'}>
        <p className={'mb-1'}>
          <strong className={'text-capitalize'}>{`Entity type: ${type}`}</strong>
        </p>
        <a href={link} className="link" download>
          {'Download example'}
        </a>
      </div>
      <div>{`Required columns: ${_.toString(_.map(mandatory, 'key'))}`}</div>

      <Table data={data} columns={columns} tableKey={'ExampleTable'} />
    </div>
  );
}

export default GenericContentGuide;
