import React from 'react';
import {Icon} from '@trustle/component-library';
import {resolveFillColor} from '../utils';
import {getLicenseName} from '../../../constants';

type Props = {
  series: string[];
};
export function CustomLegend({series}: Props) {
  return (
    <div className="tr-flex tr-gap-4">
      <div className="tr-flex tr-gap-1 tr-items-center">
        <Icon type="settings" size="sm" color="#FFDFB2" />
        <strong className="tr-text-[#64748B]">Exposure</strong>
      </div>
      {series.map((name) => (
        <div key={name} className="tr-flex tr-gap-1 tr-items-center">
          <Icon type="settings" size="sm" color={resolveFillColor(name)} />
          <strong className="tr-text-[#64748B]">{getLicenseName(name)}</strong>
        </div>
      ))}
    </div>
  );
}
