import React from 'react';
import {formatDateFromNow, formatFullDate, formatUserName} from 'src/lib';
import {ConnectionServiceE, UserT} from 'src/types';
import _ from 'lodash';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Link} from 'react-router-dom';
import {Account} from 'src/stores/domainObjects/Account';
import ShowAuthorizedAccount from 'src/components/design/tables/common/ShowAuthorizedAccount';
import {AccountTabTypeE} from './ResourceAccounts';
import {Table, Tooltip} from '@trustle/component-library';

type AccountDetailModalProps = {
  userAccount: Account;
  resource: Resource;
};

function AccountDetail(props: AccountDetailModalProps) {
  const {userAccount, resource} = props;

  const showLastLogin = (resource: Resource, userAccount: Account): React.ReactElement => {
    if (resource.type === ConnectionServiceE.GITHUB) {
      return <>{showDates(userAccount.lastAccountActivity, '-', true)}</>;
    }
    const lastLogin = userAccount.lastLogin;
    if (_.isUndefined(lastLogin)) {
      return <span>{resource.isUsageLastLoginAvailable ? 'Never' : 'Unavailable'}</span>;
    }
    return <>{showDates(lastLogin, '-', true)}</>;
  };

  const showDates = (
    date: string | undefined | null,
    emptyDateString: string = '-',
    inline: boolean = false
  ): React.ReactElement | string => {
    if (!date) {
      return emptyDateString;
    }
    if (!inline) {
      return (
        <Tooltip content={formatFullDate(date)} position="bottom">
          {formatDateFromNow(date)}
        </Tooltip>
      );
    }
    return `${formatDateFromNow(date)} (${formatFullDate(date)})`;
  };

  const accountData = () => {
    const assignedBy: UserT | undefined = !resource.connectionId ? userAccount.createdByUser : userAccount.editedByUser;
    const manager = assignedBy ? {id: assignedBy?.id, displayName: formatUserName(assignedBy)} : undefined;
    const user = userAccount.forUser ? (
      <Tooltip content={formatUserName(userAccount.forUser)} position="bottom">
        {userAccount.forUser?.email}
      </Tooltip>
    ) : (
      _.startCase(AccountTabTypeE.UNASSIGNED)
    );

    const rows: {
      key: string;
      value?: string | JSX.Element;
      by?: any;
    }[] = [
      {key: 'User', value: user},
      {
        key: 'Account',
        value: (
          <div style={{maxWidth: '200px'}} className="text-truncate inline-flex-align tr-items-center">
            <span>{userAccount.account}</span>
            <span className="ml-1">
              <ShowAuthorizedAccount account={userAccount} iconSize="md" />
            </span>
          </div>
        ),
      },
      {key: 'Type', value: userAccount.accountType},
      {
        key: 'Resource',
        value: (
          <Tooltip content={resource.description} position="bottom" size="lg">
            <div>{resource.name}</div>
          </Tooltip>
        ),
      },
      {
        key: 'Status',
        value: (
          <Tooltip content="Account Status on remote system" position="bottom">
            {_.startCase(userAccount.status)}
          </Tooltip>
        ),
      },
    ];

    if (resource.type === ConnectionServiceE.AWS) {
      rows.push(
        {
          key: 'Last activity',
          value: showDates(userAccount.lastAccountActivity, 'Never', true),
        },
        {
          key: 'Console Last sign-in',
          value: showDates(userAccount.lastLogin, 'Never', true),
        },
        {
          key: 'Last programmatic access',
          value: showDates(userAccount.lastProgrammaticAccess, 'Never', true),
        }
      );
    } else {
      rows.push({
        key: resource.type === ConnectionServiceE.GITHUB ? 'Last Activity' : 'Last Login',
        value: showLastLogin(resource, userAccount),
      });
    }

    rows.push(
      {key: 'Imported', value: showDates(userAccount.createdAt, '-', true)},
      {key: 'Linked', value: showDates(userAccount.editedAt, '-', true), by: manager}
    );

    return rows;
  };

  return (
    <Table
      data={accountData()}
      overrideKeyField="key"
      tableKey={'accountData'}
      columns={[
        {
          dataField: 'key',
          text: 'Account info',
          headerClasses: 'text-left',
          classes: 'text-left pl-5',
          formatter: (_cell: any, row: any) => <strong className="text-left">{row.key}</strong>,
        },
        {dataField: 'value', text: '', classes: 'text-left', style: {width: '30%'}},
        {
          dataField: 'by',
          text: '',
          classes: 'text-left',
          formatter: (manager: {id: string; displayName: string}) =>
            manager ? (
              <div>
                {'By: '}
                <Link to={`/users/${manager.id}`} className="underline">
                  {manager.displayName}
                </Link>
              </div>
            ) : (
              <></>
            ),
        },
        {dataField: 'extra', text: ''},
      ]}
      striped={true}
      wrapperClasses="simple-table-bordered mt-2"
      bordered={false}
    ></Table>
  );
}

export default AccountDetail;
