import React from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import ManualResource from './ManualResource';
import ConnectResourceSelector from './ConnectResourceSelector';
import GenericConnector from './GenericConnector';
import {Icon} from '@trustle/component-library';

export default function CreateResource() {
  const pageContent = (
    <Switch>
      <Route path="/resources/create" exact={true}>
        <ConnectResourceSelector />
      </Route>
      <Route path="/resources/create/manual" exact={true}>
        <ManualResource />
      </Route>
      <Route path="/resources/create/generic" exact={true}>
        <GenericConnector />
      </Route>
    </Switch>
  );

  return (
    <div>
      <div className="tr-h-32 tr-text-white tr-bg-trustle-royal tr-overflow-hidden">
        <div className="tr-absolute -tr-left-16 tr-flex tr-items-center">
          <Link
            to="/manage"
            className="tr-text-white hover:tr-bg-trustle-selected tr-w-32 tr-h-32 tr-rounded-full tr-flex tr-items-center tr-justify-end"
          >
            <Icon type="back" size="lg" className="tr-opacity-100 tr-mr-2" />
          </Link>
          <h1 className="tr-m-0 tr-ml-2">Create a New System</h1>
        </div>
      </div>
      <div className="container tr-my-5">{pageContent}</div>
    </div>
  );
}
