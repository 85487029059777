import axios from 'axios';
import _ from 'lodash';
import {makeAutoObservable, runInAction} from 'mobx';
import {
  AccessRecordT,
  AccessRequestCommentT,
  AccessRequestType,
  AccountT,
  DenormGenericAccessRequestChangeT,
  DurationUnitT,
  PermissionT,
  ResourceT,
  TaskActionsEnum,
  TicketType,
  UserT,
} from 'src/types';
import RootStore from '..';
import {ProvisionOptions} from '@trustle/component-library/dist/types';
import moment from 'moment';
import {getErrorMessage} from 'src/utils';

export class Task {
  id!: string;
  oid!: string;
  uid!: string;
  rid?: string;
  refId?: string;
  refType?: string;
  uaid?: string;
  accessId?: string;
  createdAt: string = '';
  isSetup: boolean = true;
  assigneeIds: string[] = [];
  assignees: UserT[] = [];
  comments?: AccessRequestCommentT[];
  forAccessRequest?: Task;
  forPermission?: PermissionT;
  forResource?: ResourceT;
  forSystem?: ResourceT;
  forProvisionalUserId?: string;
  forAccess?: AccessRecordT | null;
  forUserAccount?: Partial<AccountT> | null;
  forUser?: Partial<UserT>;
  durationUnit?: DurationUnitT;
  durationValue?: number;
  accessDurationUnit?: DurationUnitT;
  accessDurationValue?: number;
  closedAt?: string;
  ticketType?: TicketType;
  status?: string;
  assignedToUsers?: string;
  history?: DenormGenericAccessRequestChangeT[];
  type?: string;
  processing?: boolean;
  message: string = '';
  processed?: boolean;
  sensitivityId?: string;
  connectorDetails?: {
    method: string;
  };
  startedBy?: Partial<UserT>;
  provisionDetails?: {
    id: string;
    path: string;
    provisionMode: ProvisionOptions;
    deprovisionMode: ProvisionOptions;
    initiateExpiredDeprovision: boolean;
    hidden: boolean;
    connectionDisabled: boolean;
  };
  accumIds: string[] = [];
  accumAssignees: UserT[] = [];
  accumUser: UserT[] = [];
  error: any;

  constructor(private rootStore: RootStore, attributes: Partial<Task>) {
    Object.assign(this, attributes);
    makeAutoObservable(this, {id: false});
    this.rootStore = rootStore;
  }

  async submitAction(action: TaskActionsEnum, extraParams?: any) {
    const ids = (_.size(this.accumIds) > 0 ? this.accumIds : [this.id]) ?? [];
    const params = {..._.omit(extraParams, 'duration'), ...extraParams.duration};

    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      runInAction(() => {
        this.processing = true;
      });

      const {forUserAccount, forUser, forPermission} = this;
      const message = `'${action}' for '${forUser?.email ?? forUserAccount?.account}' / '${forPermission?.label}`;

      try {
        await axios.post(
          `/api/tasks${action === TaskActionsEnum.VERIFY_USER ? '/access_request' : ''}/${id}/${action}`,
          action === TaskActionsEnum.VERIFY_USER ? {} : params
        );

        if (this.targetUser) {
          await this.targetUser.loadAccesses();
        }

        this.rootStore.toast.success(`Action successfully submitted: ${message}`);
        runInAction(() => {
          this.processing = false;
          this.processed = true;
        });
        await this.rootStore.tasksStore.refresh();
      } catch (err: any) {
        const error = getErrorMessage(err);
        const errorMsg = `Error submitting action ${message}: ${error}`;

        this.rootStore.toast.error(errorMsg);

        runInAction(() => {
          this.error = error;
          this.processing = false;
          this.processed = false;
        });
        throw err;
      }
    }
  }

  get sensitivity() {
    return this.rootStore.org.sensitivitySettings.find((sensitivity) => {
      return sensitivity.id === (this.sensitivityId ?? this.rootStore.org.defaultSensitivityId);
    });
  }

  get connector() {
    const rid = this.rid || this.forSystem?.id;
    if (!rid) {
      return null;
    }
    return this.rootStore.newResourceStore.resourceMap[rid]?.connector;
  }

  get isApprovalExpired() {
    if (!this.forAccess) {
      return false;
    }
    return (
      !this.forAccess.isApproved ||
      (!_.isNil(this.forAccess.expiresTs) && moment(this.forAccess.expiresTs).isBefore(moment()))
    );
  }

  get isAccessExpired() {
    if (!this.forAccess) {
      return false;
    }
    return !_.isNil(this.forAccess.accessExpiresTs) && moment(this.forAccess.accessExpiresTs).isBefore(moment());
  }

  get wasManualRequest() {
    if (!this.forAccess) {
      return false;
    }
    return !this.isApprovalExpired && !this.isAccessExpired;
  }

  get calculatedType() {
    const {ticketType, type} = this;
    if (type === AccessRequestType.PROVISIONAL_USER) {
      return AccessRequestType.PROVISIONAL_USER;
    }
    if (!ticketType) {
      return AccessRequestType.GENERIC;
    }
    return ticketType;
  }

  get isMulti() {
    return this.accumIds && _.size(this.accumIds) > 1;
  }

  get targetUser() {
    return this.forUser && this.forUser.id ? this.rootStore.usersStore.usersMap[this.forUser.id] : undefined;
  }

  get system() {
    return this.rootStore.newResourceStore.resourceMap[this.forSystem!.id];
  }

  get access() {
    return this.rootStore.accessStore.accessMap[this.forAccess!.id];
  }

  get permission() {
    return this.rootStore.permissionStore.permissionMap[this.forPermission!.id];
  }

  //NR: TODO: Raw implementation, this should be updated as soon as is dinamized.
  get expiresIn() {
    return moment(this.createdAt).add(7, 'days').toISOString();
  }
}
