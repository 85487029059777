import {Badge, Icon} from '@trustle/component-library';
import _ from 'lodash';
import React from 'react';
import {Task} from 'src/stores/domainObjects/Task';
import {TicketType, AccessRequestType, TaskTypesLabelEnum} from 'src/types';
import {BadgeVariantEnum, ItemIconTypesEnum} from '@trustle/component-library/dist/types';

const {WF_MANAGER_APPROVAL, WF_OWNER_APPROVAL, WF_EXECUTIVE_APPROVAL, WF_PROVISION, WF_DEPROVISION, WF_LINK_USER} =
  TicketType;

export const tagDefs: _.Dictionary<[BadgeVariantEnum, ItemIconTypesEnum]> = {
  [WF_DEPROVISION]: ['deepblue', 'deprovision'],
  [WF_PROVISION]: ['fucsia', 'request'],
  [WF_LINK_USER]: ['primary', 'linkNewUser'],
  [WF_EXECUTIVE_APPROVAL]: ['warning', 'admin'],
  [WF_MANAGER_APPROVAL]: ['coral', 'admin'],
  [WF_OWNER_APPROVAL]: ['success', 'orgOwner'],

  [AccessRequestType.GENERIC]: ['lime', 'admin'],
  [AccessRequestType.PROVISIONAL_USER]: ['primary', 'user'],
};

function TaskTypeIcon(props: {accessRequest: Task}) {
  const {accessRequest} = props;

  const tagDef = tagDefs[accessRequest.calculatedType];

  if (!tagDef) {
    return null;
  }

  return (
    <Badge variant={tagDef[0]} size="xs">
      <Icon type={tagDef[1]} forcedSize={16} className="mr-1" />
      {TaskTypesLabelEnum[props.accessRequest.calculatedType]}
    </Badge>
  );
}

export default TaskTypeIcon;
