import React from 'react';
import _ from 'lodash';
import FilterButton from '../FilterButton';
import InviteStatusIndicator from 'src/components/InviteStatusIndicator';
import {Icon, SystemsAccordion} from '@trustle/component-library';
import {observer} from 'mobx-react';
import {UserStatus} from 'src/stores/usersStore';
import {User} from 'src/stores/domainObjects/User';
import {FilteredByT} from '../AdminUserStatuses';
import {useRootStore} from 'src/lib/hooks';
import moment from 'moment';
import {SystemCounts} from '@trustle/component-library/dist/molecules/SystemsModal/SystemsAccordion';

type LensFiltersPropsT = {
  filters: any;
  filteredUsers: User[];
  setFilters: any;
  showLensFilters: {active: keyof FilteredByT | null};
  setShowLensFilters: any;
  tab: UserStatus;
};

const LensFilters = observer(function AdminUsersCmp(props: LensFiltersPropsT) {
  const {filters, setFilters, setShowLensFilters, showLensFilters, tab, filteredUsers = []} = props;
  const {newResourceStore: resourceStore} = useRootStore();

  const expiredInvitations = filteredUsers.filter((u) => moment().diff(moment(u.invitedAt), 'days') > 1);
  const sentInvitations = filteredUsers.filter((u) => moment().diff(moment(u.invitedAt), 'days') <= 1);
  const notInvited = filteredUsers.filter((u) => !u.invitedAt);
  const userNotInvited = _.first(notInvited);

  //This element is just a placeholder apparently, we need to make it show the dynamic
  const countedSystems = _.countBy(resourceStore?.activeSystems, 'type') as unknown as SystemCounts;

  function handleLensFilter(lens: keyof FilteredByT) {
    setShowLensFilters((prev: {active: string}) => ({
      ...prev,
      active: prev.active === lens ? null : lens,
    }));
  }

  const LensButton = ({lensName}: {lensName: keyof FilteredByT}) => {
    const lensMapName: Record<keyof FilteredByT, string> = {
      departments: 'department',
      titles: 'title',
      roles: 'remoteRole',
      teams: 'memberTeams',
      systems: 'resourcesNames',
    };

    const usersFilteredBy =
      lensName === 'teams' || lensName === 'systems'
        ? _.compact(_.uniq(_.flatMap(filteredUsers, lensMapName[lensName])))
        : _.compact(_.uniq(_.map(filteredUsers, lensMapName[lensName])));

    return (
      <>
        <div
          className={`tr-px-2 xl:tr-px-4 xl:tr-mr-6 cursor hover:tr-bg-[#C2E5FF33] rounded-xl ${
            showLensFilters.active === lensName && 'tr-bg-[#E9F4FD] hover:tr-bg-[#E9F4FD] rounded-xl'
          }`}
          data-testid={lensName}
          onClick={() => handleLensFilter(lensName)}
        >
          <div className="tr-flex tr-justify-between tr-w-full tr-text-sm md:tr-text-xl lg:tr-text-3xl xl:tr-text-6xl">
            {usersFilteredBy.length}
          </div>
          <div className="tr-text-sm">{_.startCase(lensName)}</div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className={`tr-flex align-items-center rounded-2xl shadow-md tr-p-2 tr-mb-8 tr-max-w-[1092px] tr-gap-[30px]`}
      >
        <div className="tr-flex tr-pl-1 tr-justify-between tr-text-navy tr-items-center">
          <div className="tr-flex-col align-items-center tr-mr-2">
            <FilterButton users={filteredUsers} filter={UserStatus.ACTIVE} reset={true} />
          </div>

          {tab === UserStatus.PENDING_APPROVAL && (
            <div className="tr-flex-col ">
              <div
                className={`tr-flex tr-px-2 tr-cursor-pointer tr-py-1 rounded-lg tr-w-[130px] tr-text-sm ${
                  filters.Invited === 'Pending' ? 'tr-bg-[#E9F4FD]' : ''
                } `}
                onClick={() => setFilters({...filters, Invited: 'Pending'})}
                data-testid={`sent count`}
              >
                {userNotInvited && <InviteStatusIndicator user={userNotInvited} />}
                <div className="tr-flex tr-px-3">{notInvited.length}</div>
                <div className="tr-flex justify-content-center status-tag">{'Pending'}</div>
              </div>
              <div
                className={`tr-flex tr-px-2 tr-cursor-pointer tr-py-1 rounded-lg tr-w-[130px] tr-text-sm ${
                  filters.Invited === 'Sent' ? 'tr-bg-[#E9F4FD]' : ''
                } `}
                onClick={() => setFilters({...filters, Invited: 'Sent'})}
                data-testid={`pending count`}
              >
                <Icon
                  data-testid={`resendsent`}
                  key={`resendsent`}
                  title={'Sent'}
                  type="emailPending"
                  size="sm"
                  className="tr-pr-1"
                />
                <div className="tr-flex tr-px-3">{sentInvitations.length}</div>
                <div className="tr-flex justify-content-center status-tag">{'Sent'}</div>
              </div>
              <div
                className={`tr-flex tr-px-2 tr-cursor-pointer tr-py-1 rounded-lg tr-w-[130px] tr-text-sm ${
                  filters.Invited === 'Expired' ? 'tr-bg-[#E9F4FD]' : ''
                } `}
                onClick={() => setFilters({...filters, Invited: 'Expired'})}
                data-testid={`expired count`}
              >
                <Icon
                  data-testid={`resendexpired`}
                  key={`resendexpired`}
                  title={'Expired'}
                  type="emailExpired"
                  size="sm"
                  className="tr-pr-1"
                />
                <div className="tr-flex tr-px-3">{expiredInvitations.length}</div>
                <div className="tr-flex justify-content-center status-tag">{'Expired'}</div>
              </div>
            </div>
          )}
        </div>
        <div className="tr-w-[1px] tr-h-[96px] relative tr-bg-gray-300"></div>
        <div className="tr-flex tr-flex-col xl:tr-flex-row tr-justify-between tr-items-center">
          <div className="tr-flex tr-flex-col tr-font-bold tr-text-navy tr-pr-4 tr-pb-2 xl:tr-pb-0">
            <LensButton lensName="systems" />
          </div>

          <SystemsAccordion {...countedSystems} showLabel={true} title="Available Systems" />
        </div>
        <div className="tr-w-[1px] tr-h-[96px] relative tr-bg-gray-300"></div>
        <div className="tr-text-navy tr-font-bold 1tr-min-w-[450px] tr-flex tr-py-1">
          <div className="tr-flex tr-flex-col lg:tr-flex-row">
            <LensButton lensName="teams" />
            <LensButton lensName="departments" />
          </div>
          <div className="tr-flex tr-flex-col lg:tr-flex-row">
            <LensButton lensName="roles" />
            <LensButton lensName="titles" />
          </div>
        </div>
      </div>
    </>
  );
});

export default LensFilters;
