import _ from 'lodash';
import React, {useState} from 'react';
import {formatFullDate, formatUserName, fuseSearch} from 'src/lib';
import {UserT} from 'src/types';
import TeamForm from './TeamForm';
import AddToTeamForm from './AddToTeamForm';
import {Button, Checkbox, Modal, Table} from '@trustle/component-library';
import {Search} from 'src/components/Search/Search';
import {useRootStore} from 'src/lib/hooks';

function CreateTeam() {
  const {usersStore, org} = useRootStore();

  const users = usersStore.activeUsers;
  const [selectedUsers, setSelectedUsers] = useState<UserT[]>([]);
  const [creatingTeam, setCreatingTeam] = useState<boolean>();
  const [addToTeam, setAddToTeam] = useState<boolean>();
  const [teamQuery, setTeamQuery] = useState('');

  const selectRow = {
    mode: 'checkbox' as const,
    style: {background: 'rgba(0,81,155,0.1)'},
    onSelect: (row: UserT, isSelect: boolean) => {
      toggleSelection(row, isSelect);
    },
    onSelectAll: (isSelect: boolean, rows: UserT[]) => {
      const rowstoupdate = isSelect ? rows : [];
      setSelectedUsers(rowstoupdate);
    },
    selectionRenderer: ({rowIndex}: {rowIndex: number}) => (
      <Checkbox
        id={`teams-${filteredUsers[rowIndex].id}`}
        checked={_.includes(selectedUsers, filteredUsers[rowIndex])}
      />
    ),
    selectionHeaderRenderer: ({indeterminate, checked}: {indeterminate: any; checked: boolean}) => {
      return (
        <Checkbox
          id={`select-teams-all`}
          checked={checked}
          ref={(input) => {
            if (input) {
              input.indeterminate = indeterminate;
            }
          }}
        />
      );
    },
  };

  function toggleSelection(user: UserT, checked: boolean) {
    const newSelectedUsers = [...selectedUsers];
    if (checked) {
      newSelectedUsers.push(user);
      setSelectedUsers(newSelectedUsers);
    } else {
      const newSelectedUsers = _.reject(selectedUsers, (u: UserT) => u.id === user.id);
      setSelectedUsers(newSelectedUsers);
    }
  }

  const columns = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Access',
    },
    {
      dataField: 'firstname',
      sort: true,
      text: 'Name',
      formatter: (cell: any, row: UserT) => {
        return <div>{formatUserName(row)}</div>;
      },
    },
    {
      dataField: 'email',
      sort: true,
      text: 'Email',
      formatter: (cell: any) => {
        return <div>{cell}</div>;
      },
    },
    {
      dataField: 'created',
      sort: true,
      text: 'Created on',
      formatter: (cell: any) => {
        return <span>{formatFullDate(cell)}</span>;
      },
    },
  ];

  const selectedUsersCount = selectedUsers.length ? `(${selectedUsers.length})` : '';

  function refreshAfterEdit() {
    setSelectedUsers([]);
    setAddToTeam(false);
    setCreatingTeam(false);
  }

  const createTeamButton = [
    <Search
      key="searchUser"
      placeholder="Search for Member"
      editURL={false}
      onChange={(query) => {
        setTeamQuery(query);
      }}
      className="tr-flex-grow"
    />,
    <div key="buttons">
      {!_.isNil(org.idpSettings) && (
        <Button
          variant="ternary"
          key="addToTeam"
          onClick={() => {
            setAddToTeam(true);
          }}
        >
          {`+ Add To Team ${selectedUsersCount}`}
        </Button>
      )}
      <Button
        key="createTeam"
        data-testid="createTeamButton"
        id="createTeamButton"
        onClick={() => {
          setCreatingTeam(true);
        }}
      >
        {`Create Team ${selectedUsersCount}`}
      </Button>
    </div>,
  ];

  const assignableUsers = _.reject(users, (user: UserT) => {
    return user.tombstone || user.terminated;
  });

  const filteredUsers = fuseSearch(teamQuery, ['email', 'fullname'], assignableUsers);

  return (
    <div className="my-3">
      <div>
        <span className="body1">Select users to assign them to a team</span>
      </div>
      <div className="tr-flex tr-items-center tr-my-2">{createTeamButton}</div>
      <Table
        data={filteredUsers}
        columns={columns}
        selectRow={selectRow}
        tableKey={'accountData'}
        striped={false}
        bordered={false}
      />
      {creatingTeam && (
        <Modal
          width="md"
          onClose={() => {
            setCreatingTeam(false);
          }}
          title="Create Team"
        >
          <TeamForm team={{members: selectedUsers}} afterSubmit={refreshAfterEdit} />
        </Modal>
      )}
      {addToTeam && (
        <Modal
          width="md"
          height="xs"
          onClose={() => {
            setAddToTeam(false);
          }}
          title="Add Members to Team"
        >
          <AddToTeamForm selectedUsers={selectedUsers} afterSubmit={refreshAfterEdit} />
        </Modal>
      )}
    </div>
  );
}

export default CreateTeam;
