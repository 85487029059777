import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {Formik, FormikForm, TrustlePermissionSelector} from 'src/components/design/formik';
import axios from 'axios';
import {AccessRequestType, MinUserT, PermissionT, TaskActionsEnum} from 'src/types';
import {formatDuration, formatUserNameAndEmail, retryUntilConditionMet} from 'src/lib';
import {observer} from 'mobx-react';
import {useRootStore} from 'src/lib/hooks';
import {Button, Confirmation, DurationInput, Select, TextAreaInput} from '@trustle/component-library';
import {Field} from 'formik';
import {array, object, string} from 'yup';
import moment from 'moment';
import {Task} from 'src/stores/domainObjects/Task';
import {NoActionAlerts} from '../utils';
import {actionButtonType, taskTypeSchemaEnum} from '../components/helpers';
import TakeOverTaskModal from '../TakeOverTaskModal';

type GenericAccessRequestDisplayPropsT = {
  accessRequest: Task;
  isAllowedToAct: boolean;
};

type GrantPermissionFormT = {
  permission: PermissionT | null;
  approvalDuration: {
    durationValue: number | '';
    durationUnit: string;
  };
  comment: string;
};

export const GenericAccessRequestDisplay = observer(function GenericAccessRequestDisplayComponent(
  props: GenericAccessRequestDisplayPropsT
) {
  const {accessRequest, isAllowedToAct} = props;
  const [selectedAction, setSelectedAction] = useState<TaskActionsEnum | undefined>(TaskActionsEnum.GRANT);
  const [showTakeoverConfirmDialog, setShowTakeoverConfirmDialog] = useState<boolean>(false);
  const [selectedPermission, setSelectedPermission] = useState<PermissionT | null>(null);
  const [selectedSystem, setSelectedSystem] = useState<string | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [connectionEnabledForSystem, setConnectionEnabledForSystem] = useState<boolean>(true);
  const {org, usersStore, newResourceStore} = useRootStore();
  const ticketConfiguration = taskTypeSchemaEnum[AccessRequestType.GENERIC];
  if (!ticketConfiguration) {
    return <></>;
  }
  if (org?.sensitivitySettings === undefined) {
    return <></>;
  }
  useEffect(() => {
    if (selectedSystem) {
      setConnectionEnabledForSystem(!newResourceStore.resourceMap[selectedSystem]?.connector?.disabled);
    }
  }, [selectedSystem]);

  const sensitivitySettings = org.sensitivitySettingsSorted;
  const sensitivitySetting = _.find(sensitivitySettings, {id: selectedPermission?.sensitivityId});

  async function createPermissionRequest(grantPermissionForm: GrantPermissionFormT) {
    const {approvalDuration, permission, comment} = grantPermissionForm;
    const params = {
      justification: accessRequest.message,
      permissionId: permission!.id,
      durationUnit: approvalDuration.durationUnit,
      durationValue: approvalDuration.durationValue,
      comment,
    };

    accessRequest.processing = true;

    await axios.post(`/api/tasks/access_request/${accessRequest.id}/grant_permission`, params);

    accessRequest.processing = false;
    accessRequest.processed = true;
  }

  async function saveAssignees(values: any): Promise<void> {
    accessRequest.processing = true;

    await axios.post(`/api/tasks/access_request/${accessRequest.id}/reassigned`, {
      message: values.comment,
      assigneeIds: _.map(values.assignees, 'id'),
    });
    accessRequest.processing = false;
    accessRequest.processed = true;
  }

  const defaultSelected = _.map(_.compact(accessRequest!.assignees), (assignee: MinUserT) => {
    return {id: assignee.id, value: assignee.id, label: assignee.email};
  });

  async function closeTicket() {
    accessRequest.processing = true;

    await axios.post(`/api/tasks/access_request/${accessRequest.id}/closed`, {});
    await retryUntilConditionMet(
      `/api/tasks/access_request/${accessRequest.id}`,
      (res) => {
        return res.accessRequest.status === 'closed';
      },
      5000,
      10
    );
    accessRequest.processing = false;
    accessRequest.processed = true;
  }

  const errorMessageApprovalDuration = sensitivitySetting?.maxApprovalDurationValue
    ? `Duration must be less than ${formatDuration(
        sensitivitySetting.maxApprovalDurationValue,
        sensitivitySetting.maxApprovalDurationUnit
      )}`
    : '';

  return (
    <div>
      <ticketConfiguration.heading accessRequest={accessRequest} />
      <div className="tr-my-2 tr-flex tr-justify-start tr-items-center">{ticketConfiguration.text(accessRequest)}</div>

      {selectedAction === TaskActionsEnum.REASSIGN && (
        <Formik
          initialValues={{
            assignees: defaultSelected,
            message: '',
          }}
          enableReinitialize
          validationSchema={object().shape({
            assignees: array().of(string()).min(1).required('There needs to be at least 1 valid assignee'),
          })}
          onSubmit={saveAssignees}
        >
          {({isValid, isSubmitting}) => {
            return (
              <FormikForm>
                <Field
                  component={Select}
                  name={'assignees'}
                  isMulti={true}
                  label={'Request Assignees'}
                  required={true}
                  options={_.map(usersStore.users, (suggestion: any) => {
                    return {
                      id: suggestion.id,
                      value: `${suggestion.email}`,
                      label: formatUserNameAndEmail(suggestion) ?? `${suggestion.email}`,
                    };
                  })}
                />
                <TextAreaInput name="message" label="Add comment" placeholder="Add comment..." />
                <div className="text-right">
                  <Button
                    variant="ternary"
                    type="button"
                    onClick={() => {
                      setSelectedAction(undefined);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant="secondary" type="submit" disabled={!isValid || isSubmitting}>
                    Submit
                  </Button>
                </div>
              </FormikForm>
            );
          }}
        </Formik>
      )}

      {selectedAction !== TaskActionsEnum.REASSIGN && (
        <Formik
          initialValues={{
            permission: selectedPermission,
            approvalDuration: {
              durationValue: sensitivitySetting?.maxApprovalDurationValue || 6,
              durationUnit: sensitivitySetting?.maxApprovalDurationUnit || 'month',
            },
            comment: '',
          }}
          initialTouched={{comment: true}}
          validateOnMount={true}
          onSubmit={createPermissionRequest}
          validationSchema={object().shape({
            approvalDuration: object().test('is-smaller', errorMessageApprovalDuration, (value) => {
              const maxDuration = moment.duration(
                sensitivitySetting?.maxApprovalDurationValue,
                sensitivitySetting?.maxApprovalDurationUnit!
              );
              const currentDuration = value.durationUnit
                ? moment.duration(value.durationValue, value.durationUnit)
                : null;

              return (
                !(maxDuration && currentDuration && maxDuration.asSeconds() < currentDuration.asSeconds()) ||
                !value.durationUnit ||
                !value.durationValue
              );
            }),
          })}
          enableReinitialize={true}
        >
          {({values, isValid, submitForm}) => {
            return (
              <>
                <FormikForm className="tr-my-2">
                  <TrustlePermissionSelector
                    name="permission"
                    onPermissionSelected={(permission: PermissionT) => {
                      setSelectedPermission(permission);
                    }}
                    onSystemSelected={(resource: any) => {
                      setSelectedSystem(resource);
                      setSelectedPermission(null);
                    }}
                    uid={accessRequest.uid}
                    rid={accessRequest.rid}
                    required
                  />
                  {selectedPermission && (
                    <Field
                      component={DurationInput}
                      name="approvalDuration"
                      className="tr-mt-4"
                      label="Approval Duration"
                      description="Time until re-approval is required"
                      maxDurationValue={sensitivitySetting?.maxApprovalDurationValue}
                      maxDurationUnit={sensitivitySetting?.maxApprovalDurationUnit}
                    />
                  )}
                  <Field
                    component={TextAreaInput}
                    name="comment"
                    className="tr-mt-4"
                    label="Comment"
                    data-testid="comment-textarea"
                    placeholder={'Add comment...'}
                  />
                  <NoActionAlerts connectionEnabledForSystem={connectionEnabledForSystem} isAllowedToAct={true} />
                  <div className="tr-flex tr-justify-end ">
                    {_.map(ticketConfiguration.getActions(accessRequest), (action: actionButtonType) => {
                      return (
                        <Button
                          variant={action.type === 'submit' ? 'secondary' : 'ternary'}
                          disabled={
                            action.type === 'submit' ? (!action.ignoreValidity && !isValid) || action.disabled : false
                          }
                          key={`button-${action.action}`}
                          selected={selectedAction === action.action}
                          onClick={() => {
                            if (isAllowedToAct) {
                              setShowConfirmDialog(true);
                            } else {
                              setShowTakeoverConfirmDialog(true);
                            }
                            setSelectedAction(action.action);
                          }}
                        >
                          {action.label}
                        </Button>
                      );
                    })}
                  </div>
                  {showTakeoverConfirmDialog && !(accessRequest.processing || accessRequest.processed) && (
                    <TakeOverTaskModal
                      title={'Access Request Confirmation '}
                      text={<>{'Do you want to respond to this request even if it is not assigned to you?'}</>}
                      comment={values.comment}
                      accessRequest={accessRequest}
                      setVisibility={(value: boolean) => {
                        setShowTakeoverConfirmDialog(value);
                        setSelectedAction(undefined);
                      }}
                      actionFn={async () => {
                        if (selectedAction) {
                          setShowTakeoverConfirmDialog(false);

                          if (selectedAction === TaskActionsEnum.DENY || selectedAction === TaskActionsEnum.CANCEL) {
                            void closeTicket();
                          } else {
                            await submitForm();
                          }
                        }
                      }}
                    />
                  )}
                  {showConfirmDialog && (
                    <Confirmation
                      onConfirm={async () => {
                        if (selectedAction === TaskActionsEnum.DENY) {
                          void closeTicket();
                        } else {
                          await submitForm();
                        }
                        setShowConfirmDialog(false);
                      }}
                      onClose={() => {
                        setShowConfirmDialog(false);
                      }}
                      title={'Are you sure?'}
                    >
                      <ticketConfiguration.confirmation
                        accessRequest={accessRequest}
                        selectedAction={selectedAction}
                        values={values}
                      />
                    </Confirmation>
                  )}
                </FormikForm>
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
});
