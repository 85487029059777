import React, {useState} from 'react';
import {observer} from 'mobx-react';
import './UserPermissions.scss';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import {Resource} from 'src/stores/domainObjects/Resource';
import {HistoryModal} from 'src/views/Resource/Overview/HistoryModal';
import _ from 'lodash';
import {ExtendAccessModal} from './ExtendAccessModal';
import {ConnectionServiceE} from 'src/types';
import TreeTabPanel from 'src/views/Resource/TreeView/TreeTabPanel';
import NoData from 'src/components/NoData';
import {Link} from 'react-router-dom';
import {User} from 'src/stores/domainObjects/User';
import {useRootStore} from 'src/lib/hooks';
import {Loading, TileButton, Tooltip} from '@trustle/component-library';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';

type PropsT = {
  user: User;
  resources?: Resource[];
  canEdit: boolean;
  hideFilters?: boolean;
  disableFilters?: boolean;
};

enum ModalName {
  SHOW_HISTORY = 'showHistory',
  EXTEND_ACCESS = 'extendAccess',
}

const UserPermissions = observer(function UserPermissionsCmp(props: PropsT) {
  const {user, hideFilters = false, disableFilters} = props;
  const {newResourceStore: resourceStore} = useRootStore();
  const [forceRerender, setForceRerender] = useState(false);
  const [typeFilters, setTypeFilters] = useState<_.Dictionary<{type: string; selected: boolean}>>(
    _.keyBy(
      _.map(_.keys(ConnectionServiceE), (service) => {
        return {type: service, selected: service === ConnectionServiceE.OKTA};
      }),
      'type'
    )
  );
  const [modalSelection, setModalSelection] = useState<null | {action: ModalName; accessRecord: AccessRecord}>(null);

  // Accesses are lazy loaded via /users/:id/accesses endpoint and include accesses to archived systems. Systems lazy
  // loaded is triggered when checking Access.ForSystem and does not include archived systems. We filter out accesses
  // to archived systems here to prevent errors further down.
  const accessRecords = user.nonPlaceholderAccesses.filter((access) => access.forSystem !== undefined);

  const filteredRecords = accessRecords.filter((accessRecordElem) => {
    const system = accessRecordElem.forSystem;
    return (system?.type && typeFilters[system.type]?.selected) || !_.some(typeFilters, {selected: true});
  });

  const resources = _(filteredRecords).map('forSystem').unionBy('id').value();

  function closeModal() {
    setModalSelection(null);
  }

  function renderModal() {
    if (!modalSelection) {
      return null;
    }

    const {action, accessRecord} = modalSelection;

    switch (action) {
      case ModalName.SHOW_HISTORY:
        return <HistoryModal accessRecord={accessRecord} closeModal={closeModal} />;

      case ModalName.EXTEND_ACCESS:
        return (
          <ExtendAccessModal
            afterSaving={() => {
              closeModal();
            }}
            accessRecord={accessRecord}
            closeModal={closeModal}
          />
        );
    }
  }

  const toggleIconSelected = (icon: string) => {
    const prev = typeFilters[icon]?.selected;
    const updatedDict = typeFilters;
    updatedDict[icon] = {type: icon, selected: !prev};

    setTypeFilters(updatedDict);
    setForceRerender(!forceRerender);
  };

  const renderSystemsIconList = (icons: string[], shortVersion: boolean = true) => {
    const showing = _.size(icons);
    const imgSize = showing < 3 ? 'md' : 'sm';

    return (
      <div className="tr-flex tr-items-center">
        <div className={`tr-grid tr-grid-cols-2 tr-text-xs tr-gap-1`}>
          {_.map(icons, (i: string, index) => {
            const showIcon = shortVersion ? index < 4 : true;
            return (
              showIcon && (
                <TileButton
                  key={`icon-${i}`}
                  colorVariant={typeFilters[i]?.selected ? 'primary' : undefined}
                  name={i as SystemIconsTypesEnum}
                  size={imgSize}
                  onClick={() => toggleIconSelected(i)}
                />
              )
            );
          })}
        </div>
      </div>
    );
  };

  const renderPermissionsPerSystem = (list: {key: string; count: number; times?: number}[]) => {
    return (
      <div className="row tr-flex justify-content-around align-items-center tr-w-full tr-m-1 tr-text-xs">
        {_.map(list, (obj: any) => {
          const {key, times} = obj;
          return (
            <TileButton
              name={key}
              colorVariant={typeFilters[key]?.selected ? 'primary' : undefined}
              className="tr-mx-1"
              badge={times}
              size="lg"
              onClick={() => toggleIconSelected(key)}
            />
          );
        })}
      </div>
    );
  };

  const renderSummaryHeader = () => {
    const countBySystem = _.countBy(filteredRecords, 'forSystem.type');
    const countAllBySystem = _.countBy(accessRecords, 'forSystem.type');
    const totalNameCount = _.size(_.keys(_.groupBy(filteredRecords, 'forSystem.name')));
    const iconsList: string[] = [];

    const systemSummary = _.map(_.keys(countAllBySystem), (key) => {
      const byKey = _.filter(filteredRecords, {forSystem: {type: key}});
      const nameCount = _.size(_.countBy(byKey, 'forSystem.name'));

      iconsList.push(key);

      return {key: key, count: countBySystem[key] ?? 0, times: nameCount};
    });

    const showingIcons = _.size(iconsList);

    return (
      <div className="tr-flex tr-my-3">
        {totalNameCount > 0 && (
          <div role="button" className={`cursor-pointer  px-1 align-middle`}>
            <div
              className={`tr-h-[95px] tr-rounded-md tr-text-center tr-align-middle tr-text-[53px] tr-leading-[95px] tr-shadow-md tr-bg-white tr-text-trustle-dark`}
            >
              <div className="tr-flex tr-items-center">
                <div className="tr-px-2">{totalNameCount}</div>
                {renderSystemsIconList(iconsList)}
                {showingIcons > 4 && (
                  <Tooltip content={renderSystemsIconList(iconsList, false)} position="bottom">
                    <div className="tr-text-trustle-link text-sm tr-pl-4">+{showingIcons - 4}</div>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className={`text-sm mt-2 w-[95px] text-center text-gray-400`}> {'Systems'}</div>
          </div>
        )}
        <div role="button" className={`cursor-pointer  px-1 align-middle`}>
          <div
            className={`tr-h-[95px] tr-rounded-md tr-text-center tr-align-middle tr-text-[53px] tr-leading-[95px] tr-shadow-md tr-bg-white tr-text-trustle-dark`}
          >
            <div className="tr-flex tr-items-center tr-justify-center">
              {_.uniq(filteredRecords.map((rec) => rec.forAccount?.id)).length}
            </div>
          </div>
          <div className={`text-sm mt-2 w-[95px] text-center text-gray-400`}> {'Accounts'}</div>
        </div>
        {_.size(systemSummary) > 0 && (
          <div role="button" className={`cursor-pointer  px-1 align-middle`}>
            <div
              className={`tr-h-[95px] tr-rounded-md tr-text-center tr-align-middle tr-text-[53px] tr-leading-[95px] tr-shadow-md tr-bg-white tr-text-trustle-dark`}
            >
              <div className="tr-flex tr-items-center tr-justify-center">
                {renderPermissionsPerSystem(systemSummary)}
              </div>
            </div>
            <div className={`text-sm mt-2 w-[95px] text-center text-gray-400`}> {'Accesses'}</div>
          </div>
        )}
      </div>
    );
  };

  if (resourceStore.loadingSystems) {
    return <Loading />;
  }

  if (_.isEmpty(accessRecords)) {
    return (
      <NoData>
        <>
          <div>The user currently has no authorized permissions access.</div>
          <div className="body5 text-muted mt-2">
            To request authorization for permissions access, please request via the{' '}
            <Link to={`/access/available`}>Access</Link> Tab.
          </div>
        </>
      </NoData>
    );
  }

  return (
    <div className="user-permissions-container tr-py-4 container">
      {!hideFilters && renderSummaryHeader()}
      <TreeTabPanel
        resources={resources}
        hideRoots={false}
        hideFilters={hideFilters}
        canEditResource={false}
        user={user}
        prefilteredAccesses={filteredRecords}
        hidePermissionsWithoutAccesses={true}
        disableFilters={disableFilters}
        displayActiveAccesses
      />
      {renderModal()}
    </div>
  );
});

export default UserPermissions;
