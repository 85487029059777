import React, {useRef, useState, useEffect} from 'react';
import {useField} from 'formik';
import {InputError} from './InputError';

type SecurityCodeInputPropsT = {
  name: string;
};

export default function SecurityCodeInput(props: SecurityCodeInputPropsT) {
  const refInputs = useRef(null);

  const [code, setCode] = useState('');
  const [, meta, helpers] = useField({name: props.name});
  const {value} = meta;
  const {setValue} = helpers;

  function inputArray(e: any) {
    if (!e || !e.children) {
      return [];
    }
    const inputs = [];
    for (let i = 0; i < e.children.length; i++) {
      const child = e.children[i];
      if (child.type === 'text') {
        inputs.push(child);
      }
    }
    return inputs;
  }

  function setListeners(inputs: HTMLInputElement[]) {
    for (let i = 0; i < inputs.length; i++) {
      const element = inputs[i];
      element.addEventListener('input', (event) => {
        event.preventDefault();
        // only numbers
        element.value = element.value.replace(/[^0-9]/g, '');

        let newI = i;
        if (element.value.length > 1) {
          const digits = element.value.split('');
          for (let j = i; j < inputs.length; j++) {
            const digit = digits.shift() || '';
            inputs[j].value = digit;
            newI = j;
          }
        }

        // advance
        const next = newI + 1;
        if (inputs[newI].value.length >= 1 && next < inputs.length) {
          inputs[next].focus();
        }

        let code = '';
        for (let j = 0; j < inputs.length; j++) {
          code = code + inputs[j].value;
        }

        if (value !== code) {
          setCode(code);
        }
      });

      element.addEventListener('keydown', (event) => {
        // backspace
        if (event.key === 'Backspace') {
          // devance
          const prev = i - 1;
          if (inputs[i].value.length === 0 && prev >= 0 && inputs[i].selectionEnd === 0) {
            inputs[prev].focus();
            inputs[prev].select();
          }
        }
        //left
        if (event.key === 'ArrowLeft') {
          const prev = i - 1;
          if (prev >= 0) {
            inputs[prev].focus();
            inputs[prev].select();
            event.preventDefault();
          }
        }

        //right
        if (event.key === 'ArrowRight') {
          const next = i + 1;
          if (next < inputs.length) {
            inputs[next].focus();
            inputs[next].select();
            event.preventDefault();
          }
        }
      });
    }
  }

  useEffect(() => {
    const inputs = inputArray(refInputs.current);
    setListeners(inputs);
  });

  useEffect(() => {
    setValue(code);
  }, [code]);

  return (
    <div
      style={{
        width: '20em',
        padding: '1.5em 0 3em 0',
      }}
    >
      <div ref={refInputs} className="input-group input-group-lg">
        <input type="text" name="c0" className="form-control" data-testid="security-code-input" />
        <input type="text" name="c1" className="form-control" />
        <input type="text" name="c2" className="form-control" />
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-lg">
            –
          </span>
        </div>
        <input type="text" name="c3" className="form-control" />
        <input type="text" name="c4" className="form-control" />
        <input type="text" name="c5" className="form-control" />
      </div>
      <InputError meta={meta} />
    </div>
  );
}
