import React from 'react';
import {formatDateFromNow, formatFullDate, formatUserName, getTimeRange} from 'src/lib';

import {Link} from 'react-router-dom';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import {observer} from 'mobx-react';
import {AccountTabTypeE} from '../Account/ResourceAccounts';
import _ from 'lodash';
import {Table, Tooltip} from '@trustle/component-library';
import ShowAuthorizedAccount from 'src/components/design/tables/common/ShowAuthorizedAccount';

type AccountDetailModalProps = {
  access: AccessRecord;
};

const AccessDetailModal = observer(function AccessDetailModalCmp(props: AccountDetailModalProps) {
  const {access} = props;

  const showDates = (date: string | undefined): React.ReactElement | string => {
    if (!date) {
      return '-';
    }
    return (
      <Tooltip content={formatFullDate(date)} position="bottom">
        {formatDateFromNow(date)}
      </Tooltip>
    );
  };

  const isUnmanagedOrLocked = !access.forPermission.autoProvisionValue.value || access.forPermission.isLocked;

  const accessData = () => {
    const account = access.forAccount ? access.forAccount : undefined;
    const user = access.forAccount?.forUser ? (
      <Tooltip content={formatUserName(access.forAccount.forUser)} position="bottom">
        {access.forAccount.forUser?.email}
      </Tooltip>
    ) : (
      _.startCase(AccountTabTypeE.UNASSIGNED)
    );
    const columns = [
      {key: 'User', value: user},
      {
        key: 'Account',
        value: (
          <Tooltip content={account?.account} position="bottom">
            <div style={{maxWidth: '200px'}} className="text-truncate">
              {account?.account && (
                <>
                  {account.account}
                  <ShowAuthorizedAccount account={account} iconSize="md" />
                </>
              )}
            </div>
          </Tooltip>
        ),
      },
      {key: 'Type', value: account?.accountType ?? ''},
      {
        key: 'Resource',
        value: (
          <Tooltip content={access.forResource.name} position="bottom">
            <div>{access.forResource.name}</div>
          </Tooltip>
        ),
      },
      {
        key: 'Permission',
        value: (
          <Tooltip content={access.forPermission.label} position="bottom">
            <div>{access.forPermission.label}</div>
          </Tooltip>
        ),
      },
      {key: 'Imported', value: showDates(access.created)},
    ];

    if (!isUnmanagedOrLocked) {
      columns.push(
        {
          key: 'Accesss',
          value: (
            <Tooltip
              position="top"
              content={
                <div className="tooltip-wrapper">
                  <div className="tr-flex tr-justify-start tr-items-start flex-column align-content-center p-1">
                    <div>Access Expiration:</div>
                    <div className="text-left">
                      <b>{getTimeRange(access.accessExpiresTs)}</b>
                    </div>
                    <div className="text-left">{formatFullDate(access.accessExpiresTs)}</div>
                  </div>
                  <div className="tr-justify-start tr-items-start flex-column align-content-center p-1">
                    <div>Approval Expiration:</div>
                    <div className="text-left">
                      <b>{getTimeRange(access.expiresTs)}</b>
                    </div>
                    <div className="text-left">{formatFullDate(access.expiresTs)}</div>
                  </div>
                </div>
              }
            >
              {access.accessExpiresTs ? 'Expires:' : ''} {getTimeRange(access.accessExpiresTs)}
            </Tooltip>
          ),
        },
        {
          key: 'Approval',
          value: (
            <Tooltip
              position="top"
              content={
                <div className="tooltip-wrapper">
                  <div className="tr-flex tr-justify-start tr-items-start flex-column align-content-center p-1">
                    <div>Access Expiration:</div>
                    <div className="text-left">
                      <b>{getTimeRange(access.accessExpiresTs)}</b>
                    </div>
                    <div className="text-left">{formatFullDate(access.accessExpiresTs)}</div>
                  </div>
                  <div className="tr-justify-start tr-items-start flex-column align-content-center p-1">
                    <div>Approval Expiration:</div>
                    <div className="text-left">
                      <b>{getTimeRange(access.expiresTs)}</b>
                    </div>
                    <div className="text-left">{formatFullDate(access.expiresTs)}</div>
                  </div>
                </div>
              }
            >
              {access.expiresTs ? 'Expires:' : ''} {getTimeRange(access.expiresTs)}
            </Tooltip>
          ),
        }
      );
    }

    return columns;
  };

  return (
    <>
      <Table
        data={accessData()}
        overrideKeyField="key"
        tableKey={'accessdetail'}
        columns={[
          {
            dataField: 'key',
            text: 'Account info',
            headerClasses: 'text-left',
            classes: 'text-left pl-5',
            formatter: (_cell: any, row: any) => <strong className="text-left">{row.key}</strong>,
          },
          {dataField: 'value', text: '', classes: 'text-left', style: {width: '30%'}},
          {
            dataField: 'by',
            text: '',
            classes: 'text-left',
            formatter: (manager: {id: string; displayName: string}) =>
              manager ? (
                <div>
                  {'By: '}
                  <Link to={`/users/${manager.id}`} className="underline">
                    {manager.displayName}
                  </Link>
                </div>
              ) : (
                <></>
              ),
          },
          {dataField: 'extra', text: ''},
        ]}
        striped={true}
        wrapperClasses="simple-table-bordered mt-2"
        bordered={false}
      ></Table>
    </>
  );
});

export default AccessDetailModal;
