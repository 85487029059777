import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {SubmitButton} from 'src/components/design';
import {Formik, FormikForm} from 'src/components/design/formik';
import './OktaConnector.scss';
import {NextStepT, OktaCredentialTypeE} from '../types';
import {ConnectionInfoT, ConnectionServiceE, ConnectionT, DurationUnitT} from 'src/types';
import {Button, DurationInput, Loading, TextInput, Alert} from '@trustle/component-library';
import {Field} from 'formik';
import {getAuthorizationURL, parseAndCleanUpQueryParams} from '../common/helpers';
import {useHistory, useLocation} from 'react-router-dom';
import {useConnectionCredentials} from '../../lib/hooks';
import {useSystemSetup} from 'src/lib/hooks/useSystemSetup';

type ConnectorPropsT = {
  onComplete: NextStepT;
  connectionInfo: ConnectionInfoT;
  editMode?: boolean;
};

export default function OktaSetup(props: ConnectorPropsT) {
  const {editMode = false, connectionInfo, onComplete} = props;
  const [connection] = useState<ConnectionT>(connectionInfo.connection);
  const {systemSetup, updateSetup} = useSystemSetup(connection.id ?? '');
  const [APIConnected, setAPIConnected] = useState<boolean>(false);
  const [testInProgress, setTestInProgress] = useState<boolean>(!editMode);
  const [info, setInfo] = useState<string>('');
  const [infoType, setInfoType] = useState<string>('text-warning');
  const history = useHistory();
  const location = useLocation();
  const {credentials, testConnection: testConnectionAPI} = useConnectionCredentials();

  useEffect(() => {
    async function load() {
      const err = parseAndCleanUpQueryParams(history, location, onError);

      if (!err) {
        await testConnection();
      }
    }

    void load();
  }, []);

  async function testConnection() {
    setTestInProgress(true);
    setInfoType('text-warning');
    setInfo('Trying to authenticate against Okta API...');

    try {
      const {data} = await testConnectionAPI(connection.id, {});
      const connected = !!data.ok;
      setAPIConnected(connected);

      if (connected) {
        setInfoType('text-success');
        setInfo('Successfully Connected');
      } else {
        setInfoType('text-secondary');
        setInfo('Please, authenticate with valid credentials.');
      }
    } catch (err: any) {
      setAPIConnected(false);
      setInfoType('text-secondary');
      setInfo('Please, authenticate with valid credentials.');
    }

    setTestInProgress(false);
  }

  function onError(error: string) {
    setTestInProgress(false);

    setInfoType('text-danger');
    setInfo(error === 'invalid_client' ? `Please, verify the crendentials are valid.` : _.startCase(error));
  }

  async function submitCredentials(
    values: {
      orgId: string;
      clientId: string;
      clientSecret: string;
      accessKeyRotation: {durationValue: number; durationUnit: DurationUnitT};
    },
    setSubmitting: (submitting: boolean) => void
  ) {
    updateSetup({connectionId: connection.id ?? '', keys: values});
    setTestInProgress(true);
    setInfoType('text-warning');
    setInfo('Validating credentials...');

    try {
      await credentials(connection.id, {
        key: OktaCredentialTypeE.CLIENT_CREDENTIALS,
        value: _.omit(values, 'accessKeyRotation'),
        accessKeyRotation: values.accessKeyRotation,
      });

      window.location.replace(
        getAuthorizationURL(connection.id, ConnectionServiceE.OKTA, OktaCredentialTypeE.OAUTH_TOKEN, editMode)
      );

      if (editMode) {
        onComplete({});
      }
    } catch (err: any) {
      onError(err.response.data?.error?.message || err.response.statusText);
    }

    setSubmitting(false);
  }

  return (
    <div className="py-4">
      <h3>API Key configuration</h3>
      <div className="mb-4">
        Trustle uses an Okta API Token to import and provision access to your Okta groups and users.
      </div>
      <small>
        <a href="https://learn.trustle.com/tutorial/installing-okta" rel="noopener noreferrer" target="_blank">
          Guide: Setting up an Okta Connector
        </a>
      </small>
      <Formik
        initialValues={{
          orgId: systemSetup?.orgId ?? '',
          clientId: systemSetup?.orclientIdgId ?? '',
          clientSecret: systemSetup?.clientSecret ?? '',

          accessKeyRotation: {
            durationValue: _.get(connectionInfo, 'connection.accessKeyRotationValue', 3),
            durationUnit: _.get(connectionInfo, 'connection.accessKeyRotationUnit', 'month'),
          },
        }}
        validate={(values: any) => {
          const errors: _.Dictionary<string> = {};
          if (!values.orgId) {
            errors.orgId = 'required';
          }

          if (!values.clientId) {
            errors.clientId = 'required';
          }

          if (!values.clientSecret) {
            errors.clientSecret = 'required';
          }

          return errors;
        }}
        onSubmit={(values: any, {setSubmitting}: any) => {
          void submitCredentials(values, setSubmitting);
        }}
      >
        {({isSubmitting, status, isValid, dirty}) => (
          <FormikForm className="py-3 okta-ad-setup">
            {(editMode || (!APIConnected && !testInProgress)) && (
              <>
                <div className="tr-flex tr-flex-row">
                  <div className="tr-flex tr-flex-col tr-w-[400px]">
                    <Field
                      component={TextInput}
                      label="Organization URL"
                      placeholder="https://org-1234567890.okta.com"
                      name="orgId"
                      required
                    />
                  </div>
                </div>
                <div className="tr-flex tr-flex-row my-3">
                  <div className="tr-flex tr-flex-col tr-mr-4">
                    <Field
                      component={TextInput}
                      label="Client ID"
                      placeholder="0ab1cd2e456f7g8hI9j0"
                      name="clientId"
                      required
                    />
                  </div>
                  <div className="tr-flex tr-flex-col">
                    <Field
                      component={TextInput}
                      label="Client Secret"
                      placeholder="a_1bcdefGH2IjkLMno3PQ4rStUV5_WXy-zA6B7C8"
                      name="clientSecret"
                      required
                    />
                  </div>
                </div>
                <div className="tr-flex tr-flex-col mt-2 ml-3 pt-3 border-top">
                  <Field
                    component={DurationInput}
                    name="accessKeyRotation"
                    label="Trustle Connector key rotation"
                    required
                    includeNoneOption={false}
                    description="Prompt me to rotate the Trustle access key in the period defined below"
                  />
                </div>
              </>
            )}
            <div className="tr-flex tr-flex-row text-right mt-1 mb-3 ml-3 justify-between">
              <div className="tr-flex tr-flex-col text-left mt-1">
                <div>
                  {testInProgress && <Loading />}
                  <small className={`ml-2 ${infoType}`}>{info}</small>
                </div>
              </div>
              <div className="tr-flex tr-flex-col">
                {((!editMode && !APIConnected) || editMode) && (
                  <SubmitButton
                    inSubmit={isSubmitting}
                    disabled={testInProgress || !isValid || !dirty}
                    label={'Authenticate'}
                  />
                )}
                {!editMode && APIConnected && (
                  <Button disabled={!APIConnected} onClick={() => props.onComplete({})}>
                    {'Continue'}
                  </Button>
                )}
              </div>
            </div>
            {status && status.message && <Alert colorVariant={'danger'}>{status.message}</Alert>}
          </FormikForm>
        )}
      </Formik>

      <hr />
    </div>
  );
}
