import {ApexOptions} from 'apexcharts';

export const HEATMAP_COLORS = ['#FFFFFF', '#DCDEDF', '#C5B1FC', '#855CF8', '#253337'];

export const HEAT_MAP_OPTIONS: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    position: 'top', // Place x label on top of the chart
    labels: {
      rotate: 0, // Rotate the labels to 0 degrees (horizontal)
    },
  },
  legend: {
    show: false, // Disable default legend we use a custom one
  },
  dataLabels: {
    enabled: false, // Disable data labels on heatmap cells
  },
  plotOptions: {
    heatmap: {
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 25,
            color: HEATMAP_COLORS[0],
          },
          {
            from: 26,
            to: 50,
            color: HEATMAP_COLORS[1],
          },
          {
            from: 51,
            to: 75,
            color: HEATMAP_COLORS[2],
          },
          {
            from: 76,
            to: 85,
            color: HEATMAP_COLORS[3],
          },
          {
            from: 86,
            to: 100,
            color: HEATMAP_COLORS[4],
          },
        ],
      },
    },
  },
};
