import {useState} from 'react';
import axios from 'axios';
import {HeatmapSeriesType, LicenseUsageHeatmap, ViewType} from '../types';

type FetchParams = {
  licenses: string[];
  view: ViewType;
  x_attribute: HeatmapSeriesType;
  y_attribute: HeatmapSeriesType;
};

type Props = {
  resourceId: string;
};

export function useLicenseHeatmap({resourceId}: Props) {
  const [data, setData] = useState<LicenseUsageHeatmap>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const BASE_URL = `/api/resources/${resourceId}/licenses/heatmap`;

  const fetchLicenseUsageHeatmap = async (params: FetchParams) => {
    const {licenses} = params;

    const parsedParams: {view: ViewType; licenses: string; user?: string} = {
      ...params,
      licenses: licenses.join(','),
    };

    const queryString = new URLSearchParams(parsedParams).toString();

    setLoading(true);
    try {
      const response = await axios.get<LicenseUsageHeatmap>(`${BASE_URL}?${queryString}`);
      setData(response.data);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    error,
    fetchLicenseUsageHeatmap,
  };
}
