import React from 'react';
import {Modal} from '@trustle/component-library';
import {AuthoritySelectItem} from './internal/AuthoritySelectItem';
import {useAvailableSystems} from '../../../../../views/Resource/Access/useAvailableSystems';
import {AuthorityServiceE} from '../../../../../types';

export function CreateSelectAuthorityModal({onCancel}: {onCancel: () => void}) {
  const {availableSystems, isLoading} = useAvailableSystems();
  const availableAuthorities = availableSystems.filter((system) => system.idpFeatureEnabled);
  return (
    <Modal
      onClose={() => onCancel()}
      width="lg"
      title={
        <div className="tr-flex tr-items-center tr-justify-center tr-text-trustle-primary tr-flex-col">
          <h1>Create, or Select Authority for Trustle Users</h1>
          <h3 className="tr-text-center">
            You can create your users by onboarding a new authority, or connecting and existing one
          </h3>
        </div>
      }
    >
      {!isLoading && (
        <div className="tr-flex tr-flex-col tr-items-center tr-gap-8 tr-mt-8">
          <div className="tr-flex tr-flex-col tr-items-center">
            <h4 className="tr-text-center tr-m-0 tr-font-medium tr-text-trustle-primary tr-text-lg">
              Onboard new system for authority
            </h4>
            <div className="tr-flex tr-gap-4 tr-p-4">
              <AuthoritySelectItem
                key={`create-authority-${AuthorityServiceE.OKTA}`}
                service={AuthorityServiceE.OKTA}
                goTo={`/connect/${AuthorityServiceE.OKTA}`}
              />
              <AuthoritySelectItem
                key={`create-authority-${AuthorityServiceE.AZURE_AD}`}
                service={AuthorityServiceE.AZURE_AD}
                goTo={`/connect/${AuthorityServiceE.AZURE_AD}`}
              />
              <AuthoritySelectItem
                key={`create-authority-${AuthorityServiceE.GAPPS}`}
                service={AuthorityServiceE.GAPPS}
                goTo={`/connect/${AuthorityServiceE.GAPPS}`}
              />
            </div>
          </div>
          {availableAuthorities.length && (
            <div className="tr-flex tr-flex-col tr-items-center">
              <h4 className="tr-text-center tr-m-0 tr-font-medium tr-text-trustle-primary tr-text-lg">
                Select existing authority
              </h4>
              <div className="tr-flex tr-gap-4 tr-p-4">
                {availableAuthorities.map((authority) => {
                  const {idpIntegrationService: service, id: resourceId, name} = authority;
                  const key = `${resourceId}-${service}`;
                  const goTo = `/resource/manage/${resourceId}/settings/authority_settings`;
                  return (
                    <AuthoritySelectItem
                      key={key}
                      service={service as unknown as AuthorityServiceE}
                      goTo={goTo}
                      name={name}
                      isConfigured
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}
