import Fuse from 'fuse.js';
import _ from 'lodash';

function fuseSearch<T>(query: string, fields: string[], list: T[]): T[] {
  if (!query) {
    return list;
  }

  const options = {
    keys: fields,
    threshold: 0.3,
  };

  const fuse = new Fuse(list, options);

  return _.map(fuse.search(query), (elem) => {
    return elem.item;
  });
}

export {fuseSearch};
