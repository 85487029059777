import React, {useState} from 'react';
import axios from 'axios';
import {observer} from 'mobx-react';
import {RouteComponentProps} from 'react-router-dom';
import {useQueryParams} from 'src/lib';
import Header from './Header';
import LoginForm from './LoginForm';
import {Button} from '@trustle/component-library';
import ErrorMessage from './ErrorMessage';
import OAuthButton from '../OAuthButton';

const LoginPage: React.FC<RouteComponentProps> = observer(({history, location, match}) => {
  const [error] = useQueryParams(['error']);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [emailSent, setEmailSent] = useState<string | null>('test');
  const [emailError, setEmailError] = useState<string | null>(null);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSendEmail = async (email: string) => {
    try {
      await axios.post('/api/auth/find_orgs_login', {email});
      setEmailSent(email);
      setCurrentPage(2);
      setEmailError(null); // Reset error state on successful email send
    } catch (e) {
      setEmailError('Failed to send email');
    }
  };

  return (
    <>
      <Header currentPage={currentPage} changePage={handlePageChange} pages={[1, 2]} />
      {currentPage === 1 && (
        <>
          <div className="mt-4">
            <p className="tr-text-sm">We need to find the Trustle account associated with a Trustle Org.</p>
            <p className="tr-text-sm">To begin, Choose Gauth or Email and we’ll send you login instructions. </p>
          </div>
          {error && <ErrorMessage error={error} />}
          <OAuthButton
            onClick={() => {
              window.location.href = '/api/auth/oauth/authenticate';
            }}
          />
          <div className="tr-flex tr-items-center tr-my-4">
            <hr className="tr-grow tr-h-px" />
            <span className="tr-mx-4 tr-font-bold">or</span>
            <hr className="tr-grow tr-h-px" />
          </div>
          {emailError && <p className="tr-text-sm tr-text-red-500">{emailError}</p>}
          <LoginForm history={history} location={location} match={match} handleSendEmail={handleSendEmail} />
        </>
      )}
      {currentPage === 2 && (
        <>
          <div className="mt-4 mb-4">
            <p className="tr-text-sm">
              We sent an email to {emailSent} with links to Trustle Organizations associated with this email.
            </p>
            <p className="tr-text-sm">Please Check Your Email and select available Organizations to continue.</p>
          </div>
          <p className="tr-text-sm">Didn’t receive an email?</p>
          {emailSent && (
            <Button variant="ternary" onClick={() => handleSendEmail(emailSent)}>
              Resend Email
            </Button>
          )}
        </>
      )}
    </>
  );
});

export default LoginPage;
