import axios from 'axios';
import _ from 'lodash';
import {makeAutoObservable, onBecomeObserved, onBecomeUnobserved, runInAction} from 'mobx';
import moment from 'moment';

import RootStore from '.';
import {Task} from './domainObjects/Task';
import {Resource} from './domainObjects/Resource';
import {AccessRecord} from './domainObjects/AccessRecord';

class TasksStore {
  rootStore: RootStore;
  accessRequestTasks: Task[] = [];
  selected?: Task | undefined = undefined;
  tasksMap: Record<string, Task> = {};
  highlightedTasks: string[] = [];
  loading: boolean = false;
  loadingOpen: boolean = false;
  errorLoading: boolean = false;
  loadedAtLeastOnce: boolean = false;

  lastRequest: string | undefined = moment().format();
  startTime: string | undefined = undefined;
  endTime: string | undefined = undefined;
  retries: number = 1;
  timeWindow: number = 180;
  assignedTotal: number = 0;
  noMoreTasks: boolean = false;

  // ↓ lazily loaded properties ↓

  interval: any;

  constructor(root: RootStore) {
    makeAutoObservable(this);

    this.rootStore = root;

    onBecomeUnobserved(this, 'tasksMap', async () => clearInterval(this.interval));

    onBecomeObserved(this, 'tasksMap', async () => {
      if (this.loading) {
        return;
      }
      runInAction(() => {
        this.loading = true;
      });

      try {
        await this.loadNextPage();

        runInAction(() => {
          this.loading = false;
          this.loadedAtLeastOnce = true;
        });
      } catch (error) {
        runInAction(() => {
          this.errorLoading = true;
          this.loading = false;
        });
      }

      if (root.org) {
        this.interval = setInterval(async () => {
          try {
            if (document.hasFocus()) {
              void this.getTasks({createdAt: this.lastRequest});
              runInAction(() => {
                this.lastRequest = moment().format();
              });
            }
          } catch (error) {
            runInAction(() => {
              this.errorLoading = true;
              clearInterval(this.interval);
            });
          }
        }, 12000);
      }
    });
  }

  init() {}

  initializeTask(ar: Partial<Task>) {
    const task = new Task(this.rootStore, _.omit(ar, []));

    if (ar.forSystem) {
      this.rootStore.newResourceStore.updateResourceFromServer(ar.forSystem as Partial<Resource> & {id: string});
    }
    if (ar.forResource) {
      this.rootStore.newResourceStore.updateResourceFromServer(ar.forResource as Partial<Resource> & {id: string});
    }
    if (ar.forAccess) {
      this.rootStore.accessStore.updateAccessFromServer(ar.forAccess as Partial<AccessRecord> & {id: string});
    }

    this.tasksMap[task.id] = task;
    return task;
  }
  async getTasks(params: any, refresh?: boolean) {
    if (this.loadingOpen) {
      return;
    }
    try {
      this.loadingOpen = true;
      const callParams = {...params, totalCount: !params.createdAt};
      const tasksResult = await axios.get(
        `/api/tasks/open`,
        !refresh ? {params: callParams} : {params: {totalCount: true}}
      );

      if (tasksResult.data.assignedTotal) {
        this.assignedTotal = tasksResult.data.assignedTotal;
      }
      let tasks = tasksResult.data.tasks;
      if (tasksResult.status === 401) {
        location.reload();
      }
      if (!_.includes(this.rootStore.org.featureFlags, 'hide_generic_requests')) {
        const accessRequestResult = await axios.get(`/api/tasks/access_request/open`, !refresh ? {params} : {});
        tasks = [...tasks, ...accessRequestResult.data.accessRequests];
      }

      runInAction(() => {
        this.loadingOpen = false;

        tasks.map((ar: Partial<Task>) => {
          return this.initializeTask(ar);
        });

        if (!params.createdAt) {
          this.endTime = params.endTime;
          this.startTime = params.startTime;
        }
      });

      this.loadingOpen = false;

      if (this.retries < 6 && !params.createdAt && _.isEmpty(tasks)) {
        runInAction(() => {
          this.retries = this.retries + 1;
        });

        void this.loadNextPage();
      } else if (!params.createdAt && !_.isEmpty(tasks)) {
        runInAction(() => {
          this.retries = 1;
        });
      } else if (this.retries === 6) {
        runInAction(() => {
          this.noMoreTasks = true;
        });
      }
    } catch (err) {
      this.rootStore.toast.add(`Failed to load the tasks.`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async loadNextPage(): Promise<void> {
    const days = this.timeWindow * this.retries ?? 1;
    const endTime = this.startTime ?? moment().format();
    const startTime = moment(this.startTime)
      .add(-1 * days, 'days')
      .format();
    await this.getTasks({startTime, endTime});
  }

  async refresh() {
    this.lastRequest = moment().format();
    this.tasksMap = {};
    await this.getTasks({}, true);
  }

  setSelectedTask(task: Task | undefined) {
    runInAction(() => (this.selected = task));
  }

  get tasks() {
    return _.filter(_.values(this.tasksMap), (task: Task) => !task.processed);
  }

  get openTasksAndRequests() {
    const offboarded = _.map(this.rootStore.usersStore.offBoardedUsers, 'id');
    return _.values(this.tasksMap).filter((task) => {
      return (
        !task.closedAt &&
        !task.processed &&
        (!task.forSystem?.archived || task.assigneeIds.filter((element) => !offboarded.includes(element)))
      );
    });
  }

  get selfTasksAndRequests() {
    /*Filtering "generic" types was implemented in order to avoid showing task without assingees*/
    return this.openTasksAndRequests.filter(
      (task) =>(!task.forPermission && task.type !== 'generic') || task.assigneeIds?.includes(this.rootStore.currentUser.id)
    );
  }

  get otherTasks() {
    return _.filter(this.openTasksAndRequests, (ar) => {
      return !(ar.assigneeIds?.indexOf(this.rootStore.currentUser.id) >= 0);
    });
  }
}

export default TasksStore;
