import React from 'react';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {resolveFillColor} from './utils';
import {CustomLegend} from './internal/CustomLegend';
import {CustomTooltip} from './internal/CustomTooltip';

type Props = {
  data: any[];
  series: string[];
};
export function LicenseUsageStackedBarChart({data, series}: Props) {
  return (
    <div className="tr-flex tr-flex-col tr-gap-2">
      <CustomLegend series={series} />
      <ResponsiveContainer width="100%" height="100%" minWidth={500} minHeight={400}>
        <BarChart
          data={data}
          barCategoryGap={15}
          margin={{
            top: 50,
          }}
        >
          <CartesianGrid />
          <XAxis key="frontBar" dataKey="name" xAxisId="frontBar" textAnchor="middle" />
          <XAxis key="backBar" dataKey="name" xAxisId="backBar" hide />
          <XAxis key="reference" dataKey="index" xAxisId="reference" hide type="number" />
          <YAxis unit="Hrs" width={70} />
          <Tooltip content={<CustomTooltip />} />
          {renderBars(series)}
          {/*TODO: Should be dynamic and render Trustle onboard date*/}
          {/*<ReferenceLine*/}
          {/*  x={4}*/}
          {/*  xAxisId="reference"*/}
          {/*  isFront*/}
          {/*  strokeWidth={3}*/}
          {/*  stroke="#00519B"*/}
          {/*  strokeDasharray="3 3"*/}
          {/*  label={<Label position="top" content={renderContent} />}*/}
          {/*/>*/}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

// const renderContent: any = ({viewBox: {x, y}}: any) => {
//   const d = 28;
//   const r = d / 2;
//
//   const transform = `translate(${x - r} ${y - d - 4})`;
//
//   return (
//     <g transform={transform}>
//       <Icon type="Trustle" color="red" size="md" />
//     </g>
//   );
// };

function renderBars(series: string[]) {
  return series.map((key) => {
    const baseDataKey = `data.${key}`;
    return (
      <>
        <Bar dataKey={`${baseDataKey}.sum_of_granted_hours`} xAxisId="backBar" fill="#FFDFB2" />
        <Bar dataKey={`${baseDataKey}.sum_of_usage_hours`} xAxisId="frontBar" fill={resolveFillColor(key)} />
      </>
    );
  });
}
