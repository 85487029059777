import {observer} from 'mobx-react';
import React from 'react';
import _ from 'lodash';
import {ConnectionServiceE} from 'src/types';
import {TileButton} from '@trustle/component-library';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';

export type ServiceTypeE = ConnectionServiceE | undefined | 'custom-system';

type ServiceSelectorPropsT = {
  services: {service: ServiceTypeE; iconSrc?: string; num: number}[];
  clickHandler?: (service: ServiceTypeE) => void;
  overHandler?: (service: ServiceTypeE) => void;
  selectAllHandler?: (props: {service: ServiceTypeE; isSelected: boolean}) => void;
  children?: (props: {service: ServiceTypeE}) => any;
  showSelectAll: boolean;
};

const ServiceSelector = observer(({services, clickHandler, overHandler, children}: ServiceSelectorPropsT) => {
  return (
    <>
      {_.map(services, (s) => {
        const {service} = s;
        return (
          <div
            key={service}
            onMouseOver={() => {
              if (overHandler && _.isFunction(overHandler)) {
                overHandler(service);
              }
            }}
          >
            <TileButton
              badge={s.num}
              onClick={() => {
                if (clickHandler && _.isFunction(clickHandler)) {
                  clickHandler(service);
                }
              }}
              name={s.service as SystemIconsTypesEnum}
            />
            {children && children({service})}
          </div>
        );
      })}
    </>
  );
});

export default ServiceSelector;
