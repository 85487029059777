import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {Permission} from 'src/stores/domainObjects/Permission';
import {Accordion, Loading} from '@trustle/component-library';
import './PermissionBranch.scss';
import {User} from 'src/stores/domainObjects/User';
import {getPermissionTemplateComponent} from 'src/connectors/manifests';
import {ViewEntities} from 'src/connectors/common/types';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';

export type PermissionBranchPropsT = {
  template: ViewEntities;
  permission: Permission;
  isTreeView?: boolean;
  isRootNode?: boolean;
  accessMode?: boolean;
  opened?: string[];
  setFilters?: (value: any) => void;
  filters?: any;
  hidePermissionsWithoutAccesses?: boolean;
  prefilteredAccesses?: AccessRecord[];
  user?: User;
  visibleItems?: {resources: string[]; permissions: string[]; accesses: string[]};
};

const AccessesWrapper = observer((props: PermissionBranchPropsT & {open: boolean}) => {
  const {template, permission, open, prefilteredAccesses, visibleItems} = props;

  //This should be defined as a type when merging templantes that the attirbutes will be there

  const AccessCmp = template.AccessRecord.default!.main!;

  if (!open) {
    return <></>;
  }

  if (permission.loadingAccesses) {
    return (
      <div className="tr-flex tr-items-center tr-justify-center">
        <Loading />
      </div>
    );
  }

  return (
    <AccessCmp
      {...{
        template: template,
        permission: permission,
        resource: permission.parentResource,
        //Calculate here so we defer the lazyloading, need to modify accordion to inherit open status so it can be used here.
        filteredRecords: !_.isEmpty(prefilteredAccesses)
          ? prefilteredAccesses ?? []
          : _.filter(permission.permAccess, (acc) => {
              return visibleItems && _.size(visibleItems.accesses) > 0 ? visibleItems.accesses.includes(acc.id) : false;
            }),
        canEditResource: true,
      }}
    />
  );
});

const PermissionBranch = observer((props: PermissionBranchPropsT) => {
  const {
    template,
    permission,
    isRootNode = false,
    isTreeView = false,
    setFilters,
    filters,
    opened = [],
    accessMode = false,
    user,
  } = props;

  const PermissionCmp = getPermissionTemplateComponent(template, permission);

  const summary = (
    <div
      className="tr-flex tr-flex-row grow permission-branch"
      data-testid={`accordion-${permission.id}`}
      onClick={() => {
        if (setFilters) {
          const opened = [...(filters?.Opened ? filters.Opened.split(',') : [])];
          if (opened.includes(permission.id)) {
            _.remove(opened, (item) => item === permission.id);
          } else {
            opened.push(permission.id);
          }
          setFilters({...filters, Opened: opened});
        }
      }}
    >
      <div
        className={`${
          accessMode ? 'tr-basis-3/4' : 'tr-basis-2/5'
        } tr-flex tr-flex-row tr-items-center tr-justify-start `}
      >
        <PermissionCmp.header {...props} />
      </div>
      <div
        className={`${
          accessMode ? 'tr-basis-1/4' : 'tr-basis-3/5'
        } tr-flex tr-pl-1 tr-flex-row tr-align-items-center tr-items-center tr-justify-content-start tr-grow badge-section`}
      >
        {!user && <PermissionCmp.attributes {...props} />}
        <div className="grow"></div>
        <PermissionCmp.actions {...props} />
      </div>
    </div>
  );

  const disabled = accessMode || _.isEmpty(permission.accessAggregated);

  return (
    <Accordion
      isTreeView={isTreeView}
      content={summary}
      defaultState={opened.includes(permission.id)}
      variant="secondary"
      data-testid={`accordion-${permission.id}`}
      className={`${permission.rootResource.userIsOwner && permission.calculatedHidden.value ? 'tr-opacity-60' : '0'} `}
      key={permission.id}
      disabled={disabled}
      isRootNode={isRootNode}
    >
      {({open}) => {
        return <AccessesWrapper {...props} open={open} />;
      }}
    </Accordion>
  );
});

export default PermissionBranch;
