import _ from 'lodash';
import React, {useState} from 'react';
import {Formik, FormikForm} from 'src/components/design/formik';
import axios from 'axios';
import './Settings.scss';
import {logger} from 'src/lib';
import {useToasts} from 'react-toast-notifications';
import {observer} from 'mobx-react';
import {Resource} from 'src/stores/domainObjects/Resource';
import {useRootStore} from 'src/lib/hooks';
import {ConnectionServiceE} from 'src/types';
import {Button, IconButton, TextAreaInput, TextInput} from '@trustle/component-library';
import {object, string} from 'yup';
import {formatDateFromNow, formatUserName} from 'src/lib';
import {Field} from 'formik';
import {Loading} from '@trustle/component-library';
type PropsT = {
  resource: Resource;
  refresh?: any;
  isCreating: boolean;
  connectorType?: ConnectionServiceE;
};

export const ResourceNameAndDescriptionFields = observer(
  (props: {resource: Resource; isCreating: boolean; connectorType?: ConnectionServiceE}) => {
    const {resource, isCreating} = props;
    //Second part of clause is inherited from SystemSetupForm
    const isConnectedSystem = !!(resource.rootResource.connectionId || _.isEmpty(props.connectorType));
    const isSystemResource = !resource.rootSid;
    return (
      <>
        <Field
          component={TextInput}
          label="System Name"
          placeholder="Resource name"
          horizontal={false}
          id="name"
          className="tr-pb-[11px]"
          name="name"
          disabled={isConnectedSystem && !isSystemResource && !isCreating}
          required
        />
        <Field
          component={TextAreaInput}
          label="Description"
          horizontal={false}
          name="description"
          rows={5}
          className="tr-pb-0"
          description={isConnectedSystem ? 'This will be shown to end users' : undefined}
          data-cy="description"
          id="description"
          required={isSystemResource}
        />
      </>
    );
  }
);

const ResourceEditNameDescription = observer(function ResourceEditNameDescription(props: PropsT) {
  const rootStore = useRootStore();
  const {usersStore, newResourceStore, org, currentUser} = rootStore;

  const userEmail = currentUser!.email.toLowerCase();
  const {
    resource = new Resource(rootStore, {
      owners: [userEmail],
      pendingSetup: !!props.connectorType && props.connectorType !== ConnectionServiceE.GENERIC,
      connection: props.connectorType ? {service: props.connectorType} : undefined,
    }),
    refresh,
    isCreating,
  } = props;
  const {addToast} = useToasts();
  const [editMode, setEditMode] = useState<boolean>(isCreating);
  if (_.isNil(org) || org?.defaultSensitivityId === undefined || newResourceStore.loadingRecommendations) {
    return <Loading />;
  }

  const canSeeResource = currentUser.isOrgOwner && !_.includes(resource.owners, userEmail);
  async function saveNameDescription(values: any) {
    if (!resource) {
      return {error: true, msg: 'Resource does not exist.'};
    }
    const url = `/api/resources/${isCreating ? 'system' : resource.id}`;

    const {name, description} = values;
    try {
      const res = await axios.post(url, {resource: {name, description}});
      res.data && newResourceStore.updateResourceFromServer(res.data);
      addToast(`Resource saved successfully`, {
        appearance: 'success',
        autoDismiss: true,
      });
      setEditMode(false);
      refresh && refresh(res);
      return {error: false, msg: 'Saved the resource.'};
    } catch (err) {
      addToast(`An error occurred while saving `, {
        appearance: 'error',
        autoDismiss: true,
      });
      logger.error(err);
      return {error: true, msg: 'Failed to save the resource.'};
    }
  }

  const showNameAndDescription = (params: {
    values: any;
    isValid: boolean;
    isSubmitting: boolean;
    dirty: boolean;
    resetForm: any;
    isCreating: boolean;
  }) => {
    const {values, isValid, isSubmitting, dirty, resetForm, isCreating} = params;
    if (!editMode) {
      return (
        <div className="tr-my-4">
          <div className="tr-flex">
            <div className="tr-grid-rows-2 tr-w-5/6 tr-gap-x-4">
              <div>
                <div className="body6 strong">System Name</div>
                <div>
                  <h2 className="tr-break-words" data-testid={values.name}>
                    {values.name}
                  </h2>
                </div>
              </div>
              <div className="tr-mt-6">
                <div className="body6 strong">Description</div>
                <div>{values.description}</div>
              </div>
            </div>
            {!isCreating && (
              <div className="tr-w-2/6">
                <div className="tr-ml-3 tr-mb-1">
                  <IconButton
                    variant="ternary"
                    data-testid="editMode-icon"
                    onClick={() => {
                      setEditMode(true);
                    }}
                    disabled={canSeeResource}
                    icon="edit"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="tr-my-4">
        <div className="tr-flex">
          <div className="tr-grid-rows-2 tr-w-4/6 tr-gap-x-2">
            <ResourceNameAndDescriptionFields
              resource={resource}
              isCreating={isCreating}
              connectorType={props.connectorType}
            />
          </div>
          <div className="tr-w-2/6">
            <div className="tr-ml-3 tr-mb-1 tr-pt-6">
              <Button
                variant="secondary"
                className="tr-w-20"
                data-testid="editMode-icon"
                onClick={() => {
                  setEditMode(false);
                  resetForm();
                }}
              >
                Cancel
              </Button>
              <Button
                label={'Save'}
                variant="primary"
                className="tr-w-20"
                name="resourceSaveNameDescription"
                data-testid="resourceSaveNameDescription"
                type="submit"
                disabled={!isValid || isSubmitting || !dirty || canSeeResource}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Formik
      initialValues={{
        name: resource.name || '',
        description: resource.description || '',
      }}
      validationSchema={object().shape({
        name: string().max(80, 'The name may not be greater than 80 characters'),
        description: string().max(500, 'The description may not be greater than 500 characters'),
      })}
      onSubmit={async (values) => {
        await saveNameDescription(values);
      }}
      enableReinitialize={true}
    >
      {({isSubmitting, isValid, dirty, values, resetForm}) => {
        return (
          <FormikForm className="resource-edit text-left">
            <div className="simple-setting--edit">
              <div className="tr-grid tr-grid-cols-3 tr-gap-4 tr-my-4">
                <div className="tr-col-span-2">
                  {showNameAndDescription({values, isSubmitting, isValid, dirty, resetForm, isCreating})}
                </div>
                <div className="">
                  <div className={'text-right grayed-out body6 mt-1'}>
                    {resource.lastChange ? (
                      <>
                        <span>{`Last Edited: `}</span>
                        <span className="font-weight-bold">
                          {`${formatDateFromNow(resource.lastChange?.created)} by ${formatUserName(
                            usersStore.usersMap[resource.lastChange?.actor]
                          )}`}
                        </span>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
});

export default ResourceEditNameDescription;
