import axios from 'axios';
import {Field, Form, Formik} from 'formik';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React from 'react';
import {Permission} from 'src/stores/domainObjects/Permission';
import {SubmitButton} from '../design';
import {
  Button,
  DurationInput,
  Icon,
  Modal,
  Select,
  TextAreaInput,
  TextInput,
  Tooltip,
} from '@trustle/component-library';
import {useRootStore} from 'src/lib/hooks';
import {formatUserNameAndEmail} from 'src/lib';
import {DurationT} from 'src/types';
import {array, object, string} from 'yup';
import {translateToUnit} from 'src/lib/validations';

type ModalPropsT = {
  permission?: Permission;
  onHide: () => void;
  show: boolean;
  isManaging?: boolean;
};

const RequestAccessModal = observer(({permission, onHide, show}: ModalPropsT) => {
  const {usersStore, currentUser, org, toast} = useRootStore();
  const userOptions = currentUser.isOrgOwner ? usersStore.activeUsers : usersStore.users;
  const sensitivitySettings = org.sensitivitySettingsSorted;

  const parentResource = permission?.parentResource;
  const rootResource = parentResource?.rootResource;
  const isGenericRequest = _.isNil(permission);
  const defaultUsers = currentUser.isOrgOwner ? [] : [currentUser.id];
  const sensitivitySetting = _.find(sensitivitySettings, {id: permission?.parentResource.sensitivity?.id});
  const maxDurationValue = permission?.calcAccessDuration?.durationValue;
  const maxDurationUnit = permission?.calcAccessDuration?.durationUnit;

  return (
    <Formik<{users: string[]; justification?: string; accessDuration?: DurationT}>
      initialValues={{
        users: defaultUsers,
        justification: undefined,
        // TODO (jg): we should just hide the accessDuration field if we can't find out a max duration
        accessDuration: {durationValue: maxDurationValue, durationUnit: maxDurationUnit},
      }}
      initialTouched={{accessDuration: true}}
      validationSchema={object().shape({
        accessDuration: object().test(
          'access duration allowed by sensitivity',
          ({durationUnit: unit, durationValue: value}, {createError}) => {
            if (_.isNil(maxDurationUnit) || _.isNil(maxDurationValue)) {
              return true;
            }
            // TODO (jg): this math will likely allow invalid durations in some rare situations when future months are more than 720 hours
            const maxAllowedDurationInHours =
              (maxDurationUnit === 'month' ? 720 : maxDurationUnit === 'day' ? 24 : 1) * maxDurationValue;
            const durationInHours = (unit === 'month' ? 720 : unit === 'day' ? 24 : 1) * value;
            if (durationInHours < 0) {
              return createError({message: `Duration value must be greater than 0`});
            }
            if (durationInHours > maxAllowedDurationInHours) {
              return createError({
                message: `Error. This maximum access duration allowed for this permission is ${maxDurationValue} ${maxDurationUnit}${
                  maxDurationValue > 1 ? 's' : ''
                }`,
              });
            }
            return true;
          }
        ),
        justification: string().required('Please specify why the access is needed'),
        users: array().of(string()),
      })}
      onSubmit={async ({justification, users = [], accessDuration}, {resetForm}) => {
        if (!isGenericRequest) {
          const {errors, successes} = await permission!.requestAccess({
            userIds: users,
            justification,
            accessDuration: accessDuration,
          });

          if (!_.isEmpty(errors)) {
            _.each(errors, (e) => toast.error(e));
          }

          if (!_.isEmpty(successes)) {
            toast.success(
              `Succesfully requested access to ${permission.label} for user(s): ${_.join(successes, ', ')}`
            );
          }
        } else {
          // TODO (jg): move into mobx action in the future
          try {
            await axios.post('/api/tasks/access_request', {message: justification});
          } catch (err) {
            toast.error('Error requesting access');
          }
        }
        onHide();
        setTimeout(resetForm, 200);
      }}
    >
      {({handleSubmit, isSubmitting, submitForm, resetForm, isValid, values}) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Modal
              visible={show}
              onClose={() => {
                onHide();
                setTimeout(resetForm, 200);
              }}
              width="md"
              title={`Request access ${!isGenericRequest ? `| ${permission!.label}` : ''}`}
              footer={
                <>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      onHide();
                      setTimeout(resetForm, 200);
                    }}
                  >
                    Cancel
                  </Button>
                  <SubmitButton label="Request" disabled={isSubmitting || !isValid} onClick={submitForm} />
                </>
              }
            >
              <div>
                {!isGenericRequest && (
                  <div className="text-left">
                    <Field
                      component={TextInput}
                      className="tr-mx-3"
                      label={'System'}
                      value={rootResource?.name}
                      disabled={true}
                    />
                    <Field
                      component={TextInput}
                      className="tr-mx-3"
                      label={'Resource'}
                      value={parentResource?.name}
                      disabled={true}
                    />
                    <Field
                      component={TextInput}
                      className="tr-mx-3"
                      label={'Permission'}
                      value={permission!.label}
                      disabled={true}
                    />
                    <Field
                      component={DurationInput}
                      className="tr-mx-3 tr-mb-3"
                      name="accessDuration"
                      label={
                        <>
                          Duration
                          <Tooltip
                            className="tr-font-normal"
                            size={'lg'}
                            content={
                              <div>
                                The default length of time a Trustle User is granted just-in-time (JIT) access to a
                                resource. During this time, the Trustle user possesses on-demand access to the resource,
                                and will be in either an Approved (zero standing), or Active (standing access) state.
                                <div>
                                  <span className="font-bold">This value must be lower </span>
                                  than the maximum defined by the default Sensitivity Score.
                                </div>
                              </div>
                            }
                          >
                            <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                          </Tooltip>
                        </>
                      }
                      includeNoneOption={false}
                      maxDurationValue={translateToUnit(
                        permission.calcAccessDuration,
                        values.accessDuration?.durationUnit
                      )}
                      maxDurationUnit={sensitivitySetting?.maxAccessDurationUnit}
                    />
                  </div>
                )}
                <Field
                  component={Select}
                  name={'users'}
                  isMulti={true}
                  description={
                    _.size(userOptions) === 0
                      ? 'Only Active (Confirmed, Employee/Contractor type) Trustle Users are shown here.'
                      : ''
                  }
                  className="tr-mx-3 tr-mb-4"
                  label="Trustle User(s)"
                  options={_.map(userOptions, (user: any) => {
                    const {id, email} = user;
                    return {id, value: id, label: formatUserNameAndEmail(user) ?? `${email}`};
                  })}
                />
                <Field
                  component={TextAreaInput}
                  style={{height: '107px'}}
                  label="Reason"
                  className="tr-m-3"
                  name="justification"
                  placeholder="What is the reason for this request?"
                />
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
});

export default RequestAccessModal;
