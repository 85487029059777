import {HEATMAP_COLORS} from '../config';
import React from 'react';

export function CustomLegend() {
  return (
    <div className="tr-flex tr-items-center tr-gap-3">
      <strong>Null</strong>
      {HEATMAP_COLORS.map((color, index) => (
        <>
          {index === 1 && <strong>Least</strong>}
          <div
            key={index}
            className={`tr-h-9 tr-w-9 ${
              index === 0 ? 'tr-border-2 tr-border-solid tr-border-[#B0B5C4] tr-rounded' : ''
            }`}
            style={{backgroundColor: color}}
          ></div>
        </>
      ))}
      <strong>Most</strong>
    </div>
  );
}
