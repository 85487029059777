import axios from 'axios';
import React from 'react';
import {useState, useEffect} from 'react';
import {Loading} from '@trustle/component-library';
import MergeUpload from './MergeUpload';
import MergeDisplay from './MergeDisplay';
import {logger} from 'src/lib';
import {useToasts} from 'react-toast-notifications';
import _ from 'lodash';
import MergeHeaderMapping from './MergeHeaderMapping';
import {UserT, UserType} from 'src/types';
import {useRootStore} from 'src/lib/hooks';

const expectedCols = [
  'firstname',
  'lastname',
  'email_address',
  'user_type',
  'managers_email_address',
  'allow_login',
  'department',
  'role',
  'title',
];
const rowToRecKeyMap = {
  email_address: 'email',
  firstname: 'firstname',
  lastname: 'lastname',
  managers_email_address: 'managerEmail',
  user_type: 'type',
  allow_login: 'allowLogin',
  department: 'department',
  role: 'remoteRole',
  title: 'title',
};

const mkHeaderMap = (expectedCols: string[], cols: string[]) => {
  return Object.fromEntries(
    expectedCols.map((expected: string) => {
      const match = cols.find((col: string) => col.toLowerCase() === expected);
      return [expected, match];
    })
  );
};

const findDiffs = (rec: any) => {
  const diffCols = [];
  for (const col of Object.values(rowToRecKeyMap)) {
    if (rec[col] !== rec.curr[col] && !(_.isNil(rec[col]) && _.isNil(rec.curr[col]))) {
      diffCols.push(col);
    }
  }
  return diffCols;
};

const normalizeAllowLogin = (t: any) => {
  if (t === true || t === 1 || t === '1') {
    return true;
  }
  if (t === false || !t || t === 0 || t === '0') {
    return false;
  }
  t = t.toLowerCase();
  if (t === 'true' || t === 't' || t === 'yes' || t === 'y') {
    return true;
  }
  return false;
};

function MergeManager(props: {setModalTitle: (title: string) => void; onClose: () => void}) {
  /* loadState(s):
    0. Instructions && upload button && Parsing CSV & Downloading users -> 2
    2. Column Mapping & Process Changes -> 4
    4. Display Actions -> 5
    5. Start Save
  */
  const {setModalTitle, onClose} = props;
  const {addToast} = useToasts();

  const [loadState, setLoadState] = useState(0);
  const [rows, setRows] = useState<any>();
  const [records, setRecords] = useState<any>();
  const [filename, setFilename] = useState<string>('');
  const [rowsCount, setRowsCount] = useState<number>(0);
  const [error, setError] = useState<string | undefined>();
  const [headerMap, setHeaderMap] = useState<any>();
  const [users, setUsers] = useState<UserT[]>();
  const {usersStore} = useRootStore();

  useEffect(() => {
    void axios.get('/api/users/admin/all').then((result) => setUsers(result.data.users));
  }, []);

  useEffect(() => {
    const saveRecords = async () => {
      try {
        if (!_.isEmpty(records)) {
          await axios.post('/api/users/admin/import', {records});

          setRecords([]);

          //Newly imported just shown in the success modal
          usersStore.setNewlyImported(
            _.filter(records, (user) => {
              return !user.id;
            })
          );
          addToast('Success', {appearance: 'success', autoDismiss: true});
          onClose();
        }
      } catch (err: any) {
        logger.error(err.response.data);
        setError(err.response.data?.error?.message || 'Failed to save changes to records');
        setLoadState(4);
      }
    };

    if (loadState === 4) {
      setModalTitle('Ready to Upload');
    }

    if (loadState === 5) {
      void saveRecords();
    }
  }, [loadState]);

  const next = (err?: any) => {
    if (err) {
      setError(err);
    } else {
      if (loadState === 0) {
        setLoadState(2);
      }
      if (loadState === 2) {
        setLoadState(4);
      }
      if (loadState === 4) {
        setLoadState(5);
      }
    }
    return <Loading />;
  };

  const prev = () => {
    setError(undefined);
    if (loadState === 0) {
      onClose();
    } else {
      setLoadState(0);
    }
    return <Loading />;
  };

  if (loadState === 0) {
    return (
      <MergeUpload
        handleError={setError}
        handleUpload={(data: any, filename: string) => {
          if (!data || data.length === 0) {
            next('No data uploaded.');
          }

          setFilename(filename);
          setRowsCount(_.size(data));
          setRows(data);
          next();
        }}
      />
    );
  }

  if (_.isNil(users)) {
    return <Loading />;
  }

  if (loadState === 2 && _.isNil(headerMap)) {
    const cols = Object.keys(rows[0]);
    const initialHeaderMap = mkHeaderMap(expectedCols, cols);
    const hasUnmatchedHeaders = Object.entries(initialHeaderMap).find((e) => e[1] === undefined);
    if (hasUnmatchedHeaders) {
      return <MergeHeaderMapping cols={cols} headerMap={initialHeaderMap} setHeaderMap={setHeaderMap} />;
    } else {
      setHeaderMap(initialHeaderMap);
    }
    return <Loading />;
  }

  if (loadState === 2) {
    let records = rows.map((row: any) =>
      Object.fromEntries(expectedCols.map((col) => [(rowToRecKeyMap as any)[col], row[headerMap[col]]]))
    );

    // Mark all status as new, changed, or none and normalize
    records = records.map((record: any) => {
      record.allowLogin = normalizeAllowLogin(_.trim(record.allowLogin));
      record.action = 'none';
      record.status = 'none';
      record.doAction = false;
      record.diffs = [];
      record.email = _.trim(_.toLower(record.email));
      record.managerEmail = record.managerEmail?.toLowerCase();
      record.firstname = _.trim(record.firstname);
      record.lastname = _.trim(record.lastname);
      record.type = !_.isEmpty(record.type) ? record.type.toLowerCase() : UserType.employee;
      record.department = _.trim(record.department);
      record.role = _.trim(record.role);
      record.title = _.trim(record.title);

      const match = users.find((u) => u.email.toLowerCase() === record.email);
      if (match) {
        record = {...match, ...record};
        record.curr = match;
        record.diffs = findDiffs(record);
        record.status = record.diffs.length ? 'changed' : 'none';
        record.action = record.diffs.length ? 'update' : 'none';
      } else {
        record.status = 'new';
        record.action = 'add';
      }
      return record;
    });

    for (const user of users) {
      if (!records.some((rec: any) => rec.email === user.email.toLowerCase())) {
        const rec = {
          ...user,
          status: user.tombstone ? 'none' : 'missing',
          action: user.tombstone ? 'none' : 'delete',
          type: user.type,
          diffs: [],
          doAction: false,
          email: user.email.toLowerCase(),
        };

        records.push(rec);
      }
    }
    setRecords(_.orderBy(records, [(user) => user.email.toLowerCase()]));
    return next();
  }

  if (loadState === 4 || loadState === 5) {
    return (
      <div>
        {loadState === 5 && <Loading />}
        <MergeDisplay
          records={records}
          setRecords={setRecords}
          prev={prev}
          next={next}
          filename={filename}
          rowsCount={rowsCount}
          onClose={onClose}
          error={error}
        />
      </div>
    );
  }

  logger.error(`Unknown load state = ${loadState}`);
  return next(`An error has occurred while merging your results.`);
}

export default MergeManager;
