import axios from 'axios';
import _ from 'lodash';
import {makeAutoObservable, onBecomeObserved, runInAction} from 'mobx';

import {DurationUnitT, ResourceCategoryEnum} from 'src/types';
import RootStore from '.';
import {Recommendation} from './domainObjects/Recommendation';
import {Resource} from './domainObjects/Resource';

export type ResourceResponseT = {
  id: string;
  oid: string;
  name: string;
  icon?: string | null;
  description: string;
  parentId?: string;
  createdAt: string;
  reviewedAt?: string;
  deletedAt?: string;
  hidden?: boolean;
  rootSid?: string;
  foreignId?: string;
  approvalDefinition?: string;
  provisionDefinition?: string;
  reapprovalDefinition?: string;
  deprovisionDefinition?: string;
  provisionInstructions?: string;
  accessInstructions?: string;
  durationValue?: number;
  durationUnit?: DurationUnitT;
  accessDurationValue?: number;
  accessDurationUnit?: DurationUnitT;
  autoProvision?: boolean;
  archived?: boolean;
  pendingSetup?: boolean;
  connectionId: string | null;
  sensitivityId?: string | null;
  locked?: boolean;
  category?: ResourceCategoryEnum;
  numAccounts?: number;
  numUnusedAccounts?: number;
  numUnusedAccountsFlagged?: number;
  numAccountsCertified?: number;
  numAccountsLinked?: number;
};

class ResourceStore {
  resourceMap: Record<string, Resource> = {};
  recommendationsMap: Record<string, Recommendation[]> = {};

  error: boolean = false;

  loadingSystems: boolean = true;
  private systemsLoadedAtLeastOnce: boolean = false;

  loadingRecommendations: boolean = false;
  errorLoadingRecommendations: boolean = false;

  loadingAvailable: boolean = true;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this);

    onBecomeObserved(this, 'recommendationsMap', async () => {
      await this.loadRecommendations();
    });

    onBecomeObserved(this, 'resourceMap', async () => {
      await this.loadSystems();
    });
  }
  async loadRecommendations() {
    if (this.loadingRecommendations) {
      return;
    }

    runInAction(() => (this.loadingRecommendations = true));

    try {
      const res = await axios.get('/api/recommendations?includeArchived=true');
      runInAction(() => {
        this.recommendationsMap = _.groupBy(
          res.data.map((data: Partial<Recommendation>) => new Recommendation(this.rootStore, data)),
          'rid'
        );
        this.loadingRecommendations = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errorLoadingRecommendations = true;
        this.loadingRecommendations = false;
      });
    }
  }
  async loadSystems({includeArchived}: {includeArchived?: boolean} = {}) {
    if (!this.systemsLoadedAtLeastOnce) {
      this.loadingSystems = true;
    }
    const res = await axios.get<{resources: ResourceResponseT[]}>(
      `/api/resources/systems${includeArchived ? '?includeArchived=true' : ''}`
    );
    runInAction(() => {
      res.data.resources.forEach((resource) => this.updateResourceFromServer(resource));
      this.loadingSystems = false;
      this.systemsLoadedAtLeastOnce = true;
    });
  }

  get availableReviewedSystems() {
    return _.filter(this.resources, (r) => {
      return !r.rootSid && !r.archived && !r.pendingSetup && !r.deletedAt;
    });
  }

  get systems() {
    return this.resources.filter((r) => {
      return !r.parentId && !r.deletedAt;
    });
  }

  get ownedSystems() {
    return _.filter(this.systems, (r) => {
      return r.userIsOwner && !r.deletedAt;
    });
  }

  get activeSystems() {
    return this.rootStore.currentUser?.isOrgOwner
      ? this.systems.filter((r) => !r.archived && !r.connector?.disabled)
      : this.ownedSystems.filter((r) => !r.archived && !r.connector?.disabled);
  }

  get archivedSystems(): Resource[] {
    return this.rootStore.currentUser?.isOrgOwner
      ? this.systems.filter((r) => r.archived && !r.connector?.disabled)
      : this.ownedSystems.filter((r) => r.archived && !r.connector?.disabled);
  }

  get disabledSystems(): Resource[] {
    return this.rootStore.currentUser?.isOrgOwner
      ? this.systems.filter((r) => r.connector?.disabled)
      : this.ownedSystems.filter((r) => r.connector?.disabled);
  }

  get userResources(): Resource[] {
    return _.filter(this.resources, 'userHasAccess');
  }

  get resources(): Resource[] {
    return _.values(this.resourceMap);
  }

  /** loads basic info about a system */
  async loadDetailed(sid: string, force?: boolean) {
    // only load resource if it hasn't been loaded (as part of loadSystem/loadAvailable).
    // for example when refreshing a Resource page

    if (this.resourceMap[sid] === undefined || force) {
      const res = await axios.get<{resource: ResourceResponseT}>(`/api/resources/${sid}`);
      runInAction(() => this.rootStore.newResourceStore.updateResourceFromServer(res.data.resource));
    }
  }

  updateResourceFromServer(attributes: Partial<Resource> & {id: string}) {
    const existingResource = this.resourceMap[attributes.id];
    if (existingResource) {
      Object.assign(existingResource, {
        ..._.omit(attributes, [
          'permissions',
          'compliance',
          'numAccounts',
          'numUnusedAccounts',
          'numUnusedAccountsFlagged',
          'numAccountsCertified',
          'numAccountsLinked',
        ]),
        _numAccounts: attributes.numAccounts ?? 0,
        _numUnusedAccounts: attributes.numUnusedAccounts ?? 0,
        _numUnusedAccountsFlagged: attributes.numUnusedAccountsFlagged ?? 0,
        _numAccountsCertified: attributes.numAccountsCertified ?? 0,
        _numAccountsLinked: attributes.numAccountsLinked ?? 0,
      });
    } else {
      // build new resource object from latest attributes and replace old one
      const resource = new Resource(this.rootStore, _.omit(attributes, 'permissions'));
      this.resourceMap[resource.id] = resource;
    }
  }

  deleteResource(resource: Resource) {
    void axios.post(`/api/resources/${resource.id}/delete`);
    [resource, ...resource.allNestedResources].forEach((r) => {
      delete this.resourceMap[r.id];
    });
  }

  /** @returns a number between 0 and 1 representing percent of system recs completed  */
  get percentOfRecsCompleted(): number {
    if (_.isEmpty(this.activeSystems)) {
      return 1;
    }
    return _.mean(
      [...this.activeSystems].map((sys) => {
        const recs = this.recommendationsMap[sys.id] ?? [];
        const completed = recs.filter((rec) => !_.isNil(rec.completedOn)).length;
        return recs.length > 0 ? completed / recs.length : 1;
      })
    );
  }

  /** @returns a number between 0 and 1 representing percent of account recs completed  */
  get percentOfAccountRecsCompleted(): number {
    if (_.isEmpty(this.activeSystems)) {
      return 1;
    }
    return _.mean([...this.activeSystems].map((sys) => sys.percentOfAccountRecsCompleted));
  }

  getResourceByConnectionId(connectionId?: string): Resource | undefined {
    if (!connectionId) {
      return undefined;
    }
    return this.resources.find((r) => r?.connectionId === connectionId);
  }
}

export default ResourceStore;
