import React from 'react';
import Import from 'src/components/Import';
import {UserType} from 'src/types';
import _ from 'lodash';
import './MergeUpload.scss';

function MergeUpload(props: {handleUpload: (data: any, filename: string) => void; handleError: (err: any) => void}) {
  const columns = [
    {
      key: 'firstname',
      isRequired: true,
      description: "The user's first name.",
      subtext: 'e.g.: Jon T.',
    },
    {
      key: 'lastname',
      isRequired: true,
      description: "The user's last name.",
      subtext: 'e.g: Appleseed',
    },
    {
      key: 'email_address',
      isRequired: true,
      description: "The user's email address, which will be their primary username.",
      subtext: 'e.g.: username@example.com',
    },
    {
      key: 'user_type',
      isRequired: false,
      description: `The user can be either an ${UserType.employee}, a ${UserType.contractor}, a ${UserType.customer}, or a ${UserType.system}.`,
      subtext: `One of: ${_.values(UserType).join(', ')}`,
    },
    {
      key: 'managers_email_address',
      isRequired: false,
      description: "The email address for the user's manager.",
      subtext:
        "The CEO's manager's email address should be left blank, manager approvals will go to the Trustle workspace owners for approval.",
    },
    {
      key: 'allow_login',
      isRequired: false,
      description: 'Flag to determine if the user can login.',
      subtext: 'Valid options: true ("true", "t", 1, "yes", or "y"), false (anything else).',
    },
    {
      key: 'department',
      isRequired: false,
      description: 'The division or business unit of the organization to which this user belongs.',
      subtext: 'e.g. IT',
    },
    {
      key: 'role',
      isRequired: false,
      description: 'The position or purpose that this user has in the organization.',
      subtext: 'e.g. Chief Technology Officer',
    },
    {
      key: 'title',
      isRequired: false,
      description: 'Professional degree',
      subtext: 'e.g. Systems Engineer',
    },
  ];

  return (
    <div className="merge-upload">
      <div className={'border-bottom mb-3'}>
        <strong>Columns</strong>
      </div>
      <div className={'mt-1 pb-5'}>
        {columns.map((column: any) => (
          <div className={'mb-1'} key={column.key}>
            <b className={'text-capitalize pr-2'}>{`${column.key}:`}</b> {column.description}
            <br />
            <div className={'text-muted'}>{column.subtext}</div>
          </div>
        ))}
      </div>
      <div className="container-import">
        <Import {...props} headerTransform={'snakecase'} />
      </div>
    </div>
  );
}

export default MergeUpload;
