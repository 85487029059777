import {Button, Icon, Tooltip} from '@trustle/component-library';
import React, {useState} from 'react';
import {AttributeMapField} from './AttributeMapField';
import {SERVICE_FIELD_MAPPING_OPTIONS, SERVICE_FIELD_MAPPINGS} from '../helpers';
import {useFormikContext} from 'formik';
import axios from 'axios';
import {AuthorityServiceE} from '../../../../../types';
import {FieldMappingTestResultModal} from '../../modals/FieldMappingTestResultModal';

type Props = {
  service: AuthorityServiceE;
  connectionId: string;
  disableInteractions?: boolean;
};

const ProfileMapping = [
  {
    fieldName: 'email',
    fieldLabel: 'Email',
    mandatory: true,
  },
  {
    fieldName: 'manager',
    fieldLabel: 'Manager',
  },
  {
    fieldName: 'type',
    fieldLabel: 'Type',
  },
  {
    fieldName: 'department',
    fieldLabel: 'Department',
  },
  {
    fieldName: 'role',
    fieldLabel: 'Role',
  },
  {
    fieldName: 'title',
    fieldLabel: 'Title',
  },
];

const TrustleUserTypeMapping = [
  {
    fieldName: 'employee',
    fieldLabel: 'Employee',
    disableMandatory: true,
  },
  {
    fieldName: 'contractor',
    fieldLabel: 'Contractor',
    disableMandatory: true,
  },
  {
    fieldName: 'customer',
    fieldLabel: 'Customer',
    disableMandatory: true,
  },
  {
    fieldName: 'system',
    fieldLabel: 'System',
    disableMandatory: true,
  },
];

export function FieldMappingSection(props: Props) {
  const {service, connectionId, disableInteractions = false} = props;
  const {values} = useFormikContext<any>();
  const [showTestModal, setShowTestModal] = useState(false);
  const [testResults, setTestResults] = useState<any[]>([]);
  const [loadingTestResults, setLoadingTestResults] = useState(false);

  const testMappingHandler = async () => {
    setLoadingTestResults(true);
    const {data} = await axios.post(`/api/connect/${connectionId}/settings/testAuthorityMapping`, {
      service,
      fieldMapping: values?.idpFieldMapping || {},
    });
    setLoadingTestResults(false);
    setTestResults(data);
    setShowTestModal(true);
  };

  return (
    <>
      <FieldMappingTestResultModal
        users={testResults}
        show={showTestModal}
        setShow={setShowTestModal}
        fieldMapping={values?.idpFieldMapping || {}}
      />
      <div id="field-mapping-container">
        <div id="field-mapping-header">
          <span className="tr-flex tr-gap-1 tr-items-center tr-mb-2">
            <h3 className="tr-mb-0">Field Mapping</h3>
            <Tooltip
              interactive
              size={'md'}
              content={
                <div className="tr-py-1 tr-px-2">
                  <p>
                    Field Mapping enables Trustle to derive User Attributes via users’ IDP Authority attributes. Read
                    more about it in:
                  </p>
                  <a href="https://learn.trustle.com/" target="_blank" rel="noopener noreferrer">
                    Trustle Docs under Field Mapping
                  </a>
                </div>
              }
            >
              <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
            </Tooltip>
            <div className="tr-ml-[auto]">
              <Button
                variant="secondary"
                size="md"
                onClick={() => {
                  void testMappingHandler();
                }}
                disabled={loadingTestResults || disableInteractions}
                className="tr-min-w-[200px] tr-p-2"
              >
                Test
              </Button>
            </div>
          </span>
          <p className="small text-muted">
            Fill the following form with the names of the attributes in which the corresponding value is saved in the
            remote system's user profile.
          </p>
        </div>
        <div id="field-mapping-body" className="tr-flex tr-gap-12 tr-flex-wrap xl:tr-flex-nowrap">
          <div id="field-mapping-user-profile" className="tr-w-full">
            <h4>User Profile Information</h4>
            <div className="tr-flex tr-flex-col tr-p-2">
              {ProfileMapping.map(({fieldName, fieldLabel, mandatory}) => (
                <AttributeMapField
                  key={fieldName}
                  fieldName={`idpFieldMapping.${fieldName}`}
                  fieldLabel={fieldLabel}
                  options={SERVICE_FIELD_MAPPING_OPTIONS[service]}
                  serviceFieldMappings={SERVICE_FIELD_MAPPINGS[service]}
                  forceMandatory={mandatory}
                />
              ))}
            </div>
          </div>
          <div id="field-mapping-trustle-types" className="tr-w-full">
            <span className="tr-flex tr-gap-4">
              <h4>Trustle User Type</h4>
            </span>
            <div className="tr-flex tr-flex-col tr-p-2">
              {TrustleUserTypeMapping.map(({fieldName, fieldLabel, disableMandatory}) => (
                <AttributeMapField
                  key={fieldName}
                  fieldName={`idpFieldMapping.${fieldName}`}
                  fieldLabel={fieldLabel}
                  supportRegex
                  options={SERVICE_FIELD_MAPPING_OPTIONS[service]}
                  serviceFieldMappings={SERVICE_FIELD_MAPPINGS[service]}
                  disableMandatory={disableMandatory}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
