import React from 'react';
import {useState} from 'react';
import {observer} from 'mobx-react';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
import {Resource} from 'src/stores/domainObjects/Resource';
import {
  AccessStatusIcon,
  Accordion,
  AutoProvisionIcon,
  Button,
  Card,
  CircleGauge,
  DeepLinkRow,
  Icon,
} from '@trustle/component-library';
import {recStats} from 'src/Routes/Recommendations/MainPage';
import {SystemRecommendationsTab} from 'src/Routes/Recommendations/SystemRecommendations/SystemRecommendations';
import {useFeatureFlags, useRootStore} from 'src/lib/hooks';
import ListGroup from 'src/components/ListGroup/ListGroup';
import {Tab} from 'react-bootstrap';
import {ItemIconTypesEnum} from '@trustle/component-library/dist/types';
import {ConnectionServiceE} from '../../../types';
import {M365LicenseUsageCard} from '../../../dashboard/m365-license-usage/M365LicenseUsageCard';

type PropsT = {
  resource: Resource;
};

const OverviewDashboard = observer(function Overview(props: PropsT) {
  const [selected, setSelected] = useState<string>('account');
  const history = useHistory();
  const {tasksStore, accessStore} = useRootStore();
  const {resource} = props;

  const featureFlagViewer = useFeatureFlags();
  const m365LicensesUsage = featureFlagViewer.isEnabled('m365_licenses_usage');

  const showM365LicenseUsage = m365LicensesUsage && resource.connector?.service === ConnectionServiceE.M365;

  const circles = [
    {color: '#32C5FF', percent: resource.percentOfAccountRecsCompleted},
    {color: '#FF9500', percent: resource.percentOfRecsCompleted},
  ];

  const sysRecs = recStats(resource.sysRecs);
  const tokenAndCertRecs = recStats(resource.tokenAndCertRecs);

  const accessRecordStatuses = _.groupBy(resource.accessAggregates, (ar) => {
    return accessStore.statusArray[ar.value];
  });

  const accessRecordUsers = _.groupBy(resource.accessAggregates, (ar) => {
    return ar.value === 0 ? 'Linked' : 'Unlinked';
  });

  return (
    <div className="container mt-2">
      <div
        className={[
          'tr-max-w-[700px] lg:tr-max-w-[1400px]',
          'tr-flex',
          'lg:tr-flex-row',
          'tr-flex-col',
          'lg:tr-justify-between',
          'xl:tr-content-around',
          'tr-p-2',
        ].join(' ')}
      >
        <div className="tr-w-full xl:tr-w-1/2 tr-flex tr-justify-center">
          <div className="tr-w-1/2 lg:tr-w-full tr-flex tr-flex-col">
            <h1 className="">Trustle Score</h1>
            <>
              <CircleGauge className="sm:tr-w-min-30" circles={circles}>
                <span className="lg:tr-text-5xl md:tr-text-3xl sm:tr-text-5xl strong">
                  {Math.round(100 * _.mean([resource.percentOfAccountRecsCompleted, resource.percentOfRecsCompleted]))}%
                </span>
              </CircleGauge>
              <div className="mt-8">
                {circles.map((circle, i) => {
                  return (
                    <div key={i} className="flex tr-flex-row align-items-center tr-justify-start tr-w-1/2 mb-2">
                      <div>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="18" height="18" rx="4" fill={circle.color} />
                        </svg>
                      </div>
                      <div>
                        <span className="font-bold m-1 body4">{i === 0 ? 'Account' : 'Resources'}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
              {!showM365LicenseUsage && (
                <div className="flex justify-content-end mt-3">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      history.push(`/resource/manage/${resource.id}/accounts`);
                    }}
                  >
                    See Details
                  </Button>
                </div>
              )}
            </>
          </div>
        </div>
        <div className="tr-w-full">
          <Card className="tr-flex flex-column">
            <h1 className="">System Details</h1>
            <div className="tr-flex flex-row">
              <Card
                className="m-4 cursor grow"
                variant={selected === 'resource' ? 'primary' : 'secondary'}
                shadow={true}
                onClick={() => {
                  setSelected('resource');
                }}
              >
                <h1 className="mb-3 mt-3 text-5xl">{_.size(resource.allNestedResources)}</h1>
                <div className="tr-flex align-items-center body1 strong text-lg">
                  <Icon type="resource" size="md" />
                  Resources
                </div>
              </Card>
              <Card
                className="m-4 grow cursor"
                shadow={true}
                variant={selected === 'account' ? 'primary' : 'secondary'}
                onClick={() => {
                  setSelected('account');
                }}
              >
                <h1 className="mb-3 mt-3 text-5xl">{_.size(resource.accounts.filter((a) => !a.refId))}</h1>
                <div className="tr-flex align-items-center body1 strong text-lg">
                  <Icon type="members" size="md" />
                  Accounts
                </div>
              </Card>
            </div>
            {selected === 'resource' && (
              <>
                <DeepLinkRow
                  icon={<AutoProvisionIcon value={true} size="sm" />}
                  title={'Managed By Trustle'}
                  rightSection={`${_.size(resource.allManagedResources)}`}
                  size={'md'}
                  onClick={() => {
                    history.push(`/resource/manage/${resource.id}/resources?Provisioning=Managed`);
                  }}
                />
                <DeepLinkRow
                  icon={<AutoProvisionIcon value={false} size="sm" />}
                  title={'Unmanaged'}
                  size={'md'}
                  rightSection={`${_.size(resource.allUnmanagedResources)}`}
                  onClick={() => {
                    history.push(`/resource/manage/${resource.id}/resources?Provisioning=Unmanaged`);
                  }}
                />
                <DeepLinkRow
                  icon={<AccessStatusIcon status={0} size="sm" />}
                  title={'Unlinked'}
                  rightSection={`${_.size(resource.allUnmappedAccessRecords)}`}
                  size={'md'}
                  onClick={() => {
                    history.push(`/resource/manage/${resource.id}/resources?Status=Attention+Needed`);
                  }}
                />

                <div className="flex justify-content-end mt-2">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      history.push(`/resource/manage/${resource.id}/resources`);
                    }}
                  >
                    {`Go to Resources  >`}
                  </Button>
                </div>
              </>
            )}
            {selected === 'account' && (
              <>
                <Accordion
                  variant="ternary"
                  size="sm"
                  content={
                    <div className="tr-flex items-align-center pl-3 pr-2">
                      <Icon type="members" size="sm" />
                      <span>Users</span>
                    </div>
                  }
                >
                  {() => {
                    return (
                      <div className="p-2">
                        {_.keys(accessRecordUsers).map((key) => {
                          const userItems = accessRecordUsers[key];
                          const label = key;
                          return (
                            <DeepLinkRow
                              key={key}
                              icon={<Icon type="members" size="sm" />}
                              title={label}
                              size={'md'}
                              rightSection={`${_.size(userItems)}`}
                              onClick={() => {
                                history.push(
                                  `/resource/manage/${resource.id}/resources?${
                                    label === 'Unlinked' ? 'Status=Unlinked Account' : `User=${label}`
                                  }`
                                );
                              }}
                            />
                          );
                        })}
                      </div>
                    );
                  }}
                </Accordion>
                <Accordion
                  variant="ternary"
                  size="sm"
                  content={
                    <div className="tr-flex items-align-center pl-3 pr-2">
                      <Icon type="access" size="sm" />
                      <span>Access</span>
                    </div>
                  }
                >
                  {() => {
                    return (
                      <div className="p-2">
                        {_.keys(accessRecordStatuses).map((key) => {
                          const statusItems = accessRecordStatuses[key];
                          return (
                            <DeepLinkRow
                              key={key}
                              icon={<Icon type={key as ItemIconTypesEnum} size="sm" />}
                              title={key}
                              size={'md'}
                              rightSection={`${_.size(statusItems)}`}
                              onClick={() => {
                                history.push(`/resource/manage/${resource.id}/resources?Status=${key}`);
                              }}
                            />
                          );
                        })}
                      </div>
                    );
                  }}
                </Accordion>

                <div className="flex justify-content-end mt-2">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      history.push(`/resource/manage/${resource.id}/accounts`);
                    }}
                  >
                    {`Go to Accounts  >`}
                  </Button>
                </div>
              </>
            )}
          </Card>
        </div>
        <div className="tr-w-full xl:tr-w-1/2">
          <Card className="tr-flex flex-column ">
            <h1 className="">Needs Attention</h1>
            {selected === 'resource' ? (
              <>
                <DeepLinkRow
                  title={'System Recommendations'}
                  variant="secondary"
                  rightSection={`${_.size(sysRecs.unreviewed)}`}
                  size={'md'}
                  onClick={() => {
                    history.push(`/resource/manage/${resource.id}/recommendations/accounts/Accounts Detected`);
                  }}
                />
                <DeepLinkRow
                  title={`${SystemRecommendationsTab.TOKENS_AND_CERTIFICATES} Recommendations`}
                  variant="secondary"
                  size={'md'}
                  rightSection={`${_.size(tokenAndCertRecs.unreviewed)}`}
                  onClick={() => {
                    history.push(`/resource/manage/${resource.id}/recommendations/accounts/Unused Accounts`);
                  }}
                />
                <DeepLinkRow
                  title={'Tasks to Complete'}
                  variant="secondary"
                  rightSection={`${_.size(
                    tasksStore.accessRequestTasks.filter((accessRequest) => accessRequest.forSystem?.id === resource.id)
                  )}`}
                  size={'md'}
                  onClick={() => {
                    history.push(`/resource/manage/${resource.id}/recommendations/accounts/Access Review`);
                  }}
                />
              </>
            ) : (
              <>
                <DeepLinkRow
                  title={'Accounts Detected'}
                  variant="secondary"
                  rightSection={`${_.size(resource.accounts.filter((a) => !a.refId))}`}
                  size={'md'}
                  onClick={() => {
                    history.push(`/resource/manage/${resource.id}/recommendations/accounts/Accounts Detected`);
                  }}
                />
                <DeepLinkRow
                  title={'Unused Accounts'}
                  variant="secondary"
                  size={'md'}
                  rightSection={`${_.size(resource.unusedAccounts)}`}
                  onClick={() => {
                    history.push(`/resource/manage/${resource.id}/recommendations/accounts/Unused Accounts`);
                  }}
                />
                <DeepLinkRow
                  title={'Access Reviews'}
                  variant="secondary"
                  rightSection={`${_.size(_.filter(resource.unreviewedAccounts, {isOffboarded: false}))}`}
                  size={'md'}
                  onClick={() => {
                    history.push(`/resource/manage/${resource.id}/recommendations/accounts/Access Review`);
                  }}
                />
              </>
            )}
          </Card>
        </div>
      </div>
      {!showM365LicenseUsage && (
        <div className="tr-mt-3">
          <Tab.Container defaultActiveKey="1">
            <div className="tr-flex tr-gap-6 tr-flex-col md:tr-flex-row tr-w-full">
              <div className="tr-w-full md:tr-w-1/3">
                <ListGroup>
                  <ListGroup.Item action eventKey="1" variant="ltblue">
                    What is your Trustle score?
                  </ListGroup.Item>
                  <ListGroup.Item action eventKey="2" variant="ltblue">
                    How does Trustle manage your system?
                  </ListGroup.Item>
                  <ListGroup.Item action eventKey="3" variant="ltblue">
                    How to setup your default settings?
                  </ListGroup.Item>
                  <ListGroup.Item action eventKey="4" variant="ltblue">
                    Why link accounts?
                  </ListGroup.Item>
                </ListGroup>
              </div>
              <div className="tr-w-full md:tr-w-2/3">
                <Tab.Content>
                  <Tab.Pane eventKey="1">
                    <h2>Your Trustle score is calculated based on our security recommendations</h2>
                    <p className=" font-weight-bold">Trustle recommendations entail things like:</p>
                    <ul>
                      <li className="mb-3">
                        <strong>Unusual Access </strong> - review access that looks odd based on role.
                      </li>
                      <li className="mb-3">
                        <strong>Unused Accounts</strong> - Discover unused accounts or resources.
                        <p>
                          Trustle looks at the last activity of each user’s account and compares their usage to their
                          level of access.
                        </p>
                      </li>
                      <li className="mb-3">
                        <strong>Rotate Access Keys</strong> - on accounts that have key(s) in use for long periods of
                        time.
                      </li>
                      <li className="mb-3">
                        <strong>Safe and Secure</strong> - Trustle updates the recommendations everytime you connect.
                        <p>
                          This will allow you to stay current with everything happening in your system so you know you
                          are working with the most recent and relavent information keeping your Org Secure.
                        </p>
                      </li>
                    </ul>
                  </Tab.Pane>
                  <Tab.Pane eventKey="2">
                    <h2>Trustle can automate and manage your team’s access for you!</h2>
                    <p className="font-weight-bold">
                      By default, Trustle won’t manage anything until you can give us the go-ahead.{' '}
                    </p>
                    <ul>
                      <li className="mb-3">
                        <strong>Unmanged </strong> - You will need to grant, manage, review and revoke access manually.
                        <p>
                          Use trustle as a monitoring system. See what is happening in your org, who has access to what,
                          control everything from one centralized platform.{' '}
                        </p>
                      </li>
                      <li className="mb-3">
                        <strong>Managed</strong> - Trustle will handle automatic provisioning and deprovisioning tasks
                        based on the parameters you set.{' '}
                        <p>
                          Allow trustle to automate your team’s access requests and automatically deprovision access
                          when it is no longer needed. Allowing users to re-request access easily and keeping your
                          system secure.
                        </p>
                      </li>
                    </ul>
                  </Tab.Pane>
                  <Tab.Pane eventKey="3">
                    <h2>You’re in control!</h2>
                    <p className="font-weight-bold">
                      How you setup your default settings determines what trustle can do for you.{' '}
                    </p>
                    <ul>
                      <li className="mb-3">
                        <strong>Allow Trustle to manage </strong> - This is the best way to get the most of what trustle
                        has to offer.
                        <p>
                          Trustle can automatically keep your system secure by removing unused accounts, granting
                          developers “just-in-time” access and taking the stress of of securing your system off your
                          shoulders.
                        </p>
                      </li>
                      <li className="mb-3">
                        <strong>Use Trustle unmanged</strong> - See who has access, generate compliance reports, and
                        manually have control over each of your connected systems.
                        <p>
                          Allow trustle to automate your team’s access requests and automatically deprovision access
                          when it is no longer needed. Allowing users to re-request access easily and keeping your
                          system secure.
                        </p>
                      </li>
                      <li className="mb-3">
                        <strong>Duration, Visability, and more... </strong> - Set up each of you policies, groups and
                        teams with tailored parameters to keep your assets secure and allow your team to do what they
                        need to.
                        <p>
                          Set the “access duration”, and “approval duration” to allow easy-to-request access. Set
                          certain high-senstive assets to be hidden from general users.
                        </p>
                      </li>
                    </ul>
                  </Tab.Pane>
                  <Tab.Pane eventKey="4">
                    <h2>Unlinked accounts are “Unmonitored accounts”. </h2>
                    <p className="font-weight-bold">
                      Unlinked accounts show who existis in your organization. Linked accounts show so much more.
                    </p>
                    <ul>
                      <li className="mb-3">
                        <p>
                          <strong>Unlinked Accounts </strong> - You will be able to see who is there, what they have
                          access to, but that is it. You wont be able to make use of all the capabilities trustle
                          brings.
                        </p>
                      </li>
                      <li className="mb-3">
                        <p>
                          <strong>Linked Accounts</strong> - Allow trustle to automate access, monitor accounts,
                          highlight suspicious activity, visualize usage etc.
                        </p>
                      </li>
                    </ul>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      )}

      {showM365LicenseUsage && (
        <div className="tr-mt-4 tr-mb-8">
          <M365LicenseUsageCard showHeatmap resourceId={resource.id} />
        </div>
      )}
    </div>
  );
});

export default OverviewDashboard;
