import React, {Dispatch} from 'react';
import {Checkbox, Table} from '@trustle/component-library';
import {COLUMNS} from './config';

type Props = {
  licenses: {key: string; displayName: string}[];
  selectedLicenses: string[];
  setSelectedLicenses: Dispatch<React.SetStateAction<string[]>>;
  summarizeAllLicenses: boolean;
  setSummarizeAllLicenses: Dispatch<React.SetStateAction<boolean>>;
};

const MAX_LICENSES_SELECTED = 4;

export function LicensesTable({
  licenses,
  selectedLicenses,
  setSelectedLicenses,
  summarizeAllLicenses,
  setSummarizeAllLicenses,
}: Props) {
  const selectLicensesHandler = (row: {key: string}, isSelect: boolean) => {
    if (isSelect) {
      setSelectedLicenses((prev) => {
        if (prev.length < MAX_LICENSES_SELECTED) {
          return [...prev, row.key];
        }
        return [...prev.slice(1), row.key];
      });
    } else {
      setSelectedLicenses((prev) => prev.filter((license) => license !== row.key));
    }
  };

  return (
    <div className="tr-flex tr-flex-col">
      <h2>Licenses</h2>
      <div className="tr-flex tr-gap-2">
        <div>
          <Checkbox
            id="overall-license-usage"
            name="overall-license-usage"
            checked={summarizeAllLicenses}
            onChange={(e: any) => {
              setSummarizeAllLicenses(e.target.checked);
            }}
          />
        </div>
        <span className="tr-flex tr-gap-2 ">
          <strong>Overall Utilization</strong>
          <p className="tr-font-light">(Or select up to 4 to compare)</p>
        </span>
      </div>
      <Table
        overrideKeyField="key"
        tableKey="licenses"
        data={licenses}
        columns={COLUMNS}
        hideSizePerPage
        selectRow={{
          mode: 'checkbox',
          clickToSelect: false,
          hideSelectAll: true,
          selected: selectedLicenses,
          onSelect: selectLicensesHandler,
        }}
      />
    </div>
  );
}
