import {ConnectionServiceE} from 'src/types';
import {History, Location} from 'history';

export function parseAndCleanUpQueryParams(history: History, location: Location, onError: (e: any) => void) {
  const queryParams = new URLSearchParams(location.search);

  if (queryParams.has('oauth_error')) {
    const error = queryParams.get('oauth_error');
    onError(error);
    queryParams.delete('oauth_error');
    history.replace({search: queryParams.toString()});
    return error;
  }
}

export const getAuthorizationURL = (
  connectionId: string,
  service: ConnectionServiceE,
  credentialsType: any,
  editMode: boolean
) => {
  const params = new URLSearchParams({
    connectionId: connectionId,
    editConnection: `${editMode}`,
    credentialsType,
    grantType: 'authorization_code',
  });

  return `/api/connect/${service}/oauth/start?${params.toString()}`;
};

export const getAvailableFields = (service: ConnectionServiceE | undefined) => {
  switch (service) {
    case ConnectionServiceE.AZURE_AD: {
      return {
        'Display Name': 'displayName',
        'First Name': 'givenName',
        'Last Name': 'surname',
        'User Principal Name': 'userPrincipalName',
        Email: 'mail',
        Manager: 'manager.mail',
        'Employee Type': 'userType',
        Department: 'department',
        Title: 'jobTitle',
        Role: 'role',
      };
    }
    case ConnectionServiceE.OKTA: {
      return {
        Username: 'login',
        'Display Name': 'displayName',
        'First Name': 'firstName',
        'Last Name': 'lastName',
        Email: 'email',
        Manager: 'manager',
        Department: 'department',
        Title: 'title',
        Role: 'role',
      };
    }
    default:
      return {};
  }
};
