import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Field, Form, Formik, ErrorMessage} from 'formik';
import {Button, Icon, Modal, TextInput} from '@trustle/component-library';
import {boolean, object, ref, string} from 'yup';
import axios from 'axios';
import SecurityCodeInput from 'src/components/design/formik/SecurityCodeInput';
import _ from 'lodash';
import Eula from './EULA';
import {passwordPolicyHint} from 'src/views/User/ResetPassword/ResetPasswordForm';

const SignupForm = observer(() => {
  const [page, setPage] = useState<1 | 2 | 3>(1);
  const [values, setValues] = useState({email: '', firstname: '', lastname: '', termsAccepted: false});
  const [password, setPassword] = useState<string>();
  const [termsModalShowing, setTermsModalShowing] = useState(false);
  const [triedOAuth, setTriedOAuth] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const handleShowBtnChange = (value: boolean): void => {
    setShowButton(value);
  };

  const ErrorIcon = () => (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.666992 12.9999H15.3337L8.00033 0.333252L0.666992 12.9999ZM8.66699 10.3333C8.66699 10.7014 8.36852 10.9999 8.00033 10.9999C7.63214 10.9999 7.33366 10.7014 7.33366 10.3333C7.33366 9.96506 7.63214 9.66659 8.00033 9.66659C8.36852 9.66659 8.66699 9.96506 8.66699 10.3333ZM8.66699 7.66659C8.66699 8.03478 8.36852 8.33325 8.00033 8.33325C7.63214 8.33325 7.33366 8.03478 7.33366 7.66659V4.99992C7.33366 4.63173 7.63214 4.33325 8.00033 4.33325C8.36852 4.33325 8.66699 4.63173 8.66699 4.99992V7.66659Z"
        fill="#E53451"
      />
    </svg>
  );

  type EulaFooterContentProps = {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  };

  const EulaFooterContent: React.FC<EulaFooterContentProps> = ({setFieldValue}) => {
    return (
      <div className="tr-flex tr-flex-col md:tr-flex-row tr-justify-between tr-mr-6">
        <Button onClick={() => setTermsModalShowing(false)} variant="secondary">
          Decline
        </Button>
        <div className="tr-flex tr-flex-col-reverse md:tr-flex-row md:tr-items-center ">
          <span className="tr-mx-4 tr-text-sm tr-opacity-60">
            by clicking accept you are agreeing to the terms and conditions
          </span>
          <Button
            onClick={() => {
              setFieldValue('termsAccepted', true, false);
              setTermsModalShowing(false);
              if (triedOAuth) {
                window.location.href = '/api/auth/oauth/authenticate?signup=true';
              }
            }}
            disabled={!showButton}
          >
            Accept
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className="tr-text-3xl tr-border-solid tr-border-gray-200 tr-border-0 tr-border-b-2 tr-flex tr-justify-between tr-items-center tr-pb-4 tr-mb-4">
        {['Sign Up', 'Set Password', 'Verify Email'][page - 1]}
        <div className="tr-text-base tr-text-blue">
          <span
            className={`tr-border-solid tr-border-0 tr-px-1 ${page === 1 ? 'tr-border-b-4' : 'tr-opacity-40'} tr-mr-2`}
          >
            1
          </span>
          <span
            className={`tr-border-solid tr-border-0 tr-px-1 ${page === 2 ? 'tr-border-b-4' : 'tr-opacity-40'} tr-mr-2`}
          >
            2
          </span>
          <span className={`tr-border-solid tr-border-0 tr-px-1 ${page === 3 ? 'tr-border-b-4' : 'tr-opacity-40'}`}>
            3
          </span>
        </div>
      </h2>
      {page === 1 && (
        <>
          <Formik
            initialValues={values}
            validationSchema={object().shape({
              email: string()
                .email()
                .required()
                .test('checkEmail', 'Email is already taken', async (email) => {
                  const res = await axios.post('/api/auth/email_check', {email});
                  return !res.data;
                }),
              firstname: string().required('First name is required'),
              lastname: string().required('Last name is required'),
              termsAccepted: boolean()
                .required('You must accept the terms and conditions')
                .oneOf([true], 'You must accept the terms and conditions'),
            })}
            onSubmit={(values) => {
              // user fills out password fields while email is being sent
              void axios.post<{error?: {type: string; uid: string}}>('/api/auth/email_code', {
                ...values,
                validateInvited: true,
              });

              setPage(2);
              setValues(values);
            }}
          >
            {({values, isValid, isSubmitting, setFieldValue}) => (
              <Form>
                <button
                  type="button"
                  className="tr-bg-[#F2F2F2] disabled:tr-opacity-25 tr-mt-2 tr-p-[1px] tr-text-sm hover:tr-no-underline tr-rounded-lg tr-items-center tr-border-0 tr-w-full"
                  onClick={() => {
                    if (values.termsAccepted) {
                      window.location.href = '/api/auth/oauth/authenticate?signup=true';
                    } else {
                      setTriedOAuth(true);
                      setTermsModalShowing(true);
                    }
                  }}
                >
                  <Icon type="GoogleLogo" title="Google logo" className="tr-p-2" forcedSize={16} />
                  <span className="tr-grow tr-text-center">Sign up with Google</span>
                </button>
                <div className="tr-flex tr-items-center tr-my-4">
                  <hr className="tr-grow tr-h-px" />
                  <span className="tr-mx-4 tr-font-bold">or</span>
                  <hr className="tr-grow tr-h-px" />
                </div>
                <p className="tr-font-bold">Use your email</p>
                <Field component={TextInput} label="Email Address *" name="email" />
                <Field component={TextInput} label="First Name *" name="firstname" />
                <Field component={TextInput} label="Last Name *" name="lastname" />
                <div className="tr-flex tr-justify-between tr-items-center">
                  <label className="tr-flex tr-items-center">
                    <Field type="checkbox" name="termsAccepted" />

                    <div className="tr-text-xs tr-ml-2 tr-text-gray-400">
                      <span>I've read and agree to the</span>
                      <button
                        className="tr-border-0 tr-bg-inherit text-left tr-p-0 tr-underline tr-text-gray-400 tr-font-bold"
                        type="button"
                        onClick={() => {
                          setTermsModalShowing(true);
                        }}
                      >
                        Terms of Service and Privacy Policy
                      </button>

                      <Modal
                        width="lg"
                        onClose={() => {
                          setTermsModalShowing(false);
                        }}
                        visible={termsModalShowing}
                        footer={<EulaFooterContent setFieldValue={setFieldValue} />}
                      >
                        <Eula onShowBtnChange={handleShowBtnChange} />
                      </Modal>
                    </div>
                  </label>
                  <Button type="submit" disabled={!isValid || isSubmitting}>
                    Continue
                  </Button>
                </div>
                <ErrorMessage name="termsAccepted" component="div">
                  {(message) => (
                    <div className="tr-flex items-center">
                      <ErrorIcon />
                      <span className="mt-2 mb-2 flex text-caption12 opacity-60 ml-1">{message}</span>
                    </div>
                  )}
                </ErrorMessage>
              </Form>
            )}
          </Formik>
        </>
      )}
      {page === 2 && (
        <Formik
          initialValues={{password: '', confirmPassword: ''}}
          validationSchema={object().shape({
            password: string()
              .required()
              .min(8)
              .test('checkPassword', passwordPolicyHint, async (password) => {
                const res = await axios.post('/api/auth/password_check', {password});
                return !res.data.potentiallyExposed;
              }),
            passwordConfirmation: string().oneOf([ref('password'), null], 'passwords must match'),
          })}
          onSubmit={({password}) => {
            setPassword(password);
            setPage(3);
          }}
        >
          {({isValid, dirty, isSubmitting}) => (
            <Form>
              <p className="tr-font-bold">Username</p>
              <p className="tr-font-bold">{}</p>
              <Field component={TextInput} label="Password *" name="password" type="password" />
              <Field component={TextInput} label="Confirm Password * " name="passwordConfirmation" type="password" />
              <div className="tr-flex tr-justify-between tr-items-center">
                <button
                  className="tr-border-0 tr-bg-inherit tr-text-[#007bff] tr-text-sm tr-font-bold"
                  type="button"
                  onClick={() => setPage(1)}
                >
                  {'< Go Back'}
                </button>
                <Button type="submit" disabled={!isValid || !dirty || isSubmitting}>
                  Continue
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {page === 3 && (
        <Formik
          initialValues={{code: ''}}
          validationSchema={object().shape({code: string().min(6, '').max(6, '')})}
          onSubmit={async ({code}, {setErrors}) => {
            try {
              const res = await axios.post<{redirectUrl: string}>('/api/auth/confirm_code', {code, password});
              window.location.href = `${res.data.redirectUrl}/admin/setup`;
            } catch (err) {
              setErrors({code: 'Invalid code'});
            }
          }}
        >
          {({isValid, isSubmitting}) => (
            <Form className="relative">
              {isSubmitting && (
                <div className="tr-absolute tr-inset-x-0 -tr-top-16 -tr-bottom-24 tr-bg-white tr-z-50 tr-opacity-90 tr-flex tr-flex-col tr-items-center">
                  <p className="tr-font-bold tr-mt-32 tr-text-lg">Verifying Email...</p>
                  <svg
                    className="tr-animate-spin -tr-ml-1 tr-h-7 tr-w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="tr-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path
                      className="tr-opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                </div>
              )}
              <p>
                We sent an email to {values.email} with a 6 digit code. To continue, please Check Your Email and verify
                your account.
              </p>
              <div className="tr-flex tr-justify-center">
                <SecurityCodeInput name="code" />
              </div>
              <div className="tr-flex tr-justify-between">
                <div className="tr-flex tr-flex-col">
                  <p className="tr-text-sm tr-mb-0">Didn't receive an email?</p>
                  <Button
                    variant="ternary"
                    onClick={_.debounce(() => void axios.post('/api/auth/email_code', {email: values.email}), 5000, {
                      leading: true,
                      trailing: false,
                    })}
                  >
                    Resend Email
                  </Button>
                </div>
                <Button className="tr-h-fit" type="submit" disabled={!isValid || isSubmitting}>
                  Verify
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
});

export default SignupForm;
