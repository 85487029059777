import {Button, Icon, Tooltip} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import {ColumnDescription} from '@trustle/component-library';
import {Link, useHistory} from 'react-router-dom';
import {createdDaysAgo, dateRange, formatFullDate, formatUserName} from 'src/lib';
import {useRootStore} from 'src/lib/hooks';
import {Account} from 'src/stores/domainObjects/Account';
import {Recommendation, RECOMMENDATION_TYPE} from 'src/stores/domainObjects/Recommendation';
import UserAccountColumnContent from '../common/UserAccount';
import * as AccountColumns from './AccountColumns';

const {ROTATE_ACCESS_KEY_1: RAK1, ROTATE_ACCESS_KEY_2: RAK2} = RECOMMENDATION_TYPE;

export const TypeCol: ColumnDescription<Recommendation> = {
  dataField: 'type',
  text: 'Recommendation',
  headerAlign: 'left',
  align: 'left',
  sort: true,
};

export const CompletedOnCol: ColumnDescription<Recommendation> = {
  dataField: 'completedOn',
  text: 'Completed On',
  headerAlign: 'left',
  align: 'left',
  formatter: (timestamp: string) => moment(timestamp).format('MMMM Do, YYYY'),
  sort: true,
};

// TODO (jg): might need to dereference completedBy/assignedBy/assignedTo inside of observer if there is update problems
const ObservableUserLink = observer(({uid}: {uid?: string}) => {
  const {usersStore} = useRootStore();
  if (_.isNil(uid)) {
    return <></>;
  }
  return <Link to={`/users/${uid}/current`}>{formatUserName(usersStore.usersMap[uid])}</Link>;
});

export const CompletedByCol: ColumnDescription<Recommendation> = {
  dataField: 'completedBy',
  text: 'Completed By',
  headerAlign: 'left',
  align: 'left',
  formatter: (_cell, rec: Recommendation) => <ObservableUserLink uid={rec.completedBy} />,
  sort: true,
};

export const AssignedToCol: ColumnDescription<Recommendation> = {
  dataField: 'assignedTo',
  text: 'Linked To',
  headerAlign: 'left',
  align: 'left',
  formatter: (_cell, rec: Recommendation) => <ObservableUserLink uid={rec.assignedTo} />,
  sort: true,
};

export const AssignedOnCol: ColumnDescription<Recommendation> = {
  dataField: 'assignedOn',
  text: 'Linked On',
  headerAlign: 'left',
  align: 'left',
  formatter: (timestamp: string) => moment(timestamp).format('MMMM Do, YYYY'),
  sort: true,
};

export const AssignedByCol: ColumnDescription<Recommendation> = {
  dataField: 'assignedBy',
  text: 'Linked By',
  headerAlign: 'left',
  align: 'left',
  formatter: (_cell, rec: Recommendation) => <ObservableUserLink uid={rec.assignedBy} />,
  sort: true,
};

export const ActionsCol: ColumnDescription<Recommendation> = {
  dataField: 'actions',
  text: 'Actions',
  style: {width: '16rem'},
  headerStyle: {width: '16rem'},
  formatter: (_cell, rec: Recommendation) => {
    const isOwner = rec.system?.userIsOwner;
    const history = useHistory();
    return [RAK1, RAK2].includes(rec.type) && rec.associatedAccount?.uid ? (
      <></>
    ) : (
      <Button variant="secondary" disabled={!isOwner} onClick={() => history.push(rec.actionLink)}>
        {rec.actionText}
      </Button>
    );
  },
};

export const UserAccountCol: ColumnDescription<Recommendation> = {
  ...(_.omit(AccountColumns.UserAccountColumn({}), 'formatter', 'sortValue') as ColumnDescription<any>),
  formatter: (_cell, rec: Recommendation) => <UserAccountColumnContent account={rec.associatedAccount} />,
  sortValue: (_cell, rec: Recommendation) => rec.associatedAccount?.forUser?.email ?? '',
};

export const RiskScoreCol: ColumnDescription<Recommendation> = {
  ...(AccountColumns.RiskScoreCol as ColumnDescription<any>),
  formatter: (cell, rec: Recommendation, i, formatExtraData: {onClick?: (acc?: Account) => void}) => {
    return (
      <AccountColumns.ObservableRisk
        account={rec.associatedAccount}
        onClick={() => formatExtraData.onClick?.(rec.associatedAccount)}
      />
    );
  },
  sortValue: (_cell: any, rec: Recommendation) =>
    _.isNumber(rec.associatedAccount?.riskScore) ? rec.associatedAccount?.riskScore ?? 0 : -1,
};

export const LastRotatedCol: ColumnDescription<Recommendation> = {
  dataField: 'data.lastRotated',
  text: 'Last Rotated',
  formatter: (lastRotated: string) => {
    return (
      <Tooltip size="lg" content={formatFullDate(lastRotated)}>
        {createdDaysAgo(lastRotated)}
      </Tooltip>
    );
  },
  headerAlign: 'left',
  align: 'left',
  sort: true,
};

// ---- Grouped Recommendation Columns -----

export enum GROUPED_REC_TYPE {
  RDC = 'Review Default Configuration',
  RCS = 'Review Connection Settings',
  ESU = 'Enable Retrieving Service Usage Data',
  RAK = 'Rotate Access Keys',
  RC = 'Rotate Certificates',
}

export type GroupedRecs = {id: number; type: GROUPED_REC_TYPE; expandable: boolean; recs: Recommendation[]};

export const GroupedRecsTypeCol: ColumnDescription<GroupedRecs> = {
  dataField: 'type',
  text: 'Recommendations',
  formatter: (_cell, {type, recs}) => (
    <div className="text-base">{`${type} ${
      [GROUPED_REC_TYPE.RAK, GROUPED_REC_TYPE.RC].includes(type) || recs.length > 1 ? `(${recs.length})` : ''
    }`}</div>
  ),
  sort: true,
  headerAlign: 'left',
  align: 'left',
  headerStyle: {width: '16rem'},
  style: {width: '16rem'},
};

export const ExpandIndicatorCol: ColumnDescription<GroupedRecs> = {
  dataField: 'expandable',
  text: '',
  formatter: (expandable: boolean) => (expandable ? <Icon type="chevronRight" /> : <></>),
  headerStyle: {width: '6rem'},
  style: {width: '6rem'},
};

export const GroupedRecsStatusCol: ColumnDescription<GroupedRecs> = {
  dataField: 'status',
  text: 'Status',
  formatter: (_cell, {type, recs}) =>
    [GROUPED_REC_TYPE.RAK, GROUPED_REC_TYPE.RC].includes(type)
      ? `Last Time Rotated: ${dateRange(recs.map((r) => r.data?.lastRotated))}`
      : recs[0].status,
  headerAlign: 'left',
  align: 'left',
};

export const GroupedRecsActionsCol: ColumnDescription<GroupedRecs> = {
  dataField: 'actions',
  text: 'Actions',
  formatter: (
    _unused,
    {type, recs},
    _i,
    {selected, onClick}: {selected: _.Dictionary<string[]>; onClick: (recs: Recommendation[]) => void}
  ) => {
    const isOwner = recs[0].system?.userIsOwner;
    const history = useHistory();
    if (recs.some((r) => r.assignedTo || r.completedBy)) {
      return <></>;
    }
    if ([GROUPED_REC_TYPE.RAK, GROUPED_REC_TYPE.RC].includes(type)) {
      // hide button if all associated accounts are unmapped
      if (!recs.some((rec) => rec.associatedAccount?.uid)) {
        return <></>;
      }
      const numSelected = selected[type]?.length ?? 0;
      const allSelected = numSelected === 0 || numSelected === recs.length;
      return (
        <Button
          variant="secondary"
          className="w-50"
          size="sm"
          disabled={!isOwner}
          onClick={(e) => {
            e.stopPropagation();
            const selectedRecs = _.compact(selected[type]?.map((id: string) => _.find(recs, {id})) ?? []);
            onClick(allSelected ? recs : selectedRecs);
          }}
        >{`Request ${allSelected ? 'All' : `(${numSelected})`} Users Rotate Keys`}</Button>
      );
    }

    return (
      <Button variant="secondary" className="w-50" disabled={!isOwner} onClick={() => history.push(recs[0].actionLink)}>
        {recs[0].actionText}
      </Button>
    );
  },
};

export const GroupedRecsCompletedOnCol: ColumnDescription<GroupedRecs> = {
  ...(_.omit(CompletedOnCol, 'formatter') as ColumnDescription<any>),
  formatter: (_cell, {recs}, i) => CompletedOnCol.formatter?.(_cell, _.maxBy(recs, 'completedOn')!, i),
};

export const GroupedRecsCompletedByCol: ColumnDescription<GroupedRecs> = {
  ...(_.omit(CompletedByCol, 'formatter') as ColumnDescription<any>),
  formatter: (_cell, {recs}, i) => CompletedByCol.formatter?.(_cell, _.maxBy(recs, 'completedOn')!, i),
};
