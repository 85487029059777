import React, {useState} from 'react';
import axios from 'axios';
import {observer} from 'mobx-react';
import {RouteComponentProps} from 'react-router-dom';
import Header from './Header';
import {Field, Form, Formik, ErrorMessage} from 'formik';
import {Button, Modal, TextInput, Icon, PasswordInput} from '@trustle/component-library';
import Eula from '../EULA';
import {boolean, object, string} from 'yup';

import OAuthButton from '../OAuthButton';

const CreateOrgPage: React.FC<RouteComponentProps> = observer(() => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [values, setValues] = useState({email: '', firstname: '', lastname: '', password: '', termsAccepted: false});
  const [showButton, setShowButton] = useState(false);
  const [triedOAuth, setTriedOAuth] = useState(false);

  const [termsModalShowing, setTermsModalShowing] = useState(false);
  const handleSendEmail = async () => {
    try {
      void axios.post<{error?: {type: string; uid: string}}>('/api/auth/signup', {
        ...values,
        validateInvited: true,
      });
      setCurrentPage(2);
    } catch (e) {
      throw new Error(`Error sending email: ${e}`);
    }
  };
  const pages = [1, 2];

  const handleShowBtnChange = (value: boolean): void => {
    setShowButton(value);
  };

  type EulaFooterContentProps = {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  };

  const EulaFooterContent: React.FC<EulaFooterContentProps> = ({setFieldValue}) => {
    return (
      <div className="tr-flex tr-flex-col md:tr-flex-row tr-justify-between tr-mr-6">
        <Button onClick={() => setTermsModalShowing(false)} variant="secondary">
          Decline
        </Button>
        <div className="tr-flex tr-flex-col-reverse md:tr-flex-row md:tr-items-center ">
          <span className="tr-mx-4 tr-text-sm tr-opacity-60">
            by clicking accept you are agreeing to the terms and conditions
          </span>
          <Button
            onClick={() => {
              setFieldValue('termsAccepted', true, false);
              setTermsModalShowing(false);
              if (triedOAuth) {
                window.location.href = '/api/auth/oauth/authenticate?signup=true';
              }
            }}
            disabled={!showButton}
          >
            Accept
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header currentPage={currentPage} changePage={() => {}} pages={pages} />
      {currentPage === 1 && (
        <>
          <Formik
            initialValues={values}
            validateOnBlur={true}
            validateOnChange={true}
            initialTouched={{
              firstname: true,
              lastname: true,
              email: true,
              password: true,
            }}
            validationSchema={object().shape({
              email: string()
                .email()
                .required('Email is required')
                .test('checkEmail', 'Email is already taken', async (email) => {
                  if (!email) {
                    return false;
                  }
                  const res = await axios.post('/api/auth/email_check', {email});
                  return !res?.data;
                }),
              firstname: string().max(50).required('First name is required'),
              lastname: string().max(50).required('Last name is required'),
              password: string().max(50).required('Password is required'),
              termsAccepted: boolean()
                .required('You must accept the terms and conditions')
                .oneOf([true], 'You must accept the terms and conditions'),
            })}
            onSubmit={(values) => {
              void axios.post<{error?: {type: string; uid: string}}>('/api/auth/signup', {
                ...values,
                validateInvited: true,
              });

              setCurrentPage(2);
              setValues(values);
            }}
          >
            {({values, isValid, isSubmitting, setFieldValue}) => {
              return (
                <Form>
                  <OAuthButton
                    onClick={() => {
                      if (values.termsAccepted) {
                        window.location.href = '/api/auth/oauth/authenticate?signup=true';
                      } else {
                        setTriedOAuth(true);
                        setTermsModalShowing(true);
                      }
                    }}
                    signup
                  />
                  <div className="tr-flex tr-items-center tr-my-4">
                    <hr className="tr-grow tr-h-px" />
                    <span className="tr-mx-4 tr-font-bold">or</span>
                    <hr className="tr-grow tr-h-px" />
                  </div>
                  <p className="tr-font-bold">Use your email</p>
                  <Field component={TextInput} required label="Email Address *" name="email" />
                  <Field component={TextInput} required label="First Name *" name="firstname" />
                  <Field component={TextInput} label="Last Name *" name="lastname" />
                  <Field component={PasswordInput} label="Password *" name="password" />
                  <div className="tr-flex tr-justify-between tr-items-center">
                    <label className="tr-flex tr-items-center">
                      <Field type="checkbox" name="termsAccepted" />

                      <div className="tr-text-xs tr-ml-2 tr-text-gray-400">
                        <span>I've read and agree to the</span>
                        <button
                          className="tr-border-0 tr-bg-inherit text-left tr-p-0 tr-underline tr-text-gray-400 tr-font-bold"
                          type="button"
                          onClick={() => {
                            setTermsModalShowing(true);
                          }}
                        >
                          Terms of Service and Privacy Policy
                        </button>

                        <Modal
                          width="lg"
                          onClose={() => {
                            setTermsModalShowing(false);
                          }}
                          visible={termsModalShowing}
                          footer={<EulaFooterContent setFieldValue={setFieldValue} />}
                        >
                          <Eula onShowBtnChange={handleShowBtnChange} />
                        </Modal>
                      </div>
                    </label>
                    <Button type="submit" disabled={!isValid || isSubmitting || !values.termsAccepted}>
                      Continue
                    </Button>
                  </div>
                  <ErrorMessage name="termsAccepted" component="div">
                    {(message) => (
                      <div className="tr-flex items-center">
                        <Icon type={'error'} className={'warning'} />
                        <span className="tr-mt-2 tr-mb-2 tr-flex tr-text-caption12 tr-opacity-60 tr-ml-1">
                          {message}
                        </span>
                      </div>
                    )}
                  </ErrorMessage>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
      {currentPage === 2 && (
        <>
          <div className="tr-mt-4 tr-mb-4">
            <p className="tr-text-sm">
              We sent an email to {values.email} with a magic link. Please click verification link to continue.
            </p>
          </div>
          <p className="tr-text-sm">Didn’t receive an email?</p>
          {values.email && (
            <Button variant="ternary" onClick={() => handleSendEmail()}>
              Resend Email
            </Button>
          )}
        </>
      )}
    </>
  );
});

export default CreateOrgPage;
