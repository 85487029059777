import _ from 'lodash';
import {observer} from 'mobx-react';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {formatUserName} from 'src/lib';
import {useRootStore} from 'src/lib/hooks';

export const Breadcrumbs = observer(() => {
  const location = useLocation();
  const {newResourceStore, usersStore, currentUser} = useRootStore();

  const breadcrumbsLinks = location.pathname
    .split('/')
    .slice(1)
    .map((s, i, a) => {
      const link = a.slice(0, i).join('/');
      if (s.startsWith('r_')) {
        const resource = newResourceStore.resourceMap?.[s];
        if (resource) {
          return (
            <Link key={resource.id} to={`/${link}/${resource.id}`}>
              {resource.name ?? '...'}
            </Link>
          );
        } else {
          return '...';
        }
      } else if (s.startsWith('u_')) {
        const user = usersStore.usersMap?.[s];
        if (user) {
          return (
            <Link key={user.id} to={`/${link}/${user.id}`}>
              {formatUserName(user)}
            </Link>
          );
        } else {
          return '...';
        }
      } else {
        if (i > 0) {
          if (s === 'manage' && currentUser.isOrgOwner) {
            return (
              <Link key={s} to={`/manage/active`}>
                {s.charAt(0).toLocaleUpperCase() + s.slice(1)}
              </Link>
            );
          } else if (s === 'accounts' && currentUser.isOrgOwner) {
            return (
              <Link key={s} to={`../../accounts`}>
                {s.charAt(0).toLocaleUpperCase() + s.slice(1)}
              </Link>
            );
          } else {
            return (
              <Link key={s} to={`/${link}/${s}`}>
                {s.charAt(0).toLocaleUpperCase() + s.slice(1)}
              </Link>
            );
          }
        } else if (s === 'users' && currentUser.isOrgOwner) {
          return (
            <Link key={s} to={`/admin${link}/${s}`}>
              {s.charAt(0).toLocaleUpperCase() + s.slice(1)}
            </Link>
          );
        } else {
          return s.charAt(0).toLocaleUpperCase() + s.slice(1);
        }
      }
    });
  return (
    <div className="tr-text-xs">
      {_.map(breadcrumbsLinks, (link, i) => {
        return i === 0 ? (
          <span key={`breadcrumb${i}`}>{link}</span>
        ) : (
          <span key={`breadcrumb${i}`}>
            {' > '}
            <span>{link}</span>
          </span>
        );
      })}
    </div>
  );
});
