import _ from 'lodash';
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import {formatAsDollarsAndCents} from 'src/lib';
import {Icon} from '@trustle/component-library';

export function createFeatureList(
  planName: string,
  features: {label: string; icon: any}[],
  isCarousel: boolean = false
) {
  const itemPerPage = 4;
  const carouselPages = [];
  const benefits = _.map(features, (feature: {label: string; icon: any}) => {
    return (
      <div key={`${planName}-${feature.label}`} className="tr-flex align-content-center align-items-center">
        <div className="tr-m-1 tr-ml-0 tr-text-blue tr-rounded-full tr-shadow-md tr-w-9 tr-h-9 tr-flex tr-items-center tr-justify-center">
          <Icon type={feature.icon} forcedSize={20} className="tr-opacity-100" />
        </div>
        <div className="feature-item text-navy">{feature.label}</div>
      </div>
    );
  });

  if (isCarousel) {
    for (let i = 0; i < benefits.length; i += itemPerPage) {
      carouselPages.push(benefits.slice(i, i + itemPerPage));
    }
    return (
      <div className="mb-2 tr-h-72">
        <Carousel controls={false} indicators={false} fade interval={8000}>
          {carouselPages.map((page, index) => {
            return <Carousel.Item key={index}>{page}</Carousel.Item>;
          })}
        </Carousel>
      </div>
    );
  }
  return <div className="mb-2 tr-h-72">{benefits.slice(0, 5)}</div>;
}

export function pricingTitle(planName: string, product: any) {
  const monthlyPrice = _.find(product.prices, (price) => {
    return price.recurring?.interval === 'month';
  });
  if (!monthlyPrice) {
    return null;
  }
  if (planName === 'Basic') {
    return (
      <>
        <div className="body3 tr-flex flex-column justify-content-center price-per-seat">
          <span className={'m-0 mr-1'}>
            <h1 className="m-0 text-navy">FREE</h1>
          </span>
        </div>
      </>
    );
  }
  if (planName === 'Enterprise') {
    return (
      <>
        <div className="body3 tr-flex flex-column justify-content-center price-per-seat">
          <span className={'m-0 mr-1'}>
            <h1 className="m-0 text-navy">Call Us</h1>
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="body3 tr-flex flex-column justify-content-center price-per-seat">
        <span className={'m-0 mr-1'}>
          <h1 className="m-0 text-navy">{formatAsDollarsAndCents(monthlyPrice.unit_amount / 100)} </h1>
        </span>
      </div>
      <div className="tr-flex flex-column justify-content-center align-content-center">
        <span className="body5">{`per User`}</span>
        <span className="body5">{`per ${monthlyPrice.recurring?.interval}`}</span>
      </div>
    </>
  );
}

const planDescriptions: Record<string, any> = {
  Enterprise: {
    name: 'Enterprise',
    subtitle: 'Discuss options with our sales team',
    buttonLabel: 'Custom',
    setupTitle: 'Getting Started with Enterprise Features in Trustle',
    setupDescription: 'Welcome to Enterprise!',
    features: [
      {label: 'Unlimited Connectors', icon: 'resource'},
      {label: 'Dedicated Success Manager', icon: 'cleanUp'},
      {label: 'Team-driven Anomaly Detection', icon: 'cleanUp'},
      {label: 'Over 100 Users', icon: 'groupPeople'},
    ],
    actionButtonLabel: 'Contact Us',
    featuresTitle: 'Centralized Autonomy',
    usersLimit: -1,
  },
  Free: {
    name: 'Basic',
    subtitle: 'Always Free!',
    buttonLabel: 'Choose Plan',
    setupTitle: 'Getting Started With Trustle',
    setupDescription: 'Setting up Trustle takes only a few minutes. Let’s get started!',
    features: [
      {label: 'One AWS connector', icon: 'resource'},
      {label: 'AWS Recommendations', icon: 'cleanUp'},
      {label: 'Access History Reporting', icon: 'cleanUp'},
      {label: 'Community Support', icon: 'cleanUp'},
      {label: '5 Users', icon: 'groupPeople'},
    ],
    actionButtonLabel: 'Choose Plan',
    featuresTitle: 'Get a handle on AWS',
    usersLimit: 5,
  },
  Team: {
    name: 'Team',
    subtitle: 'Billing starts after a 14 day free trial',
    actionButtonLabel: 'Choose Plan',
    setupTitle: 'Getting Started with Teams Features in Trustle',
    setupDescription: 'Welcome to Teams! Let´s get your resources and team members going with Trustle!',
    features: [
      {label: 'Up to 100 users', icon: 'groupPeople'},
      {label: 'Up to 5 connections', icon: 'resource'},
      {label: 'Login using SAML', icon: 'Saml'},
      {label: 'Priority email support', icon: 'emailPlus'},
      {label: 'Automatically provision and deprovision access', icon: 'pendingProvisionIcon'},
      {label: 'Implement just-in-time access', icon: 'thumbUp'},
      {label: 'Automate approvals for on-call events', icon: 'bell'},
      {label: 'Create reports on all permissions across systems', icon: 'report'},
      {label: 'Build a catalog of resources across systems', icon: 'system'},
      {label: 'Request and approve access in Trustle, Slack, and email', icon: 'request'},
    ],
    featuresTitle: 'Control the Access Lifecycle',
    usersLimit: 100,
  },
};

export default planDescriptions;
