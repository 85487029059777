import {Icon, Tooltip} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {formatUserName} from 'src/lib';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import ShowAuthorizedAccount from './ShowAuthorizedAccount';

type PropsT = {access: AccessRecord};

const TeamAccountColumnContent = observer((props: PropsT) => {
  const {access} = props;
  const account = access.forAccount;

  if (_.isNil(account)) {
    return <></>;
  }

  function getUserAccountPopup(): ReactNode {
    return (
      <>
        <div>
          <b>Team: </b>
          {account?.account}
          {account && (
            <span className="inline-flex-align ml-1">
              <ShowAuthorizedAccount account={account} />
            </span>
          )}
        </div>
      </>
    );
  }

  const numberOfSubteams = _.size(account.referencedEntity?.allNestedResources);
  const ids = _.map(account.referencedEntity?.allNestedResources, 'id');
  return (
    <Tooltip content={getUserAccountPopup()} position="bottom">
      <div className="my-1 tr-flex tr-flex-row justify-content-start align-items-center">
        <Icon type="team" size="sm" />
        <div className="tr-flex flex-column">
          {!_.isNil(account.forUser) && (
            <Link to={`/users/${account.forUser.id}`}> {formatUserName(account.forUser)} </Link>
          )}
          {!_.isNil(account) && account.referencedEntity && (
            <div className="tr-flex tr-flex-row inline-flex-align">
              <div className="grayed-out text-break">{account.account ?? ''}</div>
              {numberOfSubteams > 0 && (
                <div>
                  <Link
                    className="ml-3"
                    to={`/resource/manage/${account.referencedEntity.rootSid}/resources?Opened=${[
                      ...account.referencedEntity.ancestorIds,
                      ...ids,
                    ].join(',')}`}
                  >{`${_.size(account.referencedEntity.allNestedResources)} subteams`}</Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  );
});
export default TeamAccountColumnContent;
