import {useFeatureFlags, formatUserNameAndEmail, fixPlurality} from 'src/lib';
import {Task} from 'src/stores/domainObjects/Task';
import {TicketType} from 'src/types';
import RequestorHeading from './RequestorHeading';
import React from 'react';

const ApprovalHeading = (accessRequest: Task) => {
  const {forUser} = accessRequest;
  const featureFlagViewer = useFeatureFlags();
  const enabledTaskExpiration = featureFlagViewer.isEnabled('enable_expiration_days_for_tasks');
  const forUserName = formatUserNameAndEmail(forUser);
  let role = 'owner';
  switch (accessRequest.type) {
    case TicketType.WF_EXECUTIVE_APPROVAL: {
      role = 'executive owner';
      break;
    }
    case TicketType.WF_MANAGER_APPROVAL: {
      role = 'manager';
      break;
    }
    case TicketType.WF_OWNER_APPROVAL: {
      role = 'owner';
      break;
    }
  }
  return (
    <div className="tr-ml-2 tr-text-sm">
      <RequestorHeading accessRequest={accessRequest} />
      <span className="font-weight-lighter">
        {` for ${accessRequest.durationValue} ${fixPlurality(
          accessRequest.durationValue!,
          accessRequest.durationUnit!
        )}.`}
      </span>
      <span className="tr-italic">{`${accessRequest.message ? ` because: "${accessRequest.message}"` : ''}`}</span>
      <div className="font-weight-lighter">
        {role ? `As ${role} of` : ''} {`${forUserName} `} you must approve or deny this request.
      </div>
      {enabledTaskExpiration && (
        <div className="font-weight-lighter">
          This access request will be implicitly denied if tasks asignees cancel, or take no action on it within 7 days
        </div>
      )}
    </div>
  );
};

export default ApprovalHeading;
