import _ from 'lodash';
import React, {useState} from 'react';
import {DurationT, ProvisionOptions} from 'src/types';
import {Formik} from 'src/components/design/formik';
import {logger} from 'src/lib';
import {observer} from 'mobx-react';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Permission} from 'src/stores/domainObjects/Permission';
import {FormikForm} from 'src/components/design/formik';
import {Field} from 'formik';
import {
  Button,
  DurationInput,
  SensitivityDropdown,
  TextAreaInput,
  TextInput,
  VisibilityDropdown,
  Tooltip,
  Icon,
  ProvisionModeDropdown,
  IconButton,
} from '@trustle/component-library';
import {useRootStore} from 'src/lib/hooks';
import {SubmitButton} from 'src/components/design';
import {object} from 'yup';
import {Loading} from '@trustle/component-library';
import {GetSensitivityDurationValidations} from 'src/lib/validations';
import AutomatedProvisionToggle from 'src/connectors/components/AutomatedProvisionToggle';
import AutomatedProvisioningModeTooltip from 'src/connectors/common/editConnection/AutomatedProvisioningModeTooltip';
import DefaultOrOverriden from './DefaultOrOverriden';
import {calculateNewDurations} from '../Resource/ResourceEdit';

type PropsT = {
  permission: Permission;
  isConnectedSystem?: boolean;
  refresh?: () => void;
  resource?: Resource;
  cancel?: () => void;
};

type PermissionFormT = {
  label: string;
  description: string | null;
  approvalDuration?: DurationT;
  accessDuration?: DurationT;
  autoProvision?: boolean;
  hidden?: boolean;
  sensitivityId?: string;
  provisionMode?: ProvisionOptions | null;
  deprovisionMode?: ProvisionOptions | null;
  initiateExpiredDeprovision?: boolean | null;
};

export const EditNameDescription = (props: {
  isConnectedSystem?: boolean;
  values: Partial<Permission | Resource>;
  nameField?: 'name' | 'label';
  descriptionField?: 'description';
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const {nameField = 'name', descriptionField = 'description', isConnectedSystem, values} = props;
  return (
    <div className="m-1 flex flex-column tr-w-full">
      <div className="tr-w-full tr-border-solid tr-border-[0px] tr-border-b-[1px] tr-border-b-gray-300 tr-ml-3 tr-p-1 tr-flex tr-flex-row tr-items-center tr-justify-between">
        {editMode ? (
          <>
            <div className="flex flex-column">
              <Field label="Name" name={nameField} disabled={isConnectedSystem} component={TextInput} />
              <Field
                label="Description"
                name={descriptionField}
                className="tr-w-64"
                description={isConnectedSystem ? 'This will be shown to end users' : undefined}
                rows={3}
                component={TextAreaInput}
              />
            </div>
            <IconButton
              icon="edit"
              variant="primary"
              data-testid="editMode-icon"
              onClick={() => {
                setEditMode(false);
              }}
            />
          </>
        ) : (
          <div className="tr-w-full tr-flex tr-flex-row tr-justify-between">
            <div>
              <label className="tr-font-bold">Description:</label>
              <span className="body6 tr-m-0 tr-ml-4 tr-mb-4">{values[descriptionField]}</span>
            </div>
            <div>
              <IconButton
                variant="ternary"
                icon="edit"
                data-testid="editMode-icon-enable"
                onClick={() => {
                  setEditMode(true);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const PermissionEdit = observer(function PermissionEditCmp(props: PropsT) {
  const {refresh, permission, isConnectedSystem, cancel} = props;
  const {org} = useRootStore();

  const [cleared, setAsCleared] = useState<boolean>(false);
  const [resetAll, setResetAll] = useState<boolean>(false); // told if reset all was clicked
  const [touchedFields, setTouchedFields] = useState<string[]>([]); // told which fields are touched

  if (_.isNil(org) || org?.defaultSensitivityId === undefined) {
    return <Loading />;
  }

  const sensitivitySettings = org.sensitivitySettingsSorted;

  function getInitialValues(permission: Permission) {
    return {
      label: permission.label,
      hidden: permission.calculatedHidden.value,
      autoProvision: permission.autoProvisionValue.value,
      sensitivityId: permission.sensitivity?.id,
      description: permission.description || '',
      approvalDuration: permission.calcApprovalDuration,
      accessDuration: permission.calcAccessDuration,
      provisionMode: permission.calculatedProvisionMode.value,
      deprovisionMode: permission.calculatedDeprovisionMode.value,
      initiateExpiredDeprovision: permission.calculatedInitiateExpiredDeprovision.value,
    };
  }

  async function handleSave(form: PermissionFormT) {
    const permissionUpdate: Record<string, any> = {
      label: form.label,
      description: form.description,
    };

    for (const fieldName of [
      'provisionMode',
      'deprovisionMode',
      'initiateExpiredDeprovision',
      'autoProvision',
      'hidden',
    ]) {
      if (!_.includes(touchedFields, fieldName) && resetAll) {
        permissionUpdate[fieldName] = null;
      } else if (_.includes(touchedFields, fieldName)) {
        permissionUpdate[fieldName] = (form as any)[fieldName];
      }
    }

    // Fields with values calculated based on the hierarchy (sensitivity and durations):
    // if the new value for the field is the same as the value in its parent, it means it is being inherited,
    // then the backend will store this as 'null'
    const sensitivityInherited = permission.parentResource.calculatedSensitivity.value === form.sensitivityId;

    permissionUpdate['sensitivityId'] = sensitivityInherited ? null : form.sensitivityId;

    const {approvalDuration, accessDuration} = calculateNewDurations(
      sensitivitySettings,
      form.sensitivityId,
      permission
    );

    const approvalInherited =
      approvalDuration.durationValue === form.approvalDuration?.durationValue &&
      approvalDuration.durationUnit === form.approvalDuration?.durationUnit;

    permissionUpdate['durationValue'] = approvalInherited ? null : form.approvalDuration?.durationValue;
    permissionUpdate['durationUnit'] = approvalInherited ? null : form.approvalDuration?.durationUnit;

    const accessInherited =
      accessDuration.durationValue === form.accessDuration?.durationValue &&
      accessDuration.durationUnit === form.accessDuration?.durationUnit;

    permissionUpdate['accessDurationValue'] = accessInherited ? null : form.accessDuration?.durationValue;
    permissionUpdate['accessDurationUnit'] = accessInherited ? null : form.accessDuration?.durationUnit;

    try {
      await permission.update(permissionUpdate);
      refresh?.();

      return {msg: 'Permission updated sucessfully'};
    } catch (err) {
      logger.error(err);
      return {error: true, msg: 'Error saving permission.'};
    }
  }

  function handleOnChange(event: any) {
    const fieldName = event.target?.name || event.field;

    if (!_.isNil(fieldName)) {
      setTouchedFields((ptf) => {
        ptf.push(fieldName);
        return ptf;
      });
      setAsCleared(false);
    }
  }

  return (
    <Formik
      initialValues={getInitialValues(permission)}
      onSubmit={handleSave}
      validationSchema={object().shape(GetSensitivityDurationValidations(org))}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({
        isSubmitting,
        values,
        setFieldValue,
        resetForm,
        isValid,
        touched,
        handleBlur,
        setFieldTouched,
        setErrors,
        setTouched,
      }) => {
        return (
          <FormikForm>
            <EditNameDescription values={values} isConnectedSystem={isConnectedSystem} nameField="label" />
            {!permission.isLocked ? (
              <>
                <Field name="sensitivityId">
                  {({field}: any) => (
                    <div className="tr-border-solid tr-border-[0px] tr-border-b-[1px] tr-border-b-gray-300 tr-ml-2 tr-p-2 tr-flex tr-flex-row tr-items-center">
                      <div className="tr-basis-2/6 tr-flex">
                        <label className="tr-font-bold m-0">Sensitivity</label>
                        <Tooltip
                          content={
                            <div className="body6">
                              Select a sensitivity setting to control approval and provision requirements.
                            </div>
                          }
                        >
                          <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                        </Tooltip>
                      </div>
                      <div key="sensitivity" className="tr-basis-3/6 tr-justify-center tr-pl-5">
                        <SensitivityDropdown
                          size="sm"
                          data-testid={'sensitivity-defaultLevelDropdown'}
                          defaultValue={permission.parentResource.calculatedSensitivity.value}
                          sensitivitySettings={sensitivitySettings!}
                          selectedId={field.value}
                          onStateSelected={(sensitivityId) => {
                            setErrors({});
                            setFieldTouched('accessDuration', false, false);
                            setFieldTouched('approvalDuration', false, false);

                            setTouched({}, false);

                            const result = calculateNewDurations(sensitivitySettings, sensitivityId, permission);
                            values.approvalDuration = result.approvalDuration;
                            values.accessDuration = result.accessDuration;

                            field.onChange({target: {name: 'approvalDuration', value: values.approvalDuration}});
                            field.onChange({target: {name: 'accessDuration', value: values.accessDuration}});
                            field.onChange({target: {name: field.name, value: sensitivityId}});

                            setTouchedFields((ptf) => {
                              ptf.push('approvalDuration');
                              ptf.push('accessDuration');
                              ptf.push('sensitivityId');
                              return ptf;
                            });

                            setAsCleared(false);
                          }}
                          onBlur={handleBlur}
                          id="sensitivityId"
                        />
                      </div>
                      <div className="tr-basis-1/6">
                        {!isSubmitting && (
                          <DefaultOrOverriden
                            value={values.sensitivityId}
                            touched={values.sensitivityId !== permission.calculatedSensitivity.value}
                            forceDefault={
                              values.sensitivityId === permission.parentResource.calculatedSensitivity.value
                            }
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="hidden">
                  {({field}: any) => (
                    <div className="tr-border-solid tr-border-[0px] tr-border-b-[1px] tr-border-b-gray-300 tr-ml-2 tr-p-2  tr-flex tr-flex-row tr-items-center">
                      <div className="tr-basis-2/6 tr-flex">
                        <label className="tr-font-bold m-0">Visibility</label>
                        <Tooltip
                          content={
                            <div className="body6">
                              Select whether a permission should be visible or not for normal users
                            </div>
                          }
                        >
                          <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                        </Tooltip>
                      </div>
                      <div className="tr-basis-3/6 tr-justify-center tr-pl-5">
                        <VisibilityDropdown
                          size="sm"
                          selectedStatus={field.value}
                          data-testid={'toggleVisibilityIcon'}
                          onSelected={(hidden?: boolean) => {
                            field.onChange({target: {name: 'hidden', value: hidden}});
                            setTouchedFields((ptf) => {
                              ptf.push('hidden');
                              return ptf;
                            });
                            setAsCleared(false);
                          }}
                          id="hidden"
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="tr-basis-1/6">
                        {!isSubmitting && (
                          <DefaultOrOverriden
                            value={permission.hidden}
                            touched={!cleared && touched.hidden}
                            forceDefault={cleared && !_.includes(touchedFields, 'hidden')}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Field>
              </>
            ) : (
              <div className="tr-flex tr-items-center tr-p-4">
                <Icon type="locked" /> <strong>Permission is locked, you are not allowed to edit other fields</strong>
              </div>
            )}
            {!permission.isLocked && (
              <div className="tr-border-solid tr-border-[0px] tr-border-b-[1px] tr-border-b-gray-300">
                <div className="tr-ml-2 tr-p-2 tr-items-center">
                  <div className="tr-mt-2">
                    <AutomatedProvisioningModeTooltip resource={props.permission.parentResource} />
                  </div>
                </div>
                <div className="">
                  <Field name="provisionMode">
                    {({field}: any) => (
                      <div className="tr-border-solid tr-border-[0px] tr-border-b-[1px] tr-border-b-gray-300 tr-ml-2 tr-p-2 tr-flex tr-flex-row tr-items-center">
                        <div className="tr-basis-2/6 tr-flex">
                          <span className="tr-ml-16">
                            <label className="tr-font-bold m-0">Provisioning</label>
                          </span>
                        </div>
                        <div className="tr-basis-3/6 tr-justify-center tr-pl-5">
                          <ProvisionModeDropdown
                            size="sm"
                            className="tr-w-[140px]"
                            selectedStatus={field.value}
                            data-testid={'provisioningMode'}
                            onBlur={handleBlur}
                            id="provisionMode"
                            onSelected={(value?: string) => {
                              field.onChange({target: {name: 'provisionMode', value: value}});
                              setTouchedFields((ptf) => {
                                ptf.push('provisionMode');
                                return ptf;
                              });
                              setAsCleared(false);
                            }}
                          ></ProvisionModeDropdown>
                        </div>
                        <div className="tr-basis-1/6">
                          {!isSubmitting && (
                            <DefaultOrOverriden
                              forceDefault={cleared && !_.includes(touchedFields, 'provisionMode')}
                              value={permission.provisionMode}
                              touched={!cleared && touched.provisionMode}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </Field>

                  <Field name="deprovisionMode">
                    {({field}: any) => (
                      <div className="tr-border-solid tr-border-[0px] tr-border-b-[1px] tr-border-b-gray-300 tr-ml-2 tr-p-2  tr-flex tr-flex-row tr-items-center">
                        <div className="tr-basis-2/6 tr-flex">
                          <span className="tr-ml-16">
                            <label className="tr-font-bold m-0">Deprovisioning</label>
                          </span>
                        </div>
                        <div className="tr-basis-3/6 tr-justify-center tr-pl-5">
                          <ProvisionModeDropdown
                            size="sm"
                            className="tr-w-[140px]"
                            selectedStatus={field.value}
                            data-testid={'deprovisioningMode'}
                            onBlur={handleBlur}
                            id="deprovisionMode"
                            onSelected={(value?: string) => {
                              field.onChange({target: {name: 'deprovisionMode', value: value}});
                              if (value === ProvisionOptions.off) {
                                setFieldValue('initiateExpiredDeprovision', false, false);
                              }
                              setTouchedFields((ptf) => {
                                ptf.push('deprovisionMode');
                                return ptf;
                              });
                              setAsCleared(false);
                            }}
                          ></ProvisionModeDropdown>
                        </div>
                        <div className="tr-basis-1/6">
                          {!isSubmitting && (
                            <DefaultOrOverriden
                              forceDefault={cleared && !_.includes(touchedFields, 'deprovisionMode')}
                              value={permission.deprovisionMode}
                              touched={!cleared && touched.deprovisionMode}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </Field>

                  <div className="tr-border-solid tr-border-[0px] tr-border-b-[1px] tr-border-b-gray-300 tr-ml-2 tr-p-2 tr-flex tr-flex-row tr-items-center tr-my-2">
                    <div className="tr-basis-5/6 tr-flex tr-items-center">
                      <span className="tr-ml-16">
                        <AutomatedProvisionToggle
                          type="checkbox"
                          disabled={_.isNil(values.deprovisionMode) || values.deprovisionMode === ProvisionOptions.off}
                          className="tr-mr-5"
                          name="initiateExpiredDeprovision"
                          onClick={() => {
                            setFieldTouched('initiateExpiredDeprovision', true);
                            setTouchedFields((ptf) => {
                              ptf.push('initiateExpiredDeprovision');
                              return ptf;
                            });
                            setAsCleared(false);
                          }}
                        />
                      </span>
                      <div className="tr-flex-initial tr-grow">
                        <div className="tr-flex-auto">
                          <span className="body4">Initiate Deprovisioning When Access Expires</span>
                          <Tooltip
                            content={
                              <div className="tr-cols-1 tr-grid tr-gap-y-4">
                                <div>
                                  When enabled, we will automatically initiate the deprovisioning process when either
                                  approval or provision access expires.
                                </div>
                                <div>
                                  In <strong>Manual mode</strong>, a task will be generated automatically for
                                  deprovisioning of the expired permission. This task is sent to the Provisioner, who
                                  must make the change on the remote system then click in the TaskUI{' '}
                                  <i>"It's Complete"</i>, or not make the change on the remote system and click
                                  "Cancel."
                                </div>
                              </div>
                            }
                          >
                            <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                          </Tooltip>
                        </div>
                      </div>
                    </div>

                    <div className="tr-basis-1/6">
                      {!isSubmitting && (
                        <DefaultOrOverriden
                          forceDefault={cleared && !_.includes(touchedFields, 'initiateExpiredDeprovision')}
                          value={permission.initiateExpiredDeprovision}
                          touched={!cleared && touched.initiateExpiredDeprovision}
                        />
                      )}
                    </div>
                  </div>

                  <div className="tr-ml-2 tr-pl-2 tr-mt-2 tr-pt-2 tr-items-center">
                    <div className="body4">Duration Settings</div>
                    <div className="tr-w-full tr-mb-4 tr-py-2 tr-items-start tr-grid tr-grid-cols-2 tr-gap-x-8">
                      <div className="tr-border-solid tr-flex tr-border-[0px] tr-border-r-[1px] tr-border-r-gray-300">
                        <Field
                          component={DurationInput}
                          label="Approval Duration"
                          className="tr-w-9/12"
                          description="This is the default amount of time a user will be approved for."
                          name="approvalDuration"
                          required={false}
                          onChange={handleOnChange}
                          onBlur={handleBlur}
                        />
                        {!isSubmitting && (
                          <div className="flex items-end tr-text-right tr-text-align-bottom tr-mb-2 tr-px-2">
                            <DefaultOrOverriden
                              value={
                                _.isNil(values.approvalDuration.durationUnit) ||
                                _.isNil(values.approvalDuration.durationUnit)
                                  ? null
                                  : 1
                              }
                              touched={
                                values.approvalDuration.durationValue !==
                                  permission.calcApprovalDuration.durationValue ||
                                values.approvalDuration.durationUnit !== permission.calcApprovalDuration.durationUnit
                              }
                              forceDefault={(() => {
                                const {approvalDuration} = calculateNewDurations(
                                  sensitivitySettings,
                                  values.sensitivityId,
                                  permission
                                );

                                return (
                                  values.approvalDuration.durationValue === approvalDuration.durationValue &&
                                  values.approvalDuration.durationUnit === approvalDuration.durationUnit
                                );
                              })()}
                            />
                          </div>
                        )}
                      </div>

                      <div className="tr-flex">
                        <Field
                          component={DurationInput}
                          name="accessDuration"
                          label="Access Duration"
                          onChange={handleOnChange}
                          onBlur={handleBlur}
                          className="tr-w-9/12"
                          description="This is the default amount of time a user will be provisioned for."
                          required={false}
                        />
                        {!isSubmitting && (
                          <div className="flex items-end tr-text-right tr-text-align-bottom tr-mb-2">
                            <DefaultOrOverriden
                              value={
                                _.isNil(values.accessDuration.durationValue) ||
                                _.isNil(values.accessDuration.durationUnit)
                                  ? null
                                  : 1
                              }
                              touched={
                                values.accessDuration.durationValue !== permission.calcAccessDuration.durationValue ||
                                values.accessDuration.durationUnit !== permission.calcAccessDuration.durationUnit
                              }
                              forceDefault={(() => {
                                const {accessDuration} = calculateNewDurations(
                                  sensitivitySettings,
                                  values.sensitivityId,
                                  permission
                                );

                                return (
                                  values.accessDuration.durationValue === accessDuration.durationValue &&
                                  values.accessDuration.durationUnit === accessDuration.durationUnit
                                );
                              })()}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="tr-text-right tr-pt-4 tr-flex tr-justify-between">
              <div>
                {!permission.isLocked && (
                  <Button
                    variant="ternary"
                    onClick={() => {
                      // reset values inheriting parent values in order to show in the form.
                      // At DB level corresponds to set to null.
                      resetForm();
                      setFieldValue('approvalDuration', permission.inheritedApprovalDuration);
                      setFieldValue('accessDuration', permission.inheritedAccessDuration);
                      setFieldValue('autoProvision', permission.parentResource.autoProvisionValue.value);
                      setFieldValue('hidden', permission.parentResource.hidden);
                      setFieldValue('sensitivityId', permission.parentResource.sensitivity?.id);
                      setFieldValue('provisionMode', permission.parentResource.calculatedProvisionMode.value);
                      setFieldValue('deprovisionMode', permission.parentResource.calculatedDeprovisionMode.value);
                      setFieldValue(
                        'initiateExpiredDeprovision',
                        permission.parentResource.calculatedInitiateExpiredDeprovision.value
                      );
                      setAsCleared(true);
                      setResetAll(true);
                      setTouchedFields([]);
                    }}
                  >
                    Reset to default
                  </Button>
                )}
              </div>
              <div>
                <Button
                  variant="secondary"
                  onClick={() => {
                    cancel?.();
                  }}
                >
                  Cancel
                </Button>
                <SubmitButton disabled={!isValid} inSubmit={isSubmitting} name="save" label="Save" />
              </div>
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
});

export default PermissionEdit;
