import React from 'react';
import {useHistory} from 'react-router-dom';
import Access from './Resource/Access/Access';
import {FacetIdEnum} from 'src/types';
import {useRootStore} from 'src/lib/hooks';
import _ from 'lodash';

function MainView() {
  const history = useHistory();
  const {org, currentUser} = useRootStore();

  if (_.isNil(org) || _.isNil(currentUser)) {
    return <></>;
  }

  if (!org.isSetup && org.ownerUids.find((v) => v === currentUser.id)) {
    history.push('/setup/org_name');
  }

  const isFreePlan = org.facetId === FacetIdEnum.FREE_AWS;
  // Redirect to manage page because /access is not avaiable for free users.
  if (isFreePlan) {
    history.push('/manage');
  }

  return <Access />;
}

export default MainView;
