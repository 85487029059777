import React, {useState, useEffect, useRef} from 'react';
import {DateRangePicker, FocusedInputShape} from 'react-dates';
import ReportDropdown from '../ReportDropdown';
import {useRootStore} from 'src/lib/hooks';
import {ConnectionServiceE, getConnectionServiceName} from 'src/types';
import './CustomReport.scss';
import moment from 'moment';
import ServiceSelector, {ServiceTypeE} from 'src/views/Resource/ServiceSelector';
import {Resource} from 'src/stores/domainObjects/Resource';
import _ from 'lodash';
import {observer} from 'mobx-react';
import ResourceList from './ResourceList';
import axios from 'axios';
import {Button, Checkbox, Modal} from '@trustle/component-library';
import {Confirmation} from '@trustle/component-library';
import {ConnectionIconSrc} from 'src/views/User/Profile/ConnectionServiceIcons';

const MapServiceToIcon: _.Dictionary<ConnectionIconSrc> = {
  [ConnectionServiceE.GITHUB]: ConnectionIconSrc.GITHUB,
  [ConnectionServiceE.AWS]: ConnectionIconSrc.AWS,
  [ConnectionServiceE.GAPPS]: ConnectionIconSrc.GAPPS,
  [ConnectionServiceE.GCP]: ConnectionIconSrc.GCP,
  [ConnectionServiceE.CLOUDFLARE]: ConnectionIconSrc.CLOUDFLARE,
  [ConnectionServiceE.AZURE_AD]: ConnectionIconSrc.AZURE_AD,
  aad: ConnectionIconSrc.AZURE_AD, // legacy name
  [ConnectionServiceE.OKTA]: ConnectionIconSrc.OKTA,
  [ConnectionServiceE.PAGERDUTY]: ConnectionIconSrc.PAGERDUTY,
  [ConnectionServiceE.STRONGDM]: ConnectionIconSrc.STRONGDM,
  [ConnectionServiceE.TABLEAU]: ConnectionIconSrc.TABLEAU,
  [ConnectionServiceE.JIRA]: ConnectionIconSrc.JIRA,
  [ConnectionServiceE.GENERIC]: ConnectionIconSrc.GENERIC,
  'custom-system': ConnectionIconSrc.CUSTOM,
  [ConnectionServiceE.M365]: ConnectionIconSrc.M365,
};

const CustomReport = observer(function CustomReport() {
  const [startDate, setStartDate] = useState<moment.Moment | null>(moment().startOf('isoWeek'));
  const [endDate, setEndDate] = useState<moment.Moment | null>(moment());
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  const [statistics, setStatistics] = useState<_.Dictionary<{users: number; permissions: number}>>({});
  const [isLoadingStatistics, setIsLoadingStatistics] = useState<boolean>(false);
  const handleDatesChange = ({startDate, endDate}: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const [modalAction, setModalAction] = useState<{
    type: 'SELECT_ALL' | 'DESELECT_ALL';
    target: any;
    service?: string;
  } | null>(null);
  const selectAllRefs = useRef<Record<string, HTMLInputElement>>({});
  const [selectedSystems, setSelectedSystems] = useState<Resource[]>([]);
  const [pendingSelectedSystems, setPendingSelectedSystems] = useState<{
    deselect: Resource[] | undefined;
    select: Resource[] | undefined;
  }>({
    deselect: [],
    select: [],
  });
  const [serviceSystemModal, setServiceSystemModal] = useState<ConnectionServiceE | 'custom-system' | undefined>(
    undefined
  );
  const {newResourceStore: resourceStore} = useRootStore();

  const systemsGroupedByService: _.Dictionary<Resource[]> = _.groupBy(resourceStore.availableReviewedSystems, 'type');
  const availableSystemServices: {
    service: ServiceTypeE;
    num: number;
    iconSrc?: string;
  }[] = _.sortBy(
    _.map(_.uniqBy(resourceStore.availableReviewedSystems, 'type'), (s) => {
      return {
        num: s.type ? _.size(systemsGroupedByService[s.type]) : 0,
        service: s.type,
        iconSrc: MapServiceToIcon[s.type!],
      };
    }),
    'type'
  );

  useEffect(() => void resourceStore.loadSystems(), []);

  const removeSelectedSystem = (resource: Resource) => {
    setSelectedSystems((prevSelectedSystem) => {
      const newSelected = _.filter(prevSelectedSystem, (s) => s.id !== resource.id);
      if (resource.connection?.service) {
        updateSelectAllStatusCheckbox(
          selectAllRefs.current[resource.connection?.service],
          resource.connection?.service,
          newSelected
        );
      }
      return newSelected;
    });
  };

  /**
   * update status of select all checkboxes to intermediate, check or non check depends of interactions
   */
  const updateSelectAllStatusCheckbox = (el: HTMLInputElement, service: string, selectedElements: Resource[]) => {
    const allResourcesOfServiceSelected = (selectedSystems: Resource[], service: string): boolean => {
      return (
        _.size(_.filter(selectedSystems, (s) => s.type === service)) ===
        _.size(_.filter(resourceStore.availableReviewedSystems, (s) => s.type === service))
      );
    };
    const noneResourcesOfServiceSelected = (selectedSystems: Resource[], service: string): boolean => {
      return _.size(_.filter(selectedSystems, (s) => s.type === service)) === 0;
    };
    const indeterminateServiceSelected = (selectedSystems: Resource[], service: string): boolean => {
      return (
        !allResourcesOfServiceSelected(selectedSystems, service) &&
        !noneResourcesOfServiceSelected(selectedSystems, service)
      );
    };

    if (service) {
      if (indeterminateServiceSelected(selectedElements, service)) {
        el.checked = false;
        el.indeterminate = true;
        return;
      }
      if (allResourcesOfServiceSelected(selectedElements, service)) {
        el.checked = true;
        el.indeterminate = false;
        return;
      }
      el.checked = false;
      el.indeterminate = false;
    }
  };

  const resetSelectAllCheckboxStauts = (service?: string) => {
    let checkboxsToReset: HTMLInputElement[];
    if (service) {
      checkboxsToReset = [selectAllRefs.current[service]];
    } else {
      checkboxsToReset = _.values(selectAllRefs.current);
    }
    _.map(checkboxsToReset, (el) => {
      el.checked = false;
      el.indeterminate = false;
    });
  };

  const modalActionContent = ((): React.ReactNode => {
    if (!modalAction) {
      return null;
    }

    switch (modalAction.type) {
      case 'SELECT_ALL': {
        return (
          <Confirmation
            onConfirm={async () => {
              setSelectedSystems((prevSelectedSystems) => {
                const selectedSystemsForService = _.map(
                  _.filter(prevSelectedSystems, (s) => s.type === modalAction.service),
                  'id'
                );
                const systemsToSelect = _.filter(
                  resourceStore.availableReviewedSystems,
                  (s) => s.type === modalAction.service && !_.includes(selectedSystemsForService, s.id)
                );
                const newSelectedSystems = prevSelectedSystems.concat(systemsToSelect);
                if (
                  _.size(newSelectedSystems) ===
                  _.size(_.filter(availableSystemServices, (s) => s.service === modalAction.service))
                ) {
                  modalAction.target.checked = true;
                }
                return newSelectedSystems;
              });
              setModalAction(null);
            }}
            onClose={() => {
              modalAction.target.checked = false;
              setModalAction(null);
            }}
            title={`Add all ${getConnectionServiceName(modalAction.service)} Systems to Access report?`}
          >
            This will include all Trustle managed {getConnectionServiceName(modalAction.service)} Systems in the access
            record report.
          </Confirmation>
        );
      }
      case 'DESELECT_ALL': {
        return (
          <Confirmation
            onConfirm={async () => {
              setSelectedSystems((prevSelectedSystem) => {
                return _.filter(prevSelectedSystem, (s) => s.type !== modalAction.service);
              });
              setModalAction(null);
            }}
            onClose={() => {
              modalAction.target.checked = true;
              setModalAction(null);
            }}
            title={`Remove all ${getConnectionServiceName(modalAction.service)}  Systems to Access report?`}
          >
            This will remove all Trustle managed {getConnectionServiceName(modalAction.service)} Systems from the access
            record report.
          </Confirmation>
        );
      }
    }
  })();
  const queryParams = new URLSearchParams();
  if (selectedSystems) {
    queryParams.set('systemId', _.toString(_.map(selectedSystems, 'id')));
  }
  if (startDate) {
    queryParams.set('startDate', moment(startDate).toISOString());
  }
  if (endDate) {
    queryParams.set('endDate', moment(endDate).toISOString());
  }

  const fetchSystemStatistics = async (service?: string) => {
    let systemIds = queryParams.get('systemId');
    let systemsToFetch: string | undefined = undefined;
    setIsLoadingStatistics(true);
    if (!_.isEmpty(service)) {
      systemsToFetch = _.join(
        _.map(
          _.filter(resourceStore.availableReviewedSystems, (s) => {
            return s.type === service;
          }),
          'id'
        ),
        ','
      );
      if (!_.isEmpty(systemsToFetch)) {
        systemIds = _.join(_.compact([systemIds, systemsToFetch]), ',');
      }
    }
    const res = await axios.get(
      `/api/orgs/custom_report_statistics/?systemId=${systemIds}&startDate=${queryParams.get(
        'startDate'
      )}&endDate=${queryParams.get('endDate')}`
    );
    setStatistics(res.data);
    setIsLoadingStatistics(false);
  };

  useEffect(() => {
    if (!_.isEmpty(selectedSystems) && !_.isEmpty(startDate) && !_.isEmpty(endDate)) {
      void fetchSystemStatistics();
    }
  }, [selectedSystems, startDate, endDate]);

  return (
    <div className="custom-report">
      <div className="report-section tr-py-4">
        <h2>Generate Access Report</h2>
      </div>
      <div className="report-section tr-py-4 tr-flex tr-items-center">
        <div className="body4 tr-mr-8">Set Date Range</div>
        <DateRangePicker
          startDate={startDate}
          startDateId="tata-start-date"
          endDate={endDate}
          appendToBody={true}
          minimumNights={0}
          showClearDates
          showDefaultInputIcon={false}
          hideKeyboardShortcutsPanel
          enableOutsideDays
          inputIconPosition="after"
          isOutsideRange={() => false}
          endDateId="data-end-date"
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        />
      </div>

      <div className="report-section tr-py-4 tr-flex tr-items-center">
        <div className="body4 tr-mr-8">Select System(s)</div>
        <div className="tr-flex">
          <ServiceSelector
            services={availableSystemServices}
            clickHandler={async (service: ServiceTypeE) => {
              setServiceSystemModal(service);
              await fetchSystemStatistics(service);
            }}
            selectAllHandler={(props) => {
              const {service, isSelected} = props;
              if (isSelected) {
                const selectedSystemsIds = _.map(selectedSystems, 'id');
                const systemsToSelect = _.filter(
                  resourceStore.availableReviewedSystems,
                  (s) => s.type === service && !_.includes(selectedSystemsIds, s.id)
                );
                setSelectedSystems(selectedSystems.concat(systemsToSelect));
              } else {
                setSelectedSystems((prevSelectedSystems) => {
                  return _.filter(prevSelectedSystems, (s) => s.type !== service);
                });
              }
            }}
            showSelectAll={true}
          >
            {(props) => {
              return (
                <div className="text-center">
                  <label>
                    <Checkbox
                      value="true"
                      ref={(el: any) => {
                        if (el && props.service) {
                          selectAllRefs.current[props.service] = el;
                        }
                      }}
                      onChange={(cb) => {
                        cb.persist();
                        if (cb.target.checked) {
                          setModalAction({type: 'SELECT_ALL', target: cb.target, service: props.service});
                        } else {
                          setModalAction({type: 'DESELECT_ALL', target: cb.target, service: props.service});
                        }
                      }}
                    />
                    Select all
                  </label>
                </div>
              );
            }}
          </ServiceSelector>
        </div>
      </div>

      <div className="tr-py-4 tr-flex tr-justify-between tr-items-center">
        <div className="body4">Report will include Selected Systems Below</div>
        <div className="text-right">
          <ReportDropdown
            disabled={_.size(selectedSystems) === 0 || _.isEmpty(startDate) || _.isEmpty(endDate)}
            title="Generate Report"
            url={`/api/orgs/custom_report/`}
            queryParams={queryParams}
          />
        </div>
      </div>
      <ResourceList
        systems={selectedSystems}
        selectedSystems={selectedSystems}
        onRemoveHandler={(resource) => removeSelectedSystem(resource)}
        onChangeSelectedHandler={(changes) => {
          setPendingSelectedSystems({
            deselect: changes.initialSelected,
            select: changes.finishSelected,
          });
        }}
        onRemoveAllHandler={() => {
          setSelectedSystems([]);
          resetSelectAllCheckboxStauts();
        }}
        showSelectColumn={false}
        showActionColumn
        statistics={statistics}
        statisticsLoading={isLoadingStatistics}
      />
      {modalActionContent}

      <Modal
        onClose={() => {
          setServiceSystemModal(undefined);
        }}
        visible={!!serviceSystemModal}
        width="lg"
        title={'Select systems for Access report'}
      >
        <ResourceList
          systems={_.filter(resourceStore.availableReviewedSystems, (s) => s.type === serviceSystemModal)}
          selectedSystems={_.filter(selectedSystems, (s) => s.type === serviceSystemModal)}
          onChangeSelectedHandler={(changes) => {
            setPendingSelectedSystems({
              deselect: changes.initialSelected,
              select: changes.finishSelected,
            });
          }}
          onRemoveAllHandler={() => {
            resetSelectAllCheckboxStauts(serviceSystemModal);
          }}
          showSelectColumn={true}
          statistics={statistics}
          statisticsLoading={isLoadingStatistics}
        />
        <Button
          onClick={() => {
            setSelectedSystems((prevSelectedSystems) => {
              if (!serviceSystemModal) {
                return [];
              }
              const newSystems = _.filter(
                prevSelectedSystems,
                (ps) => !_.includes(_.map(pendingSelectedSystems.deselect, 'id'), ps.id)
              );
              const systemsToAdd = _.filter(resourceStore.availableReviewedSystems, (s) => {
                return _.includes(_.map(pendingSelectedSystems.select, 'id'), s.id);
              });
              const systemsToReturn = newSystems.concat(systemsToAdd);
              updateSelectAllStatusCheckbox(
                selectAllRefs.current[serviceSystemModal],
                serviceSystemModal,
                systemsToReturn
              );

              return systemsToReturn;
            });
            setServiceSystemModal(undefined);
          }}
          disabled={_.size(pendingSelectedSystems.select) === 0 && _.size(pendingSelectedSystems.deselect) === 0}
        >
          {'Modify selected'}
        </Button>
      </Modal>
    </div>
  );
});

export default CustomReport;
