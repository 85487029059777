import React from 'react';

const NoData = (props: {children: React.ReactElement | string}) => {
  return (
    <div className="text-center mt-4 pt-4 tr-w-full">
      <img src="/russel-thinking.svg" className="m-2" style={{width: '190px'}} alt="" />
      <div className="body5 mt-3">{props.children}</div>
    </div>
  );
};

export default NoData;
