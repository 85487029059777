import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {formatUserName} from 'src/lib';
import GetTasksDoneEmptyStateSVG from 'src/components/design/assets/getTasksDoneEmptyState.svg';
import {useMediaQuery, useQueryStrings, useRootStore} from 'src/lib/hooks';
import './TasksTable.scss';
import {Button, Dropdown, DropdownItem, DropdownMenu, Icon, InfiniteScroll, Modal} from '@trustle/component-library';
import {Task} from 'src/stores/domainObjects/Task';
import TaskDetailedView from './components/TaskDetailedView';
import TaskCardItem from './components/TaskCardItem';
import {Search} from 'src/components/Search/Search';
import {filterRequests} from './utils';
import {TaskTypesLabelEnum} from 'src/types';

type SelectedTaskViewPropsT = {
  selectedTask?: Task;
  resultingRecords: Task[];
  selectedAccessRequestId: string;
};

const SelectedTaskView = observer(
  ({selectedTask, resultingRecords, selectedAccessRequestId}: SelectedTaskViewPropsT) => {
    if (!selectedTask || selectedTask.processed) {
      return (
        <div className="tt--task-view empty-task">
          <div className="text-center">
            <img src={GetTasksDoneEmptyStateSVG} alt="Checklist Icon" />
            <h4>Let's close out some tasks!</h4>
            <div className="body6">Select a task on the left column to see more details</div>
          </div>
        </div>
      );
    }
    const defaultSelected = _.find(resultingRecords, (record) => {
      record.id.toString() === selectedAccessRequestId;
    });
    if (defaultSelected) {
      return <TaskDetailedView accessRequest={defaultSelected as Task} />;
    }
    return <TaskDetailedView accessRequest={selectedTask} />;
  }
);

type AccessRequestsTablePropsT = {selectedAccessRequestId?: string; tasks: Task[]};
const GenericAccessRequestsTable = observer(function GenericAccessRequestsTable(props: AccessRequestsTablePropsT) {
  const {selectedAccessRequestId = '', tasks} = props;
  const {org, tasksStore} = useRootStore();
  const isSm = useMediaQuery('(max-width: 768px)');

  const [filters, setFilters] = useQueryStrings();

  const [selectedTask, setSelectedTask] = useState<Task | undefined>();
  const [isDescending, setIsDescending] = useState<boolean>(true);

  if (org?.sensitivitySettings === undefined) {
    return <></>;
  }

  const sensitivitySettings = org.sensitivitySettingsSorted;

  const systemOptions = _(tasks)
    .groupBy('forSystem.id')
    .map((accessRequests: Task[]) => {
      const forSystem = accessRequests[0].forSystem;
      if (!forSystem) {
        return null;
      }

      return {
        value: forSystem.id,
        label: forSystem.name,
        count: _.size(accessRequests),
      };
    })
    .compact()
    .value();

  const resultingRecords = filterRequests(tasks, filters);

  const assignedUsers = _.flatMap(tasks, 'assignees');

  const taskByUser = _.countBy(assignedUsers, 'id');

  const userOptions = _.map(
    _.uniqBy(assignedUsers, (au) => {
      return au.id;
    }),
    (u) => {
      return {...u, count: taskByUser[u.id]};
    }
  );

  const types: string[] = _.uniq(_.map(tasks, (task) => TaskTypesLabelEnum[task.calculatedType]));

  const [filterOptions] = useState({
    Resource: _.map(systemOptions, 'label'),
    Type: types,
    User: _.map(userOptions, (user) => {
      return formatUserName(user);
    }),
    Sensitivity: _.map(sensitivitySettings, 'level'),
  });

  const userFilterOptions = _.map(userOptions, (user) => {
    return {value: user.id, label: formatUserName(user)};
  });

  const sortedAccessRequests = _.orderBy(resultingRecords, ['createdAt'], [isDescending ? 'desc' : 'asc']);

  return (
    <>
      <div className="tr-py-4 tr-flex tr-flex-row tr-flex-wrap tr-justify-start tr-items-center">
        <Search
          placeholder="Search in tasks"
          className="tr-min-w-[200px] md:tr-min-w-[500px] sm:tr-min-[200px]"
          filterOptions={filterOptions as Record<string, (string | undefined)[]>}
          onChange={(_query, filters) => {
            setFilters(filters);
          }}
        />
        <div className="tr-flex tr-flex-row tr-justify-between">
          <Dropdown
            variant={'ternary'}
            title={''}
            selectedValue={filters.User}
            {..._.omit(props, ['size', 'onStateSelected', 'selectedStatus'])}
            dropdownToggle={
              _.isUndefined(filters.User) ? <>All Users {`(${_.size(userFilterOptions)})`}</> : <>{filters.User}</>
            }
            dropdownMenu={
              <DropdownMenu>
                <DropdownItem
                  className="tr-flex tr-flex-row tr-items-center tr-justify-start"
                  onClick={() => {
                    setFilters({...filters, User: undefined});
                  }}
                >
                  <span> {`All users`}</span>
                </DropdownItem>
                <>
                  {userFilterOptions.map((options) => {
                    return (
                      <DropdownItem
                        className="tr-flex tr-flex-row tr-items-center tr-justify-start"
                        onClick={() => {
                          setFilters({...filters, User: options.label});
                        }}
                      >
                        <span> {` ${options.label} `}</span>
                      </DropdownItem>
                    );
                  })}
                </>
              </DropdownMenu>
            }
          ></Dropdown>
          <Dropdown
            variant={'ternary'}
            title={''}
            selectedValue={filters.Type}
            {..._.omit(props, ['size', 'onStateSelected', 'selectedStatus'])}
            dropdownToggle={
              _.isUndefined(filters.Type) ? (
                <>All Types {`(${_.size(_.filter(types, (t) => t))})`}</>
              ) : (
                <>{filters.Type}</>
              )
            }
            dropdownMenu={
              <DropdownMenu>
                <DropdownItem
                  className="tr-flex tr-flex-row tr-items-center tr-justify-start"
                  onClick={() => {
                    setFilters({...filters, Type: undefined});
                  }}
                >
                  <span> {`All Types`}</span>
                </DropdownItem>
                <>
                  {_.filter(types, (t) => t).map((type) => {
                    return (
                      <DropdownItem
                        className="tr-flex tr-flex-row tr-items-center tr-justify-start"
                        onClick={() => {
                          setFilters({...filters, Type: type as string});
                        }}
                      >
                        <span> {` ${type} `}</span>
                      </DropdownItem>
                    );
                  })}
                </>
              </DropdownMenu>
            }
          ></Dropdown>
        </div>
      </div>
      <div className="tr-grid tr-grid-cols-5">
        <div className="lg:tr-col-span-2 md:tr-col-span-2 tr-col-span-5">
          <div className="tr-py-2 tr-px-2 tr-flex tr-flex-row tr-justify-between ">
            <div className="tr-text-xs">
              <span>{`Showing: ${resultingRecords.length}`}</span>
              <Button className="tr-ml-2 tr-cursor-pointer" size="sm" variant="ternary" disabled={tasksStore.loading}>
                <Icon
                  type="refresh"
                  size="sm"
                  title="Refresh"
                  onClick={() => {
                    void tasksStore.refresh();
                  }}
                />
              </Button>
            </div>

            <div
              className="tr-flex tt--task-order tr-cursor-pointer tr-text-xs tr-items-center"
              onClick={() => setIsDescending(!isDescending)}
            >
              <span className="tr-text-xs">{isDescending ? 'Newest First' : 'Oldest First'}</span>
              <Icon type={isDescending ? 'chevronUp' : 'chevronDown'} />
            </div>
          </div>
          <div className="!tr-h-[800px] blue-scrollbar tr-rounded-md tr-pb-2 tr-overflow-auto">
            <InfiniteScroll
              hasMore={!tasksStore.noMoreTasks}
              onEndReached={() => {
                void tasksStore.loadNextPage();
              }}
              className="!tr-h-[800px] blue-scrollbar tr-rounded-md tr-pb-5"
            >
              {_.map(sortedAccessRequests, (accessRequest: Task, index: number) => {
                return (
                  <TaskCardItem
                    key={index + accessRequest.createdAt + accessRequest.id}
                    accessRequest={accessRequest as Task}
                    isSelected={accessRequest.id === selectedTask?.id}
                    data-testid={`card-${accessRequest.id}`}
                    onSelectedTaskChange={() => setSelectedTask(accessRequest as Task)}
                  />
                );
              })}
              <Button
                variant="ternary"
                className="tr-w-[92%]"
                onClick={() => {
                  void tasksStore.loadNextPage();
                }}
              >
                Load more
              </Button>
            </InfiniteScroll>
          </div>
        </div>
        <div className="tr-hidden md:tr-block lg:tr-block tr-col-span-0 lg:tr-col-span-3 md:tr-col-span-3 tr-p-5">
          <SelectedTaskView
            selectedTask={selectedTask}
            resultingRecords={resultingRecords}
            selectedAccessRequestId={selectedAccessRequestId}
          />
        </div>
        <div className="tr-block md:tr-hidden lg:tr-hidden">
          {isSm && selectedTask && (
            <Modal
              visible={!!selectedTask}
              onClose={() => {
                void setSelectedTask(undefined);
              }}
            >
              <TaskDetailedView accessRequest={selectedTask} />
            </Modal>
          )}
        </div>
      </div>
    </>
  );
});

export default GenericAccessRequestsTable;
