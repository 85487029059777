import {Button, ColumnDescription, Confirmation, Modal, Table, BoxFilter} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useEffect, useMemo, useState} from 'react';
import {
  ConnectedAppsCol,
  EditableTypeCol,
  EditableUserNameCol,
  FlaggedForReviewColumn,
  IssueCol,
  LastActivityColumn,
  LastLoginColumn,
  MultipleActionsCol,
  NumAccessesLinkCol,
  RiskScoreCol,
  StatusColumn,
  UsageCol,
  UserAccountColumn,
} from 'src/components/design/tables/SharedColumnDefinitions/AccountColumns';
import {useQueryStrings, useRootStore} from 'src/lib/hooks';
import {Account, UnassignedAccountT} from 'src/stores/domainObjects/Account';
import {Resource} from 'src/stores/domainObjects/Resource';
import {AccountAccessType, ConnectionServiceE} from 'src/types';
import AccountDetailModal, {TabModeE} from './AccountDetailsModal';
import NewResourceAccountModal from './NewResourceAccountModal';
import {FilterMatchTypeE} from './ResourceAccounts';
import ResourceAccountsUpload from './ResourceAccountsUpload';
import {getAccountsTemplate} from 'src/connectors/manifests';

export type UserDataT = {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  accountType?: AccountAccessType;
};

const UnlinkedAccounts = observer(({accounts, system}: {accounts: Account[]; system: Resource}) => {
  const {accountsStore, usersStore} = useRootStore();
  const {newResourceStore: resourceStore} = useRootStore();

  const [selected, setSelected] = useState<string[]>([]);
  const [newMappings, setNewMappings] = useState<Record<string, {account: UnassignedAccountT; userId?: string}>>({});
  const [filters, setFilters] = useQueryStrings();
  const [assignCsvModalShowing, setAssignCsvModalShowing] = useState(false);
  const [assignNewModal, setAssignNewModal] = useState<{
    account: Pick<Account, 'id' | 'account' | 'accountType'> & {email: string};
    user: UserDataT;
  }>();
  const [confirmModalShowing, setConfirmModalShowing] = useState(false);
  const [detailsModal, setDetailsModal] = useState<{account: Account; mode: TabModeE} | undefined>(undefined);

  const accountMap: Record<FilterMatchTypeE, Account[]> = {
    [FilterMatchTypeE.PerfectMatch]: accounts.filter((a) => !_.isEmpty(a.perfectUserMatch)),
    [FilterMatchTypeE.PartialMatch]: accounts.filter((a) => !_.isEmpty(a.partialUserMatch)),
    [FilterMatchTypeE.NoMatch]: accounts.filter((a) => _.isEmpty(a.perfectUserMatch) && _.isEmpty(a.partialUserMatch)),
  };
  const [selectedBox, setSelectedBox] = useState<FilterMatchTypeE>(FilterMatchTypeE.NoMatch);
  const [preSelectedAccount, setPreSelectedAccount] = useState<Account | undefined>(undefined);

  useEffect(() => {
    setTimeout(() => {
      const account = _.find(accountsStore.accountsMap, (a) => a.account === filters['Account Name']);
      if (account) {
        setPreSelectedAccount(account);
        setDetailsModal(filters.open ? {account, mode: TabModeE.SERVICE_USAGE} : undefined);
      }
    }, 1000);
  }, [accounts]);

  useEffect(() => {
    if (!_.isEmpty(preSelectedAccount?.partialUserMatch)) {
      setSelectedBox(FilterMatchTypeE.PartialMatch);
    } else if (!_.isEmpty(preSelectedAccount?.perfectUserMatch)) {
      setSelectedBox(FilterMatchTypeE.PerfectMatch);
    } else {
      setSelectedBox(FilterMatchTypeE.NoMatch);
    }
  }, [preSelectedAccount]);

  accounts = accountMap[selectedBox];

  const onUpdateAccount = ({
    accountId,
    userId,
    accountType,
  }: {
    accountId: string;
    userId?: string;
    accountType?: AccountAccessType;
  }) => {
    const prevMapping = newMappings[accountId] ?? {account: {accountType: AccountAccessType.PERSONAL}};
    const newerAccount = {...prevMapping.account, accountType: accountType ?? prevMapping.account.accountType};
    setNewMappings({...newMappings, [accountId]: {account: newerAccount, userId: userId ?? prevMapping.userId}});
  };

  const columns = useMemo(() => {
    const isAWSConnection = system.type === ConnectionServiceE.AWS;
    const isGAPPSConnection = system.type === ConnectionServiceE.GAPPS;

    const template = getAccountsTemplate(system?.type as string);
    const UnlinkedAccountsTable = template!.Unlinked ? template!.Unlinked({accounts, system}) : [];

    const defaultCols: ColumnDescription<Account>[] = [
      FlaggedForReviewColumn,
      StatusColumn,
      UserAccountColumn({columnTitle: 'System Account', columnWidth: '12rem'}),
      NumAccessesLinkCol,
      {
        ...ConnectedAppsCol,
        hidden: !isGAPPSConnection,
        formatExtraData: {
          onClick: (account: Account) => setDetailsModal({account, mode: TabModeE.CONNECTED_APPLICATIONS}),
        },
      },
      {
        ...UsageCol,
        formatExtraData: {
          onClick: (account: Account) => setDetailsModal({account, mode: TabModeE.SERVICE_USAGE}),
        },
        hidden: !system.retrieveUsageDataEnabled,
      },
      {
        ...RiskScoreCol,
        hidden: !isAWSConnection,
        formatExtraData: {
          onClick: (account: Account) => setDetailsModal({account, mode: TabModeE.RISK_REPORT}),
        },
      },
      {
        ...EditableTypeCol,
        formatExtraData: {
          onChange: (id: string, type: AccountAccessType) => onUpdateAccount({accountId: id, accountType: type}),
          disabled: !resourceStore.resourceMap[system.id].canEdit,
        },
      },
      {
        ...EditableUserNameCol,
        formatExtraData: {
          newMappings,
          onSaveMatch: (aid: string, uid: string) => {
            const updAcc = newMappings[aid]?.account ?? accountsStore.accountsMap[aid];
            void usersStore.usersMap[uid].mapAccount(aid, updAcc.account, updAcc.accountType);
          },
          onChangeSelected: onUpdateAccount,
          onMapUser: (account: Account, user: UserDataT, email: string) => {
            const newAccountType = (newMappings[account.id]?.account ?? account).accountType;
            if (_.isNil(user)) {
              // if just a search open create user modal
              setAssignNewModal({
                account: {
                  email,
                  ..._.pick(account, ['id', 'account']),
                  accountType: newAccountType ?? AccountAccessType.PERSONAL,
                },
                user: {
                  firstName: account.foreignData?.firstName,
                  lastName: account.foreignData?.lastName,
                },
              });
            } else {
              // if user is set auto-map it
              void usersStore.usersMap[user.id!].mapAccount(account.id, account.account, newAccountType);
            }
          },
        },
      },
      {
        ...LastActivityColumn,
        hidden:
          system.type !== ConnectionServiceE.GITHUB &&
          system.type !== ConnectionServiceE.AWS &&
          system.type !== ConnectionServiceE.M365,
      },
      {
        ...LastLoginColumn,
        hidden:
          system.type === ConnectionServiceE.GITHUB ||
          system.type === ConnectionServiceE.AWS ||
          system.type === ConnectionServiceE.M365,
      },
      {
        ...IssueCol,
        formatExtraData: {
          onClick: (account: Account) => setDetailsModal({account, mode: TabModeE.ACCOUNT_SECURITY}),
        },
        hidden: !system.retrieveUsageDataEnabled && selectedBox !== FilterMatchTypeE.NoMatch,
      },
      {...MultipleActionsCol, hidden: !system.userIsOwner},
    ];

    const enabledColumns: ColumnDescription<Account>[] = _.map(
      defaultCols,
      (col: ColumnDescription<Account>): ColumnDescription<Account> => {
        return {...col, hidden: col.hidden || !UnlinkedAccountsTable.includes(col.dataField)};
      }
    );
    return enabledColumns;
  }, [system?.type]);

  return (
    <>
      <div className="mr-3 tr-flex service-usage-header tr-justify-between align-items-center">
        <div className="tr-flex tr-justify-between">
          {Object.values(FilterMatchTypeE).map((type) => (
            <BoxFilter
              key={type}
              selected={selectedBox === type}
              caption={_.startCase(type)}
              role="button"
              onClick={() => setSelectedBox(type)}
              className="tr-mr-8"
            >
              {accountMap[type].length}
            </BoxFilter>
          ))}
        </div>
        <div className="tr-flex flex-column">
          <Button
            variant="primary"
            disabled={!system.userIsOwner || _.isEmpty(accounts)}
            onClick={() => setAssignCsvModalShowing(true)}
            data-testid="bulk-linking"
          >
            Bulk Linking
          </Button>
          <div className="my-2 mr-2 tr-flex justify-content-end">
            <a
              href={'/docs/accounts/mapping/csv_guide'}
              rel="noopener noreferrer"
              target="_blank"
              className="text-underline"
            >
              <p style={{fontSize: 'small'}}>Documentation</p>
            </a>
          </div>
        </div>
      </div>
      <div className="tt-account-list-wrapper align-middle pr-4 tr-w-auto">
        <Table
          id="unlinked-accounts"
          tableKey="unlinked-accounts"
          selectRow={{
            hideSelectColumn: !system.userIsOwner || selectedBox === FilterMatchTypeE.NoMatch,
            mode: 'checkbox',
            style: {background: 'rgba(0,81,155,0.1)'},
            selected,
            onSelect: (account: Account, isSelect: boolean) =>
              setSelected(isSelect ? _.union(selected, [account.id]) : _.without(selected, account.id)),
            onSelectAll: (isSelect: boolean, accounts: Account[]) =>
              setSelected(isSelect ? accounts.map((a) => a.id) : []),
          }}
          bulkActionBtnProps={{
            btnLabel: `Link ${selected.length} Account${selected.length !== 1 ? 's' : ''}`,
            onClick: () => setConfirmModalShowing(true),
          }}
          data={accounts}
          columns={columns}
          rowClasses={(row: Account) => (row.isAuthzOwner ? 'authz-row' : '')}
          showEmptyElement={
            <div className="text-center mt-4 pt-4 tr-w-full">
              <img src="/russel-thinking.svg" className="m-2" style={{width: '190px'}} alt="" />
              <div className="body5 mt-3">
                Hmm, we couldn’t seem to find any unlinked accounts with {_.startCase(selectedBox)}
              </div>
              <div className="body5 text-muted mt-2" style={{width: '250px', display: 'inline-grid'}}>
                Try clicking on the one of the other tabs to see more accounts
              </div>
            </div>
          }
          striped={false}
          wrapperClasses="rounded-xl border border-black rounded"
          bordered={false}
        />
      </div>
      {assignCsvModalShowing && (
        <Modal title="Link Accounts" width="xl" onClose={() => setAssignCsvModalShowing(false)}>
          <div>
            <ResourceAccountsUpload
              resource={system}
              onClose={() => {
                setAssignCsvModalShowing(false);
                // refresh();
              }}
            />
          </div>
        </Modal>
      )}
      {assignNewModal && (
        <NewResourceAccountModal
          resource={system}
          defaultData={{...assignNewModal.account, ..._.pick(assignNewModal.user, ['firstName', 'lastName'])}}
          closeModal={() => setAssignNewModal(undefined)}
        />
      )}
      {detailsModal && (
        <AccountDetailModal
          userAccount={detailsModal.account}
          mode={detailsModal.mode}
          closeModal={() => {
            setDetailsModal(undefined);
            if (filters['open']) {
              setFilters(_.omit(filters, 'open'));
            }
          }}
        />
      )}
      {confirmModalShowing && (
        <Confirmation
          onConfirm={() => {
            selected.forEach((id) => {
              const account = accountsStore.accountsMap[id];
              const userId =
                newMappings[id]?.userId ??
                _.first([...(account.perfectUserMatch ?? []), ...(account.partialUserMatch ?? [])])?.id;

              const newAccountType = (newMappings[id]?.account ?? account).accountType;
              void usersStore.usersMap[userId!].mapAccount(account.id, account.account, newAccountType);
            });
            setSelected([]);
            setConfirmModalShowing(false);
            // refresh();
          }}
          onClose={() => {
            setSelected([]);
            setConfirmModalShowing(false);
            // refresh();
          }}
          title={'Confirm Linking'}
        >
          <div>{`This action will associate the ${
            selected.length > 1 ? `${selected.length} selected accounts` : 'selected account'
          } with the selected user${selected.length > 1 ? 's' : ''}.`}</div>
        </Confirmation>
      )}
    </>
  );
});

export default UnlinkedAccounts;
