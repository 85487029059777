import _ from 'lodash';
import React, {useState} from 'react';
import './EditConnectionParams.scss';
import {observer} from 'mobx-react-lite';
import {
  Icon,
  Tooltip,
  Button,
  Alert,
  Select,
  DurationInput,
  SliderInput,
  AutoProvisionIcon,
  Tab,
} from '@trustle/component-library';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ConnectionServiceE, ProvisionMode, ProvisionOptions, UpdateConnectorSettingsT} from 'src/types';
import {Formik, Form as FormikForm, Field, useFormikContext} from 'formik';
import './EditConnectionParams.scss';
import AutomatedProvisionToggle from 'src/connectors/components/AutomatedProvisionToggle';
import {useRootStore} from 'src/lib/hooks';
import {Loading} from '@trustle/component-library';
import {RECOMMENDATION_TYPE} from 'src/stores/domainObjects/Recommendation';
import AutomatedProvisioningModeTooltip from './AutomatedProvisioningModeTooltip';
import {GetSensitivityDurationValidations, translateToUnit} from 'src/lib/validations';
import {object} from 'yup';
import {SensitivityLevelDefinitions} from 'src/components/sensitivity';

const ProvisionSettingModes = observer((props: {resource: Resource; isCreating: boolean}) => {
  const rootStore = useRootStore();
  const {org, currentUser} = rootStore;
  const [showAdvanceControls, setShowAdvanceControls] = useState<boolean>(
    props.resource.provisionMode !== props.resource.deprovisionMode
  );
  const confirmed = props.isCreating || props.resource.defaultConfigurationConfirmed;
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const hideDurationAndSensitivity = !!props.resource.rootSid && props.resource.isLocked;
  const sensitivitySettings = org.sensitivitySettingsSorted;
  const sensitivitySetting = _.find(sensitivitySettings, {id: props.resource.sensitivity?.id})!;

  const userEmail = currentUser!.email.toLowerCase();
  const canSeeResource = currentUser.isOrgOwner && !_.includes(props.resource.owners, userEmail);

  if (props.isCreating || !props.resource.isConnectedSystem) {
    return <></>;
  }
  if (_.isNil(org) || org?.defaultSensitivityId === undefined) {
    return <Loading />;
  }
  const modesDescription: _.Dictionary<{
    name: string;
    resourceVisibility: string;
    approvalTasks: string;
    provisioningTasks: string;
    makeChangesRemotely: string;
    tasksDisplayedProvisioner: string;
  }> = {
    0: {
      name: 'Off',
      resourceVisibility: 'All Hidden',
      approvalTasks: 'None Generated',
      provisioningTasks: 'None Generated',
      makeChangesRemotely: 'No changes will be made on the remote system',
      tasksDisplayedProvisioner: 'None. No task will be generated in Trustle',
    },
    1: {
      name: 'Manual',
      resourceVisibility: 'Users can see active resources and request access',
      approvalTasks: 'Yes',
      provisioningTasks: 'Yes',
      makeChangesRemotely: 'No. Manual action must be completed on remote system',
      tasksDisplayedProvisioner: '“It’s Complete” or “Cancel”',
    },
    2: {
      name: 'Interactive',
      resourceVisibility: 'Users can see active resources and request access',
      approvalTasks: 'Yes',
      provisioningTasks: 'Yes',
      makeChangesRemotely: 'Yes. Approval required. Trustle will make changes on remote system',
      tasksDisplayedProvisioner: '“Initiate De/provision” or “Cancel”',
    },
    3: {
      name: 'Automatic',
      resourceVisibility: 'Users can see active resources and request access',
      approvalTasks: 'Yes',
      provisioningTasks: 'No, (unless overridden)',
      makeChangesRemotely: 'Yes, Trustle will automatically make changes on remote system',
      tasksDisplayedProvisioner:
        'Unless there is an issue provisioning on the remote endpoint, Trustle is making the change automatically on the remote system once the access is approved, so there is no TaskUI',
    },
  };

  const ProvisionModeDescription = (props: {mode: number | null}) => {
    const mode = props.mode || 0;
    return (
      <div className="tr-grid tr-grid-cols-2 tr-gap-y-6 tr-gap-x-8 tr-mt-3">
        <div className="body4">Mode Description: {modesDescription[mode].name}</div>
        <div></div>
        <div className="body5-normal">Approval Tasks:</div>
        <div className="body5">{modesDescription[mode].approvalTasks}</div>
        <div className="body5-normal">Provisioning Tasks:</div>
        <div className="body5">{modesDescription[mode].provisioningTasks}</div>
        <div className="body5-normal">Allow Trustle to make changes on Remote System:</div>
        <div className="body5">{modesDescription[mode].makeChangesRemotely}</div>
        <div className="body5-normal">What will TaskUI options be displayed to the Provisioner?:</div>
        <div className="body5">{modesDescription[mode].tasksDisplayedProvisioner}</div>
      </div>
    );
  };

  const provisionOptionToNum = (mode: ProvisionOptions | null | undefined) => {
    if (_.isNil(mode)) {
      return ProvisionMode.off;
    }
    return ProvisionMode[mode];
  };

  const provisionNumToOption = (num: number | null | undefined) => {
    if (_.isNil(num)) {
      return num;
    }
    const inverted = _.invert(ProvisionMode);
    return inverted[num] as ProvisionOptions;
  };
  const {resource} = props;
  const isSystemResource = !resource.rootSid;
  const [, setFormValuesChange] = useState<_.Dictionary<any>>({});

  async function submitConnection(values: UpdateConnectorSettingsT, formikArgs: any) {
    if (!confirmed) {
      await resource.completeRecommendation(RECOMMENDATION_TYPE.REVIEW_DEFAULT_CONFIGURATION);
    }
    const valuesToUpdate = {
      ...values,
      provisionMode: provisionNumToOption(values.provisionMode as number),
      deprovisionMode: provisionNumToOption(values.deprovisionMode as number),
    };
    await resource.updateConnectionSettings(valuesToUpdate);
    // Reset dirty status.
    formikArgs.resetForm({values});
  }

  const initialFormValues: UpdateConnectorSettingsT = {
    visibility: resource.calculatedHidden.value ? 'hidden' : 'visible',
    sensitivityId: resource.sensitivity ? resource.sensitivity.id : org.defaultSensitivityId ?? null,
    approvalDuration: resource.calcApprovalDuration,
    accessDuration: resource.calcAccessDuration,
    initiateExpiredDeprovision: resource?.initiateExpiredDeprovision ?? false,
    provisionMode: provisionOptionToNum(resource.provisionMode) || 0,
    deprovisionMode: provisionOptionToNum(resource.deprovisionMode) || 0,
  };
  function onChangeFields(event: any, _values: any, _setFieldValue: any) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setFormValuesChange((prevFormValueChange) => {
      const newValues = {
        ...prevFormValueChange,
        ...{[target.name]: _.toString(_.get(initialFormValues, name)) !== _.toString(value)},
      };
      return newValues;
    });
  }
  const sliderHeader = () => {
    if (resource.connector?.service === ConnectionServiceE.GENERIC) {
      return <></>;
    }
    return (
      <div className="ml-1 flex w-full flex-row justify-between">
        <div className="flex-1 self-start">
          <AutoProvisionIcon size="sm" value={false} />
        </div>
        <div className="flex-3 relative left-0.5 flex w-[67%] flex-row self-end">
          <div
            style={{
              flex: '1',
              border: '0px',
              borderTop: '1px',
              borderLeft: '1px',
              borderColor: 'black',
              borderTopStyle: 'solid',
              borderLeftStyle: 'solid',
            }}
          >
            &nbsp;
          </div>
          <AutoProvisionIcon size="sm" value={true} className="relative bottom-1 flex-1" />
          <div
            style={{
              flex: '1',
              border: '0px',
              borderRight: '1px',
              borderTop: '1px',
              borderColor: 'black',
              borderTopStyle: 'solid',
              borderRightStyle: 'solid',
            }}
          >
            &nbsp;
          </div>
        </div>
      </div>
    );
  };

  const AutoSetFields = () => {
    const formikContext = useFormikContext();
    const values: UpdateConnectorSettingsT = formikContext.values as UpdateConnectorSettingsT;
    const setFieldValue = formikContext.setFieldValue;

    React.useEffect(() => {
      if (!showAdvanceControls) {
        setFieldValue('deprovisionMode', values.provisionMode, false);
      }
      if (values.provisionMode === 0 && values.deprovisionMode === 0) {
        setFieldValue('initiateExpiredDeprovision', false, false);
      }
      setSelectedIndex(0);
    }, [values.provisionMode]);

    React.useEffect(() => {
      if (values.deprovisionMode === 0) {
        setFieldValue('initiateExpiredDeprovision', false, false);
      }
      setSelectedIndex(1);
    }, [values.deprovisionMode]);

    return <></>;
  };

  return (
    <div className="tr-mb-9 tr-pb-9">
      <Formik
        initialValues={initialFormValues}
        onSubmit={submitConnection}
        validationSchema={object().shape(GetSensitivityDurationValidations(org))}
      >
        {({isSubmitting, values, dirty, isValid, submitForm, setFieldValue}) => {
          return (
            <FormikForm onChange={(e) => onChangeFields(e, values, setFieldValue)}>
              <AutoSetFields />
              <div>
                <div className="tr-flex header-tab tr-pt-4">
                  <h2>Default Provision Settings</h2>
                  <div className="text-trustle-navy tr-font-normal tr-text-xs tr-ml-1">
                    {!confirmed && (
                      <Tooltip content={<div>Settings are not yet confirmed</div>}>
                        <Icon className="tr-text-warning" type="warning" />
                      </Tooltip>
                    )}
                  </div>
                </div>

                <div className="tr-my-5">
                  {!confirmed && (
                    <Alert title="Confirm Default System Settings" colorVariant="primary">
                      <div className="flex">
                        <div className="flex-1 tr-mr-8">
                          Please confirm the system's default settings. This can always be changed later. Or make any
                          changes you would like and then click <i>Save</i>.
                        </div>
                        <div className="flex-none" style={{marginTop: '-25px'}}>
                          <Button
                            variant="secondary"
                            name="confirm-default-settings"
                            className="tr-text-right"
                            onClick={submitForm}
                          >
                            Confirm
                          </Button>
                        </div>
                      </div>
                    </Alert>
                  )}
                  {dirty && isValid && !canSeeResource && (
                    <Alert
                      title='You have changed your Provisioning Settings. Click "Save" to confirm.'
                      colorVariant="primary"
                      hideCloseButton={false}
                    />
                  )}
                  {dirty && isValid && canSeeResource && (
                    <Alert colorVariant="danger" hideCloseButton={false} title="Read Only System">
                      <span>You are not an owner of this system, you can't save any configuration changes. </span>
                    </Alert>
                  )}
                </div>

                <div className="tr-grid tr-grid-cols-2 tr-gap-x-14 tr-gap-y-6">
                  <div className="tr-flex flex-col tr-justify-between">
                    <div className="">
                      <div className="tr-grid tr-grid-cols-2">
                        <div className="tr-mt-2">
                          <AutomatedProvisioningModeTooltip resource={resource} />
                        </div>
                        <div className="tr-justify-self-end">
                          {showAdvanceControls && (
                            <Button
                              variant="ternary"
                              onClick={() => {
                                setFieldValue('deprovisionMode', values.provisionMode, false);
                                if (values.provisionMode === 0) {
                                  setFieldValue('initiateExpiredDeprovision', false, false);
                                }
                                setShowAdvanceControls(false);
                              }}
                              className="tr-mt-0"
                            >
                              &lt; Cancel
                            </Button>
                          )}
                          {!showAdvanceControls && (
                            <Button
                              variant="ternary"
                              onClick={() => {
                                setShowAdvanceControls(true);
                                setFieldValue('deprovisionMode', values.provisionMode, false);
                              }}
                              className="tr-mt-0"
                            >
                              Advanced Controls
                            </Button>
                          )}
                        </div>
                      </div>
                      <div className="tr-my-8 tr-gap-y-20 flex flex-col tr-mx-8">
                        <div className={showAdvanceControls ? 'tr-flex tr-flex-row' : ''}>
                          {showAdvanceControls && <div className="tr-basis-1/4 body5 tr-mt-9">Provisioning</div>}
                          <div className={showAdvanceControls ? 'tr-basis-3/4' : ''}>
                            <Field
                              component={SliderInput}
                              labels={
                                resource.connector?.service === 'generic'
                                  ? ['Off', 'Manual']
                                  : ['Off', 'Manual', 'Interactive', 'Automatic']
                              }
                              disabled={false}
                              name={'provisionMode'}
                              min={0}
                              max={resource.connector?.service === 'generic' ? 1 : 3}
                              defaultValue={values.provisionMode}
                              key={'provisionMode'}
                              sliderHeader={sliderHeader()}
                            />
                          </div>
                        </div>
                        {showAdvanceControls && (
                          <div className="tr-flex tr-flex-row">
                            <div className="tr-basis-1/4 body5 tr-mt-3">Deprovisioning</div>
                            <div className="tr-basis-3/4">
                              <Field
                                component={SliderInput}
                                labels={
                                  resource.connector?.service === 'generic'
                                    ? ['Off', 'Manual']
                                    : ['Off', 'Manual', 'Interactive', 'Automatic']
                                }
                                disabled={false}
                                name={'deprovisionMode'}
                                key={'deprovisionMode'}
                                min={0}
                                max={resource.connector?.service === 'generic' ? 1 : 3}
                                defaultValue={values.deprovisionMode}
                                sliderHeader={sliderHeader()}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <Field
                        component={Select}
                        name="visibility"
                        className="tr-w-full"
                        label={
                          <div data-testid="select-visibility">
                            Visibility
                            <Tooltip
                              className="tr-font-normal"
                              content={
                                <div>
                                  This will be the default visibility for any resource/permission added to this system.
                                  If hidden, they will not be visible on the access page.
                                </div>
                              }
                            >
                              <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                            </Tooltip>
                          </div>
                        }
                        options={[
                          {label: 'Visible', value: 'visible'},
                          {label: 'Hidden', value: 'hidden'},
                        ]}
                        formatOptionLabel={({value, label}: {value: 'hidden' | 'visible'; label: string}) => (
                          <div className="tr-flex tr-items-center">
                            <Icon
                              data-testid="toggleVisibilityIcon"
                              type={value === 'hidden' ? 'visibilityOff' : 'visibilityOn'}
                              size="sm"
                            />
                            {label}
                          </div>
                        )}
                        isSearchable={false}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="tr-text-right">
                      <Button
                        name="edit-connection-save"
                        onClick={submitForm}
                        disabled={!isValid || isSubmitting || !dirty || canSeeResource}
                      >
                        Save
                      </Button>
                    </div>
                    <div className="mode-description">
                      {showAdvanceControls && (
                        <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
                          <Tab.List variant="line">
                            <Tab data-testid={'Provisioning'} key={'Provisioning'}>
                              {_.startCase('Provisioning')}
                            </Tab>
                            <Tab data-testid={'Deprovisioning'} key={'Deprovisioning'}>
                              {_.startCase('Deprovisioning')}
                            </Tab>
                          </Tab.List>
                          <Tab.Panels>
                            <Tab.Panel key={'Provisioning'}>
                              <ProvisionModeDescription mode={values.provisionMode as number} />
                            </Tab.Panel>
                            <Tab.Panel key={'Deprovisioning'}>
                              <ProvisionModeDescription mode={values.deprovisionMode as number} />
                            </Tab.Panel>
                          </Tab.Panels>
                        </Tab.Group>
                      )}
                      {!showAdvanceControls && <ProvisionModeDescription mode={values.provisionMode as number} />}
                    </div>
                    <div>
                      {isSystemResource && values.deprovisionMode !== 0 && (
                        <div className="tr-flex tr-mt-6">
                          <div>
                            <AutomatedProvisionToggle
                              type="checkbox"
                              className="tr-mr-5"
                              name="initiateExpiredDeprovision"
                            />
                          </div>
                          <div className="tr-flex-initial tr-grow">
                            <div className="tr-flex-auto">
                              <span className="body4 tr-leading-none">Initiate Deprovisioning When Access Expires</span>
                              <Tooltip
                                content={
                                  <div className="tr-cols-1 tr-grid tr-gap-y-4">
                                    <div>
                                      When enabled, we will automatically initiate the deprovisioning process when
                                      either approval or provision access expires.
                                    </div>
                                    <div>
                                      In <strong>Manual mode</strong>, a task will be generated automatically for
                                      deprovisioning of the expired permission. This task is sent to the Provisioner,
                                      who must make the change on the remote system then click in the TaskUI{' '}
                                      <i>"It's Complete"</i>, or not make the change on the remote system and click
                                      "Cancel."
                                    </div>
                                  </div>
                                }
                              >
                                <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {!hideDurationAndSensitivity && (
                  <div className="tr-my-4 tr-pt-8">
                    <div className="tr-grid tr-grid-cols-1">
                      <div className="tr-flex header-tab">
                        <h2>Default Sensitivity Score</h2>
                        <div className="text-trustle-navy tr-font-normal tr-text-xs tr-ml-1">
                          {!confirmed && (
                            <Tooltip content={<div>Settings are not yet confirmed</div>}>
                              <Icon className="tr-text-warning" type="warning" />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="tr-grid tr-grid-cols-2 tr-gap-24">
                      <div>
                        <Field
                          component={Select}
                          name="sensitivityId"
                          label={
                            <div data-testid="select-sensitivity">
                              Sensitivity Score
                              <Tooltip
                                className="tr-font-normal"
                                content={
                                  <div>
                                    This will be the default Sensitivity Score for all resources and permissions on this
                                    system. The durations below will be limited by this score.
                                  </div>
                                }
                              >
                                <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                              </Tooltip>
                            </div>
                          }
                          options={sensitivitySettings.map((s) => ({value: s.id, label: s.level}))}
                          formatOptionLabel={({label}: {label: string}) => (
                            <>
                              <Icon
                                type={_.find(SensitivityLevelDefinitions, {level: label})?.icon ?? 'error'}
                                size="sm"
                                className="tr-mr-2"
                              />
                              <span data-testId={`sensitivity-option-${label}`}>{_.upperFirst(label)}</span>
                            </>
                          )}
                          isSearchable={false}
                          onChange={async (s: any) => {
                            const sensitivity = _.find(sensitivitySettings, {id: s.value})!;
                            setFieldValue('approvalDuration', {
                              durationValue: sensitivity.maxApprovalDurationValue,
                              durationUnit: sensitivity.maxApprovalDurationUnit,
                            });
                            setFieldValue('accessDuration', {
                              durationValue: sensitivity.maxApprovalDurationValue,
                              durationUnit: sensitivity.maxApprovalDurationUnit,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <div className="tr-grid tr-grid-cols-2 tr-gap-4 tr-gap-x-14 tr-justify-items-stretch">
                          <div>
                            <Field
                              component={DurationInput}
                              name="approvalDuration"
                              label={
                                <>
                                  Approval Duration
                                  <Tooltip
                                    className="tr-font-normal"
                                    content={
                                      <div>
                                        The default length of time a Trustle User is granted just-in-time (JIT) access
                                        to a resource. During this time, the Trustle user possesses on-demand access to
                                        the resource, and will be in either an Approved (zero standing), or Active
                                        (standing access) state.
                                        <div>
                                          <span className="font-bold">This value must be lower </span>
                                          than the maximum defined by the default Sensitivity Score.
                                        </div>
                                      </div>
                                    }
                                  >
                                    <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                                  </Tooltip>
                                </>
                              }
                              maxDurationValue={translateToUnit(
                                {
                                  durationUnit: sensitivitySetting.maxApprovalDurationUnit,
                                  durationValue: sensitivitySetting.maxApprovalDurationValue,
                                },
                                values?.approvalDuration?.durationUnit ?? 'hour'
                              )}
                              maxDurationUnit={sensitivitySetting?.maxApprovalDurationUnit}
                              required={true}
                            />
                          </div>
                          <div>
                            <Field
                              component={DurationInput}
                              name="accessDuration"
                              label={
                                <div data-testid="select-sensitivity-level">
                                  Access Duration
                                  <Tooltip
                                    className="tr-font-normal"
                                    content={
                                      <div>
                                        The default length of time a Trustle User is allowed to be in an Active
                                        (standing access) state before they are returned to an Approved (zero standing)
                                        state.
                                        <div>
                                          <span className="font-bold">This value must be lower </span>than the maximum
                                          allowed by the default Sensitivity Score.
                                        </div>
                                      </div>
                                    }
                                  >
                                    <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                                  </Tooltip>
                                </div>
                              }
                              maxDurationValue={translateToUnit(
                                {
                                  durationUnit: sensitivitySetting.maxAccessDurationUnit,
                                  durationValue: sensitivitySetting.maxAccessDurationValue,
                                },
                                values?.accessDuration?.durationUnit ?? 'hour'
                              )}
                              maxDurationUnit={sensitivitySetting?.maxAccessDurationUnit}
                              required={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
});

export default ProvisionSettingModes;
