import React, {useState} from 'react';
import _ from 'lodash';
import AccessDetail from './AccessDetail';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import ReportDropdown from 'src/views/admin/reports/ReportDropdown';
import {DateRangePicker, FocusedInputShape} from 'react-dates';
import moment from 'moment';
import {Button, Modal, Tab} from '@trustle/component-library';
import {useFeatureFlags} from 'src/lib';
import OrgEvents from 'src/views/Events/OrgEvents';

type AccessDetailModalProps = {
  closeModal: any;
  visible: boolean;
  access?: AccessRecord;
};

enum TabModeE {
  GENERAL = 'general',
  HISTORY = 'history',
}

function AccessDetailModal(props: AccessDetailModalProps) {
  const {closeModal, access, visible} = props;
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);

  const featureFlags = useFeatureFlags();
  const orgEventsReportsHidden = featureFlags.isEnabled('hide_reports_until_peryton_events_stable');

  if (!access) {
    return <></>;
  }

  const handleDatesChange = ({startDate, endDate}: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const params = new URLSearchParams();
  if (startDate) {
    params.set('startDate', startDate.format('MM/DD/YYYY'));
  }

  if (endDate) {
    params.set('endDate', endDate.format('MM/DD/YYYY'));
  }

  params.set('aid', access.id);

  function isOutsideRange(day: moment.Moment): boolean {
    return day.isAfter(moment());
  }

  return (
    <Modal
      onClose={closeModal}
      width="lg"
      height="md"
      visible={visible}
      title={`${access.forAccount?.account} Details`}
    >
      <Tab.Group className="m-2">
        <Tab.List variant="line">
          <Tab data-testid={TabModeE.GENERAL} key={TabModeE.GENERAL}>
            {_.startCase(TabModeE.GENERAL)}
          </Tab>
          <Tab data-testid={TabModeE.HISTORY} key={TabModeE.HISTORY}>
            {_.startCase(TabModeE.HISTORY)}
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel key={TabModeE.GENERAL}>
            <AccessDetail access={access} />
          </Tab.Panel>
          <Tab.Panel key={TabModeE.HISTORY}>
            <div className="history-tab tr-flex tr-flex-col">
              <div className="flex-align tr-justify-between tr-items-center generate-report-button-wrapper">
                <label className="body3">Show all history</label>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    setGeneratingReport(!generatingReport);
                  }}
                  selected={generatingReport}
                >
                  Generate Report
                </Button>
              </div>
              {!orgEventsReportsHidden && (
                <div className="flex-align tr-justify-between tr-items-center generate-report-form-wrapper">
                  <label className="m-1 body4">Select date range</label>
                  <DateRangePicker
                    startDate={startDate}
                    startDateId="tata-start-date"
                    endDate={endDate}
                    isOutsideRange={isOutsideRange}
                    showDefaultInputIcon
                    inputIconPosition="after"
                    endDateId="tata-end-date"
                    onDatesChange={handleDatesChange}
                    customArrowIcon={<>-</>}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                  />

                  <ReportDropdown
                    title="Download Report"
                    url={`/api/access/complete_history_report`}
                    queryParams={params}
                  />
                </div>
              )}
              <OrgEvents entityType={'access'} id={access.id} />
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Modal>
  );
}

export default AccessDetailModal;
