import _ from 'lodash';
import React, {useState} from 'react';
import {Formik, FormikForm} from 'src/components/design/formik';
import axios from 'axios';
import './Settings.scss';
import {logger} from 'src/lib';
import {useToasts} from 'react-toast-notifications';
import {observer} from 'mobx-react';
import {convertResourceToForm} from 'src/lib/ancestors';
import {Resource} from 'src/stores/domainObjects/Resource';
import {useRootStore} from 'src/lib/hooks';
import {ConnectionServiceE} from 'src/types';
import {Alert, Button, DurationInput, Select, Tooltip, Icon, Loading} from '@trustle/component-library';
import {object, string, boolean} from 'yup';
import {RECOMMENDATION_TYPE} from 'src/stores/domainObjects/Recommendation';
import {Field} from 'formik';
import {SensitivityLevelDefinitions} from 'src/components/sensitivity';
import ResourceOwnersAndProvisioners from '../ResourceOwnersAndProvisioners';
import ResourceEditNameDescription, {ResourceNameAndDescriptionFields} from './ResourceEditNameDescription';
import {GetSensitivityDurationValidations} from 'src/lib/validations';
import ProvisionSettingModes from 'src/connectors/common/editConnection/ProvisionSettingModes';

type PropsT = {
  resource?: Resource;
  refresh?: any;
  isCreating: boolean;
  descriptionInTitle?: boolean;
  connectorType?: ConnectionServiceE;
  cancel?: () => void;
};

const ResourceForm = observer(function ResourceEditCmp(props: PropsT) {
  const rootStore = useRootStore();
  const {newResourceStore, org, currentUser} = rootStore;

  const userEmail = currentUser!.email.toLowerCase();
  const {
    resource = new Resource(rootStore, {
      owners: [userEmail],
      pendingSetup: !!props.connectorType && props.connectorType !== ConnectionServiceE.GENERIC,
      connection: props.connectorType ? {service: props.connectorType} : undefined,
    }),
    refresh,
    isCreating,
  } = props;
  const {addToast} = useToasts();
  const [, setShowConfirmModal] = useState<boolean>(false); //showConfirmModal
  const confirmed = isCreating || resource.defaultConfigurationConfirmed;

  if (_.isNil(org) || org?.defaultSensitivityId === undefined) {
    return <Loading />;
  }

  //Second part of clause is inherited from SystemSetupForm
  const hideDurationAndSensitivity =
    (!!resource.rootSid && resource.isLocked) || (!isCreating && resource.isConnectedSystem);
  const sensitivitySettings = org.sensitivitySettingsSorted;
  const sensitivitySetting = _.find(sensitivitySettings, {id: resource.sensitivity?.id});

  async function save(values: any) {
    if (!resource) {
      return {error: true, msg: 'Resource does not exist.'};
    }
    const url = `/api/resources/${isCreating ? 'system' : resource.id}`;

    const {approvalDuration, accessDuration} = values;
    //Just send what has changed.
    const deltas = _.reduce(
      convertResourceToForm(org!, resource),
      (result: string[], value, key: string) => {
        return _.isEqual(value, values[key]) ? result : result.concat(key);
      },
      []
    );

    if (deltas.includes('approvalDuration')) {
      deltas.push('durationUnit');
      deltas.push('durationValue');
    }

    if (deltas.includes('accessDuration')) {
      deltas.push('accessDurationUnit');
      deltas.push('accessDurationValue');
    }

    const owners = values.owners;
    const provisioners = values.provisioners;
    if (isCreating) {
      owners.push(userEmail);
      provisioners.push(userEmail);
    }

    const cleanResource = _.pickBy(
      {
        ..._.pick(values, ['name', 'description', 'sensitivityId', 'autoProvision', 'initiateExpiredDeprovision']),
        hidden: values.visibility === 'hidden',
        pendingSetup: false,
        owners: owners,
        provisioners: values.provisioners,
        durationValue: approvalDuration.durationValue,
        durationUnit: approvalDuration.durationUnit,
        accessDurationValue: accessDuration.durationValue,
        accessDurationUnit: accessDuration.durationUnit,
      },
      (v) => !_.isNil(v)
    );

    // placeholder connection to reuse the import process on CSV connectors
    if (props.connectorType === ConnectionServiceE.GENERIC) {
      cleanResource.connection = {service: props.connectorType};
    }

    try {
      const res = await axios.post(url, {resource: isCreating ? cleanResource : _.pick(cleanResource, deltas)});
      res.data && newResourceStore.updateResourceFromServer(res.data);
      addToast(`Resource saved successfully`, {appearance: 'success', autoDismiss: true});
      if (!confirmed) {
        await resource.completeRecommendation(RECOMMENDATION_TYPE.REVIEW_DEFAULT_CONFIGURATION);
      }
      refresh && refresh(res);
      return {error: false, msg: 'Saved the resource.'};
    } catch (err) {
      addToast(`An error occurred while saving`, {appearance: 'error', autoDismiss: true});
      logger.error(err);
      return {error: true, msg: 'Failed to save the resource.'};
    }
  }

  const sensitivityDurationValidations = GetSensitivityDurationValidations(org);

  let validationSchema = {
    autoProvision: boolean(),
    ...sensitivityDurationValidations,
  };
  if (isCreating) {
    validationSchema = {
      ...{
        name: string().max(80, 'The name may not be greater than 80 characters'),
        description: string().max(500, 'The description may not be greater than 500 characters'),
      },
      ...validationSchema,
    };
  }
  const sensitivityId = resource.sensitivity ? resource.sensitivity.id : org.defaultSensitivityId ?? null;
  return (
    <>
      {!isCreating && (
        <>
          <div className="tr-border-gray-200 tr-border-[0px] tr-border-b-[1px] tr-border-solid ">
            <ResourceEditNameDescription
              resource={resource}
              refresh={refresh}
              isCreating={isCreating}
              connectorType={props.connectorType}
            />
          </div>
          <div className="tr-border-gray-200 tr-border-[0px] tr-border-b-[1px] tr-border-solid tr-py-4">
            <ResourceOwnersAndProvisioners resource={resource} />
          </div>
        </>
      )}
      <ProvisionSettingModes resource={resource} isCreating={isCreating} />

      <Formik
        initialValues={{
          name: resource.name || '',
          description: resource.description || '',
          owners: resource.owners ?? [],
          provisioners: resource.provisioners ?? [],
          visibility: resource.calculatedHidden.value ? 'hidden' : 'visible',
          autoProvision: resource.autoProvisionValue?.value ?? false,
          sensitivityId: sensitivityId,
          initiateExpiredDeprovision: resource.initiateExpiredDeprovision ?? false,
          approvalDuration: resource.calcApprovalDuration,
          accessDuration: {durationValue: 2, durationUnit: 'month'},
        }}
        validationSchema={object().shape(validationSchema)}
        onSubmit={async (values) => {
          setShowConfirmModal(false);
          await save(values);
        }}
        enableReinitialize={true}
      >
        {({isSubmitting, isValid, dirty, setFieldValue}) => {
          const submitButton = (
            <Button name="resourceSave" type="submit" disabled={(!isValid || isSubmitting || !dirty) && confirmed}>
              Save
            </Button>
          );
          return (
            <FormikForm className="resource-edit text-left">
              <div>
                {isCreating && (
                  <div className="tr-my-4">
                    <div className="tr-flex">
                      <div className="tr-grid-rows-2 tr-w-4/6 tr-gap-x-2">
                        <ResourceNameAndDescriptionFields
                          resource={resource}
                          isCreating={isCreating}
                          connectorType={props.connectorType}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!hideDurationAndSensitivity && (
                  <>
                    <div className="my-4 tr-border-gray-200 tr-border-[0px] tr-border-t-[1px] tr-border-solid tr-pt-6">
                      <div className="tr-grid tr-grid-cols-2">
                        <div>
                          <div className="tr-grid tr-grid-cols-2">
                            <div className="tr-flex header-tab">
                              <h2>Default System Settings</h2>
                              <div className="text-trustle-navy tr-font-normal tr-text-xs tr-ml-1">
                                {!confirmed && (
                                  <Tooltip content={<div>Settings are not yet confirmed</div>}>
                                    <Icon className="tr-text-warning" type="warning" />
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {!isCreating && (
                          <div className="text-right">
                            <span className=" tr-mt-4">{submitButton}</span>
                          </div>
                        )}
                      </div>

                      {!confirmed && (
                        <Alert title="Confirm Default System Settings" colorVariant="primary">
                          Please confirm the default system settings clicking <u>Save</u>. This can be changed later.
                        </Alert>
                      )}
                    </div>

                    <div className="tr-grid tr-grid-cols-2 tr-gap-8 tr-gap-x-28 tr-gap-y-8 tr-mb-6">
                      <div>
                        <Field
                          component={Select}
                          name="sensitivityId"
                          label={
                            <>
                              Sensitivity Score
                              <Tooltip
                                className="tr-font-normal"
                                content={
                                  <div>
                                    This will be the default Sensitivity Score for all resources and permissions on this
                                    system. The durations below will be limited by this score.
                                  </div>
                                }
                              >
                                <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                              </Tooltip>
                            </>
                          }
                          options={sensitivitySettings.map((s) => ({value: s.id, label: s.level}))}
                          formatOptionLabel={({label}: {label: string}) => (
                            <>
                              <Icon
                                type={_.find(SensitivityLevelDefinitions, {level: label})?.icon ?? 'error'}
                                size="sm"
                                className="tr-mr-2"
                              />
                              {_.upperFirst(label)}
                            </>
                          )}
                          onChange={async (s: any) => {
                            const sensitivity = _.find(sensitivitySettings, {id: s.value})!;
                            setFieldValue('approvalDuration', {
                              durationValue: sensitivity.maxApprovalDurationValue,
                              durationUnit: sensitivity.maxApprovalDurationUnit,
                            });
                            setFieldValue('accessDuration', {
                              durationValue: sensitivity.maxApprovalDurationValue,
                              durationUnit: sensitivity.maxApprovalDurationUnit,
                            });
                          }}
                          isSearchable={false}
                        />
                      </div>
                      <div>
                        <Field
                          component={Select}
                          name="visibility"
                          label={
                            <>
                              Visibility
                              <Tooltip
                                className="tr-font-normal"
                                content={
                                  <div>
                                    This will be the default visibility for any resource/permission added to this
                                    system. If hidden, they will not be visible on the access page.
                                  </div>
                                }
                              >
                                <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                              </Tooltip>
                            </>
                          }
                          options={[
                            {label: 'Visible', value: 'visible'},
                            {label: 'Hidden', value: 'hidden'},
                          ]}
                          formatOptionLabel={({value, label}: {value: 'hidden' | 'visible'; label: string}) => (
                            <div className="tr-flex tr-items-center">
                              <Icon
                                data-testid="toggleVisibilityIcon"
                                type={value === 'hidden' ? 'visibilityOff' : 'visibilityOn'}
                                size="sm"
                                className="tr-mr-2"
                              />
                              {label}
                            </div>
                          )}
                          isSearchable={false}
                        />
                      </div>
                      <div>
                        <div className="tr-grid tr-grid-cols-2 tr-gap-4 tr-gap-x-14 tr-justify-items-stretch">
                          <Field
                            component={DurationInput}
                            name="approvalDuration"
                            label={
                              <>
                                Approval Duration
                                <Tooltip
                                  className="tr-font-normal"
                                  content={
                                    <div>
                                      The default length of time a Trustle User is granted just-in-time (JIT) access to
                                      a resource. During this time, the Trustle user possesses on-demand access to the
                                      resource, and will be in either an Approved (zero standing), or Active (standing
                                      access) state.
                                      <div>
                                        <span className="font-bold">This value must be lower </span>
                                        than the maximum defined by the default Sensitivity Score.
                                      </div>
                                    </div>
                                  }
                                >
                                  <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                                </Tooltip>
                              </>
                            }
                            maxDurationValue={sensitivitySetting?.maxApprovalDurationValue}
                            maxDurationUnit={sensitivitySetting?.maxApprovalDurationUnit}
                            required={true}
                          />
                          <div>
                            <Field
                              component={DurationInput}
                              name="accessDuration"
                              label={
                                <>
                                  Access Duration
                                  <Tooltip
                                    className="tr-font-normal"
                                    content={
                                      <div>
                                        The default length of time a Trustle User is allowed to be in an Active
                                        (standing access) state before they are returned to an Approved (zero standing)
                                        state.
                                        <div>
                                          <span className="font-bold">This value must be lower </span>than the maximum
                                          allowed by the default Sensitivity Score.
                                        </div>
                                      </div>
                                    }
                                  >
                                    <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                                  </Tooltip>
                                </>
                              }
                              maxDurationValue={sensitivitySetting?.maxAccessDurationValue}
                              maxDurationUnit={sensitivitySetting?.maxAccessDurationUnit}
                              required={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {isCreating && (
                <div className="text-right">
                  <span className=" tr-mt-4">{submitButton}</span>
                </div>
              )}
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
});

export default ResourceForm;
