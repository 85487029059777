import React, {useEffect, useMemo, useState} from 'react';
import {LicenseUsageStackedBarChart} from './internal/licenses-usage-stacked-bar-chart/LicenseUsageStackedBarChart';
import {LicensesTable} from './internal/licenses-table/LicensesTable';
import _ from 'lodash';
import {LicenseUsageOverview} from './internal/license-usage-overview/LicenseUsageOverview';
import {DashboardCard} from '../DashboardCard';
import {LicenseUsageHeatMap} from './internal/license-usage-heat-map/LicenseUsageHeatMap';
import {Tab} from '@trustle/component-library';
import {useLicenseUsage} from './hooks/useLicenseUsage';
import {LicenseUsage, ViewType} from './types';
import {ALL_LICENSES_KEY, AVAILABLE_LICENSES_OPTIONS} from './constants';

const DEFAULT_VIEW: {value: ViewType; label: string} = {
  value: 'week',
  label: 'Last 7 days',
};

type Props = {
  resourceId: string;
  showHeatmap?: boolean; // Default to false
  userId?: string;
};

type LicenseUsageWithLicense = LicenseUsage & {license: string};

export function M365LicenseUsageCard({showHeatmap = false, userId, resourceId = ''}: Props) {
  const [selectedLicenses, setSelectedLicenses] = useState<string[]>(['copilot']);
  const [selectedView, setSelectedView] = useState(DEFAULT_VIEW);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [summarizeAllLicenses, setSummarizeAllLicenses] = useState(false);

  const {
    data: licenseUsageData,
    fetchLicenseUsage,
    loading: loadingLicenseUsage,
  } = useLicenseUsage({
    resourceId: resourceId,
  });

  useEffect(() => {
    void fetchLicenseUsage({
      licenses: selectedLicenses,
      view: selectedView.value,
      userId,
      allLicenses: summarizeAllLicenses,
    });
  }, [selectedLicenses, selectedView, userId, summarizeAllLicenses]);

  const FILTERED_LICENSE_USAGE_DATA = useMemo(() => {
    if (!licenseUsageData?.license_usage && !licenseUsageData?.licenses) {
      return [];
    }

    let preprocessedData: LicenseUsageWithLicense[] = [];
    const usageSource = licenseUsageData?.license_usage || licenseUsageData?.licenses; // TODO: should be removed once properties are normalized
    Object.keys(usageSource).forEach((licenseKey) => {
      const dataWithLicenseKey = usageSource[licenseKey].map((usage) => ({
        ...usage,
        license: licenseKey.split('::')[0], //TODO: should be removed once keys are normalized (Keys are coming in the format 'key::key')
      }));
      preprocessedData = [...preprocessedData, ...dataWithLicenseKey];
    });

    const groupedData = _.groupBy(preprocessedData, 'reporting_period');
    return Object.keys(groupedData)
      .map((key, index) => {
        return {
          name: key,
          data: groupedData[key].reduce((acc, curr) => {
            acc[curr.license] = curr;
            return acc;
          }, {} as any),
          index,
        };
      })
      .sort((a, b) => {
        // Convert the date strings to Date objects
        const dateA = new Date(a.name);
        const dateB = new Date(b.name);

        // Compare the dates
        return dateA.getTime() - dateB.getTime();
      });
  }, [licenseUsageData]);

  if (licenseUsageData === undefined) {
    return null;
  }

  return (
    <DashboardCard loading={loadingLicenseUsage}>
      <div className="tr-flex tr-gap-2 tr-p-4">
        <div className="tr-flex tr-flex-col tr-w-full tr-gap-2 ">
          <LicenseUsageOverview
            summary={licenseUsageData?.summary_statistics || {total_usage_hours: 0, total_access_hours: 0}}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
          <div className="tr-p-2">
            {showHeatmap ? (
              <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
                <Tab.List variant="line">
                  <Tab key={'BARCHART'}>Timeline</Tab>
                  <Tab key={'HEATMAP'}>Heatmap</Tab>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel key={'BARCHART'}>
                    <LicenseUsageStackedBarChart
                      data={FILTERED_LICENSE_USAGE_DATA}
                      series={summarizeAllLicenses ? [ALL_LICENSES_KEY] : selectedLicenses}
                    />
                  </Tab.Panel>
                  <Tab.Panel key={'HEATMAP'}>
                    <LicenseUsageHeatMap
                      view={selectedView.value}
                      licenses={selectedLicenses}
                      resourceId={resourceId}
                    />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            ) : (
              <LicenseUsageStackedBarChart
                data={FILTERED_LICENSE_USAGE_DATA}
                series={summarizeAllLicenses ? [ALL_LICENSES_KEY] : selectedLicenses}
              />
            )}
          </div>
        </div>
        <div className="tr-max-w-[500px] tr-flex tr-border-gray-200 tr-border-[0px] tr-border-l-[1px] tr-border-solid tr-p-4">
          <LicensesTable
            licenses={AVAILABLE_LICENSES_OPTIONS}
            selectedLicenses={selectedLicenses}
            setSelectedLicenses={setSelectedLicenses}
            summarizeAllLicenses={summarizeAllLicenses}
            setSummarizeAllLicenses={setSummarizeAllLicenses}
          />
        </div>
      </div>
    </DashboardCard>
  );
}
