import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {Button} from '@trustle/component-library';
import {FieldMappingSection} from './internal/FieldMappingSection';
import {Form, Formik} from 'formik';
import {AuthoritySettingsAdapter, SERVICE_FIELD_MAPPINGS} from './helpers';
import {FormikHelpers} from 'formik/dist/types';
import {AlreadyConfiguredAuthorityBanner} from '../banners/AlreadyConfiguredAuthorityBanner';
import {AuthorityAssociateBanner} from '../banners/AuthorityAssociateBanner';
import {ConfirmAssociateAuthorityModal} from '../modals/ConfirmAssociateAuthorityModal';
import {ConfirmDisassociateAuthorityModal} from '../modals/ConfirmDisassociateAuthorityModal';
import {useAuthority} from '../hooks/useAuthority';
import {Resource} from '../../../../stores/domainObjects/Resource';
import {AuthorityServiceE, IDPIntegration} from '../../../../types';
import _ from 'lodash';

type Props = {
  resource: Resource;
};

export const AuthoritySettings = observer(({resource}: Props) => {
  const {
    settings: authoritySettings,
    resource: authorityResource,
    isConfigured: isAuthorityConfigured,
    updateAuthority,
    createAuthority,
  } = useAuthority();

  const [enabledAsAuthority, setEnabledAsAuthority] = useState<boolean>(resource.isConfiguredAsIDP);

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const {idpIntegrationService, connectionId, isConfiguredAsIDP: resourceIsConfiguredAsIDP} = resource;

  const idpService = idpIntegrationService as AuthorityServiceE | undefined;

  if (!idpService) {
    return null;
  }

  const disableInteractions = resource.shouldDisableInteractions;

  const initialValues = resolveInitialValues(idpService, authoritySettings, resourceIsConfiguredAsIDP);

  const submitHandler = async (values: any, formikHelpers: FormikHelpers<any>) => {
    const {idpFieldMapping} = values;
    const createOrUpdateParams = {
      associated: enabledAsAuthority,
      service: idpService.toString(),
      fieldMapping: idpFieldMapping,
    };
    isAuthorityConfigured
      ? await updateAuthority(createOrUpdateParams)
      : await createAuthority({...createOrUpdateParams}, resource);
    formikHelpers.resetForm({values});
    setShowConfirmModal(false);
  };

  if (!resourceIsConfiguredAsIDP && isAuthorityConfigured && !disableInteractions) {
    return (
      <div className="tr-flex tr-flex-col tr-gap-4 tr-py-4">
        <AlreadyConfiguredAuthorityBanner resourceId={authorityResource?.id || ''} />
      </div>
    );
  }

  const authoritySettingsHasChanges = (currentValues: {idpFieldMapping: any}) => {
    return !_.isEqual(currentValues.idpFieldMapping, authoritySettings?.fieldMapping);
  };

  return (
    <div className="tr-flex tr-flex-col tr-gap-4 tr-py-4">
      {!disableInteractions && !resourceIsConfiguredAsIDP && (
        <AuthorityAssociateBanner
          resource={resource}
          handleAuthorityStatusChange={(enable) => {
            setEnabledAsAuthority(enable);
            setShowConfirmModal(true);
          }}
        />
      )}

      <Formik initialValues={initialValues} onSubmit={submitHandler}>
        {({submitForm, values}) => (
          <Form>
            {resourceIsConfiguredAsIDP && (
              <div className="tr-flex tr-flex-col tr-gap-8">
                <FieldMappingSection
                  service={idpService}
                  connectionId={connectionId!}
                  disableInteractions={disableInteractions}
                />
                <div className="tr-ml-[auto]">
                  <Button
                    className="tr-min-w-[200px] tr-p-2"
                    onClick={submitForm}
                    disabled={!resourceIsConfiguredAsIDP || disableInteractions || !authoritySettingsHasChanges(values)}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}

            {showConfirmModal && !resourceIsConfiguredAsIDP && (
              <ConfirmAssociateAuthorityModal onConfirm={submitForm} onCancel={() => setShowConfirmModal(false)} />
            )}
            {showConfirmModal && resourceIsConfiguredAsIDP && (
              <ConfirmDisassociateAuthorityModal
                onConfirm={submitForm}
                onCancel={() => setShowConfirmModal(false)}
                name={resource.name}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
});

function resolveInitialValues(
  idpService: AuthorityServiceE,
  idpSettings: IDPIntegration | undefined,
  isConfiguredAsIDP: boolean
) {
  const shouldDefaultConfig = !isConfiguredAsIDP || !idpSettings;
  if (shouldDefaultConfig) {
    return {
      idpFieldMapping: {...SERVICE_FIELD_MAPPINGS[idpService]},
    };
  }
  const adaptedSettings = new AuthoritySettingsAdapter(idpSettings, idpService);
  const initialValues = adaptedSettings.adaptToNewFormat();
  return {idpFieldMapping: initialValues.fieldMapping};
}
