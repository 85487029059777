import axios from 'axios';
import _ from 'lodash';
import {makeAutoObservable, onBecomeObserved, runInAction} from 'mobx';
import moment from 'moment';
import React, {ReactElement} from 'react';
import {Link} from 'react-router-dom';
import {SensitivityLevelDefinitions} from 'src/components/sensitivity/SensitivityConfiguration';
import {formatUserName, logger, pLimit, retryUntilConditionMet} from 'src/lib';
import {
  ResourceOwnerT,
  ResourceProvisionerT,
  DurationUnitT,
  ResourceRequestT,
  ResourceConnectorT,
  ResourceHistoryItemT,
  SystemSetupStepT,
  SystemSetupStepId,
  TaskT,
  MinSensitivitySettingT,
  ConnectionServiceE,
  ImportStatus,
  NodeImportStateT,
  ReferenceType,
  ImportStateServiceE,
  UpdateConnectorSettingsT,
  LatestImportStateT,
  ConnectorsDataT,
  AccountT,
  ConnectorsDataDataTypeE,
  ResourceCategoryEnum,
  ProvisionOptions,
  getIntegrationService,
  AccessResponseT,
  AccessItemResponseT,
  IDPIntegration,
  UserHierarchy,
} from 'src/types';
import RootStore from '..';
import {PermissionResponseT} from '../PermissionStore';
import {ResourceResponseT} from '../resourceStore';
import {AccessRecord} from './AccessRecord';
import {Account} from './Account';
import {Permission} from './Permission';
import {PermissionSet} from './PermissionSet';
import {Recommendation, RECOMMENDATION_TYPE} from './Recommendation';
import {UserStatus} from '../usersStore';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';
import {Icon, SystemIcons} from '@trustle/component-library';
import {User} from './User';

export class Resource {
  id!: string;
  oid?: string;
  name!: string;
  description!: string;
  icon?: string | null;
  userHasAccess?: boolean;
  foreignId?: string;
  // owners is undefined for sub-resources TODO (jg): figure out a way to prevent accidentally using subResource.owners
  owners!: ResourceOwnerT[];
  provisioners!: ResourceProvisionerT[];
  deprovisionDefinition?: string;
  reapprovalDefinition?: string;
  durationValue?: number | '';
  durationUnit?: DurationUnitT;
  accessDurationValue?: number | null | '' = undefined;
  accessDurationUnit?: DurationUnitT | null | '' = undefined;
  accessInstructions?: string;
  provisionInstructions?: string;
  approvalDefinition?: string;
  provisionDefinition?: string;
  parentId?: string;
  rootSid?: string;
  autoProvision?: boolean;
  requests!: ResourceRequestT[];
  hidden?: boolean;
  archived?: boolean;
  archivedBy?: string;
  archivedOn?: string;
  deletedAt?: string;
  pendingSetup?: boolean;
  initiateExpiredDeprovision?: boolean;
  connectionId?: string | null;
  connector?: ResourceConnectorT | null = undefined;
  provisionMode?: ProvisionOptions | null = null;
  deprovisionMode?: ProvisionOptions | null = null;
  /**
   * @deprecated this property doesn't seem to be returned from any endpoints, code using it should probably be
   * using connector instead
   */
  connection?: Partial<ResourceConnectorT>;
  createdAt?: string;
  reviewedAt?: string;
  sensitivityId?: string | null;
  lastChange?: ResourceHistoryItemT = undefined;
  permissionSets: PermissionSet[] = [];
  setupSteps: SystemSetupStepT[] = [
    {
      id: SystemSetupStepId.ACCOUNTS,
      title: 'Link Accounts',
      subtitle: 'Link Accounts and Create Users',
      description:
        '1. Select a tab  -  2. Type the Name of Trustle User  -  3. Create New User, or Link User to the Account',
      number: 1,
      done: false,
      selected: false,
      enabled: false,
    },
    {
      id: SystemSetupStepId.RESOURCE,
      title: 'Resource',
      subtitle: 'Resource Setup',
      description: 'Set Sensitivity, Visbility and Provisioning for the Resources below',
      number: 2,
      done: false,
      selected: false,
      enabled: false,
    },
    {
      id: SystemSetupStepId.ACCESS,
      description:
        'Confirm the users below have the correct access, or ask if they still need it by setting up entitlement review',
      title: 'Access',
      subtitle: 'Review Access',
      number: 3,
      done: false,
      selected: false,
      enabled: false,
    },
  ];
  weekTasks: TaskT[] = [];
  loadingAccesses: boolean = false;
  loadingAccessesStatus: boolean = false;
  accessesLoadedAtLeastOnce: boolean = false;
  accessesStatusLoadedAtLeastOnce: boolean = false;

  loadingUserAccesses: boolean = false;
  userAccessesLoadedAtLeastOnce: boolean = false;

  private importingResourceUsageService: boolean = false;
  importing: boolean = false;
  nodeImportState?: NodeImportStateT;
  isLoading: boolean = false;
  detailsLoadedAtLeastOnce: boolean = false;
  riskLoadedAtLeastOnce: boolean = false;
  autoSave: boolean = true;
  connectedApps?: ConnectorsDataT[] = undefined;
  matches: {perfect: Record<string, string[]>; partial: Record<string, string[]>} = {perfect: {}, partial: {}};
  resourceIds?: string[] = undefined;
  accountIds?: string[] = [];
  accessIds?: string[] = [];
  currentUserAccessIds?: string[] = undefined;
  usersAcccesses: Record<string, string[]> = {};
  permissionIds?: string[] = undefined;
  permissionUsages?: Record<string, {usage: number; lastEventDate: string}> = undefined;
  accountUsages?: Record<string, {usage: number; lastEventDate: string}> = undefined;
  accountLastUsageEvents?: Record<
    string,
    {lastActivity?: string; loginSuccess?: string; lastProgrammaticAccess?: string}
  > = undefined;
  permissionRisks?: Record<string, {foundRisks: string[]; riskScore: number}> = undefined;
  accountNodeImportStates?: Record<string, NodeImportStateT> = undefined;
  category?: ResourceCategoryEnum;

  // assume true if not initialized with a hasPermissions value and details haven't been loaded yet
  hasPermissions: boolean = true;
  connectedUaid?: string = undefined;
  numResources?: number = undefined;
  riskReport: any = undefined;
  accessAggregates: any = undefined;

  accountsConnectorData?: Record<string, ConnectorsDataT[]> = undefined;
  resourcesConnectorData?: Record<string, ConnectorsDataT[]> = undefined;
  errorLoadingConnectorData?: any;
  errorLoadingResourcesConnectorData?: any;
  loadingAccounts: boolean = false;
  accountsLoadedAtLeastOnce: boolean = false;
  loadingHistory: boolean = false;

  loadingAccountRiskScores: boolean = false;
  errorLoadingAccountRiskScores?: any;

  errorLoadingRecommendations?: any;

  private locked?: boolean = false;
  private rootStore: RootStore;

  private _numAccounts: number = 0;
  private _numUnusedAccounts: number = 0;
  private _numUnusedAccountsFlagged: number = 0;
  private _numAccountsCertified: number = 0;
  private _numAccountsLinked: number = 0;

  constructor(rootStore: RootStore, resource: Partial<Resource>) {
    makeAutoObservable(this, {id: false, autoSave: false});
    Object.assign(
      this,
      _.omit(resource, [
        'permissions',
        'compliance',
        'numAccounts',
        'numUnusedAccounts',
        'numUnusedAccountsFlagged',
        'numAccountsCertified',
        'numAccountsLinked',
      ])
    );
    this.rootStore = rootStore;
    this._numAccounts = resource.numAccounts ?? 0;
    this._numUnusedAccounts = resource.numUnusedAccounts ?? 0;
    this._numUnusedAccountsFlagged = resource.numUnusedAccountsFlagged ?? 0;
    this._numAccountsCertified = resource.numAccountsCertified ?? 0;
    this._numAccountsLinked = resource.numAccountsLinked ?? 0;

    onBecomeObserved(this, 'recommendations', async () => this.loadRecommendations());

    onBecomeObserved(this, 'connectedApps', async () => {
      const res = await axios.get(`/api/resources/${this.rootSid ?? this.id}/accounts/connected_apps`);
      this.rootResource.connectedApps = res.data ?? [];
    });

    onBecomeObserved(this, 'accountsConnectorData', async () => {
      try {
        const res = await axios.get(`/api/resources/${resource.id}/accounts/connectors_data`);
        runInAction(() => {
          this.accountsConnectorData = _(res.data)
            .filter({refType: ReferenceType.UserAccount})
            .groupBy('refId')
            .value();
        });
      } catch (err) {
        logger.error(err);
        runInAction(() => {
          this.errorLoadingConnectorData = err;
        });
      }
    });

    onBecomeObserved(this, 'riskReport', async () => {
      const res = await axios.get<ConnectorsDataT[]>(`/api/resources/${this.id}/risk_analysis`);
      runInAction(() => (this.riskReport = res.data));
    });

    onBecomeObserved(this, 'resourcesConnectorData', async () => {
      try {
        const res = await axios.get(
          `/api/resources/${resource.id}/connectors_data?dataType=${ConnectorsDataDataTypeE.ENTITY_DATA}`
        );

        runInAction(() => {
          if (_.isArray(res.data)) {
            this.resourcesConnectorData = _.get(_.head(res.data), 'data', null);
          }
        });
      } catch (err) {
        logger.error(err);
        runInAction(() => {
          this.errorLoadingResourcesConnectorData = err;
        });
      }
    });

    onBecomeObserved(this, 'resourceIds', async () => {
      const res = await axios.get<ResourceResponseT[]>(`/api/resources/${this.rootResource.id}/resources`);
      runInAction(() => {
        res.data.forEach((resource) =>
          this.rootStore.newResourceStore.updateResourceFromServer({...resource, rootSid: this.rootSid ?? this.id})
        );
        this.rootResource.resourceIds = res.data.map((r) => r.id);
      });
    });

    onBecomeObserved(this, 'currentUserAccessIds', async () => {
      if (this.userAccessesLoadedAtLeastOnce) {
        return;
      }

      runInAction(() => {
        this.loadingUserAccesses = true;
      });
      const currentUserAccessId = this.rootStore.currentUser.id;
      void this.loadUserAccesses(currentUserAccessId);
    });

    onBecomeObserved(this, 'accountIds', async () => {
      if (this.accountsLoadedAtLeastOnce) {
        return;
      }
      runInAction(() => {
        this.loadingAccounts = true;
      });
      // TODO (jg): improve account response type accuracy
      const res = await axios.get<AccountT[]>(`/api/resources/${this.rootResource.id}/accounts`);
      runInAction(() => {
        res.data.forEach((account) => this.rootStore.accountsStore.updateAccountFromServer(account));
        (this.allNestedAccessRecords ?? []).forEach((access) => {
          if (access.forAccount?.id) {
            const account = this.rootStore.accountsStore.accountsMap[access.forAccount?.id];
            account.accessIds = _.union(account.accessIds, [access.id]);
          }
        });
        this.rootResource.accountIds = res.data.map((a) => a.id);
        this.accountsLoadedAtLeastOnce = true;
        this.loadingAccounts = false;
      });
    });

    onBecomeObserved(this, 'matches', async () => {
      const res = await axios.get<{
        unassigned: {partialMatch: Record<string, string[]>; perfectMatch: Record<string, string[]>};
      }>(`/api/resources/${this.rootResource.id}/accounts/mapping`);
      const {partialMatch, perfectMatch} = res.data.unassigned;
      runInAction(() => {
        this.rootResource.matches.partial = partialMatch;
        this.rootResource.matches.perfect = perfectMatch;
      });
    });

    onBecomeObserved(this, 'accessAggregates', async () => {
      if (this.accessesStatusLoadedAtLeastOnce) {
        return;
      }

      runInAction(() => {
        this.loadingAccessesStatus = true;
      });

      const res = await axios.get<AccessResponseT>(`/api/resources/${this.rootResource.id}/accesses_sum_up`);

      runInAction(() => {
        this.rootResource.accessAggregates = res.data;
        this.loadingAccessesStatus = false;
        this.accessesStatusLoadedAtLeastOnce = true;
      });
    });

    onBecomeObserved(this, 'permissionIds', async () => {
      const res = await axios.get<PermissionResponseT[]>(`/api/resources/${this.rootResource.id}/permissions`);
      runInAction(() => {
        res.data.forEach((attributes) => {
          this.rootStore.permissionStore.updatePermissionFromServer({...attributes, sid: this.rootSid ?? this.id});
        });
        this.rootResource.permissionIds = _.compact(res.data.map((p) => p?.id));
      });
    });

    onBecomeObserved(this, 'permissionUsages', async () => {
      const res = await axios.get<Record<string, {id: string; usage: number; lastEventDate: string}>>(
        `/api/resources/${this.rootResource.id}/permissions/connector_service_usages`
      );
      runInAction(() => (this.rootResource.permissionUsages = res.data));
    });

    onBecomeObserved(this, 'accountUsages', async () => {
      const res = await axios.get<Record<string, {id: string; usage: number; lastEventDate: string}>>(
        `/api/resources/${this.rootResource.id}/accounts/connector_service_usages`
      );
      runInAction(() => (this.rootResource.accountUsages = res.data));
    });

    onBecomeObserved(this, 'permissionRisks', async () => {
      if (this.connector?.service !== ConnectionServiceE.AWS) {
        return;
      }
      const res = await axios.get<{
        permissions: {pid: string; name: string; riskScore: number; foundRisks: string[]}[];
      }>(`/api/resources/systems/${this.rootResource.id}/risk`);
      runInAction(() => (this.rootResource.permissionRisks = _.keyBy(res.data.permissions, 'pid')));
    });

    onBecomeObserved(this, 'accountNodeImportStates', async () => {
      // ignore requests from non-owners
      if (!this.userIsOwner) {
        return;
      }

      const res = await axios.get<NodeImportStateT[]>(
        `/api/resources/${this.rootResource.id}/nodes_import_state/actual_state`
      );
      runInAction(() => {
        this.accountNodeImportStates = _(res.data).filter({refType: ReferenceType.UserAccount}).keyBy('refId').value();
      });
    });

    onBecomeObserved(this, 'accountLastUsageEvents', async () => {
      const res = await axios.get<Record<string, Record<'lastActivity' | 'loginSuccess', string>>>(
        `/api/resources/${this.rootResource.id}/accounts/last-usage-events`
      );
      runInAction(() => (this.rootResource.accountLastUsageEvents = res.data));
    });

    onBecomeObserved(this, 'lastChange', async () => {
      const res = await axios.get<ResourceHistoryItemT>(`/api/resources/${this.id}/last-change`);
      runInAction(() => (this.lastChange = res.data));
    });
  }

  upsertAccess(attributes: AccessItemResponseT) {
    this.rootStore.accessStore.updateAccessFromServer(attributes);

    if (this.accessIds && !this.accessIds.includes(attributes.id)) {
      this.accessIds.push(attributes.id);
    }
  }

  async fetchMatchingAccesses(query: string) {
    runInAction(() => {
      this.loadingAccesses = true;
    });

    const res = await axios.get<AccessResponseT>(`/api/resources/${this.rootResource.id}/accesses?query=${query}`);

    runInAction(() => {
      res.data.forEach((attributes) => {
        this.upsertAccess(attributes);
      });

      this.loadingAccesses = false;
    });
  }

  /**
   * Asynchronously loads user accesses based on the UID.
   *
   * @param {string} uid - The user ID for which accesses are to be loaded
   * @param {boolean} [includeDisabled=false] includeDisabled - Whether to load accesses for disabled systems
   * @return {Promise<void>}
   */
  async loadUserAccesses(uid: string, includeDisabled = true) {
    if (!_.isEmpty(this.rootResource.usersAcccesses[uid])) {
      return;
    }
    runInAction(() => {
      this.loadingUserAccesses = true;
    });

    const res = await axios.get<AccessResponseT>(
      `/api/resources/${this.rootResource.id}/accesses?uid=${uid}&includeDisabled=${includeDisabled}`
    );

    const loadedAccesses: AccessResponseT = res.data;

    runInAction(() => {
      loadedAccesses.forEach((attributes) => {
        this.upsertAccess(attributes);
      });

      this.rootResource.usersAcccesses[uid] = loadedAccesses.map((a) => a.id);
      if (uid === this.rootStore.currentUser.id) {
        this.rootResource.currentUserAccessIds = loadedAccesses.map((a) => a.id);
      }

      this.loadingUserAccesses = false;
    });
  }

  getUserAccesses(uid: string): AccessRecord[] {
    return this.usersAcccesses[uid]?.map((id) => this.rootStore.accessStore.accessMap[id]) ?? [];
  }
  private async loadRecommendations() {
    if (this.rootStore.newResourceStore.loadingRecommendations || !this.isSystem) {
      return;
    }

    runInAction(() => (this.rootStore.newResourceStore.loadingRecommendations = true));

    try {
      const res = await axios.get(`/api/recommendations/${this.rootResource.id}`);
      runInAction(() => {
        this.rootStore.newResourceStore.recommendationsMap[this.rootResource.id] = res.data.map(
          (data: Partial<Recommendation>) => new Recommendation(this.rootStore, data)
        );
        this.rootStore.newResourceStore.loadingRecommendations = false;
      });
    } catch (err) {
      logger.error(err);
      runInAction(() => {
        this.errorLoadingRecommendations = err;
        this.rootStore.newResourceStore.loadingRecommendations = false;
      });
    }
  }

  async updateConnectionStatus() {
    if (_.isNil(this.connectionId)) {
      return;
    }

    // refresh every 6 seconds up to 10 times until status is not pending
    const checkConnectorStatus = async (attempts: number) => {
      const res = await axios.get(`/api/connect/${this.connectionId}/status`);

      if (res.data.lastImport?.status === ImportStatus.PENDING && attempts < 30) {
        setTimeout(() => checkConnectorStatus(attempts + 1), 6000);
        return;
      }
      if (this.userIsOwner && res.data.lastImport?.status === ImportStatus.FAILED && attempts >= 10) {
        const toastContent = (
          <div>
            Failed doing import for {this.name}.{' '}
            <a
              href="#"
              onClick={() =>
                (window.location.href = `/resource/manage/${this.rootResource.id}/settings/edit_connection`)
              }
            >
              Check settings.
            </a>
          </div>
        );

        this.rootStore.toast.add(toastContent, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      runInAction(() => (this.connector = res.data));
    };

    if (this.importStatus === ImportStatus.PENDING) {
      void checkConnectorStatus(0);
    }
  }
  async update(updates: Partial<Resource>): Promise<{ok: boolean; error?: any}> {
    // TODO (jg): combine endpoints, use response in resource update
    try {
      if (!_.isNil(updates.archived)) {
        void axios.post(`/api/resources/${this.id}/${updates.archived ? 'archive' : 'unarchive'}`);
      }
      const updatesWithoutArchived = _.omit(updates, 'archived');
      if (!_.isEmpty(updatesWithoutArchived)) {
        void axios.post(`/api/resources/${this.id}`, {resource: updates});
      }
      runInAction(() => Object.assign(this, updates));

      this.rootStore.toast.add(`Successfully updated resource`, {
        appearance: 'success',
        autoDismiss: true,
      });

      return {ok: true};
    } catch (error: any) {
      this.rootStore.toast.add(`There was an error updating the resource`, {
        appearance: 'error',
        autoDismiss: true,
      });
      return {ok: false, error};
    }
  }

  async getPermissionSets() {
    try {
      const result = await axios.get(`/api/resources/${this.id}/oncall_sets?permissions`);
      runInAction(() => {
        this.permissionSets = _.map(result.data, (ps) => {
          return new PermissionSet(this.rootStore, ps);
        });
      });
    } catch (err) {
      logger.error(err);

      this.rootStore.toast.add(`Failed to load the permission sets.`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async deletePermissionSet(id: string) {
    try {
      await axios.delete(`/api/oncall_sets/${id}`);
      runInAction(() => {
        _.remove(this.permissionSets, {id: id});
      });
    } catch (err) {
      logger.error(err);

      this.rootStore.toast.add(`Failed to load the permission sets.`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async createPermissionSet(values: Partial<PermissionSet>, selectedPermissions: string[]) {
    try {
      const res = await axios.post(`/api/oncall_sets/`, {..._.omit(values, 'permissions'), resourceId: this.id});
      await axios.put(`/api/oncall_sets/${res.data.oncallSet.id}/oncall_permissions`, selectedPermissions);
      runInAction(() => {
        this.permissionSets.push(new PermissionSet(this.rootStore, res.data.oncallSet));
      });

      this.rootStore.toast.add(`Added permission set.`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      logger.error(err);

      this.rootStore.toast.add(`Failed to load the permission sets.`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async resetChildsToDefault(id: string) {
    try {
      const res: any = await axios.post(`/api/resources/${id}/reset_childs_default`);
      runInAction(() => {
        if (_.size(res.data?.updatedResources) > 0) {
          res.data.updatedResources.forEach((resource: Resource) =>
            this.rootStore.newResourceStore.updateResourceFromServer({...resource, rootSid: this.rootSid ?? this.id})
          );
        }
        if (_.size(res.data?.updatedPermissions) > 0) {
          res.data.updatedPermissions.forEach((permission: PermissionResponseT) =>
            this.rootStore.permissionStore.updatePermissionFromServer({...permission, sid: this.rootSid ?? this.id})
          );
        }
      });
      this.rootStore.toast.add(`Childs of resource were reset to default values`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      logger.error(err);

      this.rootStore.toast.add(`Failed to reset childs to default`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async getLatestImportStates(
    services: (ConnectionServiceE | ImportStateServiceE)[],
    limit?: number
  ): Promise<LatestImportStateT> {
    try {
      const {data} = await axios.post(`/api/resources/${this.id}/import_states`, {services, limit});
      return data;
    } catch (err: any) {
      logger.error('Error retrieving import states', err.response?.data?.error);

      runInAction(() => {
        this.rootStore.toast.add('Error retrieving import states', {appearance: 'error', autoDismiss: true});
      });
    }
    return {importStates: [], jobs: {}};
  }

  async updateConnectionSettings(values: UpdateConnectorSettingsT) {
    const wasIdpUpdated = !_.isNil(values.idpService);

    const {data: updatedConnectionSettings} = await axios.post(`/api/connect/${this.connectionId}/settings`, {
      pollDurationUnit: values.pollFrequency?.durationUnit ?? this.connector?.pollDurationUnit,
      pollDurationValue: values.pollFrequency?.durationValue ?? this.connector?.pollDurationValue,
      provisionMethod: values.provisionMethod ?? this.connector?.provisionMethod,
      autoProvisionEnabled: values.autoProvisionEnabled ?? this.connector?.autoProvisionEnabled,
      autoDeprovisionEnabled: values.autoDeprovisionEnabled ?? this.connector?.autoDeprovisionEnabled,
      retrieveUsageData: values.retrieveUsageData ?? this.connector?.retrieveUsageData,
      accessKeyRotationUnit: values.accessKeyRotation?.durationUnit ?? this.connector?.accessKeyRotationUnit,
      accessKeyRotationValue: values.accessKeyRotation?.durationValue ?? this.connector?.accessKeyRotationValue,
      disabled: values.disabled ?? this.connector?.disabled,
      idpParams: wasIdpUpdated
        ? {
            associated: values.idpAssociated,
            enable: values.idpEnabled,
            service: values.idpService,
            fieldMapping: values.idpFieldMapping,
            otherSettings: values.idpOtherSettings,
          }
        : undefined,
      provisionMode: values.provisionMode ?? this.connector?.provisionMode,
      deprovisionMode: values.deprovisionMode ?? this.connector?.deprovisionMode,
    });

    runInAction(() => {
      if (wasIdpUpdated) {
        const idpSettings: IDPIntegration | null =
          !_.isNil(values.idpService) && values.idpAssociated
            ? {
                service: values.idpService,
                fieldMapping: values.idpFieldMapping,
                connectionId: this.connectionId ?? undefined,
              }
            : null;

        this.rootStore.org.idpSettings = idpSettings;
      }
    });

    //update resource setting:
    await this.update({
      hidden: values.visibility === 'hidden' ? true : values.visibility === 'visible' ? false : this.hidden,
      sensitivityId: values.sensitivityId ?? this.sensitivityId,
      durationUnit: values.approvalDuration?.durationUnit ?? this.durationUnit,
      durationValue: values.approvalDuration?.durationValue ?? this.durationValue,
      accessDurationUnit: values.accessDuration?.durationUnit ?? this.accessDurationUnit,
      accessDurationValue: values.accessDuration?.durationValue ?? this.accessDurationValue,
      initiateExpiredDeprovision: values.initiateExpiredDeprovision ?? this.initiateExpiredDeprovision,
      provisionMode: (values.provisionMode as ProvisionOptions) ?? this.provisionMode,
      deprovisionMode: (values.deprovisionMode as ProvisionOptions) ?? this.deprovisionMode,
    });

    runInAction(
      () =>
        (this.connector = Object.assign({}, this.connector, {
          ...updatedConnectionSettings,
          initiateExpiredDeprovision: this.initiateExpiredDeprovision,
        }))
    );

    // TODO (jg): move this logic to backend
    const shouldReloadRecommendations =
      values.accessKeyRotation &&
      (values.accessKeyRotation?.durationValue !== this.connector?.accessKeyRotationValue ||
        values.accessKeyRotation?.durationUnit !== this.connector?.accessKeyRotationUnit);

    if (
      values.retrieveUsageData &&
      this.recommendations.find(
        (rec) => rec.type === RECOMMENDATION_TYPE.ENABLE_SERVICE_USAGE && rec.completedOn === null
      )
    ) {
      void this.completeRecommendation(RECOMMENDATION_TYPE.ENABLE_SERVICE_USAGE);
    }

    if (shouldReloadRecommendations) {
      await this.loadRecommendations();
    }

    await this.rootStore.usersStore.refresh();
  }

  loadDetailed() {
    void this.rootStore.newResourceStore.loadDetailed(this.rootResource.id);
  }

  get shouldShowRisk() {
    return this.type === ConnectionServiceE.AWS;
  }

  /** @returns all of this resource's permissions sorted by label and then status */
  get permissions(): Permission[] {
    return (this.rootResource.permissionIds ?? [])
      .map((id) => this.rootStore.permissionStore.permissionMap[id])
      .filter((p) => p.refId === this.id && !p.tombstone)
      .sort((p1, p2) => {
        return p1.label.localeCompare(p2.label);
      });
  }

  get importStatus() {
    return this.connector?.lastImport?.status;
  }

  /** @returns Resource return resources that are a descendant of the current object sorted by name  */
  get childResources(): Resource[] {
    return (this.rootResource.resourceIds ?? [])
      .map((id) => this.rootStore.newResourceStore.resourceMap[id])
      .filter((r) => r.parentId === this.id)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  /**
   * @returns all resources that belong to this resource, or one of it's resources, or one of it's resource's resources, etc
   */
  get allNestedResources(): Resource[] {
    return this.childResources
      .flatMap((resource) => [resource, ...resource.allNestedResources])
      .filter((r) => {
        return !r.deletedAt;
      });
  }

  get allManagedResources(): Resource[] {
    return this.allNestedResources.filter((resource) => !!resource.autoProvisionValue);
  }

  get allUnmanagedResources(): Resource[] {
    return this.allNestedResources.filter((resource) => !resource.autoProvisionValue);
  }

  /**
   * @returns all ancestors of this resource
   */
  get allAncestors(): Resource[] {
    const ancestors: Resource[] = [];
    let parent = this.parentResource;

    while (parent) {
      ancestors.push(parent);
      parent = parent.parentResource;
    }
    return ancestors;
  }

  /** @returns all permissions that belong to this resource or any of it's decendant resources */
  get allNestedPermissions(): Permission[] {
    return [...this.permissions, ..._.flatMap(this.allNestedResources, 'permissions')];
  }

  /**
   * @returns this resource's parent resource, or undefined if it has no parent
   */
  get parentResource(): Resource | undefined {
    return this.parentId ? this.rootStore.newResourceStore.resourceMap[this.parentId] : undefined;
  }

  /** @return this resource's root system or this resource if it is a system */
  get rootResource(): Resource {
    return this.rootSid ? this.rootStore.newResourceStore.resourceMap[this.rootSid] : this;
  }

  async createResource(resource: Partial<Resource>, onError?: (err: any) => void) {
    // TODO: try to re-use resourceStore.createResource and the make_resource endpoint for subresources
    try {
      const newResource = (await axios.post(`/api/resources/${this.id}/children`, resource)).data.resource;
      this.rootStore.newResourceStore.resources[newResource.id] = newResource;
      return newResource;
    } catch (err) {
      logger.error(err);
      this.rootStore.toast.add(`Failed to create resource.`, {appearance: 'error', autoDismiss: true});
      onError?.(err);
    }
  }

  markSetupStepSelected(id: SystemSetupStepId) {
    const selectedStepIndex = _.findIndex(this.setupSteps, (step) => {
      return step.id === id;
    });
    this.setupSteps.map((step, index) => ({
      ...step,
      selected: index === selectedStepIndex,
      enabled: index <= selectedStepIndex ? true : step.enabled,
    }));
  }

  markSetupStepDone(id: SystemSetupStepId) {
    this.setupSteps = this.setupSteps.map((step) => ({...step, done: id === step.id ? true : step.done}));
  }

  // TODO: replace this with manual updates to the params and a reaction for calling the api
  async markAsReviewed(params: {pids?: string[]; rids?: string[]; aids?: string[]; uaids?: string[]}) {
    try {
      await axios.post(`/api/resources/${this.id}/set_as_reviewed`, params);
      await this.loadDetailed();
    } catch (err) {
      this.rootStore.toast.add(`Could not review the items`, {appearance: 'error', autoDismiss: true});
    }
  }

  get hasResourceOrPermissionWarning(): boolean {
    const sysPermWarning = _.some(this.permissions, (p) => {
      return p.nodeImportState?.warning;
    });

    if (sysPermWarning) {
      return true;
    }

    for (const i in this.childResources) {
      const resource = _.get(this.childResources, i);

      if (resource.nodeImportState?.warning) {
        return true;
      }
    }

    return false;
  }

  get accessRecords(): AccessRecord[] | undefined {
    return _.filter(
      this.rootResource.accessIds?.map((id) => this.rootStore.accessStore.accessMap[id]),
      (e) => e.forResource?.id === this.id
    );
  }

  get allNestedAccessRecords(): AccessRecord[] {
    return this.rootResource.accessIds?.map((id) => this.rootStore.accessStore.accessMap[id]) ?? [];
  }

  get allActiveAccessRecords(): AccessRecord[] {
    return _.flatMap(this.permissions, (permission) => {
      return _.filter(permission.accesses, (access) => {
        return access.statusValue === 5;
      });
    });
  }

  get currentUserAccessRecords(): AccessRecord[] | undefined {
    return this.rootResource.currentUserAccessIds?.map((id) => this.rootStore.accessStore.accessMap[id]);
  }

  get allCurrentUserActiveAccessRecords(): AccessRecord[] {
    return _.filter(this.currentUserAccessRecords, (access) => {
      return access.statusValue === 5;
    });
  }

  get allUnmappedAccessRecords(): AccessRecord[] | undefined {
    return _.filter(this.allNestedAccessRecords, (access) => {
      return access.statusValue === 0;
    });
  }

  get sensitivity(): MinSensitivitySettingT | undefined {
    if (this.sensitivityId) {
      return _.find(this.rootStore.org?.sensitivitySettings, {id: this.sensitivityId});
    } else if (this.parentResource) {
      return this.parentResource.sensitivity;
    }

    return this.rootStore.org?.defaultSensitivitySetting;
  }

  get sensitivityIcon() {
    return (
      <>
        <Icon type={_.find(SensitivityLevelDefinitions, {level: this.sensitivity?.level})?.icon ?? 'error'} />
      </>
    );
  }

  get accessAggregatesByPid() {
    return _.groupBy(this.accessAggregates, 'pid');
  }

  get accessAggregatesByUaid() {
    return _.groupBy(this.accessAggregates, 'uaid');
  }

  get calcApprovalDuration(): {durationUnit: DurationUnitT; durationValue: number} {
    // overriden duration
    if (this.durationValue && this.durationUnit) {
      return {durationUnit: this.durationUnit, durationValue: this.durationValue};
    }

    // overriden sensitivity
    if (this.sensitivityId) {
      const {maxApprovalDurationUnit, maxApprovalDurationValue} = this.sensitivity!;
      return {durationValue: maxApprovalDurationValue, durationUnit: maxApprovalDurationUnit};
    }

    // inherited from parent
    return this.inheritedApprovalDuration;
  }

  get calcAccessDuration(): {durationUnit: DurationUnitT; durationValue: number} {
    // overriden duration
    if (this.accessDurationValue && this.accessDurationUnit) {
      return {durationUnit: this.accessDurationUnit, durationValue: this.accessDurationValue};
    }

    // overriden sensitivity
    if (this.sensitivityId) {
      const {maxAccessDurationUnit, maxAccessDurationValue} = this.sensitivity!;
      return {durationValue: maxAccessDurationValue, durationUnit: maxAccessDurationUnit};
    }

    // inherited from parent
    return this.inheritedAccessDuration;
  }

  get inheritedApprovalDuration(): {
    durationUnit: DurationUnitT;
    durationValue: number;
  } {
    if (this.parentResource) {
      return this.parentResource.calcApprovalDuration;
    }

    const {defaultSensitivitySetting} = this.rootStore.org;

    return {
      durationUnit: defaultSensitivitySetting?.maxApprovalDurationUnit ?? 'hour',
      durationValue: defaultSensitivitySetting?.maxApprovalDurationValue ?? 1,
    };
  }

  get inheritedAccessDuration(): {durationUnit: DurationUnitT; durationValue: number} {
    if (this.parentResource) {
      return this.parentResource.calcAccessDuration;
    }

    const {defaultSensitivitySetting} = this.rootStore.org!;
    return {
      durationUnit: defaultSensitivitySetting?.maxAccessDurationUnit ?? 'hour',
      durationValue: defaultSensitivitySetting?.maxAccessDurationValue ?? 1,
    };
  }

  get calculatedHidden(): {obj: any; value: boolean} {
    return !_.isNil(this.hidden)
      ? {obj: this, value: this.hidden}
      : this.parentResource?.calculatedHidden
      ? {obj: this.parentResource, value: this.parentResource?.calculatedHidden.value}
      : {obj: this, value: false};
  }

  get autoProvisionValue(): {obj: any; value: boolean | undefined} {
    return !_.isNil(this.autoProvision)
      ? {obj: this, value: this.autoProvision}
      : this.parentResource?.autoProvisionValue
      ? {obj: this.parentResource, value: this.parentResource?.autoProvisionValue.value}
      : {obj: this, value: false};
  }

  get calculatedProvisionMode(): {obj: any; value: ProvisionOptions | undefined} {
    return !_.isNil(this.provisionMode)
      ? {obj: this, value: this.provisionMode}
      : this.parentResource?.calculatedProvisionMode
      ? {obj: this.parentResource, value: this.parentResource?.calculatedProvisionMode.value}
      : {obj: this, value: ProvisionOptions.off};
  }

  get parentSensitivityId(): string | undefined {
    if (this.parentResource) {
      return this.parentResource?.calculatedSensitivity.value;
    }

    return _.find(this.rootStore.org?.sensitivitySettings, {id: this.rootStore.org?.defaultSensitivityId})?.id;
  }

  /** this method should return the same value as this.sensitivity, but it also returns the obj from which it is inherited */
  get calculatedSensitivity(): {obj: any; value: string | undefined} {
    return !_.isNil(this.sensitivityId)
      ? {obj: this, value: this.sensitivityId}
      : this.parentResource?.calculatedSensitivity
      ? {obj: this.parentResource, value: this.parentResource?.calculatedSensitivity.value}
      : {obj: this, value: this.sensitivity?.id};
  }

  get calculatedDeprovisionMode(): {obj: any; value: ProvisionOptions | undefined} {
    return !_.isNil(this.deprovisionMode)
      ? {obj: this, value: this.deprovisionMode}
      : this.parentResource?.calculatedDeprovisionMode
      ? {obj: this.parentResource, value: this.parentResource?.calculatedDeprovisionMode.value}
      : {obj: this, value: ProvisionOptions.off};
  }

  get calculatedInitiateExpiredDeprovision(): {obj: any; value: boolean | undefined} {
    return !_.isNil(this.initiateExpiredDeprovision)
      ? {obj: this, value: this.initiateExpiredDeprovision}
      : this.parentResource?.calculatedInitiateExpiredDeprovision
      ? {obj: this.parentResource, value: this.parentResource?.calculatedInitiateExpiredDeprovision.value}
      : {obj: this, value: undefined};
  }

  deletePermission(permission: Permission) {
    void axios.post(`/api/permissions/${permission.id}/delete`);
    this.permissions.splice(this.permissions.indexOf(permission), 1);
  }

  get shouldDisableSystemPermissions() {
    // list of connectors for which system permissions are disabled
    const SYSTEM_PERMISSIONS_DISSABLE = [ConnectionServiceE.AWS, ConnectionServiceE.AZURE_AD, ConnectionServiceE.SLACK];
    return false || _.includes(SYSTEM_PERMISSIONS_DISSABLE, this.connector?.service);
  }

  /*Deprecated ise SystemIcons */
  get iconImage() {
    return <SystemIcons name={this.type as SystemIconsTypesEnum} size={'xs'} />;
  }

  get type(): ConnectionServiceE | undefined | 'custom-system' {
    return this.rootResource.connectionId ? this.rootResource.connector?.service : 'custom-system';
  }

  /**
   * return true only for connected to 3rd party remote systems
   */
  get isConnectedSystem(): boolean {
    return (
      this.isSystem &&
      !!this.rootResource.connector?.service &&
      !_.includes(['custom system', 'slack'], _.lowerCase(this.rootResource.connector?.service))
    );
  }

  get typeDisplayName():
    | 'Custom System'
    | 'Azure AD'
    | 'GitHub'
    | 'Google Workspace'
    | 'AWS'
    | 'Slack'
    | 'Okta'
    | 'Generic'
    | 'PagerDuty'
    | 'Tableau'
    | 'M365'
    | 'AWS Identity Center'
    | undefined {
    switch (this.type) {
      case 'custom-system':
        return 'Custom System';
      case ConnectionServiceE.AZURE_AD:
        return 'Azure AD';
      case ConnectionServiceE.GITHUB:
        return 'GitHub';
      case ConnectionServiceE.GAPPS:
        return 'Google Workspace';
      case ConnectionServiceE.AWS:
        return 'AWS';
      case ConnectionServiceE.SLACK:
        return 'Slack';
      case ConnectionServiceE.OKTA:
        return 'Okta';
      case ConnectionServiceE.GENERIC:
        return 'Generic';
      case ConnectionServiceE.PAGERDUTY:
        return 'PagerDuty';
      case ConnectionServiceE.TABLEAU:
        return 'Tableau';
      case ConnectionServiceE.M365:
        return 'M365';
      case ConnectionServiceE.AWS_IDENTITY_CENTER:
        return 'AWS Identity Center';
      default:
        return undefined;
    }
  }

  /** return true if current user is an owner of this resource */
  get userIsOwner(): boolean {
    return (
      !_.isNil(this.rootStore.currentUser) &&
      (this.rootResource.owners ?? []).includes(this.rootStore.currentUser.email)
    );
  }

  isResourceOwner(user?: User): boolean {
    return !_.isNil(user) && (this.rootResource.owners ?? []).includes(user.email);
  }

  isResourceProvisioner(user?: User): boolean {
    return !_.isNil(user) && (this.rootResource.provisioners ?? []).includes(user.email);
  }

  getResourceRole(user?: User): UserHierarchy | undefined {
    return this.rootSid
      ? undefined
      : this.isResourceOwner(user)
      ? UserHierarchy.OWNER
      : this.isResourceProvisioner(user)
      ? UserHierarchy.PROVISIONER
      : UserHierarchy.REGULAR_USER;
  }

  get isDisabled(): boolean {
    return Boolean(this.connector?.disabled);
  }

  get isConnectionBroken(): boolean {
    return Boolean(this.connector?.lastImport?.status === ImportStatus.FAILED);
  }

  isProvisionStatus(provisionMode: ProvisionOptions): boolean {
    return this.calculatedProvisionMode.value === provisionMode;
  }

  /** @returns true if the current user is one of the owners of this resource */
  get canEdit(): boolean {
    return (this.owners ?? []).some((o) => o.toLowerCase() === this.rootStore.currentUser!.email.toLowerCase());
  }

  async startImport() {
    let finalStatus = '';
    await axios.post(`/api/connect/${this.connectionId}/start_import`);
    runInAction(() => {
      this.importing = true;
    });

    await retryUntilConditionMet(
      `/api/connect/${this.connectionId}/status`,
      (res) => {
        const {lastImport} = res;
        finalStatus = lastImport?.status;

        runInAction(() => {
          this.connector = res;
        });
        return lastImport?.status !== ImportStatus.PENDING;
      },
      15000,
      20
    );
    this.importing = false;
    if (this.userIsOwner) {
      if (finalStatus === ImportStatus.FAILED) {
        this.rootStore.toast.add(`Import failed`, {appearance: 'error', autoDismiss: true});
        this.loadDetailed();
      } else if (finalStatus === ImportStatus.FINISHED) {
        this.rootStore.toast.add(`Import finished succesfully`, {appearance: 'success', autoDismiss: true});
        this.loadDetailed();
      }
    }
  }

  async getConnectorStatus(id: string) {
    this.importingResourceUsageService = true;
    await retryUntilConditionMet(
      `/api/connect/${id}/status`,
      (res) => {
        runInAction(() => {
          this.importingResourceUsageService = false;
          this.connector = res;
        });
        return false;
      },
      5000,
      2
    );
  }

  async refreshResourceAccounts() {
    runInAction(() => {
      this.loadingAccounts = true;
    });
    const res = await axios.get<AccountT[]>(`/api/resources/${this.rootResource.id}/accounts`);
    runInAction(() => {
      res.data.forEach((account) => this.rootStore.accountsStore.updateAccountFromServer(account));
      (this.allNestedAccessRecords ?? []).forEach((access) => {
        if (access.forAccount?.id) {
          const account = this.rootStore.accountsStore.accountsMap[access.forAccount?.id];
          account.accessIds = _.union(account.accessIds, [access.id]);
        }
      });
      this.rootResource.accountIds = res.data.map((a) => a.id);
      this.accountsLoadedAtLeastOnce = true;
      this.loadingAccounts = false;
    });
  }

  get isSystem(): boolean {
    return _.isNil(this.rootSid);
  }

  /** @returns all of this resources permissions and if it's not a system, it's sub-resources their permissions. sorted. */
  get subResourcesAndPermissions(): (Resource | Permission)[] {
    const perms = _.filter(this.permissions, (p) => !p.tombstone);
    if (this.isSystem) {
      return perms;
    }

    return [
      ...perms,
      ...this.allNestedResources
        .sort((r1, r2) => r1.name.localeCompare(r2.name))
        .flatMap((r) => (_.isEmpty(r.permissions) ? [] : [r, ...r.permissions])),
    ];
  }

  get childResourcesAndPerms(): (Resource | Permission)[] {
    const perms = _.filter(this.permissions, (p) => !p.tombstone);

    return [...perms, ...this.childResources];
  }

  /**
   * @return number of subentities for a resource (resource or permissions)
   *
   */
  get numSubEntities(): number {
    if (_.isEmpty(this.parentId)) {
      // system permissions
      return _.size(this.permissions);
    }

    // if it has at least one resource as child return the num of resources
    if (!_.isEmpty(this.allNestedResources)) {
      return _.size(this.allNestedResources);
    }

    return (
      this.permissions.length +
      (this.rootSid ? this.allNestedResources.reduce((sum, r) => sum + r.permissions.length, 0) : 0)
    );
  }

  /** @returns all accounts associated with this resource */
  get accounts(): Account[] {
    return this.rootResource.accountIds?.map((id) => this.rootStore.accountsStore.accountsMap[id]) ?? [];
  }

  get activeAccounts(): Account[] {
    return _.filter(
      this.accounts,
      (a) => !a.isOffboarded && !a.refId && ((a.forUser && a.forUser.status !== UserStatus.OFFBOARDED) || !a.forUser)
    );
  }

  get memberCount(): number {
    return this.accounts.length;
  }

  get assignedAccounts(): Account[] {
    return this.accounts.filter((a) => a.uid && !a.isOffboarded);
  }

  get assignedAccountsWarning(): boolean {
    return _.some(this.assignedAccounts, (acc) => acc.nodeImportState?.warning);
  }

  get unassignedAccounts(): Account[] {
    return this.accounts.filter((a) => !a.uid && !a.isOffboarded);
  }

  get offboardedAccounts(): Account[] {
    return this.accounts.filter((a) => a.isOffboarded);
  }

  get isUsageLastLoginAvailable() {
    // list of connectors for which last login (usage) is available / be able to get from remote:
    const CONNECTIONS_WITH_LAST_LOGIN_AVAILABLE = [
      ConnectionServiceE.GAPPS,
      ConnectionServiceE.AWS,
      ConnectionServiceE.TABLEAU,
      ConnectionServiceE.AZURE_AD,
      ConnectionServiceE.OKTA,
    ];
    return _.includes(CONNECTIONS_WITH_LAST_LOGIN_AVAILABLE, this.rootResource.connector?.service);
  }

  /**
   * @returns true is locked property is defined as true or every sub permission is locked. Sub permissions include
   * permissions of sub resources if this resource is not a system. A resource is considered unlocked if it has no
   * permissions and it's locked property is undefined.
   * */
  get isLocked(): boolean {
    const subPermissions = this.isSystem ? this.permissions : this.allNestedPermissions;
    const allPermissionsLocked =
      !_.isEmpty(subPermissions) && subPermissions.every((permission) => permission.isLocked);
    return _.isNil(this.locked) ? false : this.locked || allPermissionsLocked;
  }

  get recommendations(): Recommendation[] {
    return this.rootStore.newResourceStore.recommendationsMap[this.rootResource.id] ?? [];
  }

  get recommendationsMap(): _.Dictionary<Recommendation> {
    return _.keyBy(this.recommendations, 'id');
  }

  get defaultConfigurationConfirmed(): boolean {
    const rec = _.find(
      this.rootResource.recommendations,
      (rec) => rec.type === RECOMMENDATION_TYPE.REVIEW_DEFAULT_CONFIGURATION
    );

    return !rec || !_.isNil(rec.completedOn);
  }

  get connectionSettingsConfirmed(): boolean {
    const rec = _.find(
      this.rootResource.recommendations,
      (rec) => rec.type === RECOMMENDATION_TYPE.REVIEW_CONNECTION_SETTINGS
    );

    return !rec || !_.isNil(rec.completedOn);
  }

  async completeRecommendation(type: RECOMMENDATION_TYPE): Promise<void> {
    const rec = this.recommendations.find((rec) => rec.type === type && _.isNil(rec.completedOn));

    if (_.isNil(rec) || _.isNil(this.rootStore.currentUser)) {
      return; // nothing to complete
    }

    const response = await axios.post(`/api/recommendations/${rec.id}/update`, {
      completedBy: this.rootStore.currentUser.id,
    });

    runInAction(() => Object.assign(this.recommendationsMap[rec.id], response.data));
  }

  /** assign recs to their associated user */
  async assignRecommendations(recIds: string[]): Promise<void> {
    const responses = await pLimit(
      _.compact(
        recIds.map((recId) => {
          const assignedTo = this.recommendationsMap[recId].associatedAccount?.uid;
          return assignedTo ? axios.post(`/api/recommendations/${recId}/update`, {assignedTo}) : undefined;
        })
      )
    );

    runInAction(() =>
      _.compact(responses).forEach(({data: rec}) => Object.assign(this.recommendationsMap[rec.id], rec))
    );
  }

  get sysRecs() {
    const cuaid = this.connectedUaid ?? this.connectorAccount?.id;
    return this.recommendations.filter((rec) => rec.refType === ReferenceType.System || rec.refId === cuaid);
  }

  get numSysRecsUncompleted() {
    return this.sysRecs.filter((r) => _.isNil(r.completedOn)).length;
  }

  get tokenAndCertRecs() {
    const cuaid = this.connectedUaid ?? this.connectorAccount?.id;
    return this.recommendations.filter((rec) => rec.refType === ReferenceType.UserAccount && rec.refId !== cuaid);
  }

  /** @return the account associated with this system's connector */
  get connectorAccount() {
    if (this.connectedUaid) {
      return this.accounts.find((a) => a.id === this.connectedUaid);
    }
    const arn = this.rootResource.connector?.authzOwner?.Arn;
    return arn ? this.accounts.find((a) => a.foreignData?.arn === arn) : undefined;
  }

  get retrieveUsageDataEnabled() {
    return this.connector?.retrieveUsageData;
  }

  get isServiceUsageStatusImporting() {
    return (
      this.importingResourceUsageService || this.connector?.lastImportServiceUsage?.status === ImportStatus.PENDING
    );
  }

  get percentOfRecsCompleted(): number {
    const completed = this.recommendations.filter((rec) => !_.isNil(rec.completedOn)).length;
    return this.recommendations.length > 0 ? completed / this.recommendations.length : 1;
  }

  get unusedAccounts(): Account[] {
    return this.accounts.filter((acc) => {
      if (acc.isOffboarded || acc.forUser?.status === UserStatus.OFFBOARDED) {
        return false;
      }
      if (this.isUsageLastLoginAvailable && _.isNil(acc.lastAccountActivity)) {
        return true;
      }
      return moment(acc.lastAccountActivity) < moment().subtract(6, 'months');
    });
  }

  get unreviewedAccounts(): Account[] {
    return this.accounts.filter(
      (acc) =>
        _.isNil(acc?.reviewedAt) &&
        !acc.isOffboarded &&
        !acc.refId &&
        ((acc.forUser && acc.forUser.status !== UserStatus.OFFBOARDED) || !acc.forUser)
    );
  }

  get numAccounts(): number {
    return this.detailsLoadedAtLeastOnce ? this.accounts.filter((a) => !a.refId).length : this._numAccounts;
  }

  get numUnusedAccounts(): number {
    return this.detailsLoadedAtLeastOnce ? this.unusedAccounts.length : this._numUnusedAccounts;
  }

  get numUnusedAccountsFlagged(): number {
    return this.detailsLoadedAtLeastOnce
      ? this.unusedAccounts.filter((account) => account.flaggedForReview).length
      : this._numUnusedAccountsFlagged;
  }

  get numAccountsCertified(): number {
    return this.detailsLoadedAtLeastOnce
      ? this.accounts.filter((account) => account.reviewedAt && !account.isOffboarded).length
      : this._numAccountsCertified;
  }

  get numAccountsLinked(): number {
    return this.detailsLoadedAtLeastOnce
      ? this.accounts.filter((account) => account.uid && !account.isOffboarded).length
      : this._numAccountsLinked;
  }

  get percentOfAccountsLinked(): number {
    return this.numAccounts > 0 ? this.numAccountsLinked / this.numAccounts : 0;
  }

  get percentOfUnusedAccountsUnflagged(): number {
    return this.numUnusedAccounts > 0 ? this.numUnusedAccountsFlagged / this.numUnusedAccounts : 1;
  }

  get percentOfAccountsUnreviewed(): number {
    return this.numAccounts > 0 ? this.numAccountsCertified / this.numAccounts : 1;
  }

  get percentOfAccountRecsCompleted(): number {
    return _.mean([this.percentOfUnusedAccountsUnflagged, this.percentOfAccountsUnreviewed]);
  }

  get trustleScore(): number {
    return _.mean([this.percentOfRecsCompleted, this.percentOfAccountRecsCompleted]);
  }

  get archivedByLink(): ReactElement {
    if (_.isNil(this.archivedBy)) {
      return <></>;
    }

    return (
      <Link to={`/users/${this.archivedBy}`}>
        {formatUserName(this.rootStore.usersStore.usersMap[this.archivedBy])}
      </Link>
    );
  }

  get ancestorIds(): string[] {
    const ancestorIds: string[] = _.map(this.allAncestors, 'id');
    ancestorIds.push(this.id, this.id);
    return ancestorIds;
  }

  /*Referenced between resources */

  get referencingResources(): Resource[] {
    /*Gets referenced account which represents the resource as a principal so it can be related to an access (teams <-> repos)  */
    const referenceAccount = _.find(this.rootResource.accounts, (acc) => {
      return acc.refId === this.id;
    });

    if (!referenceAccount) {
      return [];
    }

    /*Gets access related */
    const aggregatedAccessesForAccount = _.filter(this.rootResource.accessAggregates, (aggs) => {
      return aggs.uaid === referenceAccount.id;
    });

    /*Gets resources tied to that access (the repos related to the group being shown) */
    const resources = _.flatMap(aggregatedAccessesForAccount, (aragg) => {
      const perm = _.find(this.rootResource.allNestedPermissions, (p) => p.id === aragg.pid);
      return perm ? [perm.parentResource] : [];
    });
    return resources;
  }

  get referencedAccesses(): AccessRecord[] {
    return _.filter(this.accessRecords, (access) => {
      return access.forAccount?.refId === this.id;
    });
  }

  get isConfiguredAsIDP() {
    if (!this.idpFeatureEnabled) {
      return false;
    }

    const {idpSettings} = this.rootStore.org;
    const {service: idpService, connectionId: idpConnectionId} = idpSettings || {};
    const connectorService = getIntegrationService(this.connector?.service);

    return (
      !_.isNil(idpService) &&
      !_.isNil(connectorService) &&
      idpService === connectorService &&
      idpConnectionId === this.connectionId
    );
  }

  // IDP is enabled for this specific connector type
  get idpFeatureEnabled(): boolean {
    return this.rootStore.org.isIDPFeatureEnabled(this.connector?.service);
  }

  get idpIntegrationService() {
    return getIntegrationService(this.connector?.service);
  }

  get shouldDisableInteractions(): boolean {
    return this.isDisabled || Boolean(this.archived);
  }
}
