const LICENSES_OPTIONS = [
  {
    key: 'copilot',
    displayName: 'Copilot',
  },
  {
    key: 'powerbi',
    displayName: 'PowerBI',
  },
  {
    key: 'visio',
    displayName: 'Visio',
  },
  {
    key: 'e1',
    displayName: 'E1',
  },
  {
    key: 'e3',
    displayName: 'E3',
  },
  {
    key: 'e5',
    displayName: 'E5',
  },
];

const AVAILABLE_LICENSES_KEYS = ['copilot', 'powerbi', 'visio', 'e1', 'e3', 'e5'];

export const ALL_LICENSES_KEY = 'all_licenses';

export const AVAILABLE_LICENSES_OPTIONS: {key: string; displayName: string}[] = LICENSES_OPTIONS.filter((l) =>
  AVAILABLE_LICENSES_KEYS.includes(l.key)
);

export function getLicenseName(licenseKey: string) {
  if (licenseKey === ALL_LICENSES_KEY) {
    return 'All Licenses';
  }
  return AVAILABLE_LICENSES_OPTIONS.find((l) => l.key === licenseKey)?.displayName;
}

export const VIEWS = ['year', 'quarter', 'month', 'week'] as const;
export const HEATMAP_SERIES = ['departments', 'roles', 'teams', 'titles'] as const;
