import React from 'react';
import {Button, Icon, Modal, Tab, TileButton} from '@trustle/component-library';
import {User} from '../../../stores/domainObjects/User';
import {UserBasicInfo} from './internal/UserBasicInfo';
import {UserHistory} from './internal/UserHistory';
import {SourceOfTruthE} from '../../../types';
import {useAuthority} from '../../../connectors/common/authority/hooks/useAuthority';
import {UserLoginInfo} from './internal/UserLoginInfo';
import {UserRoleAndAccess} from './internal/UserRoleAndAccess';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';

type Props = {
  user: User;
  handleCloseFn: () => void;
};
export function UserProfileModal({user, handleCloseFn}: Props) {
  const {settings: authoritySettings} = useAuthority();

  const modalHeader = (
    <div className="tr-flex tr-justify-between">
      <h2>User Details</h2>
      <div className="tr-flex tr-gap-2 tr-items-center tr-mr-8">
        <p className="tr-m-0 tr-mr-2">Authority:</p>
        {user.sourceOfTruth === SourceOfTruthE.IDP && authoritySettings ? (
          <TileButton
            data-testid="authority-image"
            size="sm"
            name={authoritySettings.service as SystemIconsTypesEnum}
          />
        ) : (
          <Icon type="Trustle" title="Trustle" forcedSize={32} data-testid="trustleIcon" />
        )}
      </div>
    </div>
  );

  return (
    <Modal width={'lg'} onClose={() => handleCloseFn()} title={modalHeader}>
      <Tab.Group>
        <Tab.List>
          <Tab data-testid={'basicInfo'} key={'basicInfo'}>
            Basic Info
          </Tab>
          <Tab data-testid={'loginInfo'} key={'loginInfo'}>
            Login Info
          </Tab>
          <Tab data-testid={'roleAndAccess'} key={'roleAndAccess'}>
            Role & Access
          </Tab>
          <Tab data-testid={'history'} key={'history'}>
            History
          </Tab>
        </Tab.List>
        <Tab.Panels className="tr-h-[450px]">
          <Tab.Panel>
            <UserBasicInfo user={user} />
          </Tab.Panel>
          <Tab.Panel>
            <UserLoginInfo user={user} />
          </Tab.Panel>
          <Tab.Panel>
            <UserRoleAndAccess user={user} />
          </Tab.Panel>
          <Tab.Panel>
            <UserHistory userId={user.id} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <div className="tr-flex tr-justify-end">
        <Button onClick={() => handleCloseFn()}>Close</Button>
      </div>
    </Modal>
  );
}
