import _ from 'lodash';
import React, {useState} from 'react';
import axios from 'axios';
import {SubmitButton} from 'src/components/design';
import {ConnectionInfoT, ConnectionT, DurationUnitT} from '../../types';
import {Formik, FormikForm} from 'src/components/design/formik';
import './M365Connector.scss';
import {Field} from 'formik';
import {Button, DurationInput, Loading, PasswordInput, TextInput, Alert} from '@trustle/component-library';
import {useConnectionCredentials} from '../../lib/hooks';
import {useHistory} from 'react-router-dom';
import {useSystemSetup} from 'src/lib/hooks/useSystemSetup';
type M365PropsT = {
  onComplete: any;
  connectionInfo: ConnectionInfoT;
  editMode?: boolean;
};

function M365Setup(props: M365PropsT) {
  const {connectionInfo, editMode} = props;
  const history = useHistory();
  const [connection] = useState<ConnectionT>(connectionInfo.connection);
  const [APIsConnected, setAPIsConnected] = useState<boolean>(false);
  const [testInProgress, setTestInProgress] = useState<boolean>(false);
  const [info, setInfo] = useState<string>('');
  const {systemSetup, updateSetup} = useSystemSetup(connectionInfo.connection.id ?? '');
  const [infoType, setInfoType] = useState<string>('text-warning');
  const {credentials, testConnection: testConnectionAPI} = useConnectionCredentials();

  async function testConnection(credentials: {tenantId: string; clientId: string; clientSecret: string}) {
    setTestInProgress(true);
    setInfoType('text-warning');
    setInfo('Trying to authenticate ...');
    async function parseError(message: any) {
      const regex: RegExp = /^(.*?)\bTrace ID\b/;
      const match: RegExpExecArray | null = regex.exec(message);
      if (match) {
        const parsedMessage: string = match[1].trim();
        return parsedMessage;
      }
      return message;
    }
    async function setError(response: any) {
      setTestInProgress(false);
      setAPIsConnected(false);
      setInfoType('text-danger');
      if (response.data?.error?.message) {
        setInfo(response.data?.error?.message);
      } else {
        setInfo(await parseError(`${response.data?.message}`)); //m365 errors
      }
      return false;
    }

    try {
      const response = await testConnectionAPI(connection.id, credentials);
      if (response.data?.ok) {
        setTestInProgress(false);
        setAPIsConnected(true);
        setInfoType('text-success');
        setInfo('Successfully Connected');
        return response;
      } else {
        await setError(response);
      }
    } catch (err: any) {
      await setError(err);
    }
  }

  async function submitCredentials(
    values: {
      tenantId: string;
      clientId: string;
      clientSecret: string;
      accessKeyRotation: {durationValue: number; durationUnit: DurationUnitT};
    },
    setSubmitting: (submitting: boolean) => void
  ) {
    const tokens = _.pick(values, ['tenantId', 'clientId', 'clientSecret']);
    try {
      updateSetup({connectionId: connection.id, keys: values});
      await credentials(connection.id, {...tokens, accessKeyRotation: values.accessKeyRotation});
      const {data} = await axios.post(`/api/connect/${connection.id}/setup/complete`, {
        pollDurationUnit: 'hour',
        pollDurationValue: 24,
      });
      history.push(`/resource/manage/${data.systemId}`);
    } catch (err: any) {
      setInfoType('text-danger');
      setInfo(err.response.data?.error?.message || err.response.statusText);
    }

    setSubmitting(false);
  }

  return (
    <div className="py-4">
      <h3>App Registration credentials</h3>
      <div>
        Trustle uses OAuth to import and provision access to your M365 account. Use your App Registration credentials to
        connect to M365.
      </div>
      <small>
        <a href="https://learn.trustle.com/tutorial/installing-microsoft-365" rel="noopener noreferrer" target="_blank">
          Guide: App Registration in M365
        </a>
      </small>
      <div className="mt-4">
        <Formik
          initialValues={{
            tenantId: systemSetup?.tenantId ?? '', // directory_id
            clientId: systemSetup?.clientId ?? '', // application_id
            clientSecret: systemSetup?.clientSecret ?? '', // client_secret_value
            accessKeyRotation: {
              durationValue: _.get(connectionInfo, 'connection.accessKeyRotationValue', 3),
              durationUnit: _.get(connectionInfo, 'connection.accessKeyRotationUnit', 'month'),
            },
          }}
          validate={(values: any) => {
            const errors: _.Dictionary<string> = {};
            if (!values.tenantId || values.tenantId.length !== 36) {
              errors.tenantId = 'Tenant ID should have 36 characters long';
            }
            if (!values.clientId || values.clientId.length !== 36) {
              errors.clientId = 'Client ID should have 36 characters long';
            }
            if (!values.clientSecret || values.clientSecret.length < 34) {
              errors.clientSecret = 'Client Secret: Value should have more than 34 characters long';
            }
            return errors;
          }}
          onSubmit={(values: any, {setSubmitting}: any) => {
            void submitCredentials(values, setSubmitting);
          }}
        >
          {({isSubmitting, status, isValid, dirty, values}) => (
            <FormikForm>
              <div>
                <div className="tr-grid tr-grid-cols-2 tr-gap-2">
                  <div>
                    <Field
                      component={TextInput}
                      label="Directory (tenant) ID"
                      placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                      name="tenantId"
                      required
                    />
                  </div>
                  <div>
                    <Field
                      component={TextInput}
                      label="Application (client) ID"
                      placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                      name="clientId"
                      required
                    />
                  </div>
                  <div>
                    <Field
                      component={PasswordInput}
                      label="Client Secret: Value"
                      className="tr-w-full"
                      placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                      name="clientSecret"
                      showreveal={true}
                      required
                    />
                  </div>
                </div>
                <div className="tr-grid tr-grid-cols-1 tr-mt-2 tr-pt-4 border-top tr-pl-0">
                  <Field
                    component={DurationInput}
                    name="accessKeyRotation"
                    label="Trustle Connector key rotation"
                    required
                    includeNoneOption={false}
                    description="Prompt me to rotate the Trustle access key in the period defined below"
                  />
                </div>

                <div className="text-left mt-5 tr-grid tr-grid-cols-1">
                  <div>
                    {testInProgress && <Loading />}
                    <div className={`${infoType}`}>{info}</div>
                    {status && status.message && <Alert colorVariant={'danger'}>{status.message}</Alert>}
                  </div>
                </div>
                <div className="text-right mt-5 mb-3">
                  <Button
                    name="edit-connection-save"
                    type="button"
                    disabled={!isValid || testInProgress || isSubmitting || !dirty}
                    onClick={async () => await testConnection(_.pick(values, ['tenantId', 'clientId', 'clientSecret']))}
                  >
                    {'Test Connection'}
                  </Button>
                  <SubmitButton
                    inSubmit={isSubmitting}
                    disabled={!APIsConnected || !isValid || isSubmitting || !dirty}
                    label={!editMode ? 'Continue' : 'Save Credentials'}
                  />
                </div>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
      <hr />
    </div>
  );
}

export default M365Setup;
