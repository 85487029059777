import {Button} from '@trustle/component-library';
import React, {useState} from 'react';
import {SyncUsersModal} from './SyncUsersModal';
import {Resource} from '../../../../../stores/domainObjects/Resource';
import {useAuthority} from '../../hooks/useAuthority';

export function SyncUsersButtonModal({resource}: {resource: Resource}) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const {isDisabled} = useAuthority();
  return (
    <>
      <Button variant="secondary" onClick={() => setShowModal(true)} disabled={isDisabled}>
        Synchronize Users
      </Button>
      {showModal && <SyncUsersModal setShowModal={setShowModal} resource={resource} />}
    </>
  );
}
