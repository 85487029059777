import {observer} from 'mobx-react';
import React from 'react';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import {Resource} from 'src/stores/domainObjects/Resource';
import _ from 'lodash';
import {Permission} from 'src/stores/domainObjects/Permission';
import {Alert, AutoProvisionIcon, Button, Icon} from '@trustle/component-library';
import './Access.scss';
import TreeTabPanel from '../TreeView/TreeTabPanel';
import ServiceRadioSelector from './ServiceRadioSelector';
import {useFeatureFlags, useRootStore} from 'src/lib/hooks';
import {User} from '../../../stores/domainObjects/User';
import {ProvisionOptions} from '../../../types';

type AvailableTabPropsT = {
  categorizedSystems: _.Dictionary<Resource[]>;
  onRequestAccess: (p?: Permission) => void;
};
const AvailableTab = observer(({categorizedSystems, onRequestAccess}: AvailableTabPropsT) => {
  const history = useHistory();
  const {newResourceStore, currentUser} = useRootStore();

  const featureFlagViewer = useFeatureFlags();
  const hideGenericRequests = featureFlagViewer.isEnabled('hide_generic_requests');
  const skipLockedCheckAvailableTab = featureFlagViewer.isEnabled('skip_locked_check_available_tab');

  const defaultSystemType = _.keys(categorizedSystems)[0];

  const getDefaultSid = (type: string): string => categorizedSystems[type]?.[0].id ?? '';

  return (
    <Switch>
      <Route
        path="/access/available/:type/:sid"
        render={(props) => {
          const {type, sid} = props.match.params;
          const systems = categorizedSystems[type];
          const currentResource = newResourceStore.resourceMap[sid];
          return (
            <>
              <div className="my-3 tr-flex px-lg-0 col-lg">
                <div className="tr-grow">
                  <h2>Available Systems</h2>
                  <p>Select a system below to see available Resources</p>
                </div>
                {!hideGenericRequests && (
                  <div className="tr-flex justify-content-lg-end align-items-center px-lg-0">
                    <span>Don't see what you are looking for?</span>
                    <Button variant="primary" size="md" onClick={() => onRequestAccess()} className="ml-3">
                      Request Access
                    </Button>
                  </div>
                )}
              </div>
              <ServiceRadioSelector
                clickHandler={(type) => history.push(`/access/available/${type}/${getDefaultSid(type)}`)}
                categorizedSystems={categorizedSystems}
              />
              <div className="tr-flex">
                <div className="tr-flex flex-column align-content-start tr-mr-6 tr-w-56">
                  {systems.map((system) => {
                    const {id, iconImage, name} = system;
                    return (
                      <button
                        key={id}
                        data-testid={name}
                        className="p-0 border-0 rounded tr-flex align-items-center text-dark tr-mb-2 tr-w-56"
                        style={{backgroundColor: id === sid ? '#EEEEEE' : '#F8F9FA'}}
                        onClick={() => history.push(`/access/available/${type}/${id}`)}
                        disabled={id === sid}
                      >
                        <div className="relative tr-p-2">
                          {(system.isConnectionBroken || system.isDisabled) && (
                            <div className="tr-absolute tr-bg-red-500 tr-w-2 tr-h-2 tr-rounded-full tr-top-1.5 tr-right-1" />
                          )}
                          {iconImage}
                        </div>

                        <span className="tr-ml-1 text-left tr-text-sm">{name}</span>
                        {system.isProvisionStatus(ProvisionOptions.off) && system.type !== 'custom-system' && (
                          <div className="ml-auto mr-2">
                            <AutoProvisionIcon value={false} size="md" />
                          </div>
                        )}
                      </button>
                    );
                  })}
                </div>
                <div className="tr-grow">
                  <TreeTabPanel
                    hideRoots={true}
                    hideFilters={false}
                    user={currentUser}
                    showTabs={true}
                    system={newResourceStore.resourceMap[sid]}
                    resources={[currentResource]}
                    canEditResource={false}
                    banner={resolveBanner(currentUser, currentResource)}
                    allowExpand={false}
                    displayActiveAccesses={skipLockedCheckAvailableTab}
                  />
                </div>
              </div>
            </>
          );
        }}
      />
      {/* redirect urls with type and no sid to /:type/:sid */}
      <Route
        path="/access/available/:type"
        render={(props) => {
          const type = props.match.params.type;
          return <Redirect to={`/access/available/${type}/${getDefaultSid(type)}`} />;
        }}
      />
      {/* redirect urls without types to a default type */}
      {defaultSystemType && <Redirect from={`/access/available`} to={`/access/available/${defaultSystemType}`} />}
      {/* default route is empty message */}
      <Route>
        {_.isEmpty(categorizedSystems) && (
          <div className="px-0">
            <div className="mt-5 tr-flex flex-column align-items-center">
              <img className="tr-w-72" src="/russel-magnifying-glass.svg" alt="" />
              <h2 className="font-weight-bold">There are No Available Systems</h2>
            </div>
          </div>
        )}
      </Route>
    </Switch>
  );
});

export default AvailableTab;

// TODO: this might be moved to common usage location if required in other places
function resolveBanner(currentUser: User, resource: Resource) {
  const isOrgOrSystemOwner = currentUser.isOrgOwner || resource.userIsOwner;
  // Priority CONNECTION_BROKEN, DISABLED, PROVISION_OFF
  if (resource.isConnectionBroken) {
    return SYSTEM_STATUS_ALERTS.CONNECTION_BROKEN(isOrgOrSystemOwner);
  }
  if (resource.isDisabled) {
    return SYSTEM_STATUS_ALERTS.DISABLED(isOrgOrSystemOwner);
  }
  if (resource.isProvisionStatus(ProvisionOptions.off) && resource.type !== 'custom-system') {
    return SYSTEM_STATUS_ALERTS.PROVISION_OFF(isOrgOrSystemOwner);
  }
  return null;
}

const SYSTEM_STATUS_ALERTS: Record<string, (isOrgOrSystemOwner: boolean) => React.ReactElement> = {
  PROVISION_OFF: (isOrgOrSystemOwner: boolean) => (
    <Alert
      title="Provisioning for this system is Off"
      colorVariant="primary"
      icon={<AutoProvisionIcon value={false} size="md" />}
    >
      {isOrgOrSystemOwner ? BANNER_MESSAGES.PROVISION_OFF.ORG_OR_SYSTEM_OWNER : BANNER_MESSAGES.PROVISION_OFF.USER}
    </Alert>
  ),
  DISABLED: (isOrgOrSystemOwner: boolean) => (
    <Alert title="System is Disabled" colorVariant="danger">
      {isOrgOrSystemOwner ? BANNER_MESSAGES.DISABLED.ORG_OR_SYSTEM_OWNER : BANNER_MESSAGES.DISABLED.USER}
    </Alert>
  ),
  CONNECTION_BROKEN: (isOrgOrSystemOwner: boolean) => (
    <Alert
      title="Broken Connection"
      icon={<Icon type="unlinkedUser" className="tr-text-error" title={'Broken connection'} />}
      colorVariant="danger"
    >
      {isOrgOrSystemOwner
        ? BANNER_MESSAGES.CONNECTION_BROKEN.ORG_OR_SYSTEM_OWNER
        : BANNER_MESSAGES.CONNECTION_BROKEN.USER}
    </Alert>
  ),
};

const BANNER_MESSAGES = {
  PROVISION_OFF: {
    ORG_OR_SYSTEM_OWNER: (
      <span>
        Visibility, provisioning, and usage tracking for this connection are disabled by default.
        <br />
        You can enable visibility and provisioning in the connection's settings.
      </span>
    ),
    USER: (
      <span>
        There are no available systems to request access to.
        <br />
        Contact your Trustle admin, or click the Request Access button in the upper right of the screen to request
        access.
      </span>
    ),
  },
  DISABLED: {
    ORG_OR_SYSTEM_OWNER: (
      <span>
        Visibility, provisioning, and usage tracking for this connection have been disabled by system owners.
        <br />
        You can re-enable visibility and provisioning in the connection's settings.
      </span>
    ),
    USER: (
      <span>
        Visibility, provisioning, and usage tracking for this connection have been disabled by system owners.
        <br />
        Contact your Trustle admin, or click the Request Access button in the upper right of the screen to request
        access.
      </span>
    ),
  },
  CONNECTION_BROKEN: {
    ORG_OR_SYSTEM_OWNER: (
      <span>
        Trustle is having problems connecting to the connection's remote endpoint. It could be due to expired/incorrect
        connection settings, or an intermittent issue with your provider. Trustle will not be able to manage this
        resource until the connection is re-established. Go to the systems connection settings page to reconnect.
      </span>
    ),
    USER: (
      <span>
        Trustle is having problems connecting to the connection's remote endpoint. Contact your Trustle admin, or click
        the Request Access button in the upper right of the screen to request access.
      </span>
    ),
  },
};
