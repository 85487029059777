import _ from 'lodash';
import {makeAutoObservable} from 'mobx';
import {AccessRequestT} from 'src/types';
import RootStore from '.';
import {AccessRecord} from './domainObjects/AccessRecord';
import {ACCESS_STATUSES} from 'src/lib/constants';

export class AccessStore {
  accessMap: Record<string, AccessRecord> = {};
  genericAccessRequests: AccessRequestT[] = [];
  isLoading: boolean = false;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this);
  }

  get accesses(): AccessRecord[] {
    return _.values(this.accessMap);
  }

  statusArray = ACCESS_STATUSES;

  statusByValue(val: number): string {
    return this.statusArray[val];
  }

  /** create an accessRecord, and replace the existing accessRecord if it exists */
  updateAccessFromServer({forResource, forPermission, forAccount: _fA, forUser, ...attributes}: any) {
    const existingAccess = this.accessMap[attributes.id];
    if (existingAccess) {
      Object.assign(existingAccess, attributes);
    } else {
      this.accessMap[attributes.id!] = new AccessRecord(this.rootStore, attributes);
    }

    if (forResource) {
      this.rootStore.newResourceStore.updateResourceFromServer(forResource);
      this.accessMap[attributes.id].rid = forResource.id;
      this.accessMap[attributes.id].rootSid = forResource.rootSid;
    }

    if (forPermission) {
      this.rootStore.permissionStore.updatePermissionFromServer(forPermission);
    }

    if (forUser) {
      this.rootStore.usersStore.updateUserFromServer(forUser);
      this.accessMap[attributes.id].uid = forUser.id;
    }
  }
}
