import axios from 'axios';
import _ from 'lodash';
import {makeAutoObservable, onBecomeObserved, runInAction} from 'mobx';
import {logger} from 'src/lib';
import RootStore from '.';
import {Team} from './domainObjects/Team';
import {TeamFormT} from 'src/views/admin/teams/TeamForm';

export type TeamsResponseT = {
  id: string;
  color: string;
  created: string;
  description: string;
  members: string;
  name: string;
  managers: string;
  tombstone: boolean;
  type?: string;
};

export class TeamStore {
  teamsMap: Record<string, Team> = {};
  loading: boolean = false;
  error?: any = undefined;

  private teamsLoadedAtLeastOnce: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
    onBecomeObserved(this, 'teamsMap', () => this.loadTeams());
  }

  private async loadTeams() {
    if (!this.teamsLoadedAtLeastOnce) {
      runInAction(() => (this.loading = true));
    }

    try {
      const teams: TeamsResponseT[] = (await axios.get(`/api/teams`)).data;
      runInAction(() => {
        teams.forEach((team) => (this.teamsMap[team.id] = new Team(this.rootStore, team)));
        this.loading = false;
        this.teamsLoadedAtLeastOnce = true;
      });
    } catch (err) {
      logger.error(err);
      runInAction(() => {
        this.error = err;
        this.loading = false;
      });
    }
  }

  async loadTeam(teamId?: string) {
    try {
      const response = await axios.get(`/api/teams/${teamId}`);
      runInAction(() => {
        const users = response.data.users;
        _.map(users, (user) => {
          this.rootStore.usersStore.updateUserFromServer({...user});
        });
      });
    } catch (err: any) {
      logger.error(err);
      runInAction(() => {
        this.rootStore.toast.add('Error loading team details', {appearance: 'error', autoDismiss: true});
        this.error = true;
      });
      return false;
    }
  }

  public async deleteTeam(team: Team) {
    if (team.type !== 'organization') {
      try {
        await axios.delete(`/api/teams/${team.id}`);
        runInAction(() => {
          delete this.teamsMap[team.id];
        });

        const successMessage = `Team '${team.name}' was deleted successfully.`;
        this.rootStore.toast.success(successMessage);
      } catch (error: any) {
        const errorMessage = error.response.data?.error?.message || 'An error occurred while deleting the team.';
        this.rootStore.toast.error(errorMessage);
      }
    }
  }

  async createTeam(team: TeamFormT, id?: string) {
    try {
      const uri = id ? `/api/teams/${id}` : `/api/teams`;
      const {data} = await axios.post(uri, team);
      const successMessage = `Team ${team.name} ${id ? 'edited' : 'created'} successfully`;
      this.rootStore.toast.success(successMessage);
      runInAction(() => {
        const existingTeam = this.teamsMap[data.id];
        if (existingTeam) {
          Object.assign(existingTeam, data);
        } else {
          this.teamsMap[data.id] = new Team(this.rootStore, data);
          return this.teamsMap[data.id];
        }
      });
    } catch (err: any) {
      const errorMessage = err.response.data?.error?.message || 'An error occurred while saving your changes';
      this.rootStore.toast.error(errorMessage);
      return undefined;
    }
  }

  /** @deprecated once teams actions correctly mutate store this won't be needed */
  async refresh() {
    return this.loadTeams;
  }

  get teams() {
    return _.values(this.teamsMap);
  }

  get departments() {
    return _.uniq(_.flatMap(this.teams, (team: Team) => team.departments));
  }

  get titles() {
    return _.uniq(_.flatMap(this.teams, (team: Team) => team.titles));
  }

  get roles() {
    return _.uniq(_.flatMap(this.teams, (team: Team) => team.roles));
  }
}
