import {UserAvatar} from '@trustle/component-library';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useRootStore} from 'src/lib/hooks';
import {NavLink as BootstrapNavLink, Dropdown} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {formatUserName} from 'src/lib';
import _ from 'lodash';

const ProfileDropdown = observer(({className, toggleColor}: {className?: string; toggleColor?: string}) => {
  const {org, currentUser} = useRootStore();

  if (_.isNil(org) || _.isNil(currentUser)) {
    return <></>;
  }
  return (
    <>
      <Dropdown className={className}>
        <Dropdown.Toggle as={BootstrapNavLink} style={{color: toggleColor ?? '#fff'}} id="basic-nav-dropdown">
          <span className="tr-inline-block">
            <UserAvatar text={formatUserName(currentUser)} size={'sm'} />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="dropdown-header">Information</div>
          <p className="dropdown-item">
            Team: <strong>{org.hostname}</strong>
          </p>
          <p className="dropdown-item">
            Email: <strong>{currentUser.email}</strong>
          </p>
          <p className="dropdown-item">
            Manager: <strong>{currentUser.managerEmail}</strong>
          </p>
          <div className="dropdown-divider"></div>
          <div className="dropdown-header">Options</div>
          <Dropdown.Item as={Link} to={`/users/${currentUser.id}/current`}>
            Profile
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={`/logout`}>
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
});
export default ProfileDropdown;
