import React, {useState} from 'react';
import {SubmitButton} from 'src/components/design';
import {observer} from 'mobx-react';
import '../common/editConnection/EditConnection.scss';
import ConnectorStatus from 'src/components/StatusIndicator';
import axios from 'axios';
import _ from 'lodash';
import FontAwesomeIcon from 'src/components/FontAwesomeIcon';
import {faCircleNotch} from 'src/components/FontAwesomeIcon';
import {parseResponse} from 'src/connectors/aws/helper';
import {ParsedTestConnectionT} from 'src/connectors/aws/helper';
import {Button, PasswordInput} from '@trustle/component-library';
import {Formik, FormikForm} from 'src/components/design/formik';
import {object, string} from 'yup';
import {Field} from 'formik';
import {useToasts} from 'react-toast-notifications';
import {ResourceT} from 'src/types';
import {useSystemSetup} from 'src/lib/hooks/useSystemSetup';

/**
 * Form for update the credentials of AWSIDC connection
 * Allow to test the connection and show errors
 *
 * @param props
 * @returns
 */
export default observer(function EditConnectionAWSIDC(props: {resource: ResourceT}) {
  const {resource} = props;
  const {addToast} = useToasts();

  const [resultTestConnection, setResultTestConnection] = useState<ParsedTestConnectionT>();
  const [testingConnection, setTestingConnection] = useState<boolean>(false);
  const [updatingCredentials, setUpdatingCredentials] = useState<boolean>(false);

  const {systemSetup, updateSetup} = useSystemSetup(resource.connectionId ?? '');
  const lastImport = resource.connector?.lastImport;
  const [connectorStatus, setConnectorStatus] = useState<string | undefined>(lastImport?.status);

  async function testConnection(values: {accessKeyId: string; secretAccessKey: string}) {
    setTestingConnection(true);
    setConnectorStatus('pending');
    const {data: responseData} = await axios.post(`/api/connect/${resource.connectionId}/setup/test_connection`, {
      accessKeyId: values.accessKeyId,
      secretAccessKey: values.secretAccessKey,
    });
    if (!responseData.ok) {
      setConnectorStatus('failed');
      addToast(responseData.error.message ?? 'Connection failed', {appearance: 'error', autoDismiss: false});
    } else {
      setConnectorStatus('finished');
      addToast('Succesfully connected', {appearance: 'success', autoDismiss: true});
    }

    setResultTestConnection(await parseResponse(responseData));
    setTestingConnection(false);
  }

  async function submitConnection(values: {accessKeyId: string; secretAccessKey: string}, formikArgs: any) {
    updateSetup({connectionId: resource.connectionId ?? '', keys: values});
    const {data: responseData} = await axios.post(`/api/connect/${resource.connectionId}/credentials`, {
      secretAccessKey: values.secretAccessKey,
      accessKeyId: values.accessKeyId,
    });

    if (resource.connector && responseData && responseData.authzOwner) {
      resource.connector.authzOwner = responseData.authzOwner;
      const parsedResponseData = await parseResponse(responseData);
      setResultTestConnection(parsedResponseData);
    }
    setUpdatingCredentials(false);
    formikArgs.resetForm();
  }
  return (
    <>
      <div className="edit-connection-aws pb-7 ">
        <Formik
          key={`newResourceaccount`}
          initialValues={{
            accessKeyId: systemSetup?.accessKeyId ?? '',
            secretAccessKey: systemSetup.secretAccessKey ?? '',
          }}
          onSubmit={submitConnection}
          validationSchema={object().shape({accessKeyId: string().required(), secretAccessKey: string().required()})}
        >
          {({values, isSubmitting, dirty}) => {
            return (
              <FormikForm>
                <div>
                  <div className="lg:tr-w-2/3 tr-w-full">
                    <div className="test-box tr-flex tr-px-8 tr-items-center tr-justify-between">
                      <div className="tr-my-2">
                        <div className="tr-grid tr-grid-cols-2">
                          <ConnectorStatus status={connectorStatus}></ConnectorStatus>
                          <div className="body4">{resource.name}</div>
                        </div>
                      </div>

                      <div className="tr-justify-self-end">
                        <Button
                          variant="secondary"
                          className=""
                          data-testid="updatecredentials"
                          disabled={testingConnection || updatingCredentials}
                          onClick={async () => {
                            setUpdatingCredentials(true);
                            setResultTestConnection(undefined);
                          }}
                        >
                          Update Credentials &gt;
                        </Button>

                        <Button
                          variant="secondary"
                          className=""
                          data-testid="test-id-button"
                          disabled={!(values.secretAccessKey && values.accessKeyId) || testingConnection}
                          onClick={async () => {
                            await testConnection(values);
                          }}
                        >
                          {testingConnection ? (
                            <>
                              Testing <FontAwesomeIcon icon={faCircleNotch} spin size="lg" />
                            </>
                          ) : (
                            'Test'
                          )}
                        </Button>
                      </div>
                    </div>
                    <div className="item tr-flex tr-justify-between mt-4 tr-mx-4">
                      {!updatingCredentials ? (
                        <></>
                      ) : (
                        <>
                          <h3>Credentials for Trustle integration</h3>
                          <Button
                            variant="ternary"
                            onClick={() => {
                              setUpdatingCredentials(false);
                            }}
                          >
                            Close
                          </Button>
                        </>
                      )}
                    </div>
                    {updatingCredentials && (
                      <div className="no-padding tr-mx-4">
                        <Field
                          component={PasswordInput}
                          label="Access Key"
                          className="tr-w-1/2"
                          placeholder="Enter access key here"
                          showreveal={true}
                          name="accessKeyId"
                          key="accessKey"
                          required
                        />
                        <Field
                          component={PasswordInput}
                          label="Secret Key"
                          showreveal={true}
                          className="tr-w-1/2"
                          placeholder="Enter secret key here"
                          name="secretAccessKey"
                          key="secretAccessKey"
                          required
                        />

                        <div className="body5">
                          <a
                            href="https://docs.aws.amazon.com/en_en/general/latest/gr/aws-sec-cred-types.html#access-keys-and-secret-access-keys"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="text-underline">Learn how to generate new credentials</span>
                          </a>
                        </div>
                        <div className="text-right">
                          <SubmitButton
                            name="edit-connection-save"
                            data-testid="edit-connection-save"
                            disabled={
                              !dirty ||
                              isSubmitting ||
                              _.isNil(resultTestConnection) ||
                              (resultTestConnection && !resultTestConnection.ok)
                            }
                            label="Save Credentials"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    </>
  );
});
