import {ConnectionServiceE} from 'src/types';
import {ConnectorSchema} from './default';
import {AccountTableParams} from '../common/types';
import React from 'react';
import ResourceBranch, {ResourceBranchPropsT} from 'src/views/Resource/TreeView/ResourceBranch';

const gapps: ConnectorSchema = {
  service: ConnectionServiceE.GAPPS,
  name: 'Google Apps',
  description:
    'Sync Google Workspace with your Trustle organization. This process will guide you through authorizing Trustle to access your Google Workspace organization.',
  serviceId: 'gapps',
  /*auth: {
    type: 'oauth',
    buttons: [
      {
        title: 'Manual (Read only)',
        href: '/api/connect/gapps/oauth/start',
        label: 'Select Manual',
        description: 'This application allows Trustle to import groups and members to track access.',
        id: 'manualConnection',
        params: {
          connectionId: undefined,
          editConnection: undefined,
          authenticationMethod: `${GAppsAuthenticationMethodE.APP_READ}`,
          reauth: (connected: boolean) => {
            return connected ? 'true' : 'false';
          },
        },
      },
      {
        title: 'Automatic (Read only)',
        href: '/api/connect/gapps/oauth/start',
        description:
          'This application allows Trustle to import your Google Workspace Organization data and will automatically provision and deprovision Google Workspace Organization access.',
        label: 'Select Automatic',
        id: 'automaticConnection',
        params: {
          connectionId: undefined,
          editConnection: undefined,
          authenticationMethod: `${GAppsAuthenticationMethodE.APP_WRITE}`,
          reauth: (connected: boolean) => {
            return connected ? 'true' : 'false';
          },
        },
      },
    ],
  },*/
  templates: {
    resources: {
      tree: {
        Resource: {
          default: {
            main: (props: ResourceBranchPropsT) => {
              return <ResourceBranch {...props} />;
            },
          },
        },
        Permission: {},
        AccessRecord: {},
      },
    },
    accounts: {
      tables: {
        Linked: (_props: AccountTableParams) => {
          return [
            'flaggedForReview',
            'status',
            'user/account',
            'accesses.length',
            'numConnectedApps',
            'usage',
            'issue',
            'lastAccountActivity',
            'lastLogin',
            'actions',
          ];
        },
        Unlinked: (_props: AccountTableParams) => {
          return [
            'flaggedForReview',
            'status',
            'user/account',
            'accesses.length',
            'numConnectedApps',
            'usage',
            'accountType',
            'email',
            'lastAccountActivity',
            'lastLogin',
            'issue',
            'actions',
          ];
        },
        Flagged: (_props: AccountTableParams) => {
          return [
            'flaggedForReview',
            'status',
            'user/account',
            'accesses.length',
            'numConnectedApps',
            'usage',
            'lastAccountActivity',
            'lastLogin',
            'issue',
            'actions',
          ];
        },
        Offboarded: (_props: AccountTableParams) => {
          return ['flaggedForReview', 'status', 'user/account', 'editedByUid', 'deletedAt', 'actions'];
        },
      },
    },
  },
};

export default gapps;
