import {ALL_LICENSES_KEY} from '../../constants';

export function resolveFillColor(serie: string) {
  switch (serie) {
    case 'copilot':
      return '#F43454';
    case 'powerbi':
      return '#6D9BEE';
    case 'visio':
      return '#00519B';
    case 'e1':
      return '#26A666';
    case 'e3':
      return '#4a1d75';
    case 'e5':
      return '#e3bd5e';
    case ALL_LICENSES_KEY:
      return '#24ab97';
    default:
      return 'none';
  }
}
