import React from 'react';
import {Icon, Select} from '@trustle/component-library';
import {ItemIconTypesEnum} from '@trustle/component-library/dist/types';
import {SummaryStatistics, ViewType} from '../../types';

const OPTIONS: {value: ViewType; label: string}[] = [
  {value: 'quarter', label: 'Last 90 days'},
  {value: 'month', label: 'Last 30 days'},
  {value: 'week', label: 'Last 7 days'},
];

type Props = {
  summary: SummaryStatistics;
  selectedView: {
    value: ViewType;
    label: string;
  };
  setSelectedView: React.Dispatch<React.SetStateAction<{value: ViewType; label: string}>>;
};

export function LicenseUsageOverview({summary, selectedView, setSelectedView}: Props) {
  const {total_usage_hours, total_access_hours} = summary;
  const hours_of_exposure = total_access_hours - total_usage_hours;
  const usagePercentage = total_usage_hours ? Math.round((total_usage_hours / total_access_hours) * 100) : 0;

  return (
    <div className="tr-flex tr-flex-col">
      <div className="tr-flex tr-items-center tr-gap-2">
        <Icon type={'M365' as ItemIconTypesEnum} size="lg" />
        <h3 className="tr-mb-0">M365 License Usage</h3>
        <div className="tr-ml-auto">
          <Select
            name="view"
            defaultValue={selectedView}
            value={selectedView}
            options={OPTIONS}
            onChange={(e: any) => {
              setSelectedView(e);
            }}
            required
          />
        </div>
      </div>
      <div className="tr-flex tr-p-4 ">
        <div className="tr-flex tr-flex-col tr-w-full tr-gap-2 tr-max-w-[50%] tr-px-3">
          {renderMetricLabel(`${total_usage_hours}(hrs)`, 'Total License Usage')}
          <div className="tr-w-full tr-bg-gray-200 tr-rounded-full tr-h-2.5 trbg-gray-700 tr-relative">
            <p className="tr-absolute tr-right-1 tr-bottom-1 tr-text-xs tr-font-medium ">Usage: {usagePercentage}%</p>
            <div className="tr-bg-teal-400 tr-h-2.5 tr-rounded-full" style={{width: `${usagePercentage}%`}}></div>
          </div>
        </div>
        <div className="tr-flex tr-gap-8 tr-border-gray-200 tr-border-[0px] tr-border-l-[2px] tr-border-solid tr-px-3">
          {renderMetricLabel(total_access_hours, 'Hours of Risk Exposure')}
          {renderMetricLabel(hours_of_exposure, 'Unused Hours')}
        </div>
      </div>
    </div>
  );
}

function renderMetricLabel(value: string | number, label: string) {
  return (
    <div className="tr-flex tr-flex-col">
      <h2 className="tr-mb-0">{value}</h2>
      <label className="tr-mb-0 tr-text-2xs tr-font-bold">{label}</label>
    </div>
  );
}
