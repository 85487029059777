import React from 'react';
import {ColumnDescription, Tooltip} from '@trustle/component-library';
import {formatDateFromNow, formatFullDate, formatServiceName, formatUserName, formatUserNameAndEmail} from 'src/lib';
import {ImportStateServiceE, ImportStateT, ImportStatus} from 'src/types';
import ConnectorStatus from 'src/components/StatusIndicator';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import {useRootStore} from 'src/lib/hooks';
import {Link} from 'react-router-dom';
import Countdown from 'react-countdown';

export enum ImportStateColumnE {
  STATUS = 'Status',
  SERVICE = 'Action',
  ACTOR = 'Initiator',
}

export const StatusCol: ColumnDescription<ImportStateT> = {
  dataField: 'status',
  text: ImportStateColumnE.STATUS,
  headerStyle: {width: '5%', textAlign: 'center'},
  style: {width: '5%', textAlign: 'center'},
  formatter: (cell) => (
    <>
      <ConnectorStatus className="tr-mr-3" status={cell} />
    </>
  ),
};

export const ServiceCol: ColumnDescription<ImportStateT> = {
  dataField: 'service',
  text: ImportStateColumnE.SERVICE,
  headerStyle: {width: '20%', textAlign: 'left'},
  style: {width: '20%', textAlign: 'left'},
  formatter: (cell) => formatServiceName(cell),
};

const ActorColFormatter = observer(({actor}: {actor: string}) => {
  const {usersStore} = useRootStore();
  const user = usersStore.usersMap[actor];

  if (!user) {
    return <div>System</div>;
  }
  return (
    <Tooltip content={formatUserNameAndEmail(user)} position="bottom">
      <Link to={`/users/${user.id}`}> {user && formatUserName(user)} </Link>
    </Tooltip>
  );
});

export const ActorCol: ColumnDescription<ImportStateT> = {
  dataField: 'actor',
  text: ImportStateColumnE.ACTOR,
  headerStyle: {width: '15%', textAlign: 'left'},
  style: {width: '15%', textAlign: 'left'},
  formatter: (actor) => <ActorColFormatter actor={actor} />,
};

export const CreatedCol: ColumnDescription<ImportStateT> = {
  dataField: 'created',
  text: 'Run At',
  headerStyle: {width: '7%', textAlign: 'left'},
  style: {width: '7%', textAlign: 'left'},
  formatter: (cell: any, _row: any) => (
    <Tooltip position="left" content={formatFullDate(cell)}>
      {formatDateFromNow(cell)}
    </Tooltip>
  ),
};

const isPendingUsageServiceImport = (importState: ImportStateT, lastImportServiceUsageStatus?: string) => {
  switch (importState.service) {
    case ImportStateServiceE.AWS_USAGE_SERVICE:
    case ImportStateServiceE.OKTA_USAGE_SERVICE: {
      return lastImportServiceUsageStatus === ImportStatus.PENDING;
    }
    default:
      return false;
  }
};

export const RetryInCol: ColumnDescription<ImportStateT, {lastImportServiceUsageStatus?: string}> = {
  dataField: 'id',
  text: 'Retrying in',
  headerStyle: {width: '7%', textAlign: 'left'},
  style: {width: '7%', textAlign: 'left'},
  formatter: (_cell, row, _index, formatExtraData) => {
    const nextPoll = row.jobs?.[0]?.runAt;
    if (!isPendingUsageServiceImport(row, formatExtraData?.lastImportServiceUsageStatus) || _.isNil(nextPoll)) {
      return <></>;
    }
    return (
      <Countdown daysInHours={true} date={nextPoll} precision={0}>
        <span>Retrieving data</span>
      </Countdown>
    );
  },
};

export const DetailsCol: ColumnDescription<ImportStateT, {lastImportServiceUsage?: ImportStateT}> = {
  dataField: 'state',
  text: 'Details',
  headerStyle: {width: '30%', textAlign: 'left'},
  style: {width: '30%', textAlign: 'left'},
  formatter: (cell, row, _index, formatExtraData) => {
    const serviceUsage = formatExtraData?.lastImportServiceUsage;
    const failedCount = serviceUsage?.progressStatus?.failedCount || 0;
    const inProgressCount = serviceUsage?.progressStatus?.inProgressCount || 0;
    const job = row.jobs?.[0];

    if (row.progressStatus?.error && row.progressStatus?.finished) {
      return row.progressStatus?.message;
    }

    if (_.isEmpty(job)) {
      return <></>;
    }
    if (failedCount + inProgressCount === 0) {
      return (
        <>
          Attempts: {job.attempts}/{job.maxAttempts}
        </>
      );
    }

    if (!isPendingUsageServiceImport(row, serviceUsage?.status)) {
      const {codeError, message} = cell?.state || {};
      return <div>{_.join(_.compact([codeError, message]), ' | ')}</div>;
    }

    return (
      <div className="mt-2">
        <ul>
          <li>Failed tasks: {failedCount}</li>
          <li>In progress Tasks: {inProgressCount}</li>
        </ul>
      </div>
    );
  },
};
