import React from 'react';
import {formatUserName} from 'src/lib';
import _ from 'lodash';
import {useRootStore} from 'src/lib/hooks';
import {Button, Icon, Tooltip, UserAvatar} from '@trustle/component-library';
import {AccountTabTypeE} from 'src/views/Resource/Account/ResourceAccounts';
import {taskTypeSchemaEnum} from './helpers';
import {Task} from 'src/stores/domainObjects/Task';

import {Link} from 'react-router-dom';

type TaskCardPropsT = {
  accessRequest: Task;
  isSelected?: boolean;
  onSelectedTaskChange: (selectedTask: Task) => void;
  className?: string;
};

function TaskCard(props: TaskCardPropsT) {
  const {currentUser} = useRootStore();

  if (_.isNil(currentUser)) {
    return <></>;
  }

  const {accessRequest, onSelectedTaskChange, isSelected = false, className} = props;
  const {assigneeIds, forSystem, forPermission, forResource} = accessRequest;

  const isAssigned = assigneeIds?.indexOf(currentUser.id) >= 0;
  const isOwner = forSystem ? forSystem.owners?.indexOf(currentUser.email) >= 0 : false;
  function getAssignedClasses() {
    return isAssigned ? AccountTabTypeE.ASSIGNED : '';
  }

  const ticketConfiguration = taskTypeSchemaEnum[accessRequest.calculatedType!];

  if (!ticketConfiguration) {
    return <></>;
  }

  const background = isSelected ? 'tr-bg-[#E9F4FD]' : 'tr-bg-transparent';
  const forUserName = `${formatUserName(accessRequest.forUser)}`;
  const isConnectionBroken = accessRequest.forSystem ? accessRequest.system.isConnectionBroken : false;
  const selectedBorder = isConnectionBroken ? 'border-red-outline' : 'tr-border-[#0091FF]';

  return (
    <div key={accessRequest.id}>
      <div
        key={`${accessRequest.id}taskitem`}
        className={`${[
          'tr-border-solid',
          'tr-border-[2px]',
          'tr-my-2',
          'tr-rounded-md',
          'tr-w-[97%]',
          'cursor-pointer',
          isSelected ? selectedBorder : 'tr-border-[#ccc]',
          background,
          'hover:tr-bg-slate-200',
          isOwner ? 'im-owner' : '',
          getAssignedClasses(),
        ].join(' ')} ${className} `}
        onClick={() => {
          onSelectedTaskChange(accessRequest);
        }}
        data-testid={accessRequest.id}
      >
        <ticketConfiguration.heading
          accessRequest={accessRequest}
          className={isConnectionBroken ? 'bg-red-lightSelected' : ''}
          prependTag={
            isConnectionBroken ? (
              <Tooltip content={`The connection for the system is broken`}>
                <div className="tr-flex tr-items-center">
                  <Icon type="unlinkedUser" className="tr-text-error" size="sm" title={'Broken connection'} />
                </div>
              </Tooltip>
            ) : undefined
          }
        />

        <div className="tr-flex tr-items-center tr-justify-start tr-px-2 tr-py-3">
          {!accessRequest.forSystem && <UserAvatar title={forUserName} text={forUserName} size="sm" />}
          <div className="tr-p-2">
            <ticketConfiguration.headingTitle accessRequest={accessRequest} />
          </div>
        </div>
        <div className="tr-px-1 tr-pt-1 tr-flex tr-align-center tr-justify-between tr-border-0 tr-border-gray-200 tr-border-t-[1px] tr-border-solid">
          {accessRequest.forSystem ? (
            <Link
              to={`/resource/manage/${forResource?.rootSid}/resources?Opened=${[
                forResource?.id,
                forResource?.rootSid,
                forPermission?.id,
              ].join(',')}`}
              className={` tr-px-1 tr-text-xs tr-flex-align tr-flex tr-flex-row tr-items-center `}
            >
              <img src={accessRequest.forSystem?.icon} height="20px" className="tr-mb-1 tr-mw-50" />
              <span className="tr-px-1" title={accessRequest.forSystem?.name}>
                {_.truncate(accessRequest.forSystem?.name, {length: 15})}
              </span>
              <span>{`>`}</span>
              {accessRequest.forSystem.id !== accessRequest.forResource?.id && (
                <>
                  <span className="tr-px-1" title={accessRequest.forResource?.name}>
                    {_.truncate(accessRequest.forResource?.name, {length: 15})}
                  </span>
                  <span>{`>`}</span>
                </>
              )}
              <span className="tr-px-1" title={accessRequest.forPermission?.label}>
                {_.truncate(accessRequest.forPermission?.label, {length: 15})}
              </span>
            </Link>
          ) : (
            <div></div>
          )}

          <Button variant="ternary" size="sm" onClick={onSelectedTaskChange}>
            <Icon type="chevronRight" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TaskCard;
