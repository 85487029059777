import axios from 'axios';
import React, {useContext} from 'react';
import {useState, useEffect} from 'react';
import {GlobalInfoContext} from 'src/context';
import {useHistory, useParams} from 'react-router-dom';
import SetupScreenTopByBottom from 'src/components/SetupScreenTopByBottom';
import SetupNav from 'src/components/SetupNav';
import {logger} from 'src/lib';
import _ from 'lodash';

function MagicLinkVerify() {
  const history = useHistory();
  const {token} = useParams<{token: string}>();
  const [error, setError] = useState<{message?: string}>();
  const globalInfo = useContext(GlobalInfoContext);

  useEffect(() => {
    void axios
      .post('/api/magic/conjure', {token})
      .then((res) => {
        const {magicInfo, nextStepUrl} = res.data.uxData;
        globalInfo.set('magicData', magicInfo);
        if (nextStepUrl?.startsWith('http')) {
          window.location.href = nextStepUrl;
        } else if (!_.isNil(nextStepUrl)) {
          history.push(nextStepUrl ?? '/home');
        }
        setError(magicInfo?.error);
      })
      .catch((err: any) => {
        logger.error(err);
        // avoid displaying technical error about response in UI
        err.message = 'An unknown error occurred.';
        setError(err);
      });
  }, []);

  if (error) {
    return (
      <>
        <SetupNav />
        <SetupScreenTopByBottom title="You clicked a magic link!" imageUrl="/russel-excited.svg">
          <p>{error?.message ?? 'An unknown error occurred.'}</p>
        </SetupScreenTopByBottom>
      </>
    );
  }

  return <></>;
}

export default MagicLinkVerify;
