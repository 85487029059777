import React, {useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, Icon, SystemIcons} from '@trustle/component-library';
import {useHistory} from 'react-router-dom';
import {useAuthority} from '../hooks/useAuthority';
import {ConfirmDisassociateAuthorityModal} from '../modals/ConfirmDisassociateAuthorityModal';
import {ConfirmDisableEnableAuthority} from '../modals/ConfirmDisableEnableAuthority';
import {CreateSelectAuthorityModal} from '../modals/create-select-authority/CreateSelectAuthorityModal';
import {SyncUsersModal} from '../modals/sync-users-modal/SyncUsersModal';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';

export function AuthoritySelector() {
  const {
    settings: authoritySettings,
    resource: authorityResource,
    isConfigured: isAuthorityConfigured,
    isDisabled: isAuthorityDisabled,
    updateAuthority,
  } = useAuthority();
  const history = useHistory();

  const [showConfirmDisassociateModal, setShowConfirmDisassociateModal] = useState<boolean>(false);
  const [showConfirmDisableEnableModal, setShowConfirmDisableEnableModal] = useState<boolean>(false);
  const [showCreateSelectAuthorityModal, setShowCreateSelectAuthorityModal] = useState<boolean>(false);
  const [showSyncUsersModal, setShowSyncUsersModal] = useState<boolean>(false);

  const syncUsersClasses = isAuthorityDisabled
    ? 'tr-cursor-not-allowed focus:tr-outline-none tr-bg-gray-background tr-border-[1px] tr-text-gray-400'
    : '';

  const authorityIcon = isAuthorityConfigured ? (
    <div className="relative">
      {isAuthorityDisabled && (
        <div className="tr-absolute tr-bg-red-500 tr-w-2 tr-h-2 tr-rounded-full tr-top-[-3px] tr-right-[-3px]" />
      )}
      <SystemIcons name={authoritySettings?.service as SystemIconsTypesEnum} />
    </div>
  ) : (
    <Icon type="Trustle" title="Trustle" forcedSize={32} />
  );

  const renderMenuItems = () => {
    if (isAuthorityConfigured) {
      return [
        <DropdownItem
          key="sync"
          onClick={() => {
            if (!isAuthorityDisabled) {
              setShowSyncUsersModal(true);
            }
          }}
          className={syncUsersClasses}
        >
          Sync Users
        </DropdownItem>,
        <DropdownItem
          key={'disassociate'}
          onClick={() => {
            setShowConfirmDisassociateModal(true);
          }}
        >
          <div className="tr-flex tr-items-center tr-justify-between">Disassociate</div>
        </DropdownItem>,
        <DropdownItem
          key={'configure'}
          onClick={() => {
            history.push(`/resource/manage/${authorityResource?.id}/settings/authority_settings`);
          }}
        >
          <div className="tr-flex tr-items-center tr-justify-between">Configure</div>
        </DropdownItem>,
        <hr key="separator" className="tr-m-0" />,
        <DropdownItem
          key={isAuthorityDisabled ? 'enable' : 'disable'}
          className={isAuthorityDisabled ? 'tr-text-trustle-primary' : 'tr-text-red-500'}
          onClick={() => {
            setShowConfirmDisableEnableModal(true);
          }}
        >
          {isAuthorityDisabled ? 'Enable Authority' : 'Disable Authority'}
        </DropdownItem>,
      ];
    } else {
      return [
        <DropdownItem
          key={'associate'}
          onClick={() => {
            setShowCreateSelectAuthorityModal(true);
          }}
        >
          <div className="tr-flex tr-items-center tr-justify-between">Associate Authority</div>
        </DropdownItem>,
      ];
    }
  };

  return (
    <div className="tr-flex tr-items-center">
      <p className="tr-m-0 tr-mr-2">Authority:</p>
      <Dropdown
        dropdownMenu={<DropdownMenu>{renderMenuItems()}</DropdownMenu>}
        variant={'secondary'}
        dropdownToggle={authorityIcon}
        size={'md'}
      />
      {showCreateSelectAuthorityModal && (
        <CreateSelectAuthorityModal onCancel={() => setShowCreateSelectAuthorityModal(false)} />
      )}
      {showConfirmDisassociateModal && (
        <ConfirmDisassociateAuthorityModal
          onConfirm={async () => {
            await updateAuthority({associated: false});
            setShowConfirmDisassociateModal(false);
          }}
          onCancel={() => setShowConfirmDisassociateModal(false)}
          name={authorityResource?.name}
        />
      )}
      {showConfirmDisableEnableModal && (
        <ConfirmDisableEnableAuthority
          onConfirm={async () => {
            await authorityResource?.updateConnectionSettings({disabled: !isAuthorityDisabled});
            setShowConfirmDisableEnableModal(false);
          }}
          onCancel={() => setShowConfirmDisableEnableModal(false)}
          enabled={isAuthorityDisabled}
        />
      )}
      {showSyncUsersModal && authorityResource && (
        <SyncUsersModal setShowModal={setShowSyncUsersModal} resource={authorityResource} />
      )}
    </div>
  );
}
