import _ from 'lodash';

export function groupBy<T>(
  collection: _.List<T> | null | undefined,
  iteratee?: _.ValueIteratee<T>
): _.Dictionary<[T, ...T[]]> {
  return _.groupBy(collection, iteratee) as _.Dictionary<[T, ...T[]]>;
}

/** load script with specified attributes if it doesn't already exist */
export function loadScript(attributes: Record<string, string>) {
  if (!document.querySelector(`script[src="${attributes.src}"]`)) {
    const script = document.createElement('script');
    Object.entries({async: 'true', ...attributes}).forEach(([key, value]) => script.setAttribute(key, value));

    document.body.appendChild(script);
    script.addEventListener('load', (_event: any) => {
      window.document.dispatchEvent(new Event('DOMContentLoaded', {bubbles: true, cancelable: true}));
    });
  }
}

export const getErrorMessage = (err: any): string => {
  return _.get(err, 'response.data.error.message') ?? err.message;
};

export function capitalizeFirstLetter(str: string) {
  if (str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}
