import {Field, useField} from 'formik';
import React from 'react';
import {Checkbox, Select, TextInput} from '@trustle/component-library';
import {FieldMappingOption, FieldMappingType} from '../helpers';

type Props = {
  fieldName: string;
  fieldLabel: string;
  supportRegex?: boolean;
  options: FieldMappingOption[];
  serviceFieldMappings: FieldMappingType;
  forceMandatory?: boolean;
  disableMandatory?: boolean;
};

export function AttributeMapField(props: Props) {
  const {
    fieldName,
    fieldLabel,
    options,
    supportRegex = false,
    serviceFieldMappings,
    forceMandatory = false,
    disableMandatory = false,
  } = props;
  const [field, , helpers] = useField(fieldName);
  const fieldValue = field.value || serviceFieldMappings[fieldName];
  return (
    <div className="tr-flex tr-gap-4" key={fieldName}>
      {!disableMandatory && (
        <Checkbox
          checked={forceMandatory || fieldValue.mandatory}
          onChange={(e: any) => {
            helpers.setValue({...fieldValue, mandatory: e.target.checked});
          }}
          disabled={forceMandatory || disableMandatory}
          className="tr-flex tr-flex-none tr-flex-row tr-items-center"
        />
      )}
      <Field component={TextInput} label="Attribute" value={fieldLabel} disabled className="tr-w-full" />
      <Field component={Select} name={`${fieldName}.name`} options={options} label="Map to" />
      {supportRegex && <Field component={TextInput} name={`${fieldName}.regex`} label="Regex" options={options} />}
    </div>
  );
}
