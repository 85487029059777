import React from 'react';
import {Icon} from '@trustle/component-library';

export const COLUMNS = [
  {
    dataField: 'displayName',
    text: 'Licenses',
    formatter: (displayName: string) => (
      <div className="tr-flex tr-items-center tr-gap-1">
        <Icon size="sm" type="licenses" color="white" />
        <span className="tr-text-trustle-link">{displayName}</span>
      </div>
    ),
  },
  // {
  //   dataField: 'accessHours',
  //   text: 'Access Hours',
  //   formatter: (accessHours: number) => (
  //     <span className="tr-font-bold tr-text-xs tr-text-trustle-primary">{accessHours}</span>
  //   ),
  // },
  // {
  //   dataField: 'usage',
  //   text: 'Usage',
  //   formatter: (usage: number) => {
  //     const usageColor = usage === 0 ? 'secondary' : usage < 50 ? 'danger' : usage < 75 ? 'primary' : 'success';
  //     return (
  //       <div className="tr-flex">
  //         <Badge variant={usageColor} pill className="tr-p-1">
  //           {usage === 0 ? 'N/A' : `${usage}%`}
  //         </Badge>
  //       </div>
  //     );
  //   },
  // },
  // {
  //   dataField: 'lastUsed',
  //   text: 'Last Used',
  //   formatter: (lastUsed: string) => (
  //     <span className=" tr-font-bold tr-text-xs tr-text-trustle-primary">{lastUsed}</span>
  //   ),
  // },
];
