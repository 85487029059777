import _ from 'lodash';
import {observer} from 'mobx-react';
import React from 'react';
import {Link} from 'react-router-dom';
import {formatUserNameAndEmail} from 'src/lib';
import {Task} from 'src/stores/domainObjects/Task';

const RequestorHeading = observer(({accessRequest}: {accessRequest: Task}) => {
  const {forResource, forPermission, forUser, startedBy} = accessRequest;
  const forUserName = formatUserNameAndEmail(forUser);
  const isSelf = startedBy?.id === forUser?.id;
  return (
    <div className="tr-text-sm">
      {!isSelf ? (
        <>
          <span className="tr-font-bold">{!_.isEmpty(startedBy) ? formatUserNameAndEmail(startedBy) : 'System'}</span>
          {` has requested access for `}
          <span className="tr-font-bold">{forUserName} </span>
          {` to `}
        </>
      ) : (
        <>
          <span className="tr-font-bold">{forUserName}</span>
          {` has requested access to `}
        </>
      )}
      <Link
        to={`/resource/manage/${forResource?.rootSid}/resources?Opened=${[
          forResource?.id,
          forResource?.rootSid,
          forPermission?.id,
        ].join(',')}`}
      >
        <span className="tr-font-bold">
          {forResource!.name}
          {` -> `}
          {forPermission!.label}
        </span>
      </Link>
    </div>
  );
});

export default RequestorHeading;
