import {observer} from 'mobx-react-lite';
import React from 'react';
import {ColumnDescription, Icon, SystemIcons} from '@trustle/component-library';
import {useRootStore} from 'src/lib/hooks';
import {User} from 'src/stores/domainObjects/User';
import {SourceOfTruthE} from 'src/types';
import _ from 'lodash';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';
export function ColumnArrow({order}: {order?: string}) {
  if (order === 'asc') {
    return <Icon type="chevronUp" />;
  }

  if (order === 'desc') {
    return <Icon type="chevronDown" />;
  }
  return null;
}

/** Shared Columns for use in User Tables */

export const AvatarCol: ColumnDescription<User> = {
  dataField: 'avatar',
  text: '',
  headerStyle: {width: '2.5rem', textAlign: 'left'},
  style: {width: '2.5rem', textAlign: 'left'},
};

const ObservableUser = observer(({user}: {user: User}) => {
  return <>{user.nameLabel}</>;
});

export const NameCol = ({columnWidth = '16rem'}: {columnWidth?: string}): ColumnDescription<User> => {
  const column: ColumnDescription<User> = {
    dataField: 'firstname',
    text: 'Name',
    sort: true,
    formatter: (_firstname, user: User) => (
      <div id="userNameCol" data-testid={`name${user.email}`}>
        <ObservableUser user={user} />
      </div>
    ),
    headerStyle: {width: columnWidth, textAlign: 'left', paddingLeft: '8px'},
    style: {width: columnWidth, textAlign: 'left', paddingLeft: '8px'},
  };

  return column;
};

const ObservableRole = observer(({user}: {user: User}) => {
  return <div data-testid={`${user.id} role`}>{user.isOrgOwner ? 'Owner' : 'User'}</div>;
});

export const RoleCol: ColumnDescription<User> = {
  dataField: 'isOrgOwner',
  sort: true,
  text: 'Trustle Role',
  formatter: (_isOrgOwner: boolean, user: User) => <ObservableRole user={user} />,
  headerStyle: {width: '7rem', textAlign: 'left'},
  style: {width: '7rem', textAlign: 'left'},
};

export const TypeCol: ColumnDescription<User> = {
  dataField: 'type',
  text: 'Trustle User Type',
  style: {width: '8.5rem', textAlign: 'left'},
  headerStyle: {width: '8.5rem', textAlign: 'left'},
  sort: true,
  formatter: (_type, user) => user.typeLabel,
};

export const Authority: ColumnDescription<User> = {
  dataField: 'sourceOfTruth',
  text: 'Authority',
  headerStyle: {width: '4rem', textAlign: 'center'},
  style: {width: '4rem'},
  sort: true,
  formatter: (_cell: any, u: User) => {
    const ObservableAuthority = observer((props: {user: User}) => {
      const {org} = useRootStore();
      const {user} = props;

      const isTrustle = _.defaultTo(user.sourceOfTruth, SourceOfTruthE.TRUSTLE) === SourceOfTruthE.TRUSTLE;
      const icon = isTrustle ? (
        <Icon type="Trustle" title="Trustle" forcedSize={32} />
      ) : (
        <SystemIcons name={org.idpSettings?.service as SystemIconsTypesEnum} size="sm" />
      );

      return (
        <div className="tr-flex tr-justify-center">
          <a href="#" title={u.authorityLabel}>
            {icon}
          </a>
        </div>
      );
    });

    return <ObservableAuthority user={u} />;
  },
  headerFormatter: (_column: any, _index: number, {sortElement}: any) => {
    const {order = 'x'} = sortElement.props;
    return (
      <div className={`sorting ${order}`}>
        Authority <span className={'subtitle'}></span>
        <ColumnArrow order={order} />
      </div>
    );
  },
  sortFunc: (value1: string, value2: string, order: string, _dataField: string, _user1: User, _user2: User) => {
    if (order === 'x') {
      return 0;
    }

    const a = _.isEmpty(value1) ? SourceOfTruthE.TRUSTLE : value1;
    const b = _.isEmpty(value2) ? SourceOfTruthE.TRUSTLE : value2;

    if (a === b) {
      return 0;
    }

    if (order === 'asc') {
      return a > b ? 1 : -1;
    } else {
      return a > b ? -1 : 1;
    }
  },
};

export const DepartmentRoleTypeCol: any = ({
  idpFeatureEnabled,
  columnSort,
  setColumnSort,
}: {
  idpFeatureEnabled: boolean;
  columnSort?: string;
  setColumnSort: (columnSort?: string) => void;
}): any => {
  const column = {
    dataField: 'department',
    sort: true,
    hidden: false,
    text: '',
    skip: !idpFeatureEnabled,
    headerStyle: {width: '13em', textAlign: 'left', overFlow: 'visible'},
    style: {width: '13em', textAlign: 'left'},
    formatter: (_field: string, record: any) => {
      const {department, remoteRole, title} = record;

      if (_.isEmpty(department) && _.isEmpty(remoteRole) && _.isEmpty(title)) {
        return <Icon type="emptyData" title="No data" size="sm" />;
      }

      return (
        <div className="tr-my-1 tr-flex-col">
          <div className={`font-weight-bold ${columnSort === 'department' && 'tr-bg-yellow-50'}`}>
            {department ?? '[No department defined]'}
          </div>
          <div className={`${columnSort === 'remoteRole' && 'tr-bg-yellow-50'}`}>
            {remoteRole ?? '[No role defined]'}
          </div>
          <div className={`tr-text-gray-600 ${columnSort === 'title' && 'tr-bg-yellow-50'}`}>
            {title ?? '[No title defined]'}
          </div>
        </div>
      );
    },
    headerFormatter: (_column: any, _index: number, {sortElement}: any) => {
      const {order = 'x'} = sortElement.props;

      const SortedColumn = (props: {columnName: string; displayName?: string}) => {
        const {columnName, displayName} = props;
        const title = displayName ?? _.startCase(columnName);

        return (
          <div className="tr-py-1" onClick={() => setColumnSort(order !== 'x' ? columnName : undefined)}>
            <span className={`tr-mx-1 ${columnSort === columnName && 'text-trustle-link'}`}>{title}</span>
          </div>
        );
      };

      return (
        <div className={`sorting ${order} tr-flex`}>
          <SortedColumn columnName="department" displayName="Dept" />
          <div className="tr-py-1">{'/'}</div>
          <SortedColumn columnName="remoteRole" displayName="Role" />
          <div className="tr-py-1">{'/'}</div>
          <SortedColumn columnName="title" />
          <div className="tr-ml-1 tr-flex align-items-center justify-center">
            <ColumnArrow order={order} />
          </div>
        </div>
      );
    },
    sortFunc: (_value1: string, _value2: string, order: string, _dataField: string, user1: User, user2: User) => {
      if (order === 'x') {
        return 0;
      }

      const getValue = (user: User) => _.trim(_.get(user, columnSort ?? '')) ?? 0;

      const value1 = getValue(user1);
      const value2 = getValue(user2);
      const empty1 = _.isEmpty(user1.department) && _.isEmpty(user1.remoteRole) && _.isEmpty(user1.title);
      const empty2 = _.isEmpty(user2.department) && _.isEmpty(user2.remoteRole) && _.isEmpty(user2.title);

      const first = order === 'asc' ? -1 : 1;
      const second = -1 * first;

      if (empty1 && empty2) {
        return 0;
      } else if (empty1 && !empty2) {
        return first;
      } else if (!empty1 && empty2) {
        return second;
      }

      return value1 < value2 ? first : second;
    },
  };
  return column;
};
