import React, {useState, useEffect} from 'react';
import './CustomReport.scss';
import {Resource} from 'src/stores/domainObjects/Resource';
import _ from 'lodash';
import ResourceMiniCard from 'src/components/design/card/resourceCard/ResourceMiniCard';
import {toJS} from 'mobx';
import {Loading, Table} from '@trustle/component-library';
import NoData from 'src/components/NoData';

const ResourceList = (props: {
  systems: Resource[];
  selectedSystems?: Resource[];
  onRemoveHandler?: (resource: Resource) => void;
  onRemoveAllHandler?: () => void;
  onChangeSelectedHandler?: (params: {
    initialSelected: Resource[] | undefined;
    finishSelected: Resource[] | undefined;
  }) => void;
  showSelectColumn?: boolean;
  showActionColumn?: boolean;
  statistics?: _.Dictionary<{users: number; permissions: number}>;
  statisticsLoading?: boolean;
}) => {
  const {
    systems,
    onRemoveHandler,
    onRemoveAllHandler,
    onChangeSelectedHandler,
    showSelectColumn = false,
    showActionColumn = false,
    selectedSystems = [],
    statistics = {},
    statisticsLoading = false,
  } = props;

  if (_.size(systems) === 0) {
    return (
      <div className="tr-py-3 tr-flex tr-flex-col tr-justify-between tr-items-center">
        <div className="tr-mb-3 tr-text-center">
          <NoData>No Systems selected</NoData>
        </div>
      </div>
    );
  }
  const resources = systems.map((r) => toJS({...r, connection: {service: r.type}} as unknown) as Resource);

  const selectedResourcesT = selectedSystems.map(
    (r) => toJS({...r, connection: {service: r.type}} as unknown) as Resource
  );

  const isManaging = true;
  const [selectedResources, setSelectedResources] = useState<Resource[]>(selectedResourcesT);

  useEffect(() => {
    if (onChangeSelectedHandler) {
      onChangeSelectedHandler({initialSelected: selectedResourcesT, finishSelected: selectedResources});
    }
  }, [selectedResources]);

  function toggleSelection(resource: Resource, checked: boolean) {
    if (checked) {
      const newSelectedResources = selectedResources.concat(resource);
      setSelectedResources(newSelectedResources);
    } else {
      const newSelectedResources = _.reject(selectedResources, (r: Resource) => r.id === resource.id);
      setSelectedResources(newSelectedResources);
    }
  }
  const selectRow = showSelectColumn
    ? {
        mode: 'checkbox' as const,
        hideSelectAll: !showSelectColumn,
        hideSelectColumn: !showSelectColumn,
        style: {background: 'rgba(0,81,155,0.1)'},
        selected: _.map(selectedResources, 'id'),
        onSelect: (row: Resource, isSelect: boolean) => {
          toggleSelection(row, isSelect);
        },
        onSelectAll: (isSelect: boolean, rows: Resource[]) => {
          const resources = isSelect ? rows : [];
          setSelectedResources(resources);
        },
      }
    : undefined;
  const columns: any = [
    {
      dataField: 'id',
      hidden: true,
      text: '',
    },
    {
      dataField: 'icon',
      text: '',
      sort: true,
      sortValue: (cell: any, row: any) => {
        return row.connection.service;
      },
      headerStyle: {width: '80px'},
      style: {width: '80px'},
      formatter: (cell: any, row: Resource) => {
        return <ResourceMiniCard resource={row} key={row.id} isManaging={isManaging} />;
      },
    },
    {
      dataField: 'name',
      text: 'System',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      classes: 'ellipsis-overflow',
      sortValue: (cell: any, row: any) => {
        return row.name;
      },
    },
    {
      dataField: 'id',
      text: 'Users',

      formatExtraData: {statistics, statisticsLoading},
      formatter: (_cell: any, row: any, _rowIndex: any, extraData: any) => {
        return extraData.statisticsLoading ? <Loading /> : extraData.statistics[row.id]?.users || '-';
      },
    },
    {
      dataField: 'id',
      text: 'Permissions',
      sort: true,
      formatExtraData: {statistics, statisticsLoading},
      formatter: (_cell: any, row: any, _rowIndex: any, extraData: any) => {
        return extraData.statisticsLoading ? <Loading /> : extraData.statistics[row.id]?.permissions || '-';
      },
    },
    {
      dataField: 'description',
      text: 'Description',
      align: 'left',
      headerAlign: 'left',
      sort: true,
      sortValue: (cell: any, row: any) => {
        return row.description;
      },
    },
    {
      dataField: 'action',
      text: '',
      sort: true,
      hidden: !showActionColumn,
      headerStyle: {width: '80px'},
      classes: 'cursor',
      style: {width: '80px'},
      formatter: (cell: any, row: Resource) => {
        return (
          <span
            className="body6 strong text-muted"
            onClick={() => {
              if (!_.isNil(onRemoveHandler)) {
                onRemoveHandler(row);
              }
            }}
          >
            Remove
          </span>
        );
      },
    },
  ];

  return (
    <Table
      id="setup-resources-table"
      data={resources}
      defaultSorted={[
        {
          dataField: 'resource.name',
          order: 'desc',
        },
      ]}
      selectRow={selectRow}
      tableKey={'resourcelist'}
      showClearSelectionBtn={true}
      onClearSelection={() => {
        if (onRemoveAllHandler) {
          onRemoveAllHandler();
        }
        setSelectedResources([]);
      }}
      columns={columns}
      striped={true}
    />
  );
};
export default ResourceList;
