import {observer} from 'mobx-react';

import React from 'react';
import {AccessStatusIcon, Icon, Loading} from '@trustle/component-library';
import _ from 'lodash';
import {PermissionBranchPropsT} from '../PermissionBranch';
import {HiddenOrLocked} from './ResourceBranchComponents';
import {PermissionWarning} from './PermissionWarning';
import {PermissionName} from './PermissionName';
import LockedOrProvision from '../LockedOrProvision';
import {useRootStore} from 'src/lib/hooks';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';

export const PermissionHeader = observer((props: PermissionBranchPropsT) => {
  const {permission, user, accessMode = false, isTreeView} = props;

  const {accessStore} = useRootStore();
  const cantActOnPermission = !permission.rootResource.userIsOwner || accessMode;

  function getHeaderIcon() {
    if (cantActOnPermission) {
      if (!isTreeView) {
        return <></>;
      }
      //Nr: Defer lazy loading unless completely necesary
      const accessRecords = props.visibleItems?.accesses ?? [];
      const filteredAccess: AccessRecord[] = _.compact(
        _.map(accessRecords, (accessRecord) => {
          const accessRecordItem = accessStore.accessMap[accessRecord];

          if (user && accessRecordItem.forAccount?.uid === user.id && accessRecordItem.pid === permission.id) {
            return accessRecordItem;
          }
        })
      );

      return (
        <>
          {permission.rootResource.loadingAccesses ? (
            <Loading />
          ) : (
            _.size(filteredAccess) > 0 && (
              <div className="tr-flex-col tr-flex tr-justify-center tr-items-start">
                {filteredAccess.map((currentAccess) => {
                  const title = `${currentAccess.forAccount?.account ?? 'Unlinked'}: ${currentAccess.status}`;

                  return (
                    <div
                      className=" tr-flex tr-justify-start tr-items-center tr-h-7"
                      title={title}
                      key={currentAccess.id}
                    >
                      <LockedOrProvision target={permission} />
                      <AccessStatusIcon
                        className="tr-mx-2"
                        size="sm"
                        title={title}
                        status={currentAccess.statusValue}
                      />
                      <p className={`tr-text-xs tr-truncate ${accessMode ? '' : 'tr-w-[100px]'} tr-m-0`}>
                        {currentAccess.forAccount?.account}
                      </p>
                    </div>
                  );
                })}
              </div>
            )
          )}
          {permission.calculatedHidden.value && (
            <div className="tr-ml-1 tr-mr-2 tr-flex tr-justify-center tr-items-center">
              <Icon type="hidden" title="This permission is hidden" />
            </div>
          )}
        </>
      );
    }

    return <HiddenOrLocked target={permission} />;
  }

  return (
    <>
      <div className="tr-ml-1 tr-mr-2 tr-flex tr-justify-center tr-items-center">
        <Icon type="permission" title={`Permission: ${permission.label}`} size="sm" />
      </div>
      {getHeaderIcon()}

      <PermissionWarning permission={permission} />
      <PermissionName permission={permission} accessMode={accessMode} />
    </>
  );
});
