import React, {useState} from 'react';
import ArchiveResourceModal from 'src/views/Resource/Edit/ArchiveResourceModal';
import UnarchiveResourceModal from 'src/views/Resource/Edit/UnarchiveResourceModal';
import {ConnectionServiceE} from 'src/types';
import {useToasts} from 'react-toast-notifications';
import {useHistory, useParams} from 'react-router-dom';
import ConfirmDeleteResourceModal from './Edit/ConfirmDeleteResourceModal';
import './SettingsHeader.scss';
import ConnectorStatus from 'src/components/StatusIndicator';
import {Button, Icon} from '@trustle/component-library';
import {formatFullDate} from 'src/lib';
import {observer} from 'mobx-react';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ResourceSettingsTabs} from './ResourceSettingsBase';
import {useRootStore} from 'src/lib/hooks';
import {useAuthority} from '../../connectors/common/authority/hooks/useAuthority';
import {SyncUsersButtonModal} from '../../connectors/common/authority/modals/sync-users-modal/SyncUsersButtonModal';

type SettingsHeaderPropsT = {
  resource: Resource;
  actionItems?: React.ReactNode[];
  canEditResource?: boolean;
  refresh: () => void;
};

const SettingsHeader = observer(function SettingsHeaderCmp(props: SettingsHeaderPropsT) {
  const {resource, actionItems = [], refresh, canEditResource} = props;
  const {currentUser} = useRootStore();
  const [showArchiveResourceModal, setShowArchiveResourceModal] = useState<boolean>(false);
  const [showUnarchiveResourceModal, setShowUnarchiveResourceModal] = useState<boolean>(false);
  const [showDeleteResourceModal, setShowDeleteResourceModal] = useState<boolean>(false);
  const {addToast} = useToasts();
  const history = useHistory();
  const {resource: authorityResource} = useAuthority();
  const {activeTab} = useParams() as {activeTab?: ResourceSettingsTabs};

  const isArchived = !!resource.archived;

  const headerButtons = (() => {
    if (!canEditResource && !currentUser.isOrgOwner) {
      return null;
    }

    if (!isArchived) {
      return (
        <Button
          variant="ternary"
          data-testid="archive-icon-enable"
          onClick={(e: any) => {
            e.preventDefault();
            setShowArchiveResourceModal(true);
          }}
        >
          <div className="flex tr-justify-center items-center">
            <span className="tr-ml-1 ">Archive</span>
            <Icon type="archived" title="Archived" />
          </div>
        </Button>
      );
    }
    return [
      <Button
        variant="ternary"
        key="unarchive"
        data-testid="unarchive-icon-enable"
        onClick={(e: any) => {
          e.preventDefault();
          setShowUnarchiveResourceModal(true);
        }}
        className="flex tr-justify-center items-center"
      >
        <div className="flex tr-justify-center items-center">
          <span className="tr-ml-1 ">Unarchive</span>
          <Icon type="unArchive" title="Unarchive System" />
        </div>
      </Button>,

      <Button
        variant="ternary"
        key="delete"
        data-testid="delete-icon-enable"
        onClick={(e: any) => {
          e.preventDefault();
          setShowDeleteResourceModal(true);
        }}
      >
        Delete
      </Button>,
    ];
  })();

  async function runImport() {
    void resource.startImport();
  }

  const {connector} = resource;

  const resourceTitle = (() => {
    if (!canEditResource || !connector || connector.service === ConnectionServiceE.GENERIC) {
      return <h2>{resource.name}</h2>;
    }
    const {lastImport} = connector;

    const importStatus = ((): {
      text: string;
      status: 'error' | 'warning' | 'success' | 'not-defined';
      icon: React.ReactNode;
      button: React.ReactNode;
    } => {
      if (!lastImport) {
        return {
          text: 'Finish configuration',
          status: 'warning',
          icon: <Icon type="unlinkedUser" />,
          button: (
            <Button
              variant="secondary"
              key="check"
              size="sm"
              onClick={() => {
                history.push(`/connect/reentry/${resource.connectionId}`);
              }}
            >
              Finish configuration
            </Button>
          ),
        };
      }

      const importButton = !resource.importing ? (
        <Button
          variant="secondary"
          key="importnow"
          disabled={resource.shouldDisableInteractions}
          title={
            resource.connector?.disabled
              ? `You couldn't start an import because the Connector was disabled`
              : 'Start Import'
          }
          onClick={runImport}
        >
          Import Now
        </Button>
      ) : (
        <></>
      );

      switch (lastImport.status) {
        case 'finished':
          return {
            text: `Last import: ${formatFullDate(lastImport.created)}`,
            status: 'success',
            icon: <Icon type={'linked'} />,
            button: importButton,
          };
        case 'pending':
          return {
            text: `Import in progress`,
            status: 'warning',
            icon: <Icon type={'linked'} />,
            button: importButton,
          };
        case 'failed':
          return {
            text: `Last import: ${formatFullDate(lastImport.created)}`,
            status: 'error',
            icon: <Icon type="unlinkedUser" />,
            button: (
              <>
                {importButton}
                {activeTab !== ResourceSettingsTabs.CONNECTION_EDIT && (
                  <Button
                    variant="secondary"
                    key="check"
                    size="sm"
                    onClick={() => {
                      history.push(`/resource/manage/${resource.id}/settings/edit_connection`);
                    }}
                  >
                    Check connection
                  </Button>
                )}
              </>
            ),
          };
        default:
          return {
            text: `Import failed`,
            status: 'error',
            icon: <Icon type="unlinkedUser" />,
            button: importButton,
          };
      }
    })();

    return (
      <div className="connector-resource-title">
        <span className="tr-ml-2">{importStatus.button}</span>
        {resource.id === authorityResource?.id && <SyncUsersButtonModal resource={authorityResource} />}
        <div className="tr-flex tr-ml-1">
          <ConnectorStatus status={importStatus.status}></ConnectorStatus>
          <span className=" body6 tooltip-text">{importStatus.text}</span>
        </div>
      </div>
    );
  })();

  return (
    <div className="tr-mt-3 tr-mb-2  settings-header tr-flex tr-items-center">
      <div className="tr-grow">{resourceTitle}</div>
      <div className="tr-flex tr-items-center tr-gap-2">
        {headerButtons}
        {actionItems}
      </div>

      {showArchiveResourceModal && (
        <ArchiveResourceModal
          resource={resource}
          closeModal={(resourceWasArchived: boolean) => {
            if (!resourceWasArchived) {
              setShowArchiveResourceModal(false);
              return;
            }

            history.push('/manage');
          }}
        />
      )}

      {showUnarchiveResourceModal && (
        <UnarchiveResourceModal
          resource={resource as Resource}
          closeModal={(resourceWasUnarchived: boolean) => {
            setShowUnarchiveResourceModal(false);
            if (!resourceWasUnarchived) {
              return;
            }
            refresh();
          }}
        />
      )}
      {showDeleteResourceModal && (
        <ConfirmDeleteResourceModal
          resource={resource}
          closeModal={(resourceWasDeleted: boolean) => {
            if (!resourceWasDeleted) {
              setShowDeleteResourceModal(false);
              return;
            }

            addToast(`${resource.name} has been deleted`, {
              appearance: 'success',
              autoDismiss: true,
            });
            history.push('/manage');
          }}
        />
      )}
    </div>
  );
});

export default SettingsHeader;
