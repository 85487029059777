import React from 'react';
import {UserStatus} from 'src/stores/usersStore';
import {AccountStatus, JWTTokenStateE} from 'src/types';
import {Tooltip} from '@trustle/component-library';
import {observer} from 'mobx-react';
export enum StatusE {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  NOT_DEFINED = 'not-defined',
  DISABLED = 'disabled',
  FINISHED = 'finished',
  PENDING = 'pending',
  FAILED = 'failed',
  ABORTED = 'aborted',
}

type PropsT = {
  status: StatusE | string | undefined;
  className?: string;
  messages?: string[];
};

export const translateTokenStatus = (status: JWTTokenStateE): StatusE => {
  return status === JWTTokenStateE.ACTIVE
    ? StatusE.SUCCESS
    : status === JWTTokenStateE.DISABLED
    ? StatusE.FAILED
    : status === JWTTokenStateE.REVOKED
    ? StatusE.DISABLED
    : StatusE.PENDING;
};

export const translateAccountStatus = (status: AccountStatus): StatusE => {
  return status === AccountStatus.ACTIVE
    ? StatusE.SUCCESS
    : status === AccountStatus.BLOCKED
    ? StatusE.WARNING
    : status === AccountStatus.SUSPENDED
    ? StatusE.DISABLED
    : StatusE.FAILED;
};

export const translateStatus = (status: UserStatus): StatusE => {
  return status === UserStatus.ACTIVE
    ? StatusE.SUCCESS
    : status === UserStatus.DISABLED
    ? StatusE.DISABLED
    : status === UserStatus.PENDING_APPROVAL
    ? StatusE.PENDING
    : StatusE.FAILED;
};

const StatusIndicator = observer(function StatusIndicator(props: PropsT) {
  const {status, messages} = props;

  const statusVariant: Record<StatusE, string> = {
    [StatusE.SUCCESS]: 'tr-bg-status-success',
    [StatusE.PENDING]: 'tr-bg-status-pending',
    [StatusE.FAILED]: 'tr-bg-status-failed',
    [StatusE.DISABLED]: 'tr-bg-status-disabled',
    [StatusE.ERROR]: 'tr-bg-status-failed',
    [StatusE.WARNING]: 'tr-bg-status-pending',
    [StatusE.NOT_DEFINED]: 'tr-bg-status-disabled',
    [StatusE.FINISHED]: 'tr-bg-status-success',
    [StatusE.ABORTED]: 'tr-bg-status-failed',
  };
  const dotColor = status ? statusVariant[status as StatusE] : 'tr-bg-status-disabled';

  const messagesContent = (
    <div className="tr-flex tr-flex-row tr-items-center">
      <div>
        {messages?.map((message) => {
          return (
            <div key="err" className="tr-flex tr-flex-col tr-items-center">
              {message}
            </div>
          );
        })}
      </div>
    </div>
  );

  const statusDot = (
    <div data-testid="status-dot" className={`tr-w-[9px] tr-h-[9px] ${dotColor} tr-rounded-full`}></div>
  );

  const content = messages ? (
    <Tooltip position="bottom" content={messagesContent}>
      {statusDot}
    </Tooltip>
  ) : (
    statusDot
  );

  return <div className="tr-flex tr-items-center tr-space-x-2 tr-w-6 tr-justify-center">{content}</div>;
});

export default StatusIndicator;
