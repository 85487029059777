import React, {useState} from 'react';
import {Formik} from 'src/components/design/formik';
import {SourceOfTruthE, UserType} from 'src/types';
import _ from 'lodash';
import {useRootStore} from 'src/lib/hooks';
import {AuthPolicyDisplayNameMap} from 'src/views/admin/authSettings/authenticationTypes';
import {Button, Select, Tab, TextInput, UserAvatarAndName} from '@trustle/component-library';
import {Field, Form} from 'formik';
import {object, string} from 'yup';
import {User} from 'src/stores/domainObjects/User';
import {observer} from 'mobx-react-lite';
import {UserStatus} from 'src/stores/usersStore';
import {formatUserName} from 'src/lib';

type EditNamePropsT = {
  user: User;
  onClose: () => void;
};

const typeOptions = _.map(UserType, (type) => {
  return {label: _.startCase(type), value: type};
});

const allowLoginOptions = [
  {label: 'Yes', value: 'yes'},
  {label: 'No', value: 'no'},
];

const isOrgOwnerOptions = [
  {label: 'Org Owner', value: 'owner'},
  {label: 'User', value: 'user'},
];

const EditProfileForm = observer(({onClose, user: userToEdit}: EditNamePropsT) => {
  const {usersStore, currentUser} = useRootStore();
  const [passwordReset, setPasswordReset] = useState(false);

  if (_.isNil(currentUser)) {
    return <></>;
  }
  const isEmployee = userToEdit.type === UserType.employee;
  const isActive = userToEdit.status === UserStatus.ACTIVE;
  const isAuthWithPass = userToEdit.loginMethod === 'password';

  const isEditableBySource = _.defaultTo(userToEdit.sourceOfTruth, SourceOfTruthE.TRUSTLE) === SourceOfTruthE.TRUSTLE;

  const currentUserAllowedToEdit =
    currentUser.isOrgOwner || userToEdit.managerUid === currentUser.id || userToEdit.id === currentUser.id;

  const fieldCanBeEdited = currentUserAllowedToEdit && isEditableBySource;

  const roleCanBeEdited =
    (currentUser.isOrgOwner || userToEdit.managerUid === currentUser.id) &&
    isEditableBySource &&
    isEmployee &&
    isActive &&
    userToEdit.id !== currentUser.id;

  return (
    <Formik
      initialValues={{
        firstname: userToEdit.firstname,
        lastname: userToEdit.lastname,
        email: userToEdit.email,
        department: userToEdit.department,
        remoteRole: userToEdit.remoteRole,
        title: userToEdit.title,
        allowLogin: userToEdit.allowLogin ? 'yes' : 'no',
        type: (userToEdit.type ?? UserType.employee) as UserType,
        loginMethod: _.get(AuthPolicyDisplayNameMap, currentUser.loginMethod!, currentUser.loginMethod),
        manager: userToEdit.manager?.id ?? undefined,
        role: userToEdit.isOrgOwner ? 'owner' : 'user',
      }}
      validationSchema={object({
        firstname: string().required('This field is required'),
        lastname: string().required('This field is required'),
        email: string().email('invalid email').required('This field is required'),
      })}
      onSubmit={async ({
        firstname,
        lastname,
        email,
        allowLogin,
        type,
        manager,
        department,
        remoteRole,
        title,
        role,
      }) => {
        await userToEdit.update({
          firstname,
          lastname,
          email,
          allowLogin: allowLogin === 'yes',
          type,
          managerUid: manager,
          department,
          remoteRole,
          title,
          isOrgOwner: role === 'owner',
        });
        onClose();
      }}
    >
      {({isValid, dirty, values, setFieldValue, isSubmitting}) => (
        <Form>
          <Tab.Group style={{minHeight: '450px'}}>
            <Tab.List className="tr-pb-4">
              <Tab key="1">Basic Info</Tab>
              <Tab key="2" data-testid="tabLoginInfo">
                Login Info
              </Tab>
              <Tab key="3" data-testid="tabRoleAccess">
                Role & Access
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel key="1">
                <Field
                  className="tr-pb-4"
                  component={TextInput}
                  label="First Name"
                  name="firstname"
                  disabled={!fieldCanBeEdited}
                  required
                />
                <Field
                  className="tr-pb-4"
                  component={TextInput}
                  label="Last Name"
                  name="lastname"
                  width={15}
                  disabled={!fieldCanBeEdited}
                  required
                />
                <Field
                  className="tr-pb-4"
                  component={TextInput}
                  label="Username"
                  name="email"
                  width={15}
                  required
                  disabled={!fieldCanBeEdited}
                />
                <Field
                  className="tr-pb-4"
                  component={TextInput}
                  label="Department"
                  name="department"
                  disabled={!fieldCanBeEdited}
                />
                <Field
                  className="tr-pb-4"
                  component={TextInput}
                  label="Org Role"
                  name="remoteRole"
                  disabled={!fieldCanBeEdited}
                />
                <Field
                  className="tr-pb-4"
                  component={TextInput}
                  label="Title"
                  name="title"
                  disabled={!fieldCanBeEdited}
                />
              </Tab.Panel>
              <Tab.Panel key="2" className="tr-flex tr-items-end">
                <Field
                  className="tr-pb-0"
                  component={TextInput}
                  label="Login Method"
                  name="loginMethod"
                  disabled={true}
                />
                {isAuthWithPass && isActive && fieldCanBeEdited && (
                  // hack because comp-lib Button has unoverridable styles
                  <div className="tr-mt-2 tr-ml-2">
                    <Button
                      className="tr-h-[42px]"
                      variant="secondary"
                      onClick={() => void usersStore.resetUserPwd(userToEdit.id).then(() => setPasswordReset(true))}
                      disabled={passwordReset}
                    >
                      {passwordReset ? 'Password Has been Reset' : 'Reset Password'}
                    </Button>
                  </div>
                )}
              </Tab.Panel>
              <Tab.Panel key="3">
                <Field
                  className="tr-pb-4"
                  component={Select}
                  label="Role"
                  name="role"
                  options={isOrgOwnerOptions}
                  isDisabled={!roleCanBeEdited}
                />
                <Field
                  component={Select}
                  className="tr-pb-4"
                  label="User Type"
                  name="type"
                  options={typeOptions}
                  onChange={({value}: {value: UserType}) => {
                    if ([UserType.customer, UserType.system].includes(value)) {
                      setFieldValue('allowLogin', 'false', false);
                    }
                  }}
                  isDisabled={!fieldCanBeEdited}
                />
                <Field
                  component={Select}
                  className="tr-pb-4"
                  label="Allow Login"
                  name="allowLogin"
                  options={allowLoginOptions}
                  isDisabled={
                    !fieldCanBeEdited || (values?.type && [UserType.customer, UserType.system].includes(values.type))
                  }
                />
                <Field
                  component={Select}
                  className="tr-pb-4"
                  label="Manager"
                  name="manager"
                  options={usersStore.activeUsers.filter((u) => u.id !== userToEdit.id)}
                  formatOptionLabel={(user: User) => <UserAvatarAndName displayName={formatUserName(user)} />}
                  filterOption={(option: {data: User}, searchText: string) => {
                    const {firstname, lastname, email} = option.data;
                    return `${firstname?.toLowerCase() ?? ''}${lastname?.toLowerCase() ?? ''}${
                      email?.toLowerCase() ?? ''
                    }`.includes(searchText);
                  }}
                  isDisabled={!fieldCanBeEdited}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
          <div className={`pt-3 tr-flex tr-flex-row${fieldCanBeEdited ? '' : '-reverse'} tr-justify-between -tr-mx-1`}>
            <Button variant="secondary" onClick={onClose}>
              {`${!fieldCanBeEdited ? 'Close' : 'Cancel'}`}
            </Button>
            {fieldCanBeEdited && (
              <Button type="submit" disabled={!fieldCanBeEdited || !isValid || !dirty || isSubmitting}>
                Save
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
});

export default EditProfileForm;
